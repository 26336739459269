import { createContext, useState } from "react";

const tabs = [
  { name: "All" },
  { name: "Food Court" },
  { name: "Food Shop" },
  { name: "Staff Canteen" },
  { name: "Hawker" },
];

export const LocationsTabsContext = createContext();
export const LocationsCurrentTabContext = createContext();

export const LocationsContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <LocationsTabsContext.Provider value={{ tabs }}>
      <LocationsCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </LocationsCurrentTabContext.Provider>
    </LocationsTabsContext.Provider>
  );
};
