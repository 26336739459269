import axios from "axios";
import { Additem } from "iconsax-react";
import { useParams } from "react-router-dom";
import commonConfigs from "../../../../../config/config";
import { useContext, useState, useEffect, useMemo } from "react";
import { DocumentDownload } from "iconsax-react";
import * as FileSaver from 'file-saver';
import Excel from "exceljs";
import bwipjs from 'bwip-js';
import Breadcrumbs from "../../../../../commons/components/breadcrumbs";
import Table from "../../../../../commons/components/table";
import Spinner from "../../../../../commons/components/spinner";

const VoucherDetailsPage = () => {
  let {voucherId, voucherType, voucherCode} = useParams();

  const pages = [
    { name: "Marketing", href: "/marketing/loyaltyprogramme", current: false },
    {
      name: "Loyalty Programme & eVouchers",
      href: "/marketing/loyaltyprogramme",
      current: false,
    },
    { name: voucherCode, href: `/marketing/loyaltyprogramme/voucher/${voucherId}/${voucherType}/${voucherCode}`, current: true },
  ];
  
  // Urls
  const getVoucherRedemptionDetails = commonConfigs.apiUrls.getVoucherRedemptionDetails(voucherId,voucherType);
  //console.log("voucher crud tier 2 url",getVoucherRedemptionDetails)
  
  // Data
  // const [addOnsData, setAddOnsData] = useState(null);
  // const [prefsData, setPrefsData] = useState(null);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [exportData, setExportData] = useState(null);

  //const [rowToEdit, setRowToEdit] = useState();

  useEffect(() => {
    async function getData() {
      await axios
        .get(getVoucherRedemptionDetails, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data.redemption_details);
          setData(res.data.result.data.redemption_details);
          setExportData(res.data.result.data.redemption_details);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Voucher Serialization ID",
      accessor: "voucher_serialization_id",
    },
    {
      Header: "Voucher SN Code",
      accessor: "voucher_sn_code",
    },
    {
      Header: "Redeemed by",
      accessor: "redeemed_by",
    },

  ]);
  
  const downloadFiles = () => {
    const workbook = new Excel.Workbook();
    console.log("Export Data: " + exportData);

    const generateBarcodeDataURL = (barcodeValue) => {
      const canvas = document.createElement('canvas');
      bwipjs.toCanvas(canvas, { bcid: 'code128', text: barcodeValue });
      return canvas.toDataURL('image/png');
    };

    const generateQRCodeDataURL = (qrValue) => {
      const canvas = document.createElement('canvas');
      bwipjs.toCanvas(canvas, { bcid: 'qrcode', text: qrValue, scale: 5 });
      return canvas.toDataURL('image/png');
    };

    const highlightColor = "F892FFFF";
    const fillStyle = Excel.Fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: highlightColor },
    };
    const worksheet = workbook.addWorksheet("Vouchers", {
      properties: { defaultColWidth: 35},
    });
  
    // Title
    worksheet.unMergeCells("A1:F1");
  
    worksheet.mergeCells("A1:F1");
    worksheet.getCell("A1").font = { size: 18, bold: true, underline: true };
    worksheet.getCell("A1").alignment = { vertical: "middle", horizontal: "center" };
    worksheet.getCell("A1").value = "Serialized Voucher Code";
  
    // Items
    worksheet.getRow(4).font = { size: 14, bold: true };
    worksheet.getRow(4).alignment = { vertical: "middle", horizontal: "center", wrapText: true };
    worksheet.getRow(4).values = [
      "VOUCHER SERIALIZATION ID",
      "VOUCHER SN CODE",
      "REDEEMED BY",
      "BARCODE",
      "QR CODE"
    ];
    for (let i = 1; i <= 5; i++) {  // Updated loop condition
      worksheet.getRow(4).getCell(i).fill = fillStyle;
    }
    let row = 5;
    for (const item of exportData) {
      worksheet.getRow(row).alignment = { vertical: "middle", horizontal: "center", wrapText: true };
      worksheet.getRow(row).font = { size: 14 };
      worksheet.getRow(row).values = [
        item.voucher_serialization_id,
        item.voucher_sn_code,
        item.redeemed_by || "-",
      ];

      const barcodeDataURL = generateBarcodeDataURL(item.voucher_sn_code);
      const imageId = workbook.addImage({ base64: barcodeDataURL, extension: 'png' });
      worksheet.addImage(imageId, 
        {
        tl: { col: 3.26, row: row - 0.1 },
        ext: { width: 220, height: 60 },
        editAs: 'twoCell'
      }
    );
      
      
      const qrCodeDataURL = generateQRCodeDataURL(item.voucher_sn_code);
      const qrImageId = workbook.addImage({ base64: qrCodeDataURL, extension: 'png' });
      worksheet.addImage(qrImageId, 
        {
        tl: { col: 4.5, row: row - 0.2 }, 
        ext: { width: 180, height: 180 }, 
        editAs: 'twoCell'
      }
    );
      worksheet.getRow(row).height = 150 / 0.75;
      
      row++;

      // Add borders
      for (let j = 1; j <= 5; j++) {  // Updated loop condition
        worksheet.getRow(row - 1).getCell(j).border = getBorderStyle();
      }
    }

    const fileName = `Serialized_Voucher_Code.xlsx`;
    const filePath = `${fileName}`;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: fileType });
      FileSaver.saveAs(blob, filePath);
    });
    return { filePath, fileName };

    function getBorderStyle() {
      return {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    }
  }

  return (
    <div>
      <div className="w-full p-6">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <Breadcrumbs pages={pages} />
          </div>
        </div>
         {/* voucher details here  */}
        <div className="w-full desktop:p-6">
          <div style={{paddingTop: '20px'}}>
            <div className="sm:flex sm:items-center sm:justify-end" style={{ position: 'relative', width: '97.6%' }}>
              <button
                type="button"
                className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
                onClick={() => downloadFiles()}
              >
                Download Data <DocumentDownload className="ml-3" />
              </button>
            </div>
            {data != null ? (
              <Table
                columns={columns}
                data={data}
                filters={columns.map((column) => column.accessor)}
              />
              //add keys to .map
            ) : (
              <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
                <div className="flex place-content-center w-full">
                  <Spinner className="w-20 h-20" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>

    // /* voucher details here */
    // <div className="w-full desktop:p-6">
    //   <div style={{paddingTop: '20px'}}>
    //     {data != null ? (
    //       <Table
    //         columns={columns}
    //         data={data}
    //         filters={columns.map((column) => column.accessor)}
    //       />
    //       //add keys to .map
    //     ) : (
    //       <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
    //         <div className="flex place-content-center w-full">
    //           <Spinner className="w-20 h-20" />
    //         </div>
    //       </div>
    //     )}
    //   </div>
    // </div>
  );
};

export default VoucherDetailsPage;
