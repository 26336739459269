import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";

export default function SurveyCard() {

  const [surveyQuestions, setSurveyQuestions] = useState([]);
  
  async function fetchSurveyQuestions() {
    var questions = [
      {
        title: "Question",
      },
      {
        title: "Question",
      },
      {
        title: "Question",
      },
      {
        title: "Question",
      },
    ];
    setSurveyQuestions(questions);
  }

  useEffect(() => {
    fetchSurveyQuestions().catch((err) => {
      console.log("err: " + err);
    });
  }, []);

  return (
    <div>
      <h3 className="text-lg">Survey Response</h3>

      <div>
        <p className="text-yellow-800">
          *Note: Click into each survey to view response statistics
        </p>
        {surveyQuestions.map((question) => (
          <div key={question.title}>
            <div className="flex mt-4 ">
              <div className="text-base mt-1">{question.title}</div>
            </div>
            <div className="w-full border-t border-gray-300 mt-4"></div>
          </div>
        ))}
      </div>
    </div>   
  );
}
