import { IncidentReportContextProvider } from "../context/incidentReportContext";
import IncidentReportLayout from "./incidentReportLayout";

const IncidentReportPage = () => {
  return (
    <IncidentReportContextProvider>
      <IncidentReportLayout />
    </IncidentReportContextProvider>
  );
};

export default IncidentReportPage;
