import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Table from "../../../../commons/components/table";
import Spinner from "../../../../commons/components/spinner";
// import { format } from "date-fns";
import commonConfigs from "../../../../config/config";
import axios from "axios";
// import { Data } from "iconsax-react";



// export default function MainMenuLayout({ data, load, setLoad }) {
export default function MainMenuLayout() {
  let { merchantId } = useParams();

  const [data, setData] = useState(null);
  // const [selectedRow, setSelectedRow] = useState(null);
  const [load, setLoad] = useState(true);

  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 100000); // 10 seconds in milliseconds

    return () => clearTimeout(timer);
  }, []);

  async function fetchData() {
    const GetMerchantTimecards = commonConfigs.apiUrls.GetMerchantTimecards(merchantId);

    await axios
      .get(GetMerchantTimecards, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (load) {
      fetchData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Time In",
        accessor: ({ time_in_datetime }) => {
          if (time_in_datetime === 'Absent') {
            return 'Absent';
          }
          return time_in_datetime && time_in_datetime !== "-" ? time_in_datetime.substring(11, 19) : "-";
        },
      },
      {
        Header: "Time Out",
        accessor: ({ time_out_datetime }) => {
          if (time_out_datetime === 'Absent') {
            return 'Absent';
          }
          return time_out_datetime && time_out_datetime !== "-" ? time_out_datetime.substring(11, 19) : "-";
        },
      },
      {
        Header: "Attendance Duration",
        accessor: "attendance_duration",
      },
      {
        Header: "Attendance Status",
        Cell: ({ row }) => {
          const timeInDatetime = row.original.time_in_datetime;
          const timeOutDatetime = row.original.time_out_datetime;
          const attendanceDuration = row.original.attendance_duration;
          
          if (timeInDatetime === 'Absent') {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                Absent
              </div>
            );
          } else if (timeInDatetime !== 'Absent' && timeOutDatetime === 'Absent') {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                Pending
              </div>
            );
          } else if (attendanceDuration < 8) {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-amber-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                {"<8Hrs"}
              </div>
            );
          } else if (attendanceDuration >= 8) {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                {">8Hrs"}
              </div>
            );
          }
        },
      },
    ],
    []
  );

  if (data) {
    return (
      <div>
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      </div>
    );
  } else {
    return (
      <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
        <div className="flex place-content-center w-full">
          {showSpinner ? (
            <Spinner className="w-20 h-20" />
          ) : (
            'There are no Timecards for this merchant.'
          )}
        </div>
      </div>
    );
  }
}
