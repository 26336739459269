// import { getAuth, sendSignInLinkToEmail } from "firebase/app";
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import {
    getAuth,
    sendSignInLinkToEmail,
} from 'firebase/auth';
import firebaseConfig from '../../config/firebaseConfig'
import { initializeApp } from 'firebase/app'

import { hubCommonUrl, org_id } from '../../config/config';

class EmailService {
    emailVerification(email, mobile) {
        initializeApp(firebaseConfig)
        var url = hubCommonUrl + '/payeatfwd/employees/verification/' + org_id + '/activate/'+ encodeURIComponent(email) + '/' + encodeURIComponent(mobile);
        const auth = getAuth();
        console.log(url);
        return sendSignInLinkToEmail(auth, email, {
            url: url,
            // This must be true.
            handleCodeInApp: true,
        })
    }
}
export default new EmailService();