import { EmployeeContextProvider } from "../context/employeeTimeInOutContext";

import ScheduleDate from "./scheduleDateLayout";

const ScheduleDateLayout = () => {
  return (
    <div className="w-full bg-white">
      <EmployeeContextProvider>
        <ScheduleDate />
      </EmployeeContextProvider>
    </div>
  );
};

export default ScheduleDateLayout;
