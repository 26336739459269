import { SupplierContextProvider } from "../context/supplierContext";
import SupplierLayout from "./supplierLayout";

const SupplierPage = () => {
  return (
    <SupplierContextProvider>
      <SupplierLayout />
    </SupplierContextProvider>
  );
};

export default SupplierPage;
