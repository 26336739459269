/**
 * @desc Codes to display multiple data from an array in a single cell
 * @param {Array} props.data Array of strings to be processed
 * @returns {Array} Returns the new array of strings and commas between each element of strings.
 * @example
 *
 * array["Halal", "Indian"]
 * => ["Halal", ", ", "Indian"]
 *
 */
export default function MultipleString(props) {
  let result = [];
  if (props.data.length > 0) {
    for (var i = 0; i < props.data.length; i++) {
      result.push(props.data[i]);
      if (i + 1 < props.data.length) {
        result.push(", ");
      }
    }
  }
  return result;
}
