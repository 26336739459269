import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import OrderCard from "../components/orderCard";

const user = JSON.parse(localStorage.getItem("user"))

export default function GPOCard() {

  const [GPOData, setGPO] = useState(null);

  async function fetchGPO() {

    let getGPODonut = commonConfigs.apiUrls.GetGPODonut();
    if(user.access_multiple_location == 0 && user.location_id) {
      getGPODonut = getGPODonut + `&location_id=${user.location_id}`
    }

    await axios
      .get(getGPODonut, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);

        setGPO(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchGPO().catch((err) => {
      console.log("err: " + err);
    });
  }, []);


  return (
    <div style={{height:'inherit', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
      {GPOData && (
        <OrderCard
          title="Group Purchase Orders"
          subtitle={`$${GPOData.reduce((total, item) => total + item.gpo_total_amt, 0).toFixed(2)}`}
          data={GPOData.map((item) => ({
            name: item.division_name,
            value: item.gpo_total_amt,
          }))}
        />
      )}
    </div>   
  );
}
