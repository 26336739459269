import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

export const options = {
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        boxWidth:8 
      }
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

// const labels = ["NYP", "Ngee Ann Poly", "Bukit Canberra Hawker Centre (Official)"];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Open',
//       data: [1, 30, 44],
//       backgroundColor: "#4D73A8",
//     },
//     {
//       label: 'Closed',
//       data: [0, 0, 0],
//       backgroundColor: "#D45F75",
//     }
//   ],
// };

const DashboardPointsBarChart = ({ data }) => {
  console.log(data);
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const ds = [
      {
        label: 'Open',
        data: [],
        backgroundColor: "#4D73A8",
      },
      {
        label: 'Closed',
        data: [],
        backgroundColor: "#D45F75",
      }
    ];

    data.forEach(function (item, i) {
      // Inside value into bar chart.
      item.values.forEach((itemValue) => {
        ds.forEach((dataset) => {
          if (dataset.label == itemValue.name) {
            dataset.data.push(itemValue.value);
          }
        });
      });

      // Put 0 if no value.
      ds.forEach((dataset) => {
        if (dataset.data.length != i + 1) {
          dataset.data.push(0);
        }
      });
    });

    setGraphData({
      labels: data.map((item) => item.name),
      datasets: ds,
    });
  }, []);

  return <Bar data={graphData} options={options} />;
};

export default DashboardPointsBarChart;
