import { useState, useMemo, useEffect } from "react";
import NetsQRCreateModal from "../components/NETSQR/createNetsQRPaymentOptionModal";
import NetsQRUpdateModal from "../components/NETSQR/updateNetsQRPaymentOptionModal";
import NetsQRDeleteModal from "../components/NETSQR/deleteNetsQRPaymentOption";
import Table from "../../../../commons/components/table";
import { Additem, Edit, Trash } from "iconsax-react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import { format } from "date-fns";
import Spinner from "../../../../commons/components/spinner";


const NetsQRLayout = (props) => {
  const getNetsQRPaymentOptionUrl = commonConfigs.apiUrls.GetPaymentOptions(props.merchantId);

  const [isOpen, setOpen] = useState(false);
  const [availablePaymentMode, setAvailablePaymentMode] = useState([])
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  useEffect(() => {
    async function getData() {
      await axios
        .get(getNetsQRPaymentOptionUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          let result = res.data.result.data;
          let formattedDataArray = [];
          if (result.payment_credentials[0].payment_mode == 'Primary' && Object.keys(result.payment_credentials[0]).length > 1) {
            const {
              merchant_mid_nets_qr,
              merchant_tid_nets_qr,
              merchant_scrt_key_nets_qr,
              merchant_api_key_nets_qr
            } = result.payment_credentials[0];
            const allNull = [merchant_mid_nets_qr, merchant_tid_nets_qr, merchant_scrt_key_nets_qr, merchant_api_key_nets_qr].every(value => value === null || value === undefined);
            if(!allNull) {
              formattedDataArray.push({
                payment_mode: result.payment_credentials[0].payment_mode,
                merchant_mid_nets_qr: result.payment_credentials[0].merchant_mid_nets_qr,
                merchant_tid_nets_qr: result.payment_credentials[0].merchant_tid_nets_qr,
                merchant_scrt_key_nets_qr: result.payment_credentials[0].merchant_scrt_key_nets_qr,
                merchant_api_key_nets_qr: result.payment_credentials[0].merchant_api_key_nets_qr,
              })
            } else {
              setAvailablePaymentMode(oldArr => [...oldArr, result.payment_credentials[0].payment_mode])
            }
          } else {
            setAvailablePaymentMode(oldArr => [...oldArr, result.payment_credentials[0].payment_mode])
          }
          if (result.payment_credentials[1].payment_mode == 'Alternate' && Object.keys(result.payment_credentials[1]).length > 1) {
            const {
              merchant_mid_nets_qr,
              merchant_tid_nets_qr,
              merchant_scrt_key_nets_qr,
              merchant_api_key_nets_qr
            } = result.payment_credentials[1];
            const allNull = [merchant_mid_nets_qr, merchant_tid_nets_qr, merchant_scrt_key_nets_qr, merchant_api_key_nets_qr].every(value => value === null || value === undefined);
            if(!allNull) {
              formattedDataArray.push({
                payment_mode: result.payment_credentials[1].payment_mode,
                merchant_mid_nets_qr: result.payment_credentials[1].merchant_mid_nets_qr,
                merchant_tid_nets_qr: result.payment_credentials[1].merchant_tid_nets_qr,
                merchant_scrt_key_nets_qr: result.payment_credentials[1].merchant_scrt_key_nets_qr,
                merchant_api_key_nets_qr: result.payment_credentials[1].merchant_api_key_nets_qr,
              })
            } else {
              setAvailablePaymentMode(oldArr => [...oldArr, result.payment_credentials[1].payment_mode])
            }
          } else {
            setAvailablePaymentMode(oldArr => [...oldArr, result.payment_credentials[1].payment_mode])
          }
          setData(formattedDataArray);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Payment Mode",
      accessor: "payment_mode",
    },
    {
      Header: "Nets QR MID",
      accessor: "merchant_mid_nets_qr",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Nets QR TID",
      accessor: "merchant_tid_nets_qr",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        if (row.original.payment_mode == "Primary" && data[0].payment_mode == "Primary") {
          row.original.merchant_scrt_key_nets_qr = data[0].merchant_scrt_key_nets_qr
          row.original.merchant_api_key_nets_qr = data[0].merchant_api_key_nets_qr
        } else if (row.original.payment_mode == "Alternate") {
          if(data.length == 1) {
            row.original.merchant_scrt_key_nets_qr = data[0].merchant_scrt_key_nets_qr
            row.original.merchant_api_key_nets_qr = data[0].merchant_api_key_nets_qr
          } else {
            row.original.merchant_scrt_key_nets_qr = data[1].merchant_scrt_key_nets_qr
            row.original.merchant_api_key_nets_qr = data[1].merchant_api_key_nets_qr
          }
        }
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div>
      <div className=" flex flex-row-reverse">
        <button
          type="button"
          className="ml-3 inline-flex items-center px-4 py-2 text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
          onClick={() => setOpen(true)}
        >
          Create Payment Option <Additem className="ml-3" />
        </button>
        {isOpen && <NetsQRCreateModal setOpenModal={setOpen} load={setLoad} merchantId={props.merchantId} availablePaymentMode={availablePaymentMode} />}
      </div>

      {data != null ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      {isUpdateModal && (
        <NetsQRUpdateModal
          setUpdateModal={setUpdateModal}
          load={setLoad}
          row={rowToEdit}
          merchantId={props.merchantId}
        />
      )}
      {isDeleteModal && (
        <NetsQRDeleteModal
          setDeleteModal={setDeleteModal}
          load={setLoad}
          url={commonConfigs.apiUrls.DeletePaymentOption(props.merchantId)}
          body={rowToEdit}
        />
      )}
    </div>
  );
};

export default NetsQRLayout;
