import { EmployeeContextProvider } from "../../context/employeeContext";
import EmployeeDetailsLayout from "./employeeDetailsLayout";

const EmployeePage = () => {
  return (
    <EmployeeContextProvider>
      <EmployeeDetailsLayout />
   </EmployeeContextProvider>
  );
};

export default EmployeePage;
