export const NOT_SELECT_MESSAGE = "Mandatory Field(s) Not Selected.";
export const INVALID_INPUT = "Input is incorrect. Please try again.";
export const DATE_INPUT = "Date cannot be lesser than current date.";
export const MULTI_SELECT_VALIDATION_MESSAGE =
  "At least one field(s) must be selected.";
export const ACCESS_SUPER_ADMIN = 1;

// Containers.
// $logs
// advertisements
// categories
// locations
// merchants
// products
// reports
// sitemaps
// wgt-kiosks
// prizes
// dishwares

export const AZURE_BLOB_CONTAINER_ADVERTISEMENTS = "advertisements";
export const AZURE_BLOB_CONTAINER_CATEGORIES = "categories";
export const AZURE_BLOB_CONTAINER_LOCATIONS = "locations";
export const AZURE_BLOB_CONTAINER_MERCHANT = "merchants";
export const AZURE_BLOB_CONTAINER_PRODUCTS = "products";
export const AZURE_BLOB_CONTAINER_REPORTS = "reports";
export const AZURE_BLOB_CONTAINER_SITEMAPS = "sitemaps";
export const AZURE_BLOB_CONTAINER_WGT_KIOSKS = "wgt-kiosks";
export const AZURE_BLOB_CONTAINER_PRIZES = "prizes";
export const AZURE_BLOB_CONTAINER_DISHWARES = "dishwares";

export const AZURE_BLOB_SAS_URL =
  "https://beveat.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2029-12-30T17:39:01Z&st=2023-01-17T09:39:01Z&spr=https&sig=UPqBVtwD0lag7rPbNdQUF2swG%2Bw2hJYaIBdAP9ot%2Bhs%3D";
export const AZURE_URL = "https://beveat.blob.core.windows.net";

export const PLATFORM_TYPES = ["Mobile", "Kiosk", "mPOS"];
export const PAYMENT_TYPES = ["NETSClick", "NETSQR", "CASH", "SGQR"];
