import { useState, useRef } from "react";
import Chevron from "./chevron";

function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("ml-auto");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(setActive === "active" ? "ml-auto" : "ml-auto rotate-90");
  }

  return (
    <div className="flex flex-col">
      <button
        className={`cursor-pointer p-4 flex items-center ${setActive}`}
        onClick={toggleAccordion}
      >
        <p className="font-semibold text-2xl text-left">{props.index}. {props.title}</p>
        <Chevron className={`${setRotate}`} width={10} fill={"#777"} />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="bg-neutral-50 overflow-auto"
      >
        <div
          className="font-normal text-xl p-4"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </div>
  );
}

export default Accordion;
