import axios from "axios";
import { Link } from "react-router-dom";
import { Cards } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import Table from "../../../../commons/components/table";
import React, { useState, useMemo, useEffect } from "react";
import Spinner from "../../../../commons/components/spinner";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";

const BillingLocationsLayout = () => {
  
  const pages = [
    { name: "Hierarchy View", href: "/dashboard/hierarchy", current: false },
    { name: "Locations", href: `/dashboard/hierarchy/merchanttotalbilling`, current: true },
  ];

  var StartDateData = sessionStorage.getItem('startdate');
  var EndDateData = sessionStorage.getItem('enddate');
  // var DivisionData = sessionStorage.getItem('division');

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1 to get the current month.

  // Calculate the first day of the current month.
  const firstDayOfMonth = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;

  // Calculate the last day of the current month.
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  const lastDayOfMonthFormatted = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${lastDayOfMonth.getDate()}`;
  
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);

  const [isApplying, setIsApplying] = useState(false);
  const [isReseting, setIsReseting] = useState(false);
  const applyButtonEnabledClass = "bg-red-600 hover:bg-red-700";
  const applyButtonDisabledClass = "bg-red-400 cursor-not-allowed";

  const [startdate, setStartDate] = useState(firstDayOfMonth);
  const [enddate, setEndDate] = useState(lastDayOfMonthFormatted);

  const [startdateFilter, setStartDateFilter] = useState(startdate);
  const [enddateFilter, setEndDateFilter] = useState(enddate);
  
  const [divisions, setDivisions] = useState('1');
  const [divisionTypeList, setDivisionTypeList] = useState([]);

  let user = JSON.parse(localStorage.getItem("user"))

  async function fetchDivisionList() {

    const getDivisions = commonConfigs.apiUrls.getDivisions();


    await axios
      .get(getDivisions, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setDivisionTypeList(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getData() {
    var DivisionFilter = 1;
  
    if (divisions !== "all") {
      DivisionFilter = divisions;
    }

    const GetBillingLocationsT1 = commonConfigs.apiUrls.GetBillingLocationsT1(DivisionFilter, startdateFilter, enddateFilter);    
    let arr = [];
    await axios
      .get(GetBillingLocationsT1, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        let response = res.data.result.data;
        if(user.location_id && (user.access_multiple_location === 0 || !user.access_multiple_location)) {
          arr.push(response.find(({location_id}) => user.location_id === location_id))
          if(arr[0] === undefined) {
            setData([]); // show nothing if no location
          } else {
            setData(arr);
          }
        } else if(user.access_multiple_location != 0) {
          setData(res.data.result.data);
        }
        setLoad(false);
        setIsApplying(false);
        setIsReseting(false);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  
  
  const applyFilter = () => {
    setIsApplying(true); // Disable the "APPLY" button
    setLoad(true);
    ((StartDateData.length > 0)? setStartDateFilter(StartDateData): setStartDateFilter(startdate));
    ((EndDateData.length > 0)? setEndDateFilter(EndDateData): setEndDateFilter(startdate));
    // ((DivisionData.length > 0)? setDivisions(DivisionData): setDivisions(divisions));
    setDivisions(divisions);
  }

  const resetFilter = () => {
    setIsReseting(true);
    setLoad(true);
    setStartDate('')
    setEndDate('');
    sessionStorage.setItem('startdate', '');
    sessionStorage.setItem('enddate', '');
    sessionStorage.setItem('division', '');
    setStartDateFilter('')
    setEndDateFilter('');
    setDivisions('all');
  }
  
  useEffect(() => {
    fetchDivisionList().catch((err) => {
      console.log("err: " + err);
    }); 
    getData().catch((err) => {
      console.log("err: " + err);
    });
  }, [load, divisions, startdateFilter, enddateFilter]);


  const columns = useMemo(() => [
    {
      Header: "Location Name",
      accessor: "location_name",
      Cell: ({ row }) => {
        let location = row.original.location_name;
        row.values.location_name = row.original.location_name;
        return <p className="text-sm text-gray-900">{location}</p>;
      },
    },
    {
      Header: "GPO Total Amt",
      accessor: "gpo_total_amt",
      Cell: ({ row }) => {
        var sales = row.values.gpo_total_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
    {
      Header: "Nets Total Amt",
      accessor: "nets_total_amt",
      Cell: ({ row }) => {
        var sales = row.values.nets_total_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
    {
      Header: "Dishwashing Total Amt",
      accessor: "dishwashing_total_amt",
      Cell: ({ row }) => {
        var sales = row.values.dishwashing_total_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        let merchantObj = {
          id: row.original.location_id,
          name: row.original.location_name,
        };

        return (
          <div className=" text-gray-900  flex space-x-1 ">
            <button type="button">
              <Link
                to={{
                  pathname: `/dashboard/hierarchy/merchanttotalbilling/${merchantObj.name}/${merchantObj.id}`,
                }}
              >
                <Cards aria-hidden="true" className="w-6 h-6" />
              </Link>
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
      </div>

      <div style={{backgroundColor:'#FFF', padding:'20px', borderRadius:'10px', margin:'20px 0 20px 0 '}}>
        <row style={{display:'flex'}}>

          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> Start Date </h6>
            </div>
            <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={startdate} onChange={(e) => {
              setStartDate(e.target.value);
              sessionStorage.setItem('startdate', e.target.value);
            }}/>
          </div>

          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> End Date </h6>
            </div>
            <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={enddate} onChange={(e) => {
              setEndDate(e.target.value);
              sessionStorage.setItem('enddate', e.target.value); 
            }}/>
          </div>

          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> Division </h6>
            </div>
            <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={divisions} onChange={(e) => {
              sessionStorage.setItem('division', e.target.value);
              setDivisions(e.target.value)
              }}>
              {/* <option value='all'>All</option> */}
              {divisionTypeList && divisionTypeList.length > 0 && divisionTypeList.map(
                (comm, index) => {
                  return <option key={index} value={comm.division_id}>{comm.division_name}</option>
                })}
            </select>
          </div>
        
        </row>

        <div style={{ textAlign: 'end', paddingTop: '34px' }}>
          <button
            data-inline="true"
            type="button"
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
              isApplying ? applyButtonDisabledClass : applyButtonEnabledClass
            }`}
            onClick={() => applyFilter()}
            disabled={isApplying} // Disable the button when isApplying is true
          >
            {isApplying ? "Applying..." : "APPLY"}
          </button>
          <button
            data-inline="true"
            type="button"
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
              isReseting ? applyButtonDisabledClass : applyButtonEnabledClass
            }`}
            onClick={() => resetFilter()}
            disabled={isReseting} // Disable the button when isReseting is true
          >
            {isReseting ? "Reseting..." : "Reset"}
          </button>
        </div>

      </div>

      {data != null ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingLocationsLayout;
