import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import StatusCard2 from "../components/statusCard2";

const user = JSON.parse(localStorage.getItem("user"))

export default function TOCSCard() {

  const [stallStatus, setStallStatus] = useState(null);
  
  async function fetchStalls() {

    let getOperatingStalls = commonConfigs.apiUrls.GetOperatingStalls();
    if(user.access_multiple_location == 0 && user.location_id) {
      getOperatingStalls = getOperatingStalls + `&location_id=${user.location_id}`
    }

    await axios
    .get(getOperatingStalls, { headers: commonConfigs.apiHeader })
    .then((res) => {
      console.log(res.data.result.data);
      setStallStatus(res.data.result.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }


  useEffect(() => {
    fetchStalls().catch((err) => {
      console.log("err: " + err);
    });
  }, []);

  return (
    <div>
      {stallStatus && (
        <StatusCard2
          title={"Today's Open | Closed Stalls"}
          data={stallStatus}
        />
      )}
    </div>   
  );
}
