import axios from "axios";
import { Additem, Edit, Trash } from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import commonConfigs from "../../../../../config/config";
import Table from "../../../../../commons/components/table";
import Spinner from "../../../../../commons/components/spinner";
import UpdateLoyaltyProgrammeModal from "../components/updateLoyaltyProgrammeModal";
import { format } from "date-fns";
import DeleteModal from "../../../../../commons/components/deleteModal";
import CreateLoyaltyProgrammeModal from "../components/createLoyaltyProgrammeModal";

const LoyaltyProgrammeLayout = () => {
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  async function getData() {
    const GetLoyaltyProgramme = commonConfigs.apiUrls.GetLoyaltyProgramme();

    await axios
      .get(GetLoyaltyProgramme, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Programme Name",
      accessor: "loyalty_name",
    },
    {
      Header: "Claimable Location",
      accessor: "claimable_locations",
      Cell: ({ row }) => {
        let claimable_locations = row.original.claimable_locations.map(
          (location) => location.location_name
        );

        return (
          <div className="text-sm text-gray-900">
            {claimable_locations.join(", ")}
          </div>
        );
      },
    },
    {
      Header: "Accumlate Location",
      accessor: "accumulate_locations",
      Cell: ({ row }) => {
        let accumulate_locations = row.original.accumulate_locations.map(
          (location) => location.location_name
        );

        return (
          <div className="text-sm text-gray-900">
            {accumulate_locations.join(", ")}
          </div>
        );
      },
    },
    {
      Header: "Points per dollar spent",
      accessor: "points_per_dollar",
    },
    {
      Header: "Points Validty",
      accessor: "points_validity",
    },
    {
      Header: "Percent Of Min Spend",
      accessor: "percent_of_min_spend",
    },
    {
      Header: "Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            {localStorage.getItem("roleid") == 2 || localStorage.getItem("roleid") == 5 ?
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
            : <></> }
          </div>
        );
      },
    },
  ]);

  return (
    <div className="mt-8">

      <div className=" flex flex-row-reverse">
        <button
          type="button"
          className="ml-3 inline-flex items-center text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
          onClick={() => setCreateModalOpen(true)}
        >
          Create Loyalty Programme <Additem className="ml-3" />
        </button>
      </div>

      {isCreateModalOpen && <CreateLoyaltyProgrammeModal setCreateModal={setCreateModalOpen} load={setLoad} />}
      {data != null ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
       
      {isUpdateModalOpen && (
        <UpdateLoyaltyProgrammeModal
          setUpdateModal={setUpdateModalOpen}
          load={setLoad}
          row={rowToEdit}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          setDeleteModal={setDeleteModalOpen}
          load={setLoad}
          url={commonConfigs.apiUrls.UpdateLoyaltyProgramme(rowToEdit.loyalty_id)}
        />
      )}

    </div>
  );
};

export default LoyaltyProgrammeLayout;
