import EMenuLayout from "./eMenuLayout";
import { EMENUContextProvider } from "../context/eMenuContext";

const EMenuPage = () => {
  return (
    <EMENUContextProvider>
      <EMenuLayout />
    </EMENUContextProvider>
    );
};

export default EMenuPage;
