import { useState, useContext } from "react";
import DashboardTabs from "../../components/dashboardTabs";
import { DashboardCurrentTabContext } from "../../context/dashboardContext";
import LongCard from "../../components/dashboardLongCard";
import Card from "../../components/dashboardCard";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import { Link } from "react-router-dom";
import DashboardMarketingPage from "../../marketing_modules/pages/dashboardMarketingPage";
import ExportModal from "../components/exportModal";
import DashboardKPILayout from "../../kpi_modules/pages/dashboardKPILayout";
import SEVoucherCard from "../components/SEVoucherCard";
import NSEVoucherCard from "../components/NSEVoucherCard";
import TSCard from "../components/tsCard";
import TOCard from "../components/toCard";
import YTDSCard from "../components/ytdsCard";

const pages = [
  { name: "Dashboard", href: "/dashboard/revenue", current: true },
];


const DashboardLayout = () => {

  const [isExportModalOpen, setExportModal] = useState(false);
  const { currentTab } = useContext(DashboardCurrentTabContext);

  return (
    <div className="w-full desktop:p-2 bg-white">
      <div className="mb-4">
        <DashboardTabs />
        {currentTab.name == "Revenue" && (
          <div>
            <div className="flex items-center justify-between">
              <div className="flex-1 min-w-0 mobile:pt-4 mobile:ml-2">
                <Breadcrumbs pages={pages} />
              </div>
              <div className="mt-4 flex desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2">
                <Link to="/dashboard/hierarchy">
                  <button
                    type="button"
                    className="ml-3 inline-flex items-center text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
                  >
                    Hierarchy View
                  </button>
                </Link>
                <button
                  type="button"
                  className="ml-3 inline-flex items-center text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
                  onClick={() => {
                    setExportModal(true);
                  }}
                >
                  Export
                </button>
                {isExportModalOpen && (
                  <ExportModal setCreateModal={setExportModal} />
                )}
              </div>
            </div>
            
            <div style={{display: 'grid', 'grid-template-areas':`'box1 box2 box3 box3''box5 box5 box6 box6'`,'grid-template-columns': 'repeat(4, minmax(0, 1fr))', gap:4, maxWidth: 'inherit'}}>

              <div style={{ gridArea: 'box1', height:'100%', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
                <YTDSCard/>
              </div>
                
              <div style={{ gridArea: 'box2', height:'100%', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
                <TOCard/>
              </div>

              <div style={{ gridArea: 'box3', height:'100%', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
                <Card>
                  <TSCard/>
                </Card>
              </div>

              <div style={{ gridArea: 'box5', height:'100%', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
                <LongCard>
                  <SEVoucherCard/>
                </LongCard>
              </div>

              <div style={{ gridArea: 'box6', height:'100%', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
                <LongCard>
                  <NSEVoucherCard/>
                </LongCard>
              </div>
    
            </div>
          </div>

        )}

        {currentTab.name == "Marketing" && <DashboardMarketingPage />}
        {currentTab.name == "KPI" && <DashboardKPILayout />}
      </div>
    </div>
  );
  // }
  
};

export default DashboardLayout;
