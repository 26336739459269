import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";

import DashboardLineGraph from "../../components/dashboardLineGraph";

const user = JSON.parse(localStorage.getItem("user"))

export default function TSCard() {

  const [orderTypesTotalRevenueDataPoints, setOrderTypesTotalRevenueDataPoints] = useState(null);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1 to get the current month.

  // Calculate the first day of the current month.
  const firstDayOfMonth = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;

  // Calculate the last day of the current month.
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  const lastDayOfMonthFormatted = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${lastDayOfMonth.getDate()}`;

  // Set TSstartdate and TSenddate to the calculated values.
  const [TSstartdate, setTSStartDate] = useState(firstDayOfMonth);
  const [TSenddate, setTSEndDate] = useState(lastDayOfMonthFormatted);

  const [isApplying, setIsApplying] = useState(false);
  const [isReseting, setIsReseting] = useState(false);
  
  const [TSstartdateFilter, setTSStartDateFilter] = useState(TSstartdate);
  const [TSenddateFilter, setTSEndDateFilter] = useState(TSenddate);

  const [load, setLoad] = useState(true);

  const applyButtonEnabledClass = "bg-red-600 hover:bg-red-700";
  const applyButtonDisabledClass = "bg-red-400 cursor-not-allowed";

  async function fetchOrderTypesTotalRevenueDataPoints() {
    console.log(TSstartdateFilter);
    console.log(TSenddateFilter);
  
    let getOrderTypesTotalRevenueDataPoints = commonConfigs.apiUrls.GetOrderTypesTotalRevenueDataPoints(TSstartdateFilter, TSenddateFilter);
    if(user.access_multiple_location == 0 && user.location_id) {
      getOrderTypesTotalRevenueDataPoints = getOrderTypesTotalRevenueDataPoints + `&location_id=${user.location_id}`
    }

    await axios
      .get(getOrderTypesTotalRevenueDataPoints, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        setOrderTypesTotalRevenueDataPoints(res.data.result.data);
        setLoad(false);
        setIsApplying(false);
        setIsReseting(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const applyTSFilter = () => {
    setIsApplying(true); // Disable the "APPLY" button
    setLoad(true);
    setTSStartDateFilter(TSstartdate);
    setTSEndDateFilter(TSenddate);
  };

  const resetTSFilter = () => 
  {
    setIsReseting(true);
    setLoad(true);
    setTSStartDate('')
    setTSEndDate('');
    setTSStartDateFilter('')
    setTSEndDateFilter('');
  }


  useEffect(() => {
      fetchOrderTypesTotalRevenueDataPoints().catch((err) => {
        console.log("err: " + err);
      });
  }, [load, TSstartdateFilter, TSenddateFilter]);


  return (
    <div>
      
      <h3 className="text-xl">Total Sales </h3>
              
      <div style={{backgroundColor:'#FFF', padding:'20px', borderRadius:'10px'}}>
        <div style={{'text-align': 'right', display:'flex', paddingBottom: '20px'}}>
          <div style={{ paddingTop: '10px', paddingBottom: '10px', padding:'10px' }}>
            <h6> Start Date: </h6>
          </div>
          <input data-inline="true" type="date" style={{width:'30%',borderRadius:'8px'}} value={TSstartdate} onChange={(e) => {
                  setTSStartDate(e.target.value);}}
          />
          <div style={{ paddingTop: '10px', paddingBottom: '10px', padding:'10px'  }}>
            <h6> End Date: </h6>
          </div>
          <input data-inline="true" type="date" style={{width:'30%',borderRadius:'8px'}} value={TSenddate} onChange={(e) => {
                  setTSEndDate(e.target.value);}}
          />
            <div style={{'text-align': 'right', display:'flex', padding:'10px' }}>
              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  isApplying ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => applyTSFilter()}
                disabled={isApplying} // Disable the button when isApplying is true
              >
                {isApplying ? "Applying..." : "APPLY"}
              </button>
              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  isReseting ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => resetTSFilter()}
                disabled={isReseting} // Disable the button when isReseting is true
              >
                {isReseting ? "Reseting..." : "Reset"}
              </button>
            </div>
        </div>
      </div>   
    
      <div className="mt-2">
        {orderTypesTotalRevenueDataPoints?.map((item) => ({
          label: item.Date,
          value: item.total_revenue,
        })) && (
          <DashboardLineGraph
            data={orderTypesTotalRevenueDataPoints.map((item) => ({
              label: item.Date,
              value: item.total_revenue,
            }))}
          />
        )}
      </div>

    </div>   
  );
}
