import axios from "axios";
import { format } from "date-fns";
import { useParams, useLocation } from "react-router-dom";
import commonConfigs from "../../../config/config";
import Table from "../components/table";
import React, { useState, useMemo, useEffect, useContext } from "react";
import Spinner from "../../../commons/components/spinner";
import Breadcrumbs from "../../../commons/components/breadcrumbs";
import OrderItems from "../components/orderItems";
import MerchantsMenuTabs from "../components/merchantMenuTab";
import { MerchantMenuCurrentTabContext } from "../context/merchantMenuContext";

const MerchantsLayout = () => {
  const { currentTab } = useContext(MerchantMenuCurrentTabContext);
  const [data, setData] = useState(null);
  const [completedata, setCompletedData] = useState(null);
  const [load, setLoad] = useState(true);

  const locationName = useParams().locationName;
  var locationIdApi = "";

  if (localStorage.getItem('locationid') == null) {
    const { locationId } = useLocation().state;
    localStorage.setItem('locationid', locationId);
    locationIdApi = locationId;
    console.log(locationIdApi);
  } else {
    locationIdApi = localStorage.getItem('locationid');
    console.log(locationIdApi);
  }

  window.onload = refreshLocationId;

  // const getOrdersUrl = commonConfigs.apiUrls.GetOrders(locationIdApi);
  const getOrdersUrl = commonConfigs.apiUrls.GetOrders(locationIdApi, '');
  const getCompletedOrdersUrl = commonConfigs.apiUrls.GetOrders(locationIdApi,3);

  const pages = [
    { name: "Operations", href: "#", current: false },
    { name: "Locations", href: "/operations/locations", current: false },
    { name: locationName, href: `/operations/locations/${locationName}/orders`, current: false },
    { name: "Orders", href: `/operations/locations/${locationName}/orders`, current: true},
  
  ];
  
  useEffect(() => {
    async function getData() {
      await axios
        .get(getOrdersUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          setData(res.data.result.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getCompletedData() {
      await axios
        .get(getCompletedOrdersUrl, { headers: commonConfigs.apiHeader })
        // .get(getOrdersUrl, { headers: commonConfigs.apiHeader })
        
        .then((res) => {
          console.log(res.data.result.data);
          setCompletedData(res.data.result.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
      getCompletedData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Payment ID",
      accessor: "payment_id",
    },
    {
      Header: "Order No",
      accessor: "order_no",
    },
  
    {
      Header: "Order Platform",
      accessor: "order_platform_type",
    },
    {
      Header: "Consumer Mobile",
      accessor: "consumer_mobile",
    },
    {
      Header: "Total Amt",
      accessor: "total_amt",
    },
    {
      Header: "Merchant Name",
      accessor: "merchant_name",
    },
    {
      Header: "Order Type",
      accessor: "order_type",
    },
    {
      Header: "Order Items",
      accessor: "items",
      Cell: ({ row }) => {
        var items = row.values.items;
        
        // var returnVal = [];

        // for (var i = 0; i < items.length; i++){
        //   console.log(items[i]);  
           
        // }
        // console.log(returnVal)
        return <OrderItems data={items} />;
        
      },
    },
    {
      Header: "Order Status",
      accessor: "order_status",
      Cell: ({ row }) => {
        var status = row.values.order_status;
        var orderDatetime = row.values.order_datetime;

        var orderDatetimeConvert = new Date(orderDatetime).getTime();
        
        var today = new Date();
        var currentTimestamp = today.getTime();
        var newTimestamp3 = orderDatetimeConvert + (3 * 60 * 1000);
        var newTimestamp5 = orderDatetimeConvert + (5 * 60 * 1000);
        
        // var difference3 = newTimestamp3 - currentTimestamp;
        // var difference5 = newTimestamp5 - currentTimestamp;
        console.log(orderDatetimeConvert);
        console.log(currentTimestamp);
        console.log(newTimestamp3);
        console.log(newTimestamp5);
        if (status == "Completed") {
          return <div style={{ backgroundColor: "green", color: "white", textAlign:'center', borderRadius:'8px', padding:'10px'}} >
            { status}
          </div>;
        } else {
          if ((currentTimestamp > newTimestamp3) && (currentTimestamp > newTimestamp5)) {
            return <div style={{ backgroundColor: "red", color: "white", textAlign:'center', borderRadius:'8px', padding:'10px'}} >
              { status}
            </div>;
          } else if ((currentTimestamp > newTimestamp3) && (currentTimestamp < newTimestamp5)) {
            return <div style={{ backgroundColor: "orange", color: "black", textAlign:'center', borderRadius:'8px', padding:'10px'}} >
              { status}
            </div>;
          }  else {
            return <div style={{ backgroundColor: "grey", color: "white", textAlign:'center', borderRadius:'8px', padding:'10px'}} >
              { status}
            </div>;
          }  
        }
        
      },
    },
    {
      Header: "Order Datetime",
      accessor: "order_datetime",
      Cell: ({ row }) => {
        row.values.order_datetime = format(
          new Date(row.values.order_datetime),
          "dd MMM yyyy HH:mm"
        );
        var date = row.values.order_datetime;

        if (date) {
          return <p className="text-sm text-gray-900">{date}</p>;
        }
      },
    },
    {
      Header: "Voucher Type",
      accessor: "voucher_type",
    },
    {
      Header: "Voucher Code",
      accessor: "voucher_code",
    },
    {
      Header: "Voucher Amt",
      accessor: "voucher_amt",
    },
    {
      Header: "Loyalty Redemption Amt",
      accessor: "loyalty_redemption_amt",
    },
    {
      Header: "Discount Type",
      accessor: "discount_type",
    },
    {
      Header: "Discount Amt",
      accessor: "discount_amt",
    },
    {
      Header: "Discount Percent",
      accessor: "discount_percent",
    },
  ]);

  function refreshLocationId() {
    // Update the variable with a new value
    locationIdApi = localStorage.getItem('locationid');
    console.log("Variable refreshed! New value: " + locationIdApi);
  }

  setInterval(function() {
    const currentUrl = window.location.href;
    const isMatchingPage = pages.some((page) => currentUrl.includes(page.href));
    if (isMatchingPage) {
      window.location.reload();
    }
  }, 60000);

  // setInterval(function() {
  //   window.location.reload();
  // }, 60000); 
  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
        <MerchantsMenuTabs />
      </div>
      

      {currentTab.name == "Current" && (
        <>
         {data != null ? (
          <Table
            columns={columns}
            data={data}
            filters={columns.map((column) => column.accessor)}
            // pagination={true}
          />
        ) : (
          <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
            <div className="flex place-content-center w-full">
              <Spinner className="w-20 h-20" />
            </div>
          </div>
          )}
          </>
      )}
      {currentTab.name == "Completed" && (
        <>
          {completedata != null ? (
          <Table
            columns={columns}
            data={completedata}
            filters={columns.map((column) => column.accessor)}
            // pagination={true}
          />
        ) : (
          <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
            <div className="flex place-content-center w-full">
              <Spinner className="w-20 h-20" />
            </div>
          </div>
        )}
        </>
      )}
     
    </div>
  );
};

export default MerchantsLayout;
