import { NavigationContextProvider } from "../contexts/navigationContext";
import Navbar from "./navbar";

const NavbarWrapper = () => {
  return (
    <NavigationContextProvider>
      <Navbar />
    </NavigationContextProvider>
  );
};

export default NavbarWrapper;
