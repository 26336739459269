import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import commonConfigs from "../../../../config/config";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { InfoCircle } from "iconsax-react";

const user = JSON.parse(localStorage.getItem("user"));

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function UpdateEmpDetails({ 
  setUpdateModal, 
  row, 
  load 
}) {
  const timecard_id = row.timecard_id;
  // const start_time = "";

  // const GetAllTimecards = commonConfigs.apiUrls.GetAllTimecards(start_time);
  const UpdateEmployeeTimecards = commonConfigs.apiUrls.UpdateEmployeeTimecards(timecard_id);

  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(true);
  
  const timein = new Date(row.timestamp_in);
  // timein.setHours(timein.getHours() + 16);
  const TimeIn = timein.toTimeString().substring(0, 8);

  const timeout = new Date(row.timestamp_out);
  // timeout.setHours(timeout.getHours() + 16);
  const TimeOut = timeout.toTimeString().substring(0, 8);

  // const [remarksDisplay, setRemarksDisplay] = useState(false);
  // const [selectedAttendanceStatus, setSelectedAttendanceStatus] = useState('');
  
  // const [attendanceReason, setAttendanceReason] = useState([]);
  // const [attendanceStatus, setAttendanceStatus] = useState([]);
  // const [lazyData, setLazyData] = useState({});

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      supervisor_remarks: row.supervisor_remarks,
      attendance_status: 
      {
        value: row.attendance_status,
        label: row.attendance_status_name,
      },
      attendance_reason:
      {
          value: row.attendance_reason,
          label: (row.attendance_reason == '-' || row.attendance_reason == '') ? "Present" : row.attendance_reason,
      }, 
      minutes_addition: row.minutes_addition,
      minutes_deduction: row.minutes_deduction,
      timestamp_in: row.timestamp_in === null || row.timestamp_in === '-' ? '' : TimeIn,
      timestamp_out: row.timestamp_out === null || row.timestamp_out === '-' ? '' : TimeOut,
    },
  });

  

  const AttendanceStatusOptions = [
    { value: "0", label: "Pending" },
    { value: "1", label: "Confirmed" },
    { value: "2", label: "Declined" },
  ];

  const attendance_options = [
    { value: "0", label: "Present" },
    { value: "1", label: "Absent Without Leave" },
    { value: "2", label: "On Leave" },
    ];
    

  function formatData(data) {
    var attendance_status = data.attendance_status.value;
    var supervisor_remarks = data.supervisor_remarks;
    var minutes_addition = data.minutes_addition;
    var minutes_deduction = data.minutes_deduction;
    var attendance_reason = data.attendance_reason.label;

    var timestamp_in = row.timecard_date + " " + (data.timestamp_in) ;

    var timestamp_out = row.timecard_date + " " + (data.timestamp_out) ;


    var newData = data;

    newData.attendance_status = attendance_status;
    newData.supervisor_remarks = supervisor_remarks;
    newData.minutes_addition = minutes_addition;
    newData.minutes_deduction = minutes_deduction;
    newData.attendance_reason = attendance_reason;
    newData.timestamp_in = timestamp_in;
    newData.timestamp_out = timestamp_out;

    newData.updated_by = user.account_id;

    return newData;
  }

  
  const onSubmit = (data) => {
    setIsDisabled(true); // Disable the submit button

    formatData(data);
    console.log(data);

    axios
      .put(UpdateEmployeeTimecards, data, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setUpdateModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };


  // async function GetAttendanceReason() {
  //   await axios
  //     .get(GetAllTimecards, { headers: commonConfigs.apiHeader })
  //     .then((res) => {
  //       // setAttendanceReason(res.data.result.data);
  //       res.data.result.data.forEach((AttendanceReason) => {
  //         const matchingOption = attendance_options.find(option => option.value === AttendanceReason.attendance_reason);
  //         const AttendanceReasonLabel = matchingOption ? matchingOption.label : "";
  //         if (AttendanceReason.attendance_reason == row.attendance_reason) {
  //           setLazyData(
  //             (lazyData["AttendanceReason"] = {
  //               value: AttendanceReason.attendance_reason,
  //               label: AttendanceReasonLabel,
  //             })
  //           );
  //         }
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // useEffect(() => {
  //   const getSelectData = async () => {
  //     // await GetAttendanceStatus().catch((err) => {
  //     //   console.log("err for AttendanceStatus: " + err);
  //     // });
  //     await GetAttendanceReason().catch((err) => {
  //       console.log("err for AttendanceReason: " + err);
  //     }); 
  //   };

  //   getSelectData().then(() => 
  //   {
  //     reset(lazyData);
  //   }).catch((err) => 
  //   {
  //     console.log("err for AttendanceReason: " + err);
  //   });
  // }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setUpdateModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Update Employee Details
                    </Dialog.Title>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="timestamp_in" className="block text-sm font-medium text-yellow-800">
                        Time In
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="time"
                          name="timestamp_in"
                          id="timestamp_in"
                          className={
                            !errors.timestamp_in
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Start Time (HH:MM:SS:am/pm)"}
                          {...register("timestamp_in", {
                            required: true,
                            pattern: {
                              value: /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
                              message: "Please enter a valid time in the format 'HH:MM:SS'",
                            },
                          })}
                          step="1"
                        />
                        {errors.timestamp_in?.type === "required" && errorIcon}
                        {errors.timestamp_in?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.timestamp_in?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="timestamp_out" className="block text-sm font-medium text-yellow-800">
                        Time Out 
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="time"
                          name="timestamp_out"
                          id="timestamp_out"
                          className={
                            !errors.timestamp_out
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter End Time (HH:MM:SS:am/pm)"}
                          {...register("timestamp_out", {
                            required: true,
                            pattern: {
                              value: /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
                              message: "Please enter a valid time in the format 'HH:MM:SS'",
                            },
                          })}
                          step="1"
                        />
                        {errors.timestamp_out?.type === "required" && errorIcon}
                        {errors.timestamp_out?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.timestamp_out?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="attendance_reason" className="block text-sm font-medium text-yellow-800">
                        Attendance
                      </label>
                      <Controller
                        name="attendance_reason"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={attendance_options}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
                            // isMulti={true}
                            closeMenuOnSelect={true}
                          />
                        )}
                      />
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="attendance_status"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Confirmation Status
                      </label>
                      <Controller
                        name="attendance_status"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={AttendanceStatusOptions}
                            isSearchable={true}
                            placeholder="Select the status"
                            className="text-sm"
                            // onChange={(e)=>handleToggle(e)}
                          />
                        )}
                      />
                    </div>
                            
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="supervisor_remarks"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Remarks
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="supervisor_remarks"
                          id="supervisor_remarks"
                          className={
                            !errors.supervisor_remarks
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          // defaultValue={row.supervisor_remarks}
                          placeholder={"Enter Remarks"}
                          {...register("supervisor_remarks", { required: true })}
                        />
                        {errors.supervisor_remarks?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="minutes_addition"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Surplus
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="0"
                          step="1"
                          name="minutes_addition"
                          id="minutes_addition"
                          style={{backgroundColor:'#ececec'}}
                          className={
                            !errors.minutes_addition
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter number in minutes only"}
                          {...register("minutes_addition", {
                            pattern: /^[0-9]*$/,
                          })}                      
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="minutes_deduction"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Deduction
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="0"
                          step="1"
                          name="minutes_deduction"
                          id="minutes_deduction"
                          style={{backgroundColor:'#ececec'}}
                          className={
                            !errors.minutes_deduction
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter number in minutes only"}
                          {...register("minutes_deduction", {
                            pattern: /^[0-9]*$/,
                          })}                
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-8 sm:mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setUpdateModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
