import DashboardCard from "../../components/dashboardCard";
import DivisionCard from "../components/divisionCard";
import CMBCard from "../components/cmbCard";
import DishwashingCard from "../components/dishwashingServicesCard";
import GPOCard from "../components/gpoCard";
import { Link } from "react-router-dom";

const HierarchyBillingLayout = () => {

  return (
    <div>
      <div style={{ display: 'grid', 'grid-template-areas': `'box1 box2 box3 box4'`,'grid-template-columns': 'repeat(4, minmax(0, 1fr))', gap: 4, maxWidth: 'inherit'}}>

        <div style={{ gridArea: 'box1', height:'100%'}}>
          <CMBCard/>
        </div>

        <div style={{ gridArea: 'box2', height: '100%' }}>
          <DashboardCard>
            <Link to={'merchanttotalbilling'} style={{marginRight: '10px', textDecoration:'underline', display:'inline-block', float:'right' }}>
              View Details
            </Link>

            <DivisionCard/>
            
          </DashboardCard>            
        </div>
          
        <div style={{ gridArea: 'box3', height: '100%' }}>
          <DishwashingCard/>
        </div>
        
        <div style={{ gridArea: 'box4', height: '100%' }}>
          <GPOCard/>
        </div>

      </div>
    </div>
  );
  
};

export default HierarchyBillingLayout;
