import PrizeLayout from "./prizeLayout";
import { PrizeContextProvider } from "../context/prizeContext";

const PrizePage = () => {
  return (
    <PrizeContextProvider>
      <PrizeLayout />
    </PrizeContextProvider>
  );
};

export default PrizePage;