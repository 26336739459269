import HierarchyLayout from "./hierarchyLayout";
import { DashboardContextProvider } from "../context/dashboardContext";

const HierarchyPage = () => {
  return (
    <DashboardContextProvider>
      <HierarchyLayout />
    </DashboardContextProvider>
  );
};

export default HierarchyPage;
