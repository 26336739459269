import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import CurrMonthBillCard from "../components/currentMonthBillCard";

const user = JSON.parse(localStorage.getItem("user"))

export default function CMBCard() {

  const [currentMonthBill, setCurrentMonthBill] = useState(null);

  async function fetchCurrentMonthBill() {

    let getCurrentMonthBill = commonConfigs.apiUrls.GetCurrentMonthBill();
    if(user.access_multiple_location == 0 && user.location_id) {
      getCurrentMonthBill = getCurrentMonthBill + `?location_id=${user.location_id}`
    }

    await axios
    .get(getCurrentMonthBill, { headers: commonConfigs.apiHeader })
    .then((res) => {
      console.log(res.data.result.data);

        var CMBData = {
          total_revenue: {
            total_revenue: res.data.result.data.cumulative_net_total,
            revenue_stream: [
              {
                type: "NETS Total",
                revenue: res.data.result.data.nets_net_total
              },
              {
                type: "Dishwashing Nett Total",
                revenue: res.data.result.data.dishwashing_net_total
              },
              {
                type: "Group Purchase Order Nett Total",
                revenue: res.data.result.data.gpo_net_total
              },
            ]
          }
        };

        setCurrentMonthBill(CMBData);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    fetchCurrentMonthBill().catch((err) => {
      console.log("err: " + err);
    });
  }, []);


  return (
    <div style={{height:'inherit', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
      {currentMonthBill && 
        <CurrMonthBillCard data={currentMonthBill} 
      />}
    </div>   
  );
}
