import axios from "axios";
import { format } from "date-fns";
import { Link, useParams, useLocation } from "react-router-dom";
import { Edit, Trash, Cards, DocumentDownload } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import { Additem, DocumentUpload } from "iconsax-react";
import Table from "../../../../commons/components/table";
import React, { useState, useMemo, useEffect } from "react";
import Spinner from "../../../../commons/components/spinner";
import CreateMerchantModal from "../components/createMerchantModal";
import UploadMerchantModal from "../components/uploadMerchantModal";
import UpdateMerchantModal from "../components/updateMerchantModal";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import DeleteModal from "../../../../commons/components/deleteModal";
import MultipleString from "../../../../commons/components/multipleString.jsx";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

// const getMerchantsUrl = commonConfigs.apiUrls.Merchants();

const MerchantsLayout = () => {
  const [isCreateModalOpen, setCreateModal] = useState(false);
  const [isUploadModalOpen, setUploadModal] = useState(false);
  // const [isDownloadModalOpen, setDownloadModal] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState(null);
  const [exportData, setExportData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  const locationName = useParams().locationName;
  var locationIdApi = "";
  var locationCodeApi = "";

  const formatDateAndTime = (datetimeString) => {
    if (!datetimeString) {
      return '-';
    }

    const date = new Date(datetimeString);
    const formattedDate = date.toISOString().split('T')[0]; // Extract date portion
    const formattedTime = date.toISOString().split('T')[1].split('.')[0]; // Extract time portion
    return `${formattedDate} ${formattedTime}`;
  };

  if (localStorage.getItem('locationid') == null) {
    const { locationId } = useLocation().state;

    localStorage.setItem('locationid', locationId);
    locationIdApi = locationId;
    console.log(locationIdApi);
  } else {
    locationIdApi = localStorage.getItem('locationid');
    console.log(locationIdApi);

  }

  if (localStorage.getItem('locationcode') == null) {
    const { locationCode } = useLocation().state;
    localStorage.setItem('locationcode', locationCode);
    locationCodeApi = locationCode;
    console.log(locationCodeApi);
  } else {
    locationCodeApi = localStorage.getItem('locationcode');
    console.log(locationCodeApi);

  }
  const getMerchantsUrl = commonConfigs.apiUrls.GetLocationMerchants(locationIdApi);
  // const getMerchantsUrl = commonConfigs.apiUrls.GetLocationMerchants(locationCodeApi);
  const getExportMerchantsUrl = commonConfigs.apiUrls.GetAllLocationProducts(locationIdApi);

  const pages = [
    { name: "Configurations", href: "#", current: false },
    { name: "Locations", href: "/configurations/merchantlocations", current: false },
    { name: locationName, href: `/configurations/merchantlocations/${locationName}`, current: true },
  ];

  const downloadFiles = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const sheets = {};
    const arrSheetName = [];

    for (var i = 0; i < exportData.length; i++) {
      const merchantName = exportData[i].merchant_name.substring(0, 8);
      let sheetName = merchantName;
      let count = 1;
      while (arrSheetName.includes(sheetName)) {
        sheetName = `${merchantName}${count}`;
        count++;
      }
      arrSheetName.push(sheetName);

      const detailsarr = [];

      for (var x = 0; x < exportData[i].entity_products.length; x++) {
        const detailsobj = {};
        // console.log(exportData[i]);
        detailsobj['Product Name'] = exportData[i].entity_products[x].product_name;
        detailsobj['Product Price'] = exportData[i].entity_products[x].product_price;
        detailsobj['Product Desc'] = exportData[i].entity_products[x].product_desc;
        detailsobj['Product Calories'] = exportData[i].entity_products[x].product_calories[0].calorie_count == undefined ? -1 : exportData[i].entity_products[x].product_calories[0].calorie_count;
        detailsobj['Product SKU'] = exportData[i].entity_products[x].product_sku;
        detailsobj['Is Add On'] = exportData[i].entity_products[x].is_add_on;
        detailsobj['Product Sequence'] = exportData[i].entity_products[x].product_sequence;
        detailsobj['Product Stock Qty'] = exportData[i].entity_products[x].product_stock_qty;
        detailsobj['Product Start Time'] = exportData[i].entity_products[x].product_start_time;
        detailsobj['Product End Time'] = exportData[i].entity_products[x].product_end_time;
        detailsobj['Product Min Add On Qty'] = exportData[i].entity_products[x].product_min_add_on_qty;
        detailsobj['Product Min Order Qty'] = exportData[i].entity_products[x].product_min_order_qty;
        detailsobj['Product Visible Days'] = exportData[i].entity_products[x].product_visible_days;

        detailsarr.push(detailsobj);
      }

      sheets[sheetName] = XLSX.utils.json_to_sheet(detailsarr);
    }
    const wb = { Sheets: sheets, SheetNames: arrSheetName };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, locationName + fileExtension);
  }


  // }
  useEffect(() => {
    async function getData() {
      await axios
        .get(getMerchantsUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          setData(res.data.result.data.filter(item => item.is_deleted === 0));
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getExportData() {
      await axios
        .get(getExportMerchantsUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          setExportData(res.data.result.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
      getExportData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Merchant Name",
      accessor: "merchant_name",
      width: 10,
      Cell: ({ row, value }) => {
        return (
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img src={row.original.merchant_img_url} />
            </div>
            <div className="ml-4">
              <p className="text-sm text-black">{value}</p>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Location",
      accessor: "location_name",
      Cell: ({ row }) => {
        let location = row.original.location.location_name;
        row.values.location_name = row.original.location.location_name;
        return <p className="text-sm text-gray-900">{location}</p>;
      },
    },
    {
      Header: "Food Type",
      accessor: "merchant_tags",
      Cell: ({ row }) => {
        let food_types = [];

        if (row.original.merchant_tags.length > 0) {
          food_types = row.original.merchant_tags.map(
            (obj) => obj.tag_cat_name
          );
        }

        return (
          <p className="text-sm text-gray-900">
            <MultipleString data={food_types} />
          </p>
        );
      },
    },
    // {
    //   Header: "Email",
    //   accessor: "merchant_email",
    // },
    // {
    //   Header: "Mobile",
    //   accessor: "merchant_mobile",
    // },
    // {
    //   Header: "Merchant Uen",
    //   accessor: "merchant_uen",
    // },
    {
      Header: "Licensee Name",
      accessor: "merchant_poc",
    },
    {
      Header: "Unit No",
      accessor: "merchant_unit_no",
    },
    {
      Header: "Merchant Latitude",
      accessor: "merchant_lat",
      Cell: ({ row }) => {
        if (row.values.merchant_lat == null) {
          return <div>-</div>;
        } else {
          return <div>{row.values.merchant_lat}</div>;
        }
      }
    },
    {
      Header: "Merchant Longitude",
      accessor: "merchant_lng",
      Cell: ({ row }) => {
        if (row.values.merchant_lng == null) {
          return <div>-</div>;
        } else {
          return <div>{row.values.merchant_lng}</div>;
        }
      }
    },
    {
      Header: "Takeaway Charge",
      accessor: "takeaway_charge",
    },
    {
      Header: "Dishwashing Txn Fee",
      accessor: "dishwashing_txn_fee",
    },
    {
      Header: "GPO Commission Fee",
      accessor: "merchant_gpo_commission_fee",
    },
    {
      Header: "GPO Commission Fee Rate",
      accessor: "merchant_gpo_commission_fee_rate",
    },
    {
      Header: "Merchant Start Time",
      accessor: "merchant_start_time",
    },
    {
      Header: "Merchant End Time",
      accessor: "merchant_end_time",
    },
    {
      Header: "Break Start Time",
      accessor: "break_start_time",
    },
    {
      Header: "Break End Time",
      accessor: "break_end_time",
    },
    {
      Header: "Merchant Discount Quota",
      accessor: "merchant_discount_quota",
    },
    {
      Header: "Merchant Discount Quota Balance",
      accessor: "merchant_discount_quota_balance",
      Cell: ({ row }) => {
        if (row.values.merchant_discount_quota_balance == null) {
          return <div>0</div>;
        } else {
          return <div>{row.values.merchant_discount_quota_balance}</div>;
        }
      }
    },
    {
      Header: "Merchant Discount Start Time",
      accessor: "merchant_discount_start_time",
      Cell: ({ value }) => formatDateAndTime(value),
    },
    {
      Header: "Merchant Discount End Time",
      accessor: "merchant_discount_end_time",
      Cell: ({ value }) => formatDateAndTime(value),
    },
    // {
    //   Header: "Is Operating",
    //   accessor: "is_operating",
    // },
    {
      Header: "Nets Click MID",
      accessor: "nets_click_mid",
    },
    {
      Header: "Nets Click TID",
      accessor: "nets_click_tid",
    },
    {
      Header: "Nets QR MID",
      accessor: "nets_qr_mid",
    },
    {
      Header: "Nets QR TID",
      accessor: "nets_qr_tid",
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;

          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        let merchantObj = {
          merchantName: row.original.merchant_name,
          id: row.original.merchant_id,
        };
        return (
          <div className=" text-gray-900  flex space-x-1 ">
            <button type="button">
              <Link
                to={{
                  pathname: `/configurations/merchantlocations/${locationName}/emenu/${merchantObj.id}`,
                }}
              >
                <Cards aria-hidden="true" className="w-6 h-6" />
              </Link>
            </button>

            <button
              type="button"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" className="w-6 h-6" />
            </button>
            {localStorage.getItem("roleid") == 2 || localStorage.getItem("roleid") == 5 ?
            <button
              type="button"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" className="w-6 h-6" />
            </button>
            : <></> }
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="mt-4 flex mobile:pt-4  mobile: pb-4 mobile:space-x-2">
          <button
            type="button"
            className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => downloadFiles()}
          >
            Export Data <DocumentDownload className="ml-3" />
          </button>
          <button
            type="button"
            className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setUploadModal(true)}
          >
            Upload Merchant <DocumentUpload className="ml-3" />
          </button>
          <button
            type="button"
            className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setCreateModal(true)}
          >
            Create Merchant <Additem className="ml-3" />
          </button>
        </div>
      </div>

      {isCreateModalOpen && (
        <CreateMerchantModal setCreateModal={setCreateModal} load={setLoad} />
      )}
      {isUploadModalOpen && (
        <UploadMerchantModal setUploadModal={setUploadModal} />
      )}
      {isUpdateModal && (
        <UpdateMerchantModal
          setUpdateModal={setUpdateModal}
          load={setLoad}
          row={rowToEdit}
        />
      )}
      {isDeleteModal && (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          load={setLoad}
          url={commonConfigs.apiUrls.UpdateMerchants(rowToEdit.merchant_id)}
        />
      )}
      {data != null ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MerchantsLayout;
