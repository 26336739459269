import axios from "axios";
import Select from "react-select";
import { InfoCircle } from "iconsax-react";
import { useForm, Controller } from "react-hook-form";
import commonConfigs from "../../../../config/config";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { getRandomUUID } from "../../../../utils/custom/helper";
// import { AZURE_BLOB_CONTAINER_PRODUCTS } from "../../../../constants";
// import { useUploadFile } from "../../../../commons/components/uploadFileCard";

const user = JSON.parse(localStorage.getItem("user"));
const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

const options = [
  { value: 0, label: "False" },
  { value: 1, label: "True" },
];

export default function CreateAddOn({ setCreateModal, id, load, locationName, merchantName  }) {
  const getEMenuCatUrl = commonConfigs.apiUrls.GetEMenuCategory();
  const getHpbUrl = commonConfigs.apiUrls.HPB();
  const getMerchantEMenuUrl = commonConfigs.apiUrls.GetMerchantEMenu(id);

  const [open, setOpen] = useState(true);
  const [category, setCategory] = useState([]);
  const [hpb, setHpb] = useState([]);
  const [menus, setMenus] = useState([]);

  const [itemName, setItemName] = useState("");
  const [skuDefaultValue, setSkuDefaultValue] = useState('');
  const [generated, setGenerated] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  function getStallNumber(merchantName) {
    const match = merchantName.match(/Stall\s+(\d+)/);
    return match ? match[1] : '';
  }

  // const [file, fileCard, uploadFile] = useUploadFile({
  //   container: AZURE_BLOB_CONTAINER_PRODUCTS,
  // });

  const [optionLoad, setOptionLoad] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      is_visible: {
        value: 1,
        label: "True",
      },
      add_on_upper_limit: 99,
    },
  });

  function formatData(data) {
    var is_visible = data.is_visible.value;
    var hpb_health_choice = "";
    var add_on_upper_limit = data.add_on_upper_limit;
    var product_desc = "";

    if (data.product_cat) {
      var cat = [];
      if (data.product_cat.length > 0) {
        data.product_cat.forEach((element) => {
          cat.push(element.value);
        });
      }
    }

    if (data.tagged_products) {
      var products = [];
      if (data.tagged_products.length > 0) {
        data.tagged_products.forEach((element) => {
          products.push(element.value);
        });
      }
    } else {
      products = null;
    }

    if (data.hpb_health_choice) {
      hpb_health_choice = data.hpb_health_choice.value;
    } else {
      hpb_health_choice = null;
    }

    if (data.product_desc) {
      product_desc = data.product_desc.value;
    } else {
      product_desc = "";
    }

    var newData = data;

    newData.product_name = itemName;
    newData.product_sku = skuDefaultValue;
    newData.calorie_color_sys_code = 1;
    newData.hpb_health_choice = hpb_health_choice;
    newData.product_desc = product_desc;
    newData.is_visible = is_visible;
    newData.is_add_on = 1;
    newData.tagged_products = products;
    newData.product_cat = cat;
    newData.product_img_url = "";
    newData.updated_by = user.account_id;
    newData.add_on_upper_limit = add_on_upper_limit;

    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button
    const newData = formatData(data);

    console.log(newData);

    // if (file) {
    //   const image_url = await uploadFile(`products/${getRandomUUID()}`);

    //   if (image_url) data.product_img_url = image_url;
    // }

    axios
      .post(commonConfigs.apiUrls.CreateAddOn(id), newData, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setCreateModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  useEffect(() => {
    async function GetEMenuCat() {
      await axios
        .get(getEMenuCatUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          setCategory(res.data.result.data);
          setOptionLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getHpb() {
      await axios
        .get(getHpbUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          setHpb(res.data.result.data);

          // if (res.data.result.data.length > 0) {
          //   reset({
          //     hpb_health_choice: {
          //       value: res.data.result.data[0].hpb_cat_id,
          //       label: res.data.result.data[0].hpb_cat_name,
          //     },
          //   });
          // }
          setOptionLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getMerchantData() {
      await axios
        .get(getMerchantEMenuUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          setMenus(res.data.result.data);
          setOptionLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (optionLoad) {
      GetEMenuCat().catch((err) => {
        console.log("err for category: " + err);
      });
      getHpb().catch((err) => {
        console.log("err for hpb: " + err);
      });
      getMerchantData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, []);

  const categoryOptions = category.map((d) => ({
    value: d.product_cat_id,
    label: d.product_cat_name,
  }));

  const hpbOptions = hpb.map((d) => ({
    value: d.hpb_cat_id,
    label: d.hpb_cat_name,
  }));

  const menuOptions = menus.map((d) => ({
    value: d.product_id,
    label: d.product_name,
  }));

  const handleNameChange = (event) => {
    setItemName(event.target.value);
  };

  const handleChange = (event) => {
    setSkuDefaultValue(event.target.value);
    setGenerated(false);
  };

  const handleGenerateSku = () => {
    const cleanedItemName = itemName
      .replace(/\[.*?\]/g, '')
      .replace(/[^\w\s()]/g, "")
      .replace(/\(([^)]+)\)/g, (match) => {
        const matchInsideParentheses = /\(([^)]+)\)/.exec(match);
        if (matchInsideParentheses) {
          const firstWord = matchInsideParentheses[1].split(' ')[0];
          const firstLetter = firstWord.charAt(0);
          return `(${firstLetter})`;
        }
        return match;
      })
      .replace(/[\[\]()]/g, '') // Remove brackets and parentheses
      .trim();
  
    console.log(cleanedItemName);
  
    const stallNumber = getStallNumber(merchantName);
  
    const skuValue = `${locationName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .join("")}${stallNumber}GI${cleanedItemName
        .split(" ")
        .map((word) => {
          if (/^\d+$/.test(word)) {
            return word;
          } else if (/^[A-Za-z]/.test(word)) {
            return word.charAt(0).toUpperCase();
          } else {
            return "";
          }
        })
        .join("")}`;
  
    const modifiedSkuValue = skuValue.replace(/GI(\d+)(?![^(]*\))/g, 'GI');
  
    setSkuDefaultValue(modifiedSkuValue);
    setGenerated(true);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setCreateModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Create Add On Item
                    </Dialog.Title>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_cat"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Category
                      </label>
                      <Controller
                        name="product_cat"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={categoryOptions}
                            isSearchable={true}
                            placeholder="Select a Category"
                            className="text-sm"
                            isMulti={true}
  
                          />
                        )}
                      />
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="product_name" className="block text-sm font-medium text-yellow-800">
                        Item Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_name"
                          id="product_name"
                          className={
                            !errors.product_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Product Name"
                          value={itemName}
                          onChange={handleNameChange}
                          required={true}
                        />
                        {errors.product_name?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_sequence"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Sequence
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          name="product_sequence"
                          id="product_sequence"
                          className={
                            !errors.product_sequence
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Sequence"}
                          {...register("product_sequence", { required: true })}

                        />
                        {errors.product_sequence?.type === "required" && errorIcon}
                      </div>
                    </div>
                    
                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="product_sku" className="block text-sm font-medium text-yellow-800">
                        Item SKU
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_sku"
                          id="product_sku"
                          className={
                            !errors.product_sku
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Item SKU"
                          value={generated ? skuDefaultValue : skuDefaultValue} // Use generated state to conditionally handle value prop
                          onChange={handleChange}
                          required={true}
                        />
                        {errors.product_sku?.type === "required" && errorIcon}
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 pr-3 flex items-center"
                          onClick={handleGenerateSku}
                        >
                          Generate
                        </button>
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_upc"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item UPC
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_upc"
                          id="product_upc"
                          className={
                            !errors.product_upc
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item UPC"}
                          {...register("product_upc", { required: false })}

                        />
                        {errors.product_upc?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_code"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Product Code (H365)
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_code"
                          id="product_code"
                          className={
                            !errors.product_code
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Product Code (H365)"}
                          {...register("product_code", { required: false })}

                        />
                        {errors.product_code?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_desc"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Description (max 100 chars)
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <textarea
                          type="text"
                          name="product_desc"
                          id="product_desc"
                          className={
                            !errors.product_desc
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Description"}
                          {...register("product_desc", { required: false })}

                        />
                        {errors.product_desc?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_price"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Price
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="-9999999" // Set a lower bound for negative numbers
                          // max="9999999" // Set an upper bound for positive numbers
                          // min="0"
                          step="any"
                          name="product_price"
                          id="product_price"
                          className={
                            !errors.product_price
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Price"}
                          {...register("product_price", {
                            required: true,
                            valueAsNumber: true,
                            pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                            // validate: (number) => parseInt(number) > 0,
                          })}
                        />
                        {errors.product_price?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="calorie_count"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Calorie Count
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="-1"
                          name="calorie_count"
                          id="calorie_count"
                          className={
                            !errors.calorie_count
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Calorie Count"}
                          {...register("calorie_count", {
                            // required: true,
                            valueAsNumber: true,
                          })}

                        />
                        {errors.calorie_count?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="hpb_health_choice"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        HPB Healthier Choice
                      </label>
                      <Controller
                        name="hpb_health_choice"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={hpbOptions}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
  
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="tagged_products"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Tagged Products
                      </label>
                      <Controller
                        name="tagged_products"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={menuOptions}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
                            isMulti={true}
  
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="add_on_upper_limit"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Add On Upper Limit
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          name="add_on_upper_limit"
                          id="add_on_upper_limit"
                          className={
                            !errors.add_on_upper_limit
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Add On Upper Limit"}
                          {...register("add_on_upper_limit", {
                            required: true,
                            valueAsNumber: true,
                            min: 0,
                            // max: 5,
                          })}

                        />
                        {errors.add_on_upper_limit?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="is_visible"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Is Visible?
                      </label>
                      <Controller
                        name="is_visible"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={options}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
  
                          />
                        )}
                      />
                    </div>

                    {/* <div className="sm:col-span-6 mt-2">
                      <label
                        htmlFor="upload_file"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Upload file
                      </label>
                      {fileCard}
                    </div> */}


                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setCreateModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
