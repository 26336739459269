import { Fragment, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import axios from "axios";
import commonConfigs from "../../../../../config/config";
import ReactDatePicker from "react-datepicker";
import { format } from "date-fns";
import { InfoCircle } from "iconsax-react";
import DatePicker from "react-datepicker";

const user = JSON.parse(localStorage.getItem("user"));
const getLocationsUrl = commonConfigs.apiUrls.GetLocations();
const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function UpdateEVoucherModal({ setOpenModal, row, load }) {
  console.log(row);
  const id = row.voucher_id;

  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(true);
  const [voucherCode, setVoucherCode] = useState();
  const [voucherAmount, setVoucherAmount] = useState();
  const [entitlementQty, setEntitlementQty] = useState();
  const [voucherExpiry, setVoucherExpiry] = useState(
    new Date(row.voucher_expiry)
  );
  const [locations, setLocations] = useState([]);
  const [voucherQty, setVoucherQty] = useState();
  const [lazyData, setLazyData] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      voucher_type:
        row.voucher_type == 1
          ? { value: 1, label: "Non-Serialized" }
          : { value: 2, label: "Serialized" },
      voucher_qty: row.voucher_qty,
      voucher_code: row.voucher_code,
      voucher_value: row.voucher_value,
      entitlement_qty: row.entitlement_qty,
    },
  });

  function formatData(data) {
    var newData = data;
    var voucher_type = data.voucher_type.value;

    if (data.claimable_locations) {
      var claimable_locations = [];
      if (data.claimable_locations.length > 0) {
        data.claimable_locations.forEach((item) => {
          claimable_locations.push(item.value);
        });
      }
    } else {
      claimable_locations = null;
    }
    newData.voucher_type = voucher_type;
    newData.voucher_expiry = voucherExpiry;
    newData.claimable_locations = claimable_locations;
    newData.updated_by = user.account_id;
    newData.entitlement_qty = data.entitlement_qty.value;
    console.log(newData);
    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button
    var newData = formatData(data);

    console.log('formatted data', newData);

    axios
      .put(commonConfigs.apiUrls.UpdateVouchers(id), newData, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setOpenModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };
  async function getLocations() {
    await axios
      .get(getLocationsUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setLocations(res.data.result.data.filter(item => item.is_deleted === 0));

        const locationsFound = res.data.result.data.filter(item => item.is_deleted === 0).filter((location) =>
          row.claimable_locations.find(
            ({ location_name }) => location.location_name == location_name
          )
        );

        var formattedLocations = [];

        locationsFound.forEach((location) => {
          formattedLocations.push({
            value: location.location_id,
            label: location.location_name,
          });

          setLazyData((lazyData["claimable_locations"] = formattedLocations));
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const getSelectData = async () => {
      await getLocations().catch((err) => {
        console.log("err for locations: " + err);
      });
    };

    getSelectData()
      .then(() => {
        console.log("getSelectData success");
        reset(lazyData);
      })
      .catch((err) => {
        console.log("err for selected data: " + err);
      });
  }, []);

  const locationOptions = locations.map((d) => ({
    value: d.location_id,
    label: d.location_name,
  }));

  const voucherTypeOptions = [
    { value: 1, label: "Non-Serialized" },
    { value: 2, label: "Serialized" },
    { value: 4, label: "Product"},
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setOpenModal(false)}
            />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Update eVoucher
                    </Dialog.Title>
                    <div className="mt-2">
                      <label
                        htmlFor="voucher_type"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Voucher Type
                      </label>
                      <div className="mt-2 relative rounded-md shadow-sm">
                        <Controller
                          name="voucher_type"
                          control={control}
                          render={({ field }) => (
                            <Select
                              isClearable
                              {...field}
                              options={voucherTypeOptions}
                              isSearchable={true}
                              placeholder="Select Voucher Type"
                              className="text-sm"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label
                        htmlFor="voucher_code"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Voucher Code
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="voucher_code"
                          id="voucher_code"
                          className={
                            !errors.voucher_code
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Voucher Code"
                          {...register("voucher_code", { required: true })}
                          value={voucherCode}
                          onChange={(e) => setVoucherCode(e.target.value)}
                        />
                        {errors.voucher_code?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2">
                      <label
                        htmlFor="voucher_value"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Voucher Amount
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="voucher_value"
                          id="c"
                          className={
                            !errors.voucher_value
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Voucher Amount"
                          {...register("voucher_value", { required: true })}
                          value={voucherAmount}
                          onChange={(e) => setVoucherAmount(e.target.value)}
                        />
                        {errors.voucher_value?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="sm:col-span-6 mt-2">
                      <label
                        htmlFor="voucher_expiry"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Voucher Expiry
                      </label>
                      <Controller
                        control={control}
                        name="voucher_expiry"
                        render={({ field }) => (
                          <DatePicker
                            selected={voucherExpiry}
                            onChange={(date) => {
                              setVoucherExpiry(date);
                              field.onChange(date); // Update the form value
                            }}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            placeholderText="Select Expiry Date and Time"
                            className="w-full rounded-md border-gray-300 border text-sm"
                          />
                        )}
                      />
                    </div>
                    <div className="sm:col-span-6 mt-2">
                      <label
                        htmlFor="claimable_locations"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Claimable Location
                      </label>
                      <Controller
                        name="claimable_locations"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={locationOptions}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
                            isMulti={true}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <label
                    htmlFor="voucher_qty"
                    className="block text-sm font-medium text-yellow-800"
                  >
                    Voucher Qty
                  </label>

                  <input
                    type="text"
                    name="voucher_qty"
                    id="voucher_qty"
                    className={
                      !errors.voucher_qty
                        ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                        : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                    }
                    placeholder="Enter Voucher Qty"
                    value={voucherQty}
                    onChange={(e) => setVoucherQty(e.target.value)}
                    {...register("voucher_qty", { required: true })}
                  />
                  {errors.voucher_qty?.type === "required" && errorIcon}
                </div>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <label
                    htmlFor="entitlement_qty"
                    className="block text-sm font-medium text-yellow-800"
                  >
                    Voucher Entitlement Qty
                  </label>

                  <input
                    type="text"
                    name="entitlement_qty"
                    id="entitlement_qty"
                    className={
                      !errors.entitlement_qty
                        ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                        : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                    }
                    placeholder="Enter Voucher Entitlement Qty"
                    value={entitlementQty}
                    onChange={(e) => setEntitlementQty(e.target.value)}
                    {...register("entitlement_qty", { required: true })}
                  />
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
