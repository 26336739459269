import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import commonConfigs from "../../../../config/config";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { InfoCircle } from "iconsax-react";
import Multiselect from 'multiselect-react-dropdown';

const createRolesUrl = commonConfigs.apiUrls.AccessRoles();
const getAccessTypesUrl = commonConfigs.apiUrls.AccessTypes();
const user = JSON.parse(localStorage.getItem("user"));

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function CreateAccessRoleModal({ setOpenModal, load }) {
  const [open, setOpen] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  // const [options, setOptions] = useState([]);
  // const [noParent, setNoParent] = useState([]);
  // const [withChild, setWithChild] = useState([]);
  const [accessOption, setAccessOptions] = useState([]);
  const [loadData, setLoadData] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({});

  function formatData(data) {
    var array = [];

    if (data.access_rights.length > 0) {
      data.access_rights.forEach((element) => {
        array.push(element.value);
      });
    }

    var newData = data;
    newData.access_multiple_location = data.access_multiple_location.value;
    newData.access_rights = array;
    newData.updated_by = user.account_id;
    return newData;
  }

  const onSubmit = (data) => {
    setIsDisabled(true); // Disable the submit button

    formatData(data);
    console.log(data);
    axios
      .post(createRolesUrl, data, { headers: commonConfigs.apiHeader })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setOpenModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  useEffect(() => {
    async function getAccessTypes() {
      await axios
        .get(getAccessTypesUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          var finalAccess = [];
          // var withChild = [];

          var response = res.data.result.data;
          console.log(response);

          // console.log(response);

          // for (var i = 0; i < response.length;i++){
          //   if (response[i].access_name_option == null) {
          //     noParent.push(response[i]);
          //   } else {
          //     withChild.push(response[i])
          //   }
          // }
          // console.log(noParent);
          // console.log(withChild);
          // setNoParent(noParent);
          // setWithChild(withChild);
          for (var i = 0; i < response.length; i++) {
            if (response[i].access_name_option !== null) {
              finalAccess.push({ value: response[i].access_id, label: response[i].access_name + " [" + response[i].access_name_option + "]" })
            } else {
              finalAccess.push({ value: response[i].access_id, label: response[i].access_name })
            }
          }
          // console.log(finalAccess)
          // setOptions(res.data.result.data);
          setAccessOptions(finalAccess);
          setLoadData(false);
        })
        .catch((err) => {
          console.log(err);
        });

    }
    if (loadData) {
      getAccessTypes().catch((err) => {
        console.log("err for access types: " + err);
      });
    }
  }, []);

  // const accessOptions = options.map((d) => ({
  //   value: d.access_id,
  //   label: d.access_name,
  // }));

  // console.log(accessOptions);

  const accessMultipleLocationOptions = [
    { value: 0, label: "False" },
    { value: 1, label: "True" }
  ]
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setOpenModal(false)}
            />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Create New Access Role
                    </Dialog.Title>
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="access_rights"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Access Types
                      </label>
                      <Controller
                        name="access_rights"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={accessOption}
                            isSearchable={true}
                            isMulti={true}
                            placeholder="Select at least one option"
                            className="text-sm"
                            maxMenuHeight={130}
                          />
                        )}
                      />
                    </div>
                    {/* <div className="sm:col-span-6">
                      <label
                        htmlFor="access_rights"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Access Type - 2
                      </label>
                      <Controller
                        name="access_rights"
                        control={control}
                        render={({ field }) => (
                          <Multiselect
                          placeholder="Select Access Modules"
                          options={withChild}
                          groupBy="access_name"
                          displayValue="access_name_option"
                          showCheckbox={true}
                          height={130}
                        />
                        )}
                      />
                    </div> */}
                    {/* <Multiselect
                            placeholder="Select Access Modules"
                            options={withChild}
                            groupBy="access_name"
                            displayValue="access_name_option"
                            showCheckbox={true}
                            height={130}
                          /> */}
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="role_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Role Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="role_name"
                          id="role_name"
                          className={
                            !errors.role_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Role Name"
                          {...register("role_name", { required: true })}
                        />
                        {errors.role_name?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="access_multiple_location"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Access Multiple Location
                      </label>
                      <Controller
                        name="access_multiple_location"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={accessMultipleLocationOptions}
                            isSearchable={false}
                            isMulti={false}
                            placeholder="Select either true or false"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>

                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
