import styles from '../../../styles/styles.module.css';

const GetPieChart = () => {
    return (
        <div style={{display: 'grid', 'gridTemplateAreas':`'details details code`,'gridTemplateColumns': 'repeat(3, minmax(0, 1fr))', gap:3, maxWidth: 'inherit'}}> 
        
        {/* Details component */}
        <div style={{gridArea: 'details', marginBottom: '0px'}}>
            <div className={styles.cardbod}>
                <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#378805'}}>Get by Pie Chart</h3>
                <hr />
                <p style={{marginTop: '10px'}}> This API retrieves the nutrition data in the form of a PIE chart. </p>

                {/* API Parameters */}
                <div style={{marginTop: '20px'}}>
                    <h6 style={{textDecoration: 'underline'}}>Parameters</h6>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a.SSO ID</p>
                        <p>b. date filter option : 1-d, 1-m, 1-y</p>
                    </div>

                    <h6 style={{textDecoration: 'underline', marginTop: '10px'}}>Output data</h6>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a. high calorie count</p>
                        <p>b. high total product</p>
                        <p>c. mid calorie count</p>
                        <p>d. mid total product</p>
                        <p>e. low calorie count</p>
                        <p>f. low total product</p>
                        <p>g. invalid calorie count</p>
                        <p>h. invalid total product</p>
                    </div>
                </div>
            </div>
        </div>

        {/* Code component */}
        <div style={{gridArea: 'code', marginBottom: '0px', backgroundColor: '#3f3f3f'}}>
            <div style={{backgroundColor: '#378805', color: 'white', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', paddingTop: '5px' , paddingBottom: '5px'}}>
                Get by Pie Chart API
            </div>

            <div>
                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'15px', color: 'white'}}>Request (GET)</h6>
                    <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                        <code className={styles.code}>api/v1/common/health/individual-consumption-trends/pie-charts/item-purchases?sso_id=&date_filter=</code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'10px', color: 'white'}}>Example</h6>
                    <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                        <code className={styles.code}>/api/v1/common/health/individual-consumption-trends/pie-charts/item-purchases?sso_id=1234f123-123f-4e30-123-3c4f03bb123&date_filter=1-m</code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'10px', color: 'white'}}>Header</h6>
                    <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                    <code>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        <span className={styles.codestyle2}>"authorization":</span> <span className={styles.codestyle3}>"Bearer example"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>dy1UG9zWBfLRVNTQtWQr</span>
                        <br />
                        <span className={styles.codestyle1}>{"}"}</span>
                    </code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response</h6>
                    <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                        <code>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        
                        <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        <span className={styles.codestyle2}>"data"</span><span className={styles.codestyle1}>:</span>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br />

                        <div style={{marginLeft: '15px'}}>
                        {/* start of data */}   
                        <span className={styles.codestyle2}>"high"</span><span className={styles.codestyle1}>:</span>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br/>
                        <div style={{marginLeft: '35px'}}>
                            <span className={styles.codestyle2}>"calorie_count"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 705<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2}>"total_product"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 1</span>
                            <br />
                        </div>

                        <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"}"}</span><span className={styles.codestyle1}>,</span>
                        <br/>

                        <span className={styles.codestyle2}>"mid"</span><span className={styles.codestyle1}>:</span>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br/>
                        <div style={{marginLeft: '35px'}}>
                            <span className={styles.codestyle2}>"calorie_count"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 0<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2}>"total_product"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 0</span>
                            <br />
                            </div>

                        <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"}"}</span><span className={styles.codestyle1}>,</span>
                        <br/>

                        <span className={styles.codestyle2}>"low"</span><span className={styles.codestyle1}>:</span>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br/>
                        <div style={{marginLeft: '35px'}}>
                            <span className={styles.codestyle2}>"calorie_count"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 1074<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2}>"total_product"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 19</span>
                            <br />
                            </div>

                        <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"}"}</span><span className={styles.codestyle1}>,</span>
                        <br/>

                        <span className={styles.codestyle2}>"invalid"</span><span className={styles.codestyle1}>:</span>
                        <span className={styles.codestyle1}>{"["}</span>
                        <br/>
                        <div style={{marginLeft: '35px'}}>
                            <span className={styles.codestyle2}>"calorie_count"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> -27<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2}>"total_product"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 23</span>
                            <br />
                        </div>
                        </div>

                        {/* ending brackets */}
                        <span className={styles.codestyle1} style={{marginLeft: '20px'}}>{"}"}</span>
                        <br/>
                        <span className={styles.codestyle1} style={{marginLeft: '15px'}}>{"}"}</span>
                        <br/>
                        <span className={styles.codestyle1}>{"}"}</span>
                        </code>
                    </blockquote>   
                </div>

            </div>
        </div>
        </div>
    );
  };
  
  export default GetPieChart;
