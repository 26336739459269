import styles from '../../../styles/styles.module.css';

const RequestDishware = () => {
    return (
        <div style={{display: 'grid', 'gridTemplateAreas':`'details details code`,'gridTemplateColumns': 'repeat(3, minmax(0, 1fr))', gap:3, maxWidth: 'inherit'}}> 
        
        {/* Details component */}
        <div style={{gridArea: 'details', marginBottom: '0px'}}>
            <div className={styles.cardbod}>
                <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#378805', marginTop: '25px'}}>Request Dishware Cleaning</h3>
                <hr />
                <p style={{marginTop: '10px'}}> This API requests for dishwashing cleaning services. </p>

                {/* API Parameters */}
                <div style={{marginTop: '20px'}}>
                    <h6 style={{textDecoration: 'underline'}}>Parameters</h6>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a. merchant_id : merchant's ID</p>
                        <p>b. org_id : organisation's ID</p>
                        <p>c. location_id : location's ID</p>
                        <p>d. dishwashing_total_fee : total fee for dishwashing</p>
                        <p>e. dishwares : array of details below about dishware</p>
                        <div style={{marginLeft: '18px', color: '#378805'}}>
                            <p>i. dishware_id : dishware's ID</p>
                            <p>ii. dishware_qty : quantity of this particular dishware</p>
                            <p>iii. dishwashing_fee : fee for dishwashing</p>
                            <p>iv. dishware_img_url : image url for dishware item</p>
                        </div>
                    </div>

                    <h6 style={{textDecoration: 'underline', marginTop: '10px'}}>Output data</h6>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a. status of dishware request</p>
                    </div>
                </div>
            </div>
        </div>

        {/* Code component */}
        <div style={{gridArea: 'code', marginBottom: '0px', backgroundColor: '#3f3f3f'}}>
            <div style={{backgroundColor: '#378805', color: 'white', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', paddingTop: '5px' , paddingBottom: '5px'}}>
                Request Dishwares Cleaning API
            </div>

            <div>
                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'15px', color: 'white'}}>Request (GET)</h6>
                    <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                        <code className={styles.code}>/api/v1/transactions/requests/dishwares</code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'10px', color: 'white'}}>Sample Request Body</h6>
                    <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                    <code>
                        <span className={styles.codestyle1} style={{marginLeft: '10px'}}>{"{"}</span>
                        <br/>
                        <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "a5f54016-1cd4-495d-849f-3b5b96b23469"<span className={styles.codestyle1}>,</span></span>
                        <br />
                        <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"org_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "b7ad3a7e-513d-4f5b-a7fe-73363a38699"<span className={styles.codestyle1}>,</span></span>
                        <br />
                        <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"location_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "b7fb763c-eadb-478c-ae87-fa4f3a71b486"<span className={styles.codestyle1}>,</span></span>
                        <br />
                        <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"dishwashing_total_fee"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 20<span className={styles.codestyle1}>,</span></span>
                        <br />
                        
                        <span className={styles.codestyle2}>"dishwares"</span><span className={styles.codestyle1}>:</span>
                        <span className={styles.codestyle1}>{"["}</span>
                        <br />
                        <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"{"}</span>
                        <br/>
                        <div>
                            {/* details of first array */}
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"dishware_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 4<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"dishware_qty"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 27<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"dishware_fee"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 10<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"dishware_img_url"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "https://live.staticflickr.com/6119/6427879747_725294b3fd_b.jpg"</span>
                            <br />

                        </div>
                        <span className={styles.codestyle1} style={{marginLeft: '30px'}}>{"}"}</span>
                        <br/>
                        <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span>
                        <br/>
                        <span className={styles.codestyle1} style={{marginLeft: '10px'}}>{"}"}</span>
                        <br/>
                    </code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response</h6>
                    <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                        <code>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        
                        <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        <span className={styles.codestyle2} style={{marginLeft: '20px'}}>"message"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Success, dishware request has been submitted"</span>
                        <br />
                        <span className={styles.codestyle1}style={{marginLeft: '20px'}}>{"}"}</span>
                        <br/>
                        <span className={styles.codestyle1}>{"}"}</span>
                        </code>
                    </blockquote>   
                </div>

            </div>
        </div>
        </div>
    );
  };
  
  export default RequestDishware;
