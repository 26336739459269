import { createContext, useState } from "react";

const tabs = [
  { name: "Main Items" },
  { name: "Add Ons" },
  { name: "Preferences" },
];

export const EmployeeTabsContext = createContext();
export const EmployeeCurrentTabContext = createContext();

export const EmployeeContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <EmployeeTabsContext.Provider value={{ tabs }}>
      <EmployeeCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </EmployeeCurrentTabContext.Provider>
    </EmployeeTabsContext.Provider>
  );
};
