import { useState, useEffect } from "react";
// import { ArrowRight, InfoCircle } from "iconsax-react";
import { useForm } from "react-hook-form";
import commonConfigs from "../../../config/config";
import axios from "axios";
import OTP from "../components/otpModalWithNoSSO";
import firebaseConfig from "../../../config/firebaseConfig";
import { initializeApp } from "firebase/app";
import { setUser } from "../../../services/tokenService";
const headers = commonConfigs.apiHeader;
const apiKey = headers["api-key"];

// const errorIcon = (
//   <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
//     <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
//   </div>
// );

const FeedbackPage = () => {
  const [isOpen, setOpen] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [email, setEmail] = useState();
  const [mobileNo, setMobileNo] = useState();
  const [userToken, setUserToken] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});

  function formatData(data) {
    var newData = data;
    newData.user_feedback_title = title;
    newData.user_feedback_desc = description;
    newData.user_feedback_email = email;
    newData.user_feedback_mobile = mobileNo;

    return newData;
  }

  const onSubmit = async (data) => {
    const newData = formatData(data);
    console.log(newData);

    console.log();

    axios
      .get(commonConfigs.apiUrls.VerifyMobile(mobileNo), {
        headers: {
          "api-key": apiKey,
          Authorization: "Bearer whatever",
        },
      })
      .then((res) => {
        // try {
        if (res.data.status == "success") {
          console.log(res);

          const user = {
            //account_id: res.data.result.data.account_id,
            mobile: mobileNo,
            access_token: res.data.result.data.access_token,
          };
          setUser(user);
          console.log(user);
          setUserToken(user);
          setOpen(true);
        } else {
          alert("Account does not exist");
          this.close();
        }
        // } catch (err) {
        //   console.log(err);
        // }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    localStorage.clear();
    initializeApp(firebaseConfig);
  }, []);

  return (
    <>
      <div className="min-h-screen bg-white desktop:flex" id="feedback">
        <div className="desktop:pt-60 mobile:pt-12 bg-yellow-800">
          <div className="mx-auto desktop:w-1/2 mobile:w-full mobile:text-center mobile:p-12 text-white">
            <h1 className="text-5xl mobile:text-4xl font-bold">Feedback | Delete User Account</h1>
            <p className="text-2xl mobile:text-xl mt-14">
              Please fill up the form and our team will respond to you.
            </p>
          </div>
        </div>
        <div className="flex-col flex justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 desktop:w-2/4 mobile:w-full">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="mt-8">
              <div className="mt-6">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 ">
                  <div className="space-y-1 ">
                    <label
                      htmlFor="user_feedback_email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email*
                    </label>
                    <div className="mt-1">
                      <input
                        id="user_feedback_email"
                        name="user_feedback_email"
                        type="email"
                        className={
                          !errors.user_feedback_email
                            ? "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            : "appearance-none block w-full px-3 py-2 border border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded-md shadow-sm  sm:text-sm"
                        }
                        placeholder={"Enter Email"}
                        {...register("user_feedback_email", { required: true })}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors.user_feedback_email?.type === "required"}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="user_feedback_mobile"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Mobile No*
                    </label>
                    <div className="mt-1">
                      <input
                        id="user_feedback_mobile"
                        name="user_feedback_mobile"
                        type="number"
                        className={
                          !errors.user_feedback_mobile
                            ? "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            : "appearance-none block w-full px-3 py-2 border border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded-md shadow-sm  sm:text-sm"
                        }
                        placeholder={"Enter Mobile No"}
                        {...register("user_feedback_mobile", {
                          required: true,
                          maxLength: 8,
                        })}
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                      />
                      {errors.user_feedback_mobile?.type === "required"}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="user_feedback_title"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title*
                    </label>
                    <div className="mt-1">
                      <input
                        id="user_feedback_title"
                        name="user_feedback_title"
                        type="text"
                        className={
                          !errors.user_feedback_title
                            ? "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            : "appearance-none block w-full px-3 py-2 border border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded-md shadow-sm  sm:text-sm"
                        }
                        placeholder={"Enter Title"}
                        {...register("user_feedback_title", { required: true })}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      {errors.user_feedback_title?.type === "required"}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="user_feedback_desc"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Description*
                    </label>
                    <div className="mt-1">
                      <input
                        id="user_feedback_desc"
                        name="user_feedback_desc"
                        type="textarea"
                        className={
                          !errors.user_feedback_desc
                            ? "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            : "appearance-none block w-full px-3 py-2 border border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded-md shadow-sm  sm:text-sm"
                        }
                        placeholder={"Enter Description"}
                        {...register("user_feedback_desc", { required: true })}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      {errors.user_feedback_desc?.type === "required"}
                    </div>
                  </div>
                  <div className="sm:col-span-6 mt-2">
                    {/* <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Upload Attachment
                    </label> */}
                    {/* <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"> */}
                    {/* <div className="space-y-1 text-center"> */}
                    {/* <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg> */}
                    {/* <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                          >
                            <span>Upload a file</span>
                            <input
                              id="user_feedback_file"
                              name="user_feedback_file"
                              type="user_feedback_file"
                              accept=".jpg"
                              className="sr-only"
                              onChange={(e) => {
                                setImageFile(e.target.files[0]);
                                setImage(e.target.files[0].name);
                              }}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">
                          PNG, JPG, GIF up to 10MB
                        </p> */}
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="space-x-2 items-center w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lime-500 hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <OTP
          setOpenModal={setOpen}
          mobile={mobileNo}
          email={email}
          desc={description}
          title={title}
          user={userToken}
        />
      )}
    </>
  );
};

export default FeedbackPage;
