import { useContext } from "react";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import HPBTabs from "../components/hpbTabs";
import { HPBCurrentTabContext } from "../context/hpbContext";
import HPBItemCatLayout from "./layout/hpbItemCategoryLayout";
import HPBMerchantCodesLayout from "./layout/hpbMerchantCodesLayout";

const HPBLayout = () => {

  const pages = [
    { name: "Configurations", href: "#", current: false },
    { name: "HPB H365", href: "/configurations/hpb", current: true },
  ];

  const { currentTab } = useContext(HPBCurrentTabContext);

  return (
    <div>
      <div className="w-full p-6">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <Breadcrumbs pages={pages} />
          </div>
        </div>
        <div className="mt-8 ">
          <div>
            <HPBTabs/>
            {currentTab.name === "HPB Category" ? (
            <div>
              <HPBItemCatLayout/>
            </div>
            ) : (
            <div>
              <HPBMerchantCodesLayout/>
            </div>

            )}
          </div> 
        </div>
      </div>
    </div>
  );
};

export default HPBLayout;
