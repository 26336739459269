import DashboardCard from "../../components/dashboardCard";
import { roundToTwo } from "../../../../utils/custom/helper";

export default function YTDRevenueCard({ title, subtitle, data }) {
  return (
    <DashboardCard>
      <div style={{minWidth: 0, minHeight: 0}}>
      <h3 className="text-xl">{title}</h3>
      
      {data.total_revenue.total_revenue ? <h2 className="text-yellow-800 text-4xl font-bold mb-4">${roundToTwo(data.total_revenue.total_revenue)}</h2> : <h2 className="text-yellow-800 text-4xl font-bold mb-4">$0</h2>}
      {/* {data.total_revenue.total_revenue ? <h2 className="text-yellow-800 text-4xl font-bold mb-4">${roundToTwo(data.total_revenue.total_revenue)}</h2> : (data.total_revenue.total_revenue === 0 ? <h2 className="text-yellow-800 text-4xl font-bold mb-4">No Sales</h2> : <h2 className="text-yellow-800 text-4xl font-bold mb-4">$0</h2>)} */}
        
      
      <h3 className="text-xl">{subtitle}</h3>
      <div>
        {data.revenue_stream.map((revenue) => (
          <div key={revenue.type}>
            <div className="flex justify-between mt-4 space-x-8">
              <div className="text-sm ">{revenue.platform_type}</div>

              <div className="flex flex-grow gap-x-4 justify-end">
                <div className={`text-sm ${
                    revenue.daily_percentage_change > 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}>
                  {roundToTwo(revenue.daily_percentage_change)}%
                </div>
                <div
                  className={`text-sm ${
                    revenue.daily_percentage_change > 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {revenue.daily_percentage_change > 0 ? "▲" : "▼"}
                </div>

                <div className=" text-sm text-gray-500">
                  ${roundToTwo(revenue.revenue)}
                </div>
              </div>
            </div>
            <div className="w-full border-t border-gray-300 mt-2"></div>
          </div>
        ))}
      </div>
      </div>
    </DashboardCard>
  );
}
