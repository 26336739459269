import BillingDonut from "./billingDonut";
import DashboardCard from "../../components/dashboardCard";

export default function OrderCard({ title, subtitle, data }) {
  console.log(data);
  return (
    
    <DashboardCard>
      <div style={{minHeight: 0, minWidth: 0, overflow: 'visible'}}>
      <h3 className="text-xl">{title}</h3>
      <div className="mt-8 mb-2" >
        <BillingDonut title={title} data={data} subtitle={subtitle} />
      </div>
      </div>
    </DashboardCard>
  );
}
