import React from 'react'
import {
  CCard,
  CCardBody,
  CRow,
} from '@coreui/react'
import styles from '../../styles/styles.module.css';
import Img from '../../assets/api_test.png'
import Img1 from '../../assets/api.png'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const TestIntro = () => {
    return (
        <CRow> 
        <Card className="border-top-0 border-bottom-0" style={{marginBottom: '0px', width: '60%'}}>
        <CardContent className={styles.cardbod}>
        <div name="test" style={{marginTop: '15px'}}>
         <h4 style={{fontWeight: 'bold'}}>Get Dishware Requests</h4>
         <hr />
         </div>
         <p className={styles.p}>This API requests for the dishware that requires cleaning services. </p>
         
         <h6 className={styles.h6}>Parameters: </h6>
         <div className={styles.div4}>
          <h6 style={{marginTop: '15px', marginBottom:'10px'}}>Input Data:</h6>
          <p className={styles.p}>1. <span style={{marginLeft:'5px'}}>Merchant ID</span></p>
          <p className={styles.p}>2. <span style={{marginLeft:'5px'}}>Organisation ID</span></p>
          <p className={styles.p}>3. <span style={{marginLeft:'5px'}}>Start period</span></p>
          <p className={styles.p}>4. <span style={{marginLeft:'5px'}}>End period</span></p>
          <p className={styles.p}>5. <span style={{marginLeft:'5px'}}>Status of dishware</span></p>
          <p className={styles.p}>6. <span style={{marginLeft:'5px'}}>API key</span></p>
          <div>
            {/*
            <div className={styles.div3}>
            <table>
            <thead>
              <tr>
                <th className={styles.tablestyle}>Contributor Type</th>
                <th className={styles.tablestyle}>Description</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td className={styles.tablestyle1}>0</td>
                <td className={styles.tablestyle}>All Contributors</td>
              </tr>
              </tbody>
              <tbody>
              <tr>
                <td className={styles.tablestyle1}>1</td>
                <td className={styles.tablestyle}>Events</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td className={styles.tablestyle1}>2</td>
                <td className={styles.tablestyle}>Community Kitchen</td>
              </tr>
              </tfoot>
            </table>
            </div>
            */}
            <h6>Output Data:</h6>
          <p className={styles.p}>Total number calories intake in that location.</p>
          </div>
         </div>
         </CardContent>
        </Card>
        
        {/* <Card className="border-top-0 border-bottom-0" style={{backgroundColor: '#3D3D3D', width: '40%', marginBottom: '0px', color: '#FFFFFF'}}>
        <CardContent className={styles.cardbod2}>
        <Card className="border-tertiary" style={{backgroundColor: '#80ba27', padding:'16px', width: '100%'}}>
          <h5 className={styles.h5}>Get Dishware Requests</h5>
          </Card>
          <div className={styles.div}>

          <h6 className={styles.detailHeader} style={{marginTop:'45px'}}>Request (GET)</h6>
          <blockquote className={styles.div2}>
          <code className={styles.code}>/api/developer/stats</code>
          </blockquote>

          <h6 className={styles.detailHeader} style={{marginTop:'45px'}}>Example</h6>
          <blockquote className={styles.div2}>
          <code className={styles.code}>/api/developer/stats</code>
          </blockquote>

          <h6 className={styles.detailHeader}>Post Body</h6>
          <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
            <code>
    <span className={styles.codestyle1}>{"{"}</span>
              <br />
              <span className={styles.codestyle2}>"apiKey":</span> <span className={styles.codestyle3}>"dy1UG9zWBfLRVNTQtWQr"</span><span className={styles.codestyle1}>,</span>
              <br />
              <span className={styles.codestyle2}>"type":</span> <span className={styles.codestyle3}>0</span>
              <br />
              <span className={styles.codestyle1}>{"}"}</span>
            </code>
          </blockquote>


          <h6 className={styles.detailHeader}>Sample Response</h6>
          <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
            <code>
              <span className={styles.codestyle1}>{"{"}</span>
              <br />
              <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
              <br />
              <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
              <br />
              <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"prevented"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "20"<span className={styles.codestyle1}>,</span></span>
              <br />
              <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"rescued"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "0"<span className={styles.codestyle1}>,</span></span>
              <br />
              <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"recycled"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "10"</span>
              <br />
              <span className={styles.codestyle1} style={{marginLeft: '15px'}}>{"}"}</span>
              <br />
              <span className={styles.codestyle1}>{"}"}</span>
            </code>
          </blockquote>
          </div>
        </CardContent>
        </Card> */}
        </CRow>
    );
  };
  
  export default TestIntro;
