import { Home } from "iconsax-react";

/**
 * @desc A function to display the current page with breadcrumbs
 * @param {*} props.pages Array objects with name, href and current fields.
 * @returns Breadcrumb navigation UI component 
 */

export default function Breadcrumbs(props) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex  items-center space-x-2 ">
        <li>
          <div>
            <a
              href="/dashboard/revenue"
              className="text-gray-400 hover:text-gray-500"
            >
              <Home className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
            </a>
          </div>
        </li>
        {props.pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center ">
              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <a
                href={page.href}
                className="ml-4 text-xl   font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? "page" : undefined}
              >
                {page.name}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
