import React, { useState, useEffect } from "react";
import Table from "../../../../commons/components/table";
import Spinner from "../../../../commons/components/spinner";
// import { format } from "date-fns";
// import commonConfigs from "../../../../config/config";
// import axios from "axios";
// import { Data } from "iconsax-react";


// const getGPOBySupplier = commonConfigs.apiUrls.GetGPOBySupplier();

export default function MainMenuLayout({ data, load, setLoad }) {
// export default function MainMenuLayout(props) {

  // const [data, setData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  // const [load, setLoad] = useState(true);

  // async function fetchGPOBySupplier() {
  //   console.log(props.data);
  //   setGPOBySupplier(props.data);
  //   setLoad(false);
  //   // await axios
  //   //   .get(getGPOBySupplier, { headers: commonConfigs.apiHeader })
  //   //   .then((res) => {
  //   //     console.log(res.data.result.data);
  //   //     setGPOBySupplier(res.data.result.data);
  //   //     setLoad(false);
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err);
  //   //   });
  // }

  useEffect(() => {
    console.log(data);
    // setGPOBySupplier(props.data);
    // setLoad(false);

    // if (load) {
    //   fetchGPOBySupplier().catch((err) => {
    //     console.log("err: " + err);
    //   });
    // }
  }, [load]);

  const expandedCols = [
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Time In",
      accessor: "time_in_datetime",
    },
    {
      Header: "Time Out",
      accessor: "time_out_datetime",
    },
  ];
  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "Email",
        accessor: "employee_email",
      },
      {
        Header: "Job Title",
        accessor: "job_title",
      },
      {
        Header: "Time In",
        accessor: "timestamp_in",
      },
      {
        Header: "Time Out",
        accessor: "timestamp_out",
      },
      {
        Header: "Details",
        id: "expander",
        Cell: ({ toggleAllRowsExpanded, row }) => {
          return (
            <button
              className="underline"
              onClick={() => {
                toggleAllRowsExpanded(false);
                row.toggleRowExpanded();
                console.log(row);
                setSelectedRow(row);
              }}
            >
              View More
            </button>
          );
        },
      },
     
    ],
    []
  );

  if (data) {
    return (
      <>
        <Table
          columns={columns}
          // data={data.filter((row) => row.is_add_on == 0)}
          data={data.details}
          filters={columns.map((column) => column.accessor)}
          expandedTable={
            selectedRow ? (
              <Table
                columns={expandedCols}
                data={selectedRow.original.history}
                // data={sampleCollapsibleData}
                // data={selectedRow.original.hpb_health_choice}
                filters={expandedCols.map((column) => column.accessor)}
                hasSearch={false}
                hasPagination={false}
              />
            ) : (
              <>No data</>
            )
          }
        />
      </>
    );
  } else {
    return (
      <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
        <div className="flex place-content-center w-full">
          <Spinner className="w-20 h-20" />
        </div>
      </div>
    );
  }
}
