import DishwareLayout from "./dishwareLayout";
// import { DishwareLocationsContextProvider } from "../context/dishwareLocationsContext";

const DishwareLocationsPage = () => {
  return (
    // <DishwareLocationsContextProvider>
      <DishwareLayout />
    // </DishwareLocationsContextProvider>
  );
};

export default DishwareLocationsPage;
