import Table from "../components/TITOtable";
import { useMemo, useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../config/config";
import Spinner from "../../../commons/components/spinner";

const ScheduleSideRowCard = ({ date, location  }) => {

  const [merchantTimecards, setMerchantTimecards] = useState([]);

  if(!location)
  {
    location = ':location_id'
  }

  async function fetchMerchantTimecards() {

    const GetLocationMerchantTimecards = commonConfigs.apiUrls.GetLocationMerchantTimecards(location, date);

    await axios
    .get(GetLocationMerchantTimecards, { headers: commonConfigs.apiHeader })
    .then((res) => {
      console.log(res.data.result.data);
      setMerchantTimecards(res.data.result.data);
    })
    .catch((err) => {
      console.log(err);
    });

  }

  useEffect(() => {
    fetchMerchantTimecards().catch((err) => {
      console.log("err: " + err);
    });
  }, [location]);

  const columns = useMemo(() => [
    {
      Header: "Merchant Name",
      accessor: "merchant_name",
    },
    {
      Header: "Merchant Unit No.",
      accessor: "merchant_unit_no",
    },
    {
      Header: "Today's Clock In",
      accessor: ({ time_in_datetime }) => {
        if (time_in_datetime === 'Absent') {
          return 'Absent';
        }
        return time_in_datetime && time_in_datetime !== "-" ? time_in_datetime.substring(11, 19) : "-";
      },
    },
    {
      Header: "Today's Clock Out",
      accessor: ({ time_out_datetime }) => {
        if (time_out_datetime === 'Absent') {
          return 'Absent';
        }
        return time_out_datetime && time_out_datetime !== "-" ? time_out_datetime.substring(11, 19) : "-";
      },
    },
    {
      Header: "Duration",
      accessor: "attendance_duration",
    },
    {
      Header: "Attendance Status",
      Cell: ({ row }) => {
        const timeInDatetime = row.original.time_in_datetime;
        const timeOutDatetime = row.original.time_out_datetime;
        const attendanceDuration = row.original.attendance_duration;
        
        if (timeInDatetime === 'Absent') {
          return (
            <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
              Absent
            </div>
          );
        } else if (timeInDatetime !== 'Absent' && timeOutDatetime === 'Absent') {
          return (
            <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
              Pending
            </div>
          );
        } else if (attendanceDuration < 8) {
          return (
            <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-amber-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
              {"<8Hrs"}
            </div>
          );
        } else if (attendanceDuration >= 8) {
          return (
            <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
              {">8Hrs"}
            </div>
          );
        }
      },
    },
    {
      Header: "Current Attendance",
      accessor: "attendance_current",
    },
    {
      Header: "Month-To-Date Stall Manning",
      accessor: "attendance_total",
    },
  ]);

  return (
    <div className="w-full desktop:p-6 relative">
      <div className="absolute top-0 right-0">
        <button
          type="button"
          style={{marginRight: '10px', textDecoration:'underline', display:'inline-block', float:'right' }}
          className=" text-lg text-center mt-2 font-medium focus:outline-none hover:text-black"
          onClick={() => (window.location = `/schedule/${date}/merchant-timecard`)}
        >
          View More
        </button>
      </div>
      {merchantTimecards != '' ? (
        <Table
          columns={columns}
          data={merchantTimecards}
          filters={columns.map((column) => column.accessor)}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
    </div>
  );

};

export default ScheduleSideRowCard;
