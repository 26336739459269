import axios from "axios";
import { useParams } from "react-router-dom";
import commonConfigs from "../../../../config/config";
import Table from "../../../../commons/components/billingTable";
import { useState, useMemo, useEffect } from "react";
import Spinner from "../../../../commons/components/spinner";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";

const BillingMerchantsLayout = () => {

  var StartDateData = sessionStorage.getItem('startdate');
  var EndDateData = sessionStorage.getItem('enddate');

  let { locationId } = useParams();
  let { locationcode } = useParams();
 
  console.log(locationId);
  
  const pages = [
    { name: "Hierarchy View", href: "/dashboard/hierarchy", current: false },
    { name: "Locations", href: `/dashboard/hierarchy/merchanttotalbilling`, current: false },
    { name: locationcode, href: `/dashboard/hierarchy/merchanttotalbilling/${locationcode}/${locationId}`, current: true },
  ];
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  
  const [startdate, setStartDate] = useState('');
  const [enddate, setEndDate] = useState('');

  const [isApplying, setIsApplying] = useState(false);
  const [isReseting, setIsReseting] = useState(false);
  const applyButtonEnabledClass = "bg-red-600 hover:bg-red-700";
  const applyButtonDisabledClass = "bg-red-400 cursor-not-allowed";

  const [error, setError] = useState('');

  const [startdateFilter, setStartDateFilter] = useState(StartDateData);
  const [enddateFilter, setEndDateFilter] = useState(EndDateData);

  const applyFilter = () => {
    setIsApplying(true); // Disable the "APPLY" button
    setLoad(true);
    ((StartDateData.length > 0)? setStartDateFilter(StartDateData): setStartDateFilter(startdate));
    ((EndDateData.length > 0)? setEndDateFilter(EndDateData): setEndDateFilter(enddate));
  }
  const resetFilter = () => {
    setIsReseting(true);
    setLoad(true);
    setStartDate('')
    setEndDate('');
    sessionStorage.setItem('startdate', '');
    sessionStorage.setItem('enddate', '');
    setStartDateFilter('')
    setEndDateFilter('');
  }


  async function getData() {
    const getLocationsSales = commonConfigs.apiUrls.GetBillingMerchantsT2(locationId, startdateFilter, enddateFilter);
    await axios
      .get(getLocationsSales, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        if (res.data.result.data !== null) {
          setData(res.data.result.data);
          setIsApplying(false);
          setIsReseting(false);
          setLoad(false);  
        } else {
          setError(res.data.result.data.message);
          setIsApplying(false);
          setIsReseting(false);
          setLoad(false);  
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load,startdateFilter,enddateFilter]);

  const columns = useMemo(() => [
    {
      Header: "Merchant Name",
      accessor: "merchant_name",
      width: 10,
      Cell: ({ value }) => {
        return (
          <div className="flex items-center">
            <div className="ml-4">
              <p className="text-sm text-black">{value}</p>
            </div>
          </div>
        );
      },
    },
    {
      Header: "GPO Total Amt",
      accessor: "gpo_total_amt",
      Cell: ({ row }) => {
        var sales = row.values.gpo_total_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
    {
      Header: "Nets Total Amt",
      accessor: "nets_total_amt",
      Cell: ({ row }) => {
        var sales = row.values.nets_total_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
    {
      Header: "Dishwashing Total Amt",
      accessor: "dishwashing_total_amt",
      Cell: ({ row }) => {
        var sales = row.values.dishwashing_total_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
  ]);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
      </div>

      <div style={{backgroundColor:'#FFF', padding:'20px', borderRadius:'10px', margin:'20px 0 20px 0 '}}>
        <row style={{display:'flex'}}>
          
          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> Start Date </h6>
            </div>
            <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={StartDateData} onChange={(e) => {
              sessionStorage.setItem('startdate', e.target.value);
              setStartDate(e.target.value);
            }}/>
          </div>
          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> End Date </h6>
            </div>
            <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={EndDateData} onChange={(e) => {
              sessionStorage.setItem('enddate', e.target.value);
              setEndDate(e.target.value);
          }}/>
          </div>
      
        </row>
        <div style={{ textAlign: 'end', paddingTop: '34px' }}>
          <button
            data-inline="true"
            type="button"
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
              isApplying ? applyButtonDisabledClass : applyButtonEnabledClass
            }`}
            onClick={() => applyFilter()}
            disabled={isApplying} // Disable the button when isApplying is true
          >
            {isApplying ? "Applying..." : "APPLY"}
          </button>
          <button
            data-inline="true"
            type="button"
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
              isReseting ? applyButtonDisabledClass : applyButtonEnabledClass
            }`}
            onClick={() => resetFilter()}
            disabled={isReseting} // Disable the button when isReseting is true
          >
            {isReseting ? "Reseting..." : "Reset"}
          </button>
        </div>
      </div>

      {console.log(data)}
      {data != null && data.status_code !== 404 ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : <>
          {console.log(error)}
          { data == null  ? <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
          <Spinner className="w-20 h-20" />
          </div>
        </div> : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-baseline">
          <div className="flex place-content-center w-full">
            No Transaction Record Found
          </div>
        </div>
      )}
      </>}
    </div>
  );
};

export default BillingMerchantsLayout;
