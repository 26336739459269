import Pagination from "./paginations";
import React, { useCallback, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useExpanded,
  useSortBy,
  usePagination,
} from "react-table";

// https://codesandbox.io/s/filter-props-global-filter-i18bd?file=/src/Table.tsx:1336-1479

function Table({
  columns,
  data,
  filters,
  initialFilter = null,
  initialValue = null,
  expandedTable = true,
  hasPagination = true,
  hasSearch = true,
}) {
  const [filter, setFilter] = useState("");
  const globalFilterCallback = useCallback(
    (rows, ids, query) => {
      return rows.filter((row) => {
        // console.log("Row values: ",row.values)
        if (
          initialFilter != null &&
          row.values[initialFilter] != initialValue &&
          initialValue.toLowerCase() != "all"
        ) {
          return false;
        }

        for (const filter of filters) {
          if (row.values[filter] !== undefined && row.values[filter] !== null) {
            // If its array need to loop through it.
            if (Array.isArray(row.values[filter])) {
              for (var i = 0; i < row.values[filter].length; i++) {
                var value = row.values[filter][i];
                for (var z = 0; z < Object.keys(value).length; z++) {
                  var k = Object.keys(value)[z];

                  if (value[k] !== undefined && value[k] !== null) {
                    if (
                      value[k]
                        .toString()
                        .toLowerCase()
                        .includes(query.toLowerCase())
                    ) {
                      return true;
                    }
                  }
                }
              }
            }

            // Check if its an object.
            else if (typeof row.values[filter] === "object") {
              for (var y = 0; y < Object.keys(row.values[filter]).length; y++) {
                var key = Object.keys(row.values[filter])[y];

                if (
                  row.values[filter][key] !== undefined &&
                  row.values[filter][key] !== null
                ) {
                  if (
                    row.values[filter][key]
                      .toString()
                      .toLowerCase()
                      .includes(query.toLowerCase())
                  ) {
                    return true;
                  }
                }
              }
            }

            // Check directly if is value.
            else {
              if (
                row.values[filter]
                  .toString()
                  .toLowerCase()
                  .includes(query.toLowerCase())
              )
                return true;
            }
          }
        }
      });
    },
    [filters]
  );

  const {
    page,
    state,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      globalFilter: globalFilterCallback,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(filter);
    if (!hasPagination) setPageSize(data.length);
  }, [filter, setGlobalFilter]);

  useEffect(() => {
    setGlobalFilter(filter);
  }, [data]);

  return (
    <>
      {/* Search */}
      {hasSearch && (
        <div className="sm:flex sm:gap-x-2 mobile:ml-2 ">
          <FilterBar count={rows.length} setFilter={setFilter} />
        </div>
      )}
      {/* Table */}
      <div className="flex flex-col mt-5 ">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table
                {...getTableProps()}
                border="1"
                className="min-w-full divide-y divide-gray-200  "
              >
                <thead className="bg-gray-50 ">
                  {headerGroups.map((headerGroup) => {
                    const { key, ...restHeaderGroupProps } =
                      headerGroup.getHeaderGroupProps();
                    return (
                      <tr
                        key={key}
                        {...restHeaderGroupProps}
                        className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {headerGroup.headers.map((column) => {
                          const { key, ...restColumn } = column.getHeaderProps(
                            column.getSortByToggleProps(),
                            {
                              style: { width: "10px" },
                            }
                          );
                          return (
                            <th key={key} {...restColumn}>
                              <div className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {column.render("Header")}
                                <span>
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? " ▼"
                                      : " ▲"
                                    : ""}
                                </span>
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody
                  {...getTableBodyProps}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page.map((row) => {
                    prepareRow(row);
                    // const expandedData = row.original.orders;

                    const { key, ...restRowProps } = row.getRowProps();
                    return (
                      <>
                        <tr
                          key={key}
                          {...restRowProps}
                          className=" px-2 py-4  hover:bg-gray-100"
                        >
                          {row.cells.map((cell) => {
                            const { key, ...restCellProps } =
                              cell.getCellProps();
                            return (
                              <td
                                key={key}
                                {...restCellProps}
                                className=" px-2 py-2"
                              >
                                <div className="text-sm text-gray-900">
                                  {cell.render("Cell")}
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                        {row.isExpanded && expandedTable && (
                          <>
                            <tr>
                              <td
                                className="px-6 py-4 whitespace-nowrap"
                                colSpan={columns.length}
                              >
                                {expandedTable}
                                {/* <OrdersTable
                                  columns={expandedCols}
                                  data={expandedData}
                                  hasPagination={false}
                                  hasFilter={false}
                                /> */}
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Pagination */}
      {hasPagination && (
        <Pagination
          state={state}
          pageOptions={pageOptions}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
        />
      )}
    </>
  );
}

function FilterBar({ count, setFilter }) {
  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">Search: </span>
      <input
        type="text"
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        onChange={(e) => {
          setFilter(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

export default Table;
