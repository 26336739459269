import styles from '../../../styles/styles.module.css';

const UpdateDishwareStatus = () => {
    return (
        <div style={{display: 'grid', 'gridTemplateAreas':`'details details code`,'gridTemplateColumns': 'repeat(3, minmax(0, 1fr))', gap:3, maxWidth: 'inherit'}}> 
        
        {/* Details component */}
        <div style={{gridArea: 'details', marginBottom: '0px'}}>
            <div className={styles.cardbod}>
                <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#378805'}}>Update Dishware Status</h3>
                <hr />
                <p style={{marginTop: '10px'}}> This API updates dishware status. </p>

                {/* API Parameters */}
                <div style={{marginTop: '20px'}}>
                    <h6 style={{textDecoration: 'underline'}}>Parameters</h6>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a. merchant_id : merchant's ID</p>
                        <p>b. user_id : user's ID</p>
                        <p>c. request_id : dishwashing request ID</p>
                    </div>

                    <h6 style={{textDecoration: 'underline', marginTop: '10px'}}>Output data</h6>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a. status of dishware request</p>
                    </div>
                </div>
            </div>
        </div>

        {/* Code component */}
        <div style={{gridArea: 'code', marginBottom: '0px', backgroundColor: '#3f3f3f'}}>
            <div style={{backgroundColor: '#378805', color: 'white', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', paddingTop: '5px' , paddingBottom: '5px'}}>
                Update Dishware Status API
            </div>

            <div>
                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'15px', color: 'white'}}>Request (GET)</h6>
                    <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                        <code className={styles.code}>/api/v1/transactions/requests/dishwares/update/:merchant_id/:user_id/:request_id</code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'10px', color: 'white'}}>Sample Request (Decline)</h6>
                    <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                        <code className={styles.code}>/api/v1/transactions/requests/dishwares/update/b0091047-4b02-4103-9f3f-3473ed3c1f97/""/38</code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'10px', color: 'white'}}>Sample Request (Decline)</h6>
                    <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                        <code className={styles.code}>api/v1/transactions/requests/dishwares/update/b0091047-4b02-4103-9f3f-3473ed3c1f97/8588d42e-5451-4656-8000-05cb0c9ef484/38</code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response</h6>
                    <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                        <code>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        
                        <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        <span className={styles.codestyle2} style={{marginLeft: '20px'}}>"message"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "success"</span>
                        <br />
                        <span className={styles.codestyle1}style={{marginLeft: '20px'}}>{"}"}</span>
                        <br/>
                        <span className={styles.codestyle1}>{"}"}</span>
                        </code>
                    </blockquote>   
                </div>

            </div>
        </div>
        </div>
    );
  };
  
  export default UpdateDishwareStatus;
