const ScheduleTodayOverview = (props) => {
  return (
    <div className="flex justify-center" style={{height:'inherit', width: 'inherit'}}>
      <div className="w-full block p-6 rounded-lg bg-gray-100 max-w-sm">
        {props.children}
      </div>
    </div>
  );
};

export default ScheduleTodayOverview;
