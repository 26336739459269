import { EmployeesContextProvider } from "../context/employeesContext";
import EmployeesLayout from "./employeesLayout";

const EmployeesPage = () => {
  return (
    <EmployeesContextProvider>
      <EmployeesLayout />
    </EmployeesContextProvider>
  );
};

export default EmployeesPage;
