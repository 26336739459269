import TotalCard from "../components/totalCard";
import Card from "../../components/dashboardCard";
import DashboardCard from "../../components/dashboardCard";
import LongCard from "../../components/dashboardLongCard";
import ResponseCard from "../components/responseCard";
import OOCard from "../components/ooCard";
import OOSCard from "../components/oosCard";
import TOCSCard from "../components/tocsCard";
import TMACard from "../components/tmaCard";

const DashboardKPILayout = () => {

  // const [humanTraffic, setHumanTraffic] = useState(null);
  // const [trayReturn, setTrayReturn] = useState(null);

  // async function fetchTrayReturn() {
  //   var trData = {
  //     total_revenue: {
  //       total_revenue: 120050000,
  //       revenue_stream: [
  //         {
  //           type: "Bukit Canberra",
  //           daily_percentage_change: 2,
  //           revenue: 125050
  //         },
  //         {
  //           type: "NYP",
  //           daily_percentage_change: 2,
  //           revenue: 125050
  //         },
  //         {
  //           type: "RP",
  //           daily_percentage_change: 2,
  //           revenue: 125050
  //         },
  //         {
  //           type: "TP",
  //           daily_percentage_change: 2,
  //           revenue: 125050
  //         },
  //       ]
  //     }
  //   };
  //   setTrayReturn(trData);
  // }

  // async function fetchHumanTraffic() {
  //   var htData = {
  //     total_revenue: {
  //       total_revenue: 120050000,
  //       revenue_stream: [
  //         {
  //           type: "Bukit Canberra",
  //           daily_percentage_change: 2,
  //           revenue: 125050
  //         },
  //         {
  //           type: "NYP",
  //           daily_percentage_change: 2,
  //           revenue: 125050
  //         },
  //         {
  //           type: "RP",
  //           daily_percentage_change: 2,
  //           revenue: 125050
  //         },
  //         {
  //           type: "TP",
  //           daily_percentage_change: 2,
  //           revenue: 125050
  //         },
  //       ]
  //     }
  //   };
  //   setHumanTraffic(htData);
  // }


  return (
    <div>
      <div style={{display: 'grid', 'grid-template-areas':`'box1 box1 box2 box3''box5 box5 box6 box6'`,'grid-template-columns': 'repeat(4, minmax(0, 1fr))', gap:4, maxWidth: 'inherit'}}>    
        
        <div style={{ gridArea: 'box1', height: '100%', minHeight: 0, minWidth: 0, overflowY: 'auto' }}>
          <Card>
            <OOCard/>
          </Card>
        </div>

        <div style={{ gridArea: "box2", height: "100%", minHeight: 0, minWidth: 0, overflow: "visible"}}>
          <DashboardCard>
            <ResponseCard/>
          </DashboardCard>
        </div>

        <div style={{ gridArea: 'box3', height:'100%', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
          <DashboardCard>
            <TMACard/>
          </DashboardCard>
          {/* {humanTraffic && <TotalCard title={"Total Human Traffic Count"} subtitle={"Today's Human Traffic"} data={humanTraffic} />} */}
        </div>
          
        <div style={{ gridArea: 'box4', height:'100%', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
          {/* {trayReturn && <TotalCard title={"Total Tray Return Count"} subtitle={"Today's Tray Return Count"} data={trayReturn} />} */}
        </div>

        <div style={{ gridArea: 'box5', height:'100%', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
          <LongCard>
            <OOSCard/>
          </LongCard>
        </div>

        <div style={{ gridArea: 'box6', height:'100%', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
          <LongCard>
            <TOCSCard/>
          </LongCard>
        </div>
      </div>
    </div>
  );
};

export default DashboardKPILayout;
