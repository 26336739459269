import axios from "axios";
import Select from "react-select";
import { InfoCircle } from "iconsax-react";
import CreatableSelect from "react-select/creatable";
import { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import commonConfigs from "../../../../config/config";
import { Dialog, Transition } from "@headlessui/react";

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function UpdatePreferenceModal({
  setUpdateModal,
  merchantId,
  row,
  load,
}) {
  const merchant_id = merchantId;
  const product_id = row.preference_id;

  const getMerchantEMenuUrl =
    commonConfigs.apiUrls.GetMerchantEMenu(merchant_id);

  const [open, setOpen] = useState(true);
  const [menus, setMenus] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [lazyData, setLazyData] = useState({});

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      preference_name: row.preference_name,
    },
  });

  function formatData(data) {
    console.log(data);
    var preference_name = data.preference_name;
    var preferenceOptionNames = data.preference_options.map((option) => {
      return option.label;
    });
    var taggedProductIds = data.tagged_products.map((product) => {
      return product.value;
    });

    var newData = data;
    newData.preference_name = preference_name;
    newData.tagged_products = taggedProductIds;
    newData.preference_options = preferenceOptionNames;

    return newData;
  }

  const onSubmit = (data) => {
    setIsDisabled(true); // Disable the submit button

    formatData(data);
    console.log(data);
    axios
      .put(
        commonConfigs.apiUrls.UpdatePreferences(merchant_id, product_id),
        data,
        {
          headers: commonConfigs.apiHeader,
        }
      )
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setUpdateModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);sg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);sg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);sg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  async function getPrefsData() {
    var formattedPreferenceOptions = [];
    console.log("row", row);
    row.preference_options.forEach((option) => {
      formattedPreferenceOptions.push({
        value: option.preference_option_id,
        label: option.preference_option_name,
      });
    });

    console.log(formattedPreferenceOptions);
    setLazyData((lazyData["preference_options"] = formattedPreferenceOptions));
  }

  async function getMerchantData() {
    await axios
      .get(getMerchantEMenuUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setMenus(res.data.result.data);

        const taggedProductsFound = res.data.result.data.filter(
          (taggedProduct) =>
            row.tagged_products.find(
              ({ product_id }) => taggedProduct.product_id == product_id
            )
        );

        var formattedTaggedProducts = [];

        taggedProductsFound.forEach((taggedProduct) => {
          formattedTaggedProducts.push({
            value: taggedProduct.product_id,
            label: taggedProduct.product_name,
          });
        });

        setLazyData((lazyData["tagged_products"] = formattedTaggedProducts));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const getSelectData = async () => {
      await getPrefsData().catch((err) => {
        console.log("err for preferences: " + err);
      });
      await getMerchantData().catch((err) => {
        console.log("err for tagged products: " + err);
      });
    };

    getSelectData()
      .then(() => {
        reset(lazyData);
      })
      .catch((err) => {
        console.log("err for roles: " + err);
      });
  }, []);

  const taggedProducts = menus.map((d) => ({
    value: d.product_id,
    label: d.product_name,
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setUpdateModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Update Preference Item
                    </Dialog.Title>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="preference_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Preference Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="preference_name"
                          id="preference_name"
                          className={
                            !errors.preference_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Preference Name"}
                          {...register("preference_name", { required: true })}
                          pattern="^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"

                        />
                        {errors.product_name?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="preference_options"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Preference Options
                      </label>
                      <Controller
                        name="preference_options"
                        control={control}
                        render={({ field }) => (
                          <CreatableSelect
                            isClearable
                            {...field}
                            options={[]}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
                            isMulti={true}
  
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="tagged_products"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Tagged Products
                      </label>
                      <Controller
                        name="tagged_products"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={taggedProducts}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
                            isMulti={true}
  
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setUpdateModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
