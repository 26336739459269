import { createContext, useState } from "react";

const tabs = [{ name: "All" }, { name: "Pending" }, { name: "Resolved" }, { name: "Cancelled" }];

export const FeedbackTabsContext = createContext();
export const FeedbackCurrentTabContext = createContext();

export const FeedbackContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <FeedbackTabsContext.Provider value={{ tabs }}>
      <FeedbackCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </FeedbackCurrentTabContext.Provider>
    </FeedbackTabsContext.Provider>
  );
};
