import Feedback from "../components/feedback";

const FeedbackPage = () => {
  return (
    <>
      <Feedback />
    </>
  );
};

export default FeedbackPage;
