import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import SalesCard from "./salesCard";
import { Link } from "react-router-dom";

const user = JSON.parse(localStorage.getItem('user'))

export default function OSCard() {

  const [outletsRevenueSales, setOutletsRevenueSales] = useState(null);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1 to get the current month.

  // Calculate the first day of the current month.
  const firstDayOfMonth = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;

  // Calculate the last day of the current month.
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  const lastDayOfMonthFormatted = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${lastDayOfMonth.getDate()}`;

  const options = { year: 'numeric', month: 'long' };
  const formattedDate = currentDate.toLocaleString('en-US', options);
  
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(lastDayOfMonthFormatted);

  const [load, setLoad] = useState(true);

  async function fetchOutletsRevenueSales(startdate, enddate) {
    var start = '';
    var end = '';
    
    if (startdate == undefined || startdate == "" ) {
      start = "";  
    } else {
      start = startdate;
    }
    if (enddate == undefined || enddate == "" ) {
      end = "";  
    } else {
      end = enddate;
    }
    let getSalessUrl = commonConfigs.apiUrls.GetOutletSales('platform',start, end);
    if(user.access_multiple_location == 0 && user.location_id) {
      getSalessUrl = getSalessUrl + `&location_id=${user.location_id}`
    }

    await axios
      .get(getSalessUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setOutletsRevenueSales(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchOutletsRevenueSales(startDate, endDate).catch((err) => {
      console.log("err: " + err);
    });
  }, [load, startDate, endDate]);


  return (
    <div>
      <div>
        <Link to={'merchantsales'} style={{marginRight: '10px', textDecoration:'underline', display:'inline-block', float:'right' }}>
          View Details
        </Link>
      </div>   
      <div className="mt-2">
        {outletsRevenueSales && (
          <SalesCard
            title={`Outlet Sales For ${formattedDate}`}
            data={outletsRevenueSales}
          />
        )}
      </div>
    </div>   
  );
}
