import React, {useState, useEffect} from 'react';
import { Bar } from 'react-chartjs-2'

export const options = {
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        boxWidth:8,
      }
    },
  },
  responsive: true,
  scales: {
    x: 
    {
      stacked: true,
    },
    x2: {
      display:false,
    }
  },
};

const DashboardStackedBarChart = ({ data }) => {
  // console.log(data);
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const ds = [
      {
        label: 'Vouchers Issued',
        data: [],
        backgroundColor: '#C0C0C0',
        xAxisID: 'x2'
      },
      {
        label: 'Vouchers Redeemed',
        data: [],
        backgroundColor: '#cd5c5c'
      }
    ];
    
    data.forEach(function (item, i) {
      // Insert values into the stacked bar chart.
      item.values.forEach((itemValue) => {
        ds.forEach((dataset) => {
          if (dataset.label === itemValue.label) {
            dataset.data.push(itemValue.value);
          }
        });
      });
  
      // Put 0 if no value.
      ds.forEach((dataset) => {
        if (dataset.data.length !== i + 1) {
          dataset.data.push(0);
        }
      });
    });
  
    setGraphData({
      labels: data.map((item) => item.name),
      datasets: ds,
    });
  }, [data]);

  return <Bar data={graphData} options={options} />;
};

export default DashboardStackedBarChart;
