import "./styles/App.css";
import { Suspense } from "react";
import { PrivateRoute } from "./protectedRoute";
import ErrorNotFoundPage from "./commons/pages/404";
import SuccessPage from "./commons/pages/Success";
import NoAccess from "./commons/pages/NoAccess";
import Login from "./modules/login_modules/pages/login";
import NavbarWrapper from "./commons/components/navbarWrapper";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./modules/login_modules/pages/landingPage";
import FAQLandingPage from "./modules/login_modules/pages/faqLanding.jsx";
import FeedbackPage from "./modules/login_modules/pages/feedbackPage.jsx";

// Dashboard Routes
import HierarchyPage from "./modules/dashboard_modules/hierarchy_modules/pages/hierarchyPage";
import MerchantSalesPage from "./modules/dashboard_modules/hierarchy_modules/pages/locationsPage";
import MerchantSalesMerchantsPage from "./modules/dashboard_modules/hierarchy_modules/pages/merchantsPage";
import MerchantSalesMenuPage from "./modules/dashboard_modules/hierarchy_modules/pages/merchantsMenuPage";

import BillingMerchantSalesPage from "./modules/dashboard_modules/hierarchy_modules/pages/billingLocationsPage";
import BillingMerchantSalesMerchantsPage from "./modules/dashboard_modules/hierarchy_modules/pages/billingMerchantsPage";

import DashboardKPIPage from "./modules/dashboard_modules/kpi_modules/pages/dashboardKPIPage";
import DashboardPage from "./modules/dashboard_modules/revenue_modules/pages/dashboardRevenuePage";
import DashboardMarketingPage from "./modules/dashboard_modules/marketing_modules/pages/dashboardMarketingPage";

// Schedule Routes
import Schedule from "./modules/schedule_modules/pages/schedule";
import ScheduleDate from "./modules/schedule_modules/pages/scheduleDate";
import ScheduleDateMerchantLocations from "./modules/schedule_modules/pages/merchant_timecard_details/locationsPage";
import ScheduleDateTimeCardMerchant from "./modules/schedule_modules/pages/merchant_timecard_details/merchantsPage";
import ScheduleDateTimeCardMerchantDetails from "./modules/schedule_modules/pages/merchant_timecard_details/merchantsDetailsPage";

import ScheduleDateEmployeeLocations from "./modules/schedule_modules/pages/employee_timecard_details/locationsPage";
import ScheduleDateTimeCardEmployee from "./modules/schedule_modules/pages/employee_timecard_details/merchantsDetailsPage";
// import ScheduleDateTimeCardEmployeeDetails from "./modules/schedule_modules/pages/employee_timecard_details/merchantsDetailsPage";
import ScheduleDateTimeCardEmployeeDetails from "./modules/schedule_modules/pages/employee_timecard_details/employeeDetailsPage";
import ScheduleDateTimeCardEmployeeLeave from "./modules/schedule_modules/pages/employee_timecard_details/employeeLeavePage"

// Group Purchase Routes
import Goods from "./modules/group-purchase_modules/goods_modules/pages/goodsPage";
import Orders from "./modules/group-purchase_modules/orders_modules/pages/ordersPage";
import Services from "./modules/group-purchase_modules/services_modules/pages/servicesPage";

// Action Items Routes
import Feedback from "./modules/action-items_modules/user-feedback_modules/pages/feedbackPage";
import MaintenancesLocations from "./modules/action-items_modules/maintenances_modules/pages/maintenancesLocationsPage";
import Maintenances from "./modules/action-items_modules/maintenances_modules/pages/maintenancePage";
import IncidentReport from "./modules/action-items_modules/incident-reports_modules/pages/incidentPage";
import IncidentLocations from "./modules/action-items_modules/incident-reports_modules/pages/incidentsLocationsPage";

// Manage Users Routes
import Members from "./modules/manage-users_modules/members_modules/pages/membersPage";
import Employees from "./modules/manage-users_modules/employees_modules/pages/employeesPage";

// Marketing Routes
import Survey from "./modules/marketing_modules/pages/survey/survey";
import Marketing from "./modules/marketing_modules/pages/marketing/pages/marketingPage";
import VoucherDetails from "./modules/marketing_modules/pages/marketing/pages/voucherDetailsLayout"
import Broadcasts from "./modules/marketing_modules/pages/broadcasts/pages/broadcastsPage";

// Prize Wheel Routes
// import PrizeWheel from "./modules/prize-wheel_modules/pages/prizeWheel";
// import PrizeWheel2 from "./modules/prize-wheel_modules/pages/prizeWheel2";
// import PrizeWheel3 from "./modules/prize-wheel_modules/pages/prizeWheel3";

// Configuration Routes
import FAQ from "./modules/configuration_modules/faq_modules/pages/faqPage";
import HPBPage from "./modules/configuration_modules/hpb_modules/pages/hpbPage";
import EMenu from "./modules/configuration_modules/emenu_modules/pages/eMenuPage";
import DishwareLocations from "./modules/configuration_modules/dishware_modules/pages/dishwareLocationsPage";
import Dishware from "./modules/configuration_modules/dishware_modules/pages/dishwarePage";
import Suppliers from "./modules/configuration_modules/supplier_modules/pages/supplierPage";
import BillingPage from "./modules/configuration_modules/billing_modules/pages/billingPage";
import Locations from "./modules/configuration_modules/location_modules/pages/locationsPage";
import MerchantLocations from "./modules/configuration_modules/merchants_modules/pages/locationsPage";
import Merchants from "./modules/configuration_modules/merchants_modules/pages/merchantsPage";
import Access from "./modules/configuration_modules/access_modules/pages/accessPage"
import AccessRoles from "./modules/configuration_modules/accessRoles_modules/pages/accessRolesPage";
import MerchantsEmenu from "./modules/configuration_modules/merchants_modules/pages/merchantsMenuPage";
import GroupPurchase from "./modules/configuration_modules/groupPurchase_modules/pages/groupPurchasePage";
import Leave from "./modules/configuration_modules/leave_modules/pages/leavePage";
import Prizes from "./modules/configuration_modules/prize_modules/pages/prizePage";
import PrizeWheelConfig from "./modules/configuration_modules/prizeWheel_modules/pages/prizeWheelPage"
import PrizeWheel from "./modules/configuration_modules/prizeWheel_modules/pages/prizeWheelSegmentPage"

import OpsLocations from "./modules/ops_modules/pages/locationsPage";
import OpsMerchants from "./modules/ops_modules/pages/merchantsPage";

//NEW CONFIG
import Company from "./modules/configuration_modules/company_modules/pages/companyPage";
import CompanyItem from "./modules/configuration_modules/company_modules/pages/companyItemPage";

import PaymentOptionLocations from './modules/configuration_modules/payment_option_modules/pages/locationsPage'
import MerchantPage from './modules/configuration_modules/payment_option_modules/pages/merchantsPage'
import MerchantPaymentOptions from './modules/configuration_modules/payment_option_modules/pages/paymentOptionPage'

// API Documentation
import MainLayout from "./modules/open_api_module/pages/mainLayout";
import Documentation from "./modules/open_api_module/pages/documentLayout"
import UpdateOrderStatus from "./modules/open_api_module/components/views/order_fulfillment_pages/updateOrderStatus";
import { Provider } from "react-redux";
import GetConsumerOrders from "./modules/open_api_module/components/views/order_fulfillment_pages/getConsumerOrders";
import GetOrderById from "./modules/open_api_module/components/views/order_fulfillment_pages/getOrderbyId";
import RequestDishware from "./modules/open_api_module/components/views/cleaning_services_modules/requestDishware";
import GetDishwareRequest from "./modules/open_api_module/components/views/cleaning_services_modules/getDishwareRequest";
import UpdateDishwareStatus from "./modules/open_api_module/components/views/cleaning_services_modules/updateDishwareStatus";
import GetTopFiveHealthy from "./modules/open_api_module/components/views/food_nutrition_pages/getTopFiveHealthy";
import GetTopFiveUnhealthy from "./modules/open_api_module/components/views/food_nutrition_pages/getTopUnhealthy";
import GetLineGraphs from "./modules/open_api_module/components/views/food_nutrition_pages/getLineGraphs";
import GetPieChart from "./modules/open_api_module/components/views/food_nutrition_pages/getPieChart";
import GetCaloriesTransactions from "./modules/open_api_module/components/views/food_nutrition_pages/getCaloriesTransaction";

import { userRoutes } from "./commons/contexts/navigationContext";

const routeComponents = { // contains all routes that are accessible through the sidebar navigation and some additional routes
  '/landing': LandingPage,
  '/dashboard/revenue': DashboardPage,
  '/dashboard/kpi': DashboardKPIPage,
  '/dashboard/marketing': DashboardMarketingPage,
  '/dashboard/hierarchy/merchantsales': MerchantSalesPage,
  '/dashboard/hierarchy/merchanttotalbilling': BillingMerchantSalesPage,
  '/schedule': Schedule,
  '/marketing/loyaltyprogramme': Marketing,
  '/marketing/broadcasts': Broadcasts,
  '/marketing/surveys': Survey,
  '/grouporder/orders': Orders,
  '/grouporder/goods': Goods,
  '/grouporder/services': Services,
  '/action/incidentslocations': IncidentLocations,
  '/action/maintenanceslocations': MaintenancesLocations,
  '/action/feedback': Feedback,
  '/users/employees': Employees,
  '/users/members': Members,
  '/configurations/merchantlocations': MerchantLocations,
  '/configurations/suppliers': Suppliers,
  '/configurations/dishwarelocations': DishwareLocations,
  '/configurations/dishware': Dishware,
  '/configurations/groupmenu': GroupPurchase,
  '/configurations/locations': Locations,
  '/configurations/access': Access,
  '/configurations/accessroles': AccessRoles,
  '/configurations/hpb': HPBPage,
  '/configurations/emenucat': EMenu,
  '/configurations/faq': FAQ,
  '/configurations/billing': BillingPage,
  '/configurations/prizes': Prizes,
  '/configurations/prizewheelconfig': PrizeWheelConfig,
  '/configurations/leave': Leave,
  '/configurations/companies': Company,
  '/operations/locations': OpsLocations,
  '/configurations/paymentoptionlocations': PaymentOptionLocations,
};

const dynamicRoutes = [ // child are defined as routes that has to be rendered with parameters
  {
    parent: '/dashboard/revenue',
    child: {
      '/dashboard/hierarchy': HierarchyPage,
      '/dashboard/hierarchy/merchantsales/:locationcode/:locationId': MerchantSalesMerchantsPage,
      '/dashboard/hierarchy/merchantsales/:locationcode/:locationId/:merchantname/:merchantId': MerchantSalesMenuPage,
      '/dashboard/hierarchy/merchanttotalbilling/:locationcode/:locationId': BillingMerchantSalesMerchantsPage,
    }
  },
  {
    parent: '/schedule',
    child: {
      '/schedule/leave': ScheduleDateTimeCardEmployeeLeave,
      '/schedule/date/:date/:location?': ScheduleDate,
      '/schedule/:date/merchant-timecard': ScheduleDateMerchantLocations,
      '/schedule/:date/merchant-timecard/:locationId/:locationcode': ScheduleDateTimeCardMerchant,
      '/schedule/:date/merchant-timecard/:locationId/:locationcode/:merchantId': ScheduleDateTimeCardMerchantDetails,
      '/schedule/date/employee-timecard': ScheduleDateEmployeeLocations,
      '/schedule/date/employee-timecard/:locationId/:locationcode': ScheduleDateTimeCardEmployee,
      '/schedule/date/:date/:location/employeedetails/:accountId': ScheduleDateTimeCardEmployeeDetails,
      '/schedule/date/:date/employeedetails/:accountId': ScheduleDateTimeCardEmployeeDetails,
    }
  },
  {
    parent: '/marketing/loyaltyprogramme',
    child: {
      '/marketing/loyaltyprogramme/voucher/:voucherId/:voucherType/:voucherCode': VoucherDetails,
    }
  },
  {
    parent: '/action/incidentslocations',
    child: {
      '/action/incidentslocations/:locationName/incidents': IncidentReport,
    }
  },
  {
    parent: '/action/maintenanceslocations',
    child: {
      '/action/maintenanceslocations/:locationName/maintenances': Maintenances,
    }
  },
  {
    parent: '/configurations/merchantlocations',
    child: {
      '/configurations/merchantlocations/:locationName': Merchants,
      '/configurations/merchantlocations/:locationName/emenu/:merchantId': MerchantsEmenu,
    }
  },
  {
    parent: '/configurations/prizewheelconfig',
    child: {
      '/configurations/prizewheelconfig/:prizeWheelId': PrizeWheel,
    }
  },
  {
    parent: '/configurations/companies',
    child: {
      '/configurations/companies/:companyName/:companyId': CompanyItem,
    }
  },
  {
    parent: '/operations/locations',
    child: {
      '/operations/locations/:locationName/orders': OpsMerchants,
    }
  },
  {
    parent: '/configurations/paymentoptionlocations',
    child: {
      '/configurations/paymentoption/:locationName': MerchantPage,
      '/configurations/paymentoption/:locationName/:merchantName/:merchantId': MerchantPaymentOptions
    }
  }
]


function App() {
  let readArr = []; // array just to hold routes across the logic flow
  let transformedChildRoutes = []; // array containing child routes that will be rendered after checking their parents

  const findKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  return (
    <BrowserRouter>
      <Suspense fallback={<p>loading</p>}>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route
            restricted={false}
            path="/landing"
            name="Landing Page"
            element={<LandingPage />}
          />
          <Route path="*" element={<NoAccess />} />

          {/* Open API routing */}
          <Route restricted={false} path="/documentation" element={<MainLayout />}>
            <Route index element={<Documentation />} />
            <Route path="/documentation/order/update-order-status" element={<UpdateOrderStatus />} />
            <Route path="/documentation/order/get-consumer-orders" element={<GetConsumerOrders />} />
            <Route path="/documentation/order/get-orders-by-id" element={<GetOrderById />} />

            <Route path="/documentation/nutrition/get-top-5-healthy" element={<GetTopFiveHealthy />} />
            <Route path="/documentation/nutrition/get-top-5-unhealthy" element={<GetTopFiveUnhealthy />} />
            <Route path="/documentation/nutrition/get-by-line-graphs" element={<GetLineGraphs />} />
            <Route path="/documentation/nutrition/get-by-pie-chart" element={<GetPieChart />} />
            <Route path="/documentation/nutrition/get-calories-transactions" element={<GetCaloriesTransactions />} />

            <Route path="/documentation/cleaning/request-dishware" element={<RequestDishware />} />
            <Route path="/documentation/cleaning/get-dishware-requests" element={<GetDishwareRequest />} />
            <Route path="/documentation/cleaning/update-dishware-status" element={<UpdateDishwareStatus />} />
          </Route>

          {/* <Route
            restricted={false}
            path="/prize-wheel"
            name="Prize Wheel Page"
            element={<PrizeWheel />}
          />
          <Route
            restricted={false}
            path="/prize-wheel2"
            name="Prize Wheel Page"
            element={<PrizeWheel2 />}
          />
          <Route
            restricted={false}
            path="/prize-wheel3"
            name="Prize Wheel Page"
            element={<PrizeWheel3 />}
          /> */}
          <Route
            restricted={false}
            path="/login"
            name="Login Page"
            element={<Login />}
          />
          <Route
            restricted={false}
            path="/faq"
            name="FAQ Page"
            element={<FAQLandingPage />}
          />
          <Route
            restricted={false}
            path="/feedback"
            name="Feedback Page"
            element={<FeedbackPage />}
          />
          <Route
            restricted={false}
            path="/success/:title/:subTitle"
            name="Success"
            element={<SuccessPage />}
          />
          <Route
            restricted={false}
            path="/noaccess"
            name="No Access"
            element={<NoAccess />}
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <NavbarWrapper />
              </PrivateRoute>
            }
          >

            {userRoutes.map((route) => {
              if (route.subNav) {
                route.subNav.map((subnav) => {
                  readArr.push(routeComponents[subnav.path])
                })
              } else {
                readArr.push(routeComponents[route.path]);
              }

            })}
            {/* Render parent routes first */}
            {
              readArr.map((Component) => {
                return (
                  <Route
                    path={findKeyByValue(routeComponents, Component)}
                    element={<Component />}
                  />
                )
              })
            }
            {/* Find child routes after */}
            {
              dynamicRoutes.map((a) => {
                readArr.map((b) => {
                  if (a.parent === findKeyByValue(routeComponents, b)) {
                    for (const [path, Component] of Object.entries(a.child)) {
                      transformedChildRoutes.push({ path: path, element: Component });
                    }
                    // console.log(transformedChildRoutes)
                  }
                })
              })
            }
            {/* Render child routes in transformed arr */}
            {
              transformedChildRoutes.map((data) => {
                const Component = data.element;
                // console.log(data)
                return (
                  <Route
                    path={data.path}
                    element={<Component />}
                  />
                )
              })
            }
            {/* <PublicRoute restricted = {false} exact path="/" name="Landing Page" component={props => <Landing {...props}/>} /> */}

            {/* <Route path="*" name="Page 404" element={<ErrorNotFoundPage />} /> */}
            {/* <Route element={<PrivateRoute />}> */}
            {/* <Route path="dashboard/revenue" element={<DashboardPage />} />
            <Route path="dashboard/kpi" element={<DashboardKPIPage />} />
            <Route
              path="dashboard/marketing"
              element={<DashboardMarketingPage />}
            />
            <Route path="dashboard/hierarchy" element={<HierarchyPage />} />
            <Route
              path="dashboard/hierarchy/merchantsales"
              element={<MerchantSalesPage />}
            />
            <Route
              path="dashboard/hierarchy/merchantsales/:locationcode/:locationId"
              element={<MerchantSalesMerchantsPage />}
            />
            <Route
              path="dashboard/hierarchy/merchantsales/:locationcode/:locationId/:merchantname/:merchantId"
              element={<MerchantSalesMenuPage />}
            />
            
            <Route
              path="dashboard/hierarchy/merchanttotalbilling"
              element={<BillingMerchantSalesPage />}
            />
            <Route
              path="dashboard/hierarchy/merchanttotalbilling/:locationcode/:locationId"
              element={<BillingMerchantSalesMerchantsPage />}
            />


           

            <Route path="schedule" element={<Schedule />} />
            <Route path="schedule/leave" element={<ScheduleDateTimeCardEmployeeLeave />} />

            <Route path="schedule/date/:date/:location?" element={<ScheduleDate />} />

            <Route
              path="/schedule/:date/merchant-timecard"
              element={<ScheduleDateMerchantLocations />}
            />
            <Route
              path="/schedule/:date/merchant-timecard/:locationId/:locationcode"
              element={<ScheduleDateTimeCardMerchant />}
            />
            <Route
              path="/schedule/:date/merchant-timecard/:locationId/:locationcode/:merchantId"
              element={<ScheduleDateTimeCardMerchantDetails />}
            />

            <Route
              path="/schedule/date/employee-timecard"
              element={<ScheduleDateEmployeeLocations />}
            />
            <Route
              path="/schedule/date/employee-timecard/:locationId/:locationcode"
              element={<ScheduleDateTimeCardEmployee />}
            />
            <Route
              path="/schedule/date/:date/:location/employeedetails/:accountId"
              element={<ScheduleDateTimeCardEmployeeDetails />}
            />
            <Route
              path="/schedule/date/:date//employeedetails/:accountId"
              element={<ScheduleDateTimeCardEmployeeDetails />}
            /> */}



            {/* <Route path="/schedule/date/employee-timecard/:locationcode/:merchantId" element={<ScheduleDateTimeCardEmployeeDetails />} /> */}
            {/* 
             <Route path="/marketing/loyaltyprogramme" element={<Marketing />} />
            <Route
              path="/marketing/loyaltyprogramme/voucher/:voucherId/:voucherType/:voucherCode"
              element={<VoucherDetails />}
            />
            <Route path="/marketing/broadcasts" element={<Broadcasts />} />
            <Route path="/marketing/surveys" element={<Survey />} />

            <Route path="*" element={<ErrorNotFoundPage />} />
            
            <Route path="/grouporder/orders" element={<Orders />} /> */}
            {/* <Route path="/grouporder/goods" element={<Goods />} /> */}
            {/* <Route path="/grouporder/services" element={<Services />} /> */}



            {/* <Route path="/grouporder/goods" element={<Goods />} />
              <Route path="/grouporder/orders" element={<Orders />} />
              <Route path="/grouporder/services" element={<Services />} />
            
            
              <Route path="/action/incidentslocations" element={<IncidentLocations />} />
              <Route path="/action/incidentslocations/:locationName/incidents" element={<IncidentReport />} />
              <Route path="/action/maintenanceslocations" element={<MaintenancesLocations />} />
              <Route
              path="/action/maintenanceslocations/:locationName/maintenances"
              element={<Maintenances />}
            />
              <Route path="/action/feedback" element={<Feedback />} />
          
            <Route path="/users/employees" element={<Employees />} />  */}
            {/* <Route path="/users/members" element={<Members />} /> */}



            {/* <Route path="/users/members" element={<Members />} />
              <Route path="/users/employees" element={<Employees />} /> */}




            {/* <Route
              path="/configurations/merchantlocations"
              element={<MerchantLocations />}
            />
            <Route
              path="/configurations/merchantlocations/:locationName"
              element={<Merchants />}
            />
            <Route
              path="/configurations/merchantlocations/:locationName/emenu/:merchantId"
              element={<MerchantsEmenu />}
            /> */}
            {/* <Route path="/configurations/suppliers" element={<Suppliers />} /> */}
            {/* <Route path="/configurations/dishware" element={<Dishware />} />
            <Route
              path="/configurations/groupmenu"
              element={<GroupPurchase />}
            />
            <Route path="/configurations/locations" element={<Locations />} />
            <Route
              path="/configurations/accessroles"
              element={<AccessRoles />}
            />
            <Route path="/configurations/hpb" element={<HPBPage />} />
            <Route path="/configurations/emenucat" element={<EMenu />} />
            <Route path="/configurations/faq" element={<FAQ />} />
              <Route path="/configurations/billing" element={<BillingPage />} /> */}


            {/* <Route
              path="/configurations/merchantlocations"
              element={<MerchantLocations />}
            /> */}
            {/* <Route
              path="/configurations/merchantlocations/:locationName"
              element={<Merchants />}
            />
            <Route
              path="/configurations/merchantlocations/:locationName/emenu/:merchantId"
              element={<MerchantsEmenu />}
            /> */}
            {/* <Route path="/configurations/suppliers" element={<Suppliers />} />
            <Route path="/configurations/dishware" element={<Dishware />} /> */}
            {/* <Route
              path="/configurations/groupmenu"
              element={<GroupPurchase />}
            /> */}
            {/* <Route path="/configurations/locations" element={<Locations />} /> */}
            {/* <Route
              path="/configurations/accessroles"
              element={<AccessRoles />}
            /> */}
            {/* <Route path="/configurations/hpb" element={<HPBPage />} />
            <Route path="/configurations/emenucat" element={<EMenu />} /> */}
            {/* <Route path="/configurations/faq" element={<FAQ />} /> */}
            {/* <Route path="/configurations/billing" element={<BillingPage />} /> */}



            {/* <Route path="/configurations/leave" element={<Leave />} /> */}
            {/* <Route path="/configurations/locations" element={<Locations />} /> */}


            {/* <Route
              path="/configurations/merchantlocations"
              element={<MerchantLocations />}
            />
            <Route
              path="/configurations/merchantlocations/:locationName"
              element={<Merchants />}
            />
            <Route
              path="/configurations/merchantlocations/:locationName/emenu/:merchantId"
              element={<MerchantsEmenu />}
            />
            <Route path="/configurations/suppliers" element={<Suppliers />} />
            <Route path="/configurations/dishwarelocations" element={<DishwareLocations />} />
            <Route
              path="/configurations/dishwarelocations/:locationName/dishware"
              element={<Dishware />}
            />

            <Route
              path="/configurations/groupmenu"
              element={<GroupPurchase />}
            />
            <Route path="/configurations/locations" element={<Locations />} />
            <Route path="/configurations/access" element={<Access/>} />
            <Route
              path="/configurations/accessroles"
              element={<AccessRoles />}
            />
            <Route path="/configurations/hpb" element={<HPBPage />} />
            <Route path="/configurations/emenucat" element={<EMenu />} />
            <Route path="/configurations/faq" element={<FAQ />} />
            <Route path="/configurations/billing" element={<BillingPage />} />
            <Route path="/configurations/prizes" element={<Prizes/>}/>
            <Route path="/configurations/prizewheelconfig" element={<PrizeWheelConfig/>}/>
            <Route path="/configurations/prizewheelconfig/:prizeWheelId" element={<PrizeWheel/>}/>
            <Route path="/configurations/leave" element={<Leave />} /> */}

            {/* new config   */}
            {/* <Route path="/configurations/companies" element={<Company />} /> */}
            {/* <Route
              path="/configurations/companies/:companyName/:companyId"
              element={<CompanyItem />}
            />
            <Route path="/operations/locations" element={<OpsLocations />} />
            <Route
              path="/operations/locations/:locationName/orders"
              element={<OpsMerchants />}
              /> */}
            {/* <Route path="/operations/locations" element={<OpsLocations />} /> */}
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
