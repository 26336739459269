import axios from "axios";
import { Additem } from "iconsax-react";
import { useParams } from "react-router-dom";
import AddonLayout from "./layouts/addonLayout";
import MainMenuLayout from "./layouts/mainMenuLayout";
import commonConfigs from "../../../../config/config";
import { useContext, useState, useEffect } from "react";
import PreferenceLayout from "./layouts/preferenceLayout";
import CreateMenuModal from "../components/createMenuModal";
import CreateAddOnModal from "../components/createAddOnModal";
import MerchantsMenuTabs from "../components/merchantMenuTab";
import CreatePrefsModal from "../components/createPreferencesModal";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import { MerchantMenuCurrentTabContext } from "../context/merchantMenuContext";

const MerchantsMenuLayout = () => {
  const { currentTab } = useContext(MerchantMenuCurrentTabContext);
  const [merchantName, setMerchantName] = useState("");
  let { merchantId } = useParams();
  let { locationName } = useParams();

  const pages = [
    { name: "Configurations", href: "#", current: false },
    {
      name: "Locations",
      href: "/configurations/merchantlocations",
      current: false,
    },
    {
      name: locationName,
      href: `/configurations/merchantlocations/${locationName}`,
      current: false,
    },
    {
      name: merchantName,
      href: `/configurations/merchantlocations/${locationName}/emenu/${merchantId}`,
      current: true,
    },
  ];
  // Urls.
  const getMerchantEMenuUrl =
    commonConfigs.apiUrls.GetMerchantEMenu(merchantId);
  const getAddonUrl = commonConfigs.apiUrls.GetAllAddOns(merchantId);
  const getPrefsUrl = commonConfigs.apiUrls.GetPreferencesApis(merchantId);

  // Data.
  const [data, setData] = useState(null);
  const [addOnsData, setAddOnsData] = useState(null);
  const [prefsData, setPrefsData] = useState(null);
  const [load, setLoad] = useState(true);

  // Modals.
  const [isMenuModalOpen, setMenuModal] = useState(false);
  const [isAddOnModalOpen, setAddOnModal] = useState(false);
  const [isPrefsModalOpen, setPrefsModal] = useState(false);

  async function getMerchantName() {
    await axios
      .get(commonConfigs.apiUrls.GetAMerchant(merchantId), {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        setMerchantName(res.data.result.data.merchant_name);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getEMenuData() {
    await axios
      .get(getMerchantEMenuUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setData(res.data.result.data.filter(item => item.is_deleted === 0));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getAddonsData() {
    await axios
      .get(getAddonUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setAddOnsData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getPrefsData() {
    await axios
      .get(getPrefsUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setPrefsData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (load) {
      getMerchantName().catch((err) => {
        console.log("merchant err: " + err);
      });
      getEMenuData().catch((err) => {
        console.log("emenu err: " + err);
      });

      getAddonsData().catch((err) => {
        console.log("err for add ons: " + err);
      });

      getPrefsData().catch((err) => {
        console.log("err for pref: " + err);
      });
    }
  }, [load]);

  function getButtons(name) {
    if (name === "Main Items") {
      return (
        <div className="desktop:mt-4 flex mobile:pt-4  mobile:pb-4 mobile:space-x-2">
          {/* <button
            type="button"
            className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setUploadMenuModal(true)}
          >
            Upload Menu <DocumentUpload className="ml-3" />
          </button> */}
          <button
            type="button"
            className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setMenuModal(true)}
          >
            Create Menu <Additem className="ml-3" />
          </button>
        </div>
      );
    } else if (name === "Add Ons") {
      return (
        <div className="desktop:mt-4 flex mobile:pt-4  mobile:pb-4 mobile:space-x-2">
          <button
            type="button"
            className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setAddOnModal(true)}
          >
            Create Add On Item <Additem className="ml-3" />
          </button>
        </div>
      );
    } else {
      return (
        <div className="desktop:mt-4 flex mobile:pt-4  mobile:pb-4 mobile:space-x-2">
          <button
            type="button"
            className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setPrefsModal(true)}
          >
            Create Preferences <Additem className="ml-3" />
          </button>
        </div>
      );
    }
  }

  return (
    <div className="w-full desktop:p-6 mobile:p-2 ">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
        {getButtons(currentTab.name)}
      </div>
      <MerchantsMenuTabs />

      {currentTab.name == "Main Items" && (
        <MainMenuLayout data={data} load={load} setLoad={setLoad} />
      )}
      {currentTab.name == "Add Ons" && (
        <AddonLayout data={addOnsData} load={load} setLoad={setLoad} />
      )}
      {currentTab.name == "Preferences" && (
        <PreferenceLayout
          data={prefsData}
          load={load}
          setLoad={setLoad}
          merchantId={merchantId}
        />
      )}
      {isMenuModalOpen && (
        <CreateMenuModal
          setCreateModal={setMenuModal}
          load={setLoad}
          id={merchantId}
          locationName={locationName}
          merchantName={merchantName}
        />
      )}

      {isAddOnModalOpen && (
        <CreateAddOnModal
          setCreateModal={setAddOnModal}
          load={setLoad}
          id={merchantId}
          locationName={locationName}
          merchantName={merchantName}
        />
      )}

      {isPrefsModalOpen && (
        <CreatePrefsModal
          setCreateModal={setPrefsModal}
          load={setLoad}
          id={merchantId}
        />
      )}
    </div>
  );
};

export default MerchantsMenuLayout;
