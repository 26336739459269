import { createContext, useState } from "react";
import { getUser } from "../../services/tokenService";
import navigation from "../constants/navigation";
// import {
//   Box,
//   Calendar,
//   Clipboard,
//   Home,
//   Setting2,
//   Share,
//   User,
//   Calculator
// } from "iconsax-react";

const roleId = localStorage.getItem("roleid");
const sidebarAccesses = getUser()?.access;
const accessArr = [];

if (sidebarAccesses) {
  navigation.forEach(navItem => {
    // Find access for the main navigation item
    const access = sidebarAccesses.find(acc =>
      acc.access_name === navItem.title &&
      acc.access_name_option === null
    );
    if (access) {
      navItem.access_id = access.access_id;
    }

    // Find access for sub-navigation items if they exist
    if (navItem.subNav) {
      navItem.subNav.forEach(subNavItem => {
        const subAccess = sidebarAccesses.find(acc =>
          acc.access_name === navItem.title &&
          acc.access_name_option === subNavItem.title
        );
        if (subAccess) {
          subNavItem.access_id = subAccess.access_id;
        }
      });
    }
  });

  sidebarAccesses.forEach((acc) => {
    const navItem = navigation.find(
      (nav) => {
        if (nav.access_id) {
          if (nav.access_id === acc.access_id) {
            return nav
          }
        } else if (nav.subNav) {
          let temporaryArr = []
          nav.subNav.forEach((subnav) => {
            if (subnav.access_id !== undefined) {
              if (subnav.access_id === acc.access_id) {
                temporaryArr.push(subnav)
              }
            }
          })
          return temporaryArr[0];
        }
      }
    );

    if (navItem) {
      let existingNavItem = accessArr.find(
        (item) => item.title === navItem.title
      );

      if (!existingNavItem) {
        existingNavItem = { ...navItem, subNav: [] };
        accessArr.push(existingNavItem);
      }

      if (navItem.subNav && acc.access_name_option) {
        const subNavItem = navItem.subNav.find(
          (sub) => sub.title === acc.access_name_option
        );
        if (subNavItem) {
          existingNavItem.subNav.push(subNavItem);
        }
      }
    }
  });
  accessArr.forEach((nav) => {
    if (nav.subNav && nav.subNav.length === 0) {
      delete nav.subNav;
    }
  });
}

var navigationRoutes = [];

// const Schedule = Calendar;
// const Users = User;
// const Action = Clipboard;
// const Order = Box;
// const Marketing = Share;
// const Configurations = Setting2;
// const Operations = Home;

// const sideBarIcons = [{name: "Dashboard", icon:Operations},{name: "Accounts", icon:Calculator}, {name: "Calendar", icon:Schedule}, {name:"Users",icon:Users}, {name:"Action",icon:Action}, {name:"Order",icon:Order}, {name:"Marketing",icon:Marketing}, {name:"Configurations",icon:Configurations}, {name:"Operations",icon:Operations}]
//get access types by roleid
// const accessTypesSupplier = [ //38
//   {
//     access_name: "Group Purchase Order",
//     access_options: [
//       {
//         access_id: 19,
//         access_name_option: "Orders"
//       }
//     ]

//   },
//   {
//     access_name: "Configurations",
//     access_options: [
//       {
//         access_id: 25,
//         access_name_option: "Suppliers"
//       }
//     ]

//   },
// ]
// const accessTypesOps = [ //39
//   {
//     access_name: "Operations",
//     access_options: [
//       {
//         access_id:27,
//         access_name_option: "Orders"
//       }
//     ]

//   }
// ]

// const accessTypesOpsSupervisor = [ //44
//   {
//     access_name: "Dashboard",
//     access_path: "/dashboard/revenue",
//   },
//   {
//     access_name: "Accounts",
//     access_path: "#0",
//     access_options: [
//       {
//         access_name_option: "Transactions",
//         access_path: "/dashboard/hierarchy/merchantsales",
//       },
//       {
//         access_name_option: "Fees",
//         access_path: "/dashboard/hierarchy/merchanttotalbilling",
//       },
//     ]
//   },
//   {
//     access_name: "Calendar",
//     access_path: "/schedule"
//   },
//   {
//     access_name: "Action Items",
//     access_path: "/action/incidentslocations",
//     access_options: [
//       {
//         access_id:8,
//         access_name_option: "Incident Reports",
//         access_path: "/action/incidentslocations"
//       },
//       {
//         access_id:9,
//         access_name_option: "Maintenance",
//         access_path: "/action/maintenanceslocations"
//       },
//       {
//         access_id:10,
//         access_name_option: "User Feedback",
//         access_path: "/action/feedback"
//       }
//     ]

//   },
//   {
//     access_name: "Group Purchase Order",
//     access_path: "/grouporder/goods",
//     access_options: [
//       {
//         access_id:11,
//         access_name_option: "Goods Requested",
//         access_path: "/grouporder/goods"
//       },
//       {
//         access_id:12,
//         access_name_option: "Services Requested",
//         access_path: "/grouporder/services"
//       },
//       // {
//       //   access_id:13,
//       //   access_name_option: "Food And Beverage Requested",
//       //   access_path: "/grouporder/foodbeverage"
//       // },
//       // {
//       //   access_id:14,
//       //   access_name_option: "Retails Requested",
//       //   access_path: "/grouporder/retails"
//       // },
//       {
//         access_id:13,
//         access_name_option: "Orders",
//         access_path: "/grouporder/orders"
//       }
//     ]

//   },
//   {
//     access_name: "Operations",
//     access_path: "/operations/locations",
//     access_options: [
//       {
//         access_id:27,
//         access_name_option: "Orders",
//         access_path: "/operations/locations",
//       }
//     ]

// },
// {
//   access_name: "Configurations",
//   access_path: "/configurations/merchantlocations",
//   access_options: [
//     {
//       access_id:17,
//       access_name_option: "Merchants and eMenu",
//       access_path: "/configurations/merchantlocations",
//     },
// {
//   access_id:18,
//   access_name_option: "Suppliers",
//   access_path: "/configurations/suppliers",
// },
// {
//   access_id:19,
//   access_name_option: "Dishware",
//   access_path: "/configurations/dishwarelocations",
// },
// {
//   access_id:20,
//   access_name_option: "Group Purchase Menu",
//   access_path: "/configurations/groupmenu",
// },
// {
//   access_id:21,
//   access_name_option: "Locations",
//   access_path: "/configurations/locations",
// },
// {
//   access_id:22,
//   access_name_option: "Access Roles",
//   access_path: "/configurations/accessroles",
// },
// {
//   access_id:24,
//   access_name_option: "eMenu Category",
//   access_path: "/configurations/emenucat",
// },
// {
//   access_id:25,
//   access_name_option: "FAQ",
//   access_path: "/configurations/faq",
// },
// {
//   access_id:26,
//   access_name_option: "Billing",
//   access_path: "/configurations/billing",
// },
// ]

//   }
// ]

// const accessTypesEmenu = [ //45
//   {
//     access_name: "Configurations",
//     access_path: "/configurations/merchantlocations",
//     access_options: [
//       {
//         access_id:17,
//         access_name_option: "Merchants and eMenu",
//         access_path: "/configurations/merchantlocations",
//       },
//       {
//         access_id:21,
//         access_name_option: "Locations",
//         access_path: "/configurations/locations",
//       },
//       {
//         access_id:24,
//         access_name_option: "eMenu Item Category",
//         access_path: "/configurations/emenucat",
//       },
//     ]

//   },
// ]

// const accessTypesMarketing = [ //43
//   {
//     access_name: "Dashboard",
//     access_path: "/dashboard/revenue",
//   },
//   {
//     access_name: "Manage Users",
//     access_path: "/users/members",
//     access_options: [
//       {
//         access_id:6,
//         access_name_option: "Members",
//         access_path: "/users/members",
//       }
//     ]

//   },
//   {
//     access_name: "Marketing",
//     access_path: "/marketing/loyaltyprogramme",
//     access_options: [
//       {
//         access_id:14,
//         access_name_option: "Loyalty Programme & eVouchers",
//         access_path: "/marketing/loyaltyprogramme",
//       },
//       {
//         access_id:15,
//         access_name_option: "Broadcasts",
//         access_path: "/marketing/broadcasts",
//       },
//       {
//         access_id:16,
//         access_name_option: "Surveys",
//         access_path: "/marketing/surveys",
//       },
//     ]

//   }
// ]

// const accessTypesHR = [ //42
//   {
//     access_name: "Calendar",
//     access_path: "/schedule",
//   },
//   {
//     access_name: "Manage Users",
//     access_path: "/users/employees",
//     access_options: [
//       {
//         access_id:7,
//         access_name_option: "Employees",
//         access_path: "/users/employees",
//       }
//     ]

//   },
//   {
//     access_name: "Configurations",
//     access_path: "/configurations/leave",
//     access_options: [
//       {
//         access_id: 8,
//         access_name_option: "Leave Entitlement",
//         access_path: "/configurations/leave"
//       },
//       // {
//       //   access_id: 9,
//       //   access_name_option: "Locations",
//       //   access_path: "/configurations/locations",
//       // },
//     ]
//   }
// ]

// const accessTypesAcc = [ //41
//   {
//     access_name: "Dashboard",
//     access_path: "/dashboard/revenue",
//   },
//   {
//     access_name: "Accounts",
//     access_path: "#0",
//     access_options: [
//       {
//         access_name_option: "Transactions",
//         access_path: "/dashboard/hierarchy/merchantsales",
//       },
//       {
//         access_name_option: "Fees",
//         access_path: "/dashboard/hierarchy/merchanttotalbilling",
//       },
//     ]
//   }

// ]

// const accessTypesMP = [ //46
//   {
//     access_name: "Manage Users",
//     access_path: "/users/employees",
//     access_options: [
//       {
//         access_id:7,
//         access_name_option: "Employees",
//         access_path: "/users/employees",
//       }
//     ]
//   },
// ]

// const accessTypesAM = [ //47
//   {
//     access_name: "Calendar",
//     access_path: "/schedule",
//   }
// ]

// const accessTypesOM = [ //48
//   {
//     access_name: "Calendar",
//     access_path: "/schedule",
//   }
// ]

// if (roleId == 38) {
//   for (var i = 0; i < accessTypesSupplier.length; i++) {
//     var obj = {};

//     obj.title = accessTypesSupplier[i].access_name;
//     if (accessTypesSupplier[i].access_name == "Group Purchase Order") {
//       obj.icon = Home;
//       obj.current = true;
//       obj.path = "/grouporder/goods";

//       var subnavarr = [];
//       var subobj = {};

//       for (var x = 0; x < accessTypesSupplier[i].access_options.length; x++) {
//         if (accessTypesSupplier[i].access_options[x].access_name_option == "Orders") {
//           subobj.title = accessTypesSupplier[i].access_options[x].access_name_option;
//           subobj.path = "/grouporder/goods";
//         }
//         subnavarr.push(subobj);
//       }
//       obj.subNav = subnavarr;
//     } else if (accessTypesSupplier[i].access_name == "Configurations") {
//       obj.icon = Setting2;
//       obj.current = true;
//       obj.path = "/configurations/suppliers";

//       var subnavarConfig = [];
//       var subobjConfig = {};

//       for (var xConfig = 0; xConfig < accessTypesSupplier[i].access_options.length; xConfig++) {
//         if (accessTypesSupplier[i].access_options[xConfig].access_name_option == "Suppliers") {
//           subobjConfig.title = accessTypesSupplier[i].access_options[xConfig].access_name_option;
//           subobjConfig.path = "/configurations/suppliers";
//         }
//         subnavarConfig.push(subobjConfig);
//       }
//       obj.subNav = subnavarConfig;
//     }
//     navigationRoutes.push(obj);
//   }
// } else if (roleId == 39) {
//   for (var v = 0; v < accessTypesOps.length; v++) {
//     var objOps = {};

//     objOps.title = accessTypesOps[v].access_name;
//     if (accessTypesOps[v].access_name == "Operations") {
//       objOps.icon = Home;
//       objOps.current = true;
//       objOps.path = "/operations/locations";

//       var subnavarrOps = [];
//       var subobjOps = {};

//       for (var z = 0; z < accessTypesOps[v].access_options.length; z++) {
//         if (accessTypesOps[v].access_options[z].access_name_option == "Orders") {
//           subobjOps.title = accessTypesOps[v].access_options[z].access_name_option;
//           subobjOps.path = "/operations/locations";
//         }
//         subnavarrOps.push(subobjOps);
//       }
//       objOps.subNav = subnavarrOps;
//     }
//     navigationRoutes.push(objOps);
//   }
// } else if (roleId == 44) {
//   for (var a = 0; a < accessTypesOpsSupervisor.length; a++) {
//     var objOpsS = {};

//     objOpsS.title = accessTypesOpsSupervisor[a].access_name;

//     // if (accessTypesOpsSupervisor[a].access_name == "Operations") {
//     for (var c = 0; c < sideBarIcons.length;c++) {
//       if (accessTypesOpsSupervisor[a].access_name.includes(sideBarIcons[c].name)) {
//         objOpsS.icon = sideBarIcons[c].icon;
//       }
//     }
//     objOpsS.current = true;
//     objOpsS.path = accessTypesOpsSupervisor[a].access_path;

//     var subnavarrOpsS = [];

//     if (accessTypesOpsSupervisor[a].access_options) {
//       for (var b = 0; b < accessTypesOpsSupervisor[a].access_options.length; b++) {
//         var subobjOpsS = {};

//         subobjOpsS.title = accessTypesOpsSupervisor[a].access_options[b].access_name_option;
//         subobjOpsS.path = accessTypesOpsSupervisor[a].access_options[b].access_path;

//         subnavarrOpsS.push(subobjOpsS);
//       }
//       objOpsS.subNav = subnavarrOpsS;
//     }
//     // }
//     navigationRoutes.push(objOpsS);
//   }
// } else if (roleId == 43) {
//   for (var d = 0; d < accessTypesMarketing.length; d++) {
//     var objMarkt = {};

//     objMarkt.title = accessTypesMarketing[d].access_name;

//     // if (accessTypesOpsSupervisor[a].access_name == "Operations") {
//     for (var e = 0; e < sideBarIcons.length;e++) {
//       if (accessTypesMarketing[d].access_name.includes(sideBarIcons[e].name)) {
//         objMarkt.icon = sideBarIcons[e].icon;
//       }
//     }
//     objMarkt.current = true;
//     objMarkt.path = accessTypesMarketing[d].access_path;

//     var subnavarrMarkt = [];

//     if (accessTypesMarketing[d].access_options) {

//       for (let b = 0; b < accessTypesMarketing[d].access_options.length; b++) {
//         var subobjMarkt = {};
//         subobjMarkt.title = accessTypesMarketing[d].access_options[b].access_name_option;
//         subobjMarkt.path = accessTypesMarketing[d].access_options[b].access_path;

//         subnavarrMarkt.push(subobjMarkt);
//       }
//       console.log(subnavarrMarkt);
//       objMarkt.subNav = subnavarrMarkt;
//     }
//     // }
//     navigationRoutes.push(objMarkt);
//   }
// } else if (roleId == 42) {
//   for (var f = 0; f < accessTypesHR.length; f++) {
//     var objHR = {};

//     objHR.title = accessTypesHR[f].access_name;

//     // if (accessTypesOpsSupervisor[a].access_name == "Operations") {
//     // console.log(sideBarIcons);
//     for (var g = 0; g < sideBarIcons.length; g++) {
//       if (accessTypesHR[f].access_name.includes(sideBarIcons[g].name)) {
//         objHR.icon = sideBarIcons[g].icon;
//       }
//     }
//     objHR.current = true;
//     objHR.path = accessTypesHR[f].access_path;

//     var subnavarrHR = [];
//     if (accessTypesHR[f].access_options) {
//       for (let b = 0; b < accessTypesHR[f].access_options.length; b++) {
//         var subobjHR = {};

//         subobjHR.title = accessTypesHR[f].access_options[b].access_name_option;
//         subobjHR.path = accessTypesHR[f].access_options[b].access_path;

//         subnavarrHR.push(subobjHR);
//       }
//       objHR.subNav = subnavarrHR;
//     }
//     // }
//     navigationRoutes.push(objHR);
//   }
// } else if (roleId == 41) {
//   for (let f = 0; f < accessTypesAcc.length; f++) {
//     var objAcc = {};

//     objAcc.title = accessTypesAcc[f].access_name;

//     // if (accessTypesOpsSupervisor[a].access_name == "Operations") {
//     // console.log(sideBarIcons);
//     for (let g = 0; g < sideBarIcons.length; g++) {
//     console.log(sideBarIcons[g]);
//       if (accessTypesAcc[f].access_name.includes(sideBarIcons[g].name)) {
//         objAcc.icon = sideBarIcons[g].icon;
//       }
//     }
//     objAcc.current = true;
//     objAcc.path = accessTypesAcc[f].access_path;

//     var subnavarrAcc = [];

//     if (accessTypesAcc[f].access_options) {
//       for (let b = 0; b < accessTypesAcc[f].access_options.length; b++) {
//         var subobjAcc = {};

//         subobjAcc.title = accessTypesAcc[f].access_options[b].access_name_option;
//         subobjAcc.path = accessTypesAcc[f].access_options[b].access_path;

//         subnavarrAcc.push(subobjAcc);
//       }
//       objAcc.subNav = subnavarrAcc;
//     }
//     console.log(objAcc);
//     // }
//     navigationRoutes.push(objAcc);
//   }
// } else if (roleId == 45) {
//   for (let f = 0; f < accessTypesEmenu.length; f++) {
//     var objEmenu = {};

//     objEmenu.title = accessTypesEmenu[f].access_name;

//     // if (accessTypesOpsSupervisor[a].access_name == "Operations") {
//     // console.log(sideBarIcons);
//     for (let g = 0; g < sideBarIcons.length; g++) {
//     console.log(sideBarIcons[g]);
//       if (accessTypesEmenu[f].access_name.includes(sideBarIcons[g].name)) {
//         objEmenu.icon = sideBarIcons[g].icon;
//       }
//     }
//     objEmenu.current = true;
//     objEmenu.path = accessTypesEmenu[f].access_path;

//     var subnavarrEmenu = [];

//     if (accessTypesEmenu[f].access_options) {
//       for (let b = 0; b < accessTypesEmenu[f].access_options.length; b++) {
//         var subobjEmenu = {};

//         subobjEmenu.title = accessTypesEmenu[f].access_options[b].access_name_option;
//         subobjEmenu.path = accessTypesEmenu[f].access_options[b].access_path;

//         subnavarrEmenu.push(subobjEmenu);
//       }
//       objEmenu.subNav = subnavarrEmenu;
//     }

//     // }
//     navigationRoutes.push(objEmenu);
//   }
// } else if (roleId == 46) {
//   for (let f = 0; f < accessTypesMP.length; f++) {
//     var objMP = {};

//     objMP.title = accessTypesMP[f].access_name;

//     // if (accessTypesOpsSupervisor[a].access_name == "Operations") {
//     // console.log(sideBarIcons);
//     for (let g = 0; g < sideBarIcons.length; g++) {
//     console.log(sideBarIcons[g]);
//       if (accessTypesMP[f].access_name.includes(sideBarIcons[g].name)) {
//         objMP.icon = sideBarIcons[g].icon;
//       }
//     }
//     objMP.current = true;
//     objMP.path = accessTypesMP[f].access_path;

//     var subnavarrMP = [];

//     if (accessTypesMP[f].access_options) {
//       for (let b = 0; b < accessTypesMP[f].access_options.length; b++) {
//         var subobjMP = {};

//         subobjMP.title = accessTypesMP[f].access_options[b].access_name_option;
//         subobjMP.path = accessTypesMP[f].access_options[b].access_path;

//         subnavarrMP.push(subobjMP);
//       }
//       objMP.subNav = subnavarrMP;
//     }

//     // }
//     navigationRoutes.push(objMP);
//   }
// } else if (roleId == 47) {
//   for (let f = 0; f < accessTypesAM.length; f++) {
//     var objAM = {};

//     objAM.title = accessTypesAM[f].access_name;

//     // if (accessTypesOpsSupervisor[a].access_name == "Operations") {
//     // console.log(sideBarIcons);
//     for (let g = 0; g < sideBarIcons.length; g++) {
//     console.log(sideBarIcons[g]);
//       if (accessTypesAM[f].access_name.includes(sideBarIcons[g].name)) {
//         objAM.icon = sideBarIcons[g].icon;
//       }
//     }
//     objAM.current = true;
//     objAM.path = accessTypesAM[f].access_path;

//     var subnavarrAM = [];

//     if (accessTypesAM[f].access_options) {
//       for (let b = 0; b < accessTypesAM[f].access_options.length; b++) {
//         var subobjAM = {};

//         subobjAM.title = accessTypesAM[f].access_options[b].access_name_option;
//         subobjAM.path = accessTypesAM[f].access_options[b].access_path;

//         subnavarrAM.push(subobjAM);
//       }
//       objAM.subNav = subnavarrAM;
//     }

//     // }
//     navigationRoutes.push(objAM);
//   }
// } else if (roleId == 48) {
//   for (let f = 0; f < accessTypesOM.length; f++) {
//     var objOM = {};

//     objOM.title = accessTypesOM[f].access_name;

//     // if (accessTypesOpsSupervisor[a].access_name == "Operations") {
//     // console.log(sideBarIcons);
//     for (let g = 0; g < sideBarIcons.length; g++) {
//     console.log(sideBarIcons[g]);
//       if (accessTypesOM[f].access_name.includes(sideBarIcons[g].name)) {
//         objOM.icon = sideBarIcons[g].icon;
//       }
//     }
//     objOM.current = true;
//     objOM.path = accessTypesOM[f].access_path;

//     var subnavarrOM = [];

//     if (accessTypesOM[f].access_options) {
//       for (let b = 0; b < accessTypesOM[f].access_options.length; b++) {
//         var subobjOM = {};

//         subobjOM.title = accessTypesOM[f].access_options[b].access_name_option;
//         subobjOM.path = accessTypesOM[f].access_options[b].access_path;

//         subnavarrOM.push(subobjOM);
//       }
//       objOM.subNav = subnavarrOM;
//     }
// }
//     navigationRoutes.push(objOM);
//   }
//  else {
//   navigationRoutes = [
//     {
//       title: "Dashboard",
//       path: "/dashboard/revenue",
//       icon: Home,
//       current: true,
//     },
//     {
//       title: "Accounts",
//       path: "#0",
//       icon: Calculator,
//       current: true,
//       subNav: [
//         {
//           title: "Transactions",
//           path: "/dashboard/hierarchy/merchantsales",
//         },
//         {
//           title: "Fees",
//           path: "/dashboard/hierarchy/merchanttotalbilling",
//         },
//       ]
//     },
//     {
//       title: "Calendar",
//       path: "/schedule",
//       icon: Calendar,
//       current: true,
//     },
//     {
//       title: "Manage Users",
//       path: "#0",
//       icon: User,

//       subNav: [
//         {
//           title: "Members",
//           path: "/users/members",
//         },
//         {
//           title: "Employees",
//           path: "/users/employees",
//         },
//       ],
//     },
//     {
//       title: "Action Items",
//       path: "#0",
//       icon: Clipboard,

//       subNav: [
//         {
//           title: "Incident Reports",
//           path: "/action/incidentslocations",
//         },
//         {
//           title: "Maintenances",
//           path: "/action/maintenanceslocations",
//         },
//         {
//           title: "User Feedback",
//           path: "/action/feedback",
//         },
//       ],
//     },
//     {
//       title: "Group Purchase Order",
//       path: "#0",
//       icon: Box,

//       subNav: [
//         {
//           title: "Goods Requested",
//           path: "/grouporder/goods",
//         },
//         {
//           title: "Services Requested",
//           path: "/grouporder/services",
//         },
// // {
// //   title: "Food And Beverage Requested",
// //   path: "/grouporder/foodbeverage"
// // },
// // {
// //   title: "Retails Requested",
// //   path: "/grouporder/retails"
// // },
//     {
//       title: "Orders",
//       path: "/grouporder/orders",
//     },
//   ],
// },
// {
//   title: "Marketing",
//   path: "#0",
//   icon: Share,

//       subNav: [
//         {
//           title: "Loyalty Programme & eVouchers",
//           path: "/marketing/loyaltyprogramme",
//         },
//         {
//           title: "Broadcasts",
//           path: "/marketing/broadcasts",
//         },
//         {
//           title: "Surveys",
//           path: "/marketing/Surveys",
//         },
//       ],
//     },
//     {
//       title: "Operations",
//       path: "#0",
//       icon: Home,
//       subNav: [
//         {
//           title: "Orders",
//           path: "/operations/locations",
//         },
//       ],
//     },
//     {
//       title: "Configurations",
//       path: "#0",
//       icon: Setting2,
//       subNav: [
//         {
//           title: "Merchants and eMenu",
//           path: "/configurations/merchantlocations",
//         },
//         {
//           title: "Prize Wheel",
//           path: "/configurations/prizewheelconfig",
//         },
//         {
//           title: "Prizes",
//           path: "/configurations/prizes",
//         },
//         {
//           title: "Suppliers",
//           path: "/configurations/suppliers",
//         },
//         {
//           title: "Dishware",
//           path: "/configurations/dishwarelocations",
//         },
//         {
//           title: "Group Purchase Menu",
//           path: "/configurations/groupmenu",
//         },
//         {
//           title: "Locations",
//           path: "/configurations/locations",
//         },
//         {
//           title: "Companies",
//           path: "/configurations/companies",
//         },
//         {
//           title: "Access",
//           path: "/configurations/access"
//         },
//         {
//           title: "Access Roles",
//           path: "/configurations/accessroles",
//         },
//         {
//           title: "HPB H365",
//           path: "/configurations/hpb",
//         },
//         {
//           title: "eMenu Category",
//           path: "/configurations/emenucat",
//         },
//         {
//           title: "FAQ",
//           path: "/configurations/faq",
//         },
//         {
//           title: "Billing",
//           path: "/configurations/billing",
//         },
//         {
//           title: "Leave Entitlement",
//           path: "/configurations/leave",
//         },
//       ],
//     },
//   ];
// }
// navigationRoutes.forEach(routes => {
//   let routesToRemove = [];
//   if (roleId == 2) {
//     routesToRemove = [
//       "Accounts",
//       "Prize Wheel",
//       "Suppliers",
//       "Dishware",
//       "Group Purchase Menu",
//       "eMenu Category",
//       "FAQ",
//       "Billing",
//       "Leave Entitlement",
//       "Access Roles",
//       "Locations",
//       "Merchants and eMenu",
//       "Prizes"
//     ]
//     if(routes.title === "Configurations") {
//       routes.path = "#0"
//     }
//   } else if (roleId == 5) {
//     routesToRemove = [
//       "HPB H365",
//       "Companies"
//     ]
//   }
//   if (routes.title === "Configurations" && routes.subNav && (roleId == 2 || roleId == 5)) {
//     routes.subNav = routes.subNav.filter(nav => !routesToRemove.includes(nav.title));
//   }
//   if(routes.title === "Accounts" && roleId == 2){
//     if(routesToRemove.includes(routes.title)){
//       let position = navigationRoutes.map(e => e.title).indexOf("Accounts");
//       navigationRoutes.splice(position,1);
//     }
//   }
// })

navigationRoutes = accessArr;

// console.log(navigationRoutes);

export const NavigationRoutesContext = createContext();
export const CurrentNavigationRouteContext = createContext();

export const NavigationContextProvider = (props) => {
  if (navigationRoutes.length === 0) {
    return null;
  }
  let landingRoute = navigationRoutes[0].title;
  navigationRoutes.forEach((route, i) => {
    if (route.title == "Dashboard" && i != 0) {
      landingRoute = route.title
      return landingRoute;
    }
  })

  const [currentRoute, setCurrentRoute] = useState(landingRoute);
  const contextValue = { currentRoute, setCurrentRoute };
  return (
    <NavigationRoutesContext.Provider value={{ navigationRoutes }}>
      <CurrentNavigationRouteContext.Provider value={contextValue}>
        {props.children}
      </CurrentNavigationRouteContext.Provider>
    </NavigationRoutesContext.Provider>
  );
};

export const userRoutes = navigationRoutes;
