import axios from "axios";
import { Link } from "react-router-dom";
import { Cards, CopySuccess } from "iconsax-react";
import { Edit } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import Table from "./employeeTable";
import React, { useState, useMemo, useEffect } from "react";
import Spinner from "../../../../commons/components/spinner";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import UpdateLeaveRequest from "./updateLeaveRequest";
import { format } from "date-fns";

// const getMerchantsUrl = commonConfigs.apiUrls.GetAllEmployeeLocationTimecards();

const LeaveManagementLayout = () => {

  const pages = [
    { name: "Calendar", href: "/schedule", current: false },
    { name: "Leave", href: `/schedule/Leave`, current: true },
  ];

  // const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [rowToEdit, setRowToEdit] = useState();
  
  const [leaveTimecards, setLeaveTimecards] = useState([]);

  async function fetchLeaveTimecards() {
  
    const GetAllLeaveTimecards = commonConfigs.apiUrls.GetAllLeaveTimecards();

    await axios
      .get(GetAllLeaveTimecards, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log("fetchEmployeeTimecards", res.data.result.data);
        setLeaveTimecards(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (load) {
        fetchLeaveTimecards().catch((err) => {
        console.log("err: " + err);
      });
    }
    
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Timecard Id",
      accessor: "timecard_id",
    },
    {
      Header: "Employee Name",
      accessor: "account_username",
    },
    {
      Header: "Employee Type",
      accessor: "employee_type_name",
    },
    {
      Header: "Date Of Request",
      accessor: "timecard_date",
    },
    {
      Header: "Leave Start",
      accessor: "timestamp_in",
      Cell: ({ value }) => value ? value.split(" ")[0] : ""
    },    
    {
      Header: "Leave End",
      accessor: "timestamp_out",
      Cell: ({ value }) => value ? value.split(" ")[0] : ""
    },      
    {
      Header: "Leave Requested",
      accessor: "leave_request",
    },
    {
      Header: "status",
      accessor: "attendance_status",
      Cell: ({ row }) => {
        let status = row.original.attendance_status;
        if (status == 0 || status == '-') {
          return <div className="text-sm text-orange-700">Pending</div>;
        } 
        else if (status == 1){
          return <div className="text-sm text-green-700">Confirmed</div>;
        }
        else {
          return <div className="text-sm text-red-700">Declined</div>;
        }
      },
    },  
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full bg-white">
      <div className="flex-1 min-w-0 mb-0 p-8">
        <Breadcrumbs pages={pages} />
      </div>

      <div className="w-full desktop:p-6">
        <Table
          columns={columns}
          data={leaveTimecards}
          filters={columns.map((column) => column.accessor)}
        />
        {isUpdateModal && (
          <UpdateLeaveRequest
            setUpdateModal={setUpdateModal}
            load={setLoad}
            row={rowToEdit}
          />
        )}
      </div>
    </div>
  );
};

export default LeaveManagementLayout;
