import axios from "axios";
import { Fragment, useState } from "react";
import commonConfigs from "../../../../../config/config";
import { Dialog, Transition } from "@headlessui/react";

export default function DeleteNetsClickModal({
  setDeleteModal,
  load,
  url,
  title,
  description,
  important = false,
  body
}) {
  const [open, setOpen] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  console.log(url);

  function formatData(data) {
    var newBody = data;
    newBody.merchant_mid_nets_click = null;
    newBody.merchant_tid_nets_click = null;
    newBody.merchant_scrt_key_nets_click = null;
    newBody.merchant_api_key_nets_click = null;
    return newBody;
  }

  function onSubmit() {
    setIsDisabled(true); // Disable the submit button
    const newBody = formatData(body)
    console.log(newBody)

    axios.delete(url, {
      data: newBody,
      headers: commonConfigs.apiHeader
    })
      .then((res) => {
        console.log(res)
        try {
          if (res.data.status != "success") {
            alert("danger");
            load(true);
            setDeleteModal(false);
            setIsDisabled(false); // Enable the submit button after API call
          }
          alert("success");
          setDeleteModal(false);
          load(true);
          setIsDisabled(false); // Enable the submit button after API call
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        load(true);
        setDeleteModal(false);
        setIsDisabled(false); // Enable the submit button after API call
      });
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setDeleteModal(false)}
            />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    className="h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className={`text-lg leading-6 font-medium text-gray-900 ${important ? "p-2 rounded-md bg-red-600 text-white" : ""
                      }`}
                    id="modal-title"
                  >
                    {title ? title : "Confirm to Delete "}
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {description
                        ? description
                        : "Click on Confirm to remove this Item. "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  disabled={isDisabled} // Disable the button when isDisabled is true
                  onClick={() => onSubmit()}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setDeleteModal(false) && load(true)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
