import { LocationsContextProvider } from "../../context/locationsContext";
import LocationsLayout from "./locationsLayoutPage";

const LocationsPage = () => {
  return (
    <LocationsContextProvider>
      <LocationsLayout />
    </LocationsContextProvider>
  );
};

export default LocationsPage;
