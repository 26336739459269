import axios from "axios";
// import { format } from "date-fns";
import { Link, useParams } from "react-router-dom";
import { Cards } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import Table from "../../../../commons/components/table";
import React, { useState, useMemo, useEffect } from "react";
import Spinner from "../../../../commons/components/spinner";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";



const MerchantsLayout = () => {
  let { locationcode } = useParams();
  let { locationId } = useParams();
  let { date } = useParams();
  
  const pages = [
    { name: "Calendar", href: "/schedule", current: false },
    { name: "Locations", href: `/schedule/${date}/merchant-timecard`, current: true },
    { name: locationcode, href: `/schedule/${date}/merchant-timecard/${locationId}/${locationcode}`, current: true },
  ];
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const getMerchantsUrl = commonConfigs.apiUrls.GetLocationMerchantTimecards(locationId, '');
    console.log(getMerchantsUrl);
    async function getData() {
      await axios
        .get(getMerchantsUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res);
          setData(res.data.result.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Merchant Name",
      accessor: "merchant_name",
      width: 10,
      Cell: ({ value }) => {
        return (
          <div className="flex items-center">
            {/* <div className="flex-shrink-0 h-10 w-10">
              <img src={row.original.merchant_img_url} />
            </div> */}
            <div className="ml-4">
              <p className="text-sm text-black">{value}</p>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Today's Clock In",
      accessor: ({ time_in_datetime }) => {
        if (time_in_datetime === 'Absent') {
          return 'Absent';
        }
        return time_in_datetime && time_in_datetime !== "-" ? time_in_datetime.substring(11, 19) : "-";
      },
    },
    {
      Header: "Today's Clock Out",
      accessor: ({ time_out_datetime }) => {
        if (time_out_datetime === 'Absent') {
          return 'Absent';
        }
        return time_out_datetime && time_out_datetime !== "-" ? time_out_datetime.substring(11, 19) : "-";
      },
    },
    {
      Header: "Duration",
      accessor: "attendance_duration",
    },
    {
      Header: "Current Attendance",
      accessor: "attendance_current",
    },
    {
      Header: "Month-To-Date Stall Manning",
      accessor: "attendance_total",
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        let merchantObj = {
          merchantName: row.original.merchant_name,
          id: row.original.merchant_id,
        };

        return (
          <div className=" text-gray-900  flex space-x-1 ">
            <button type="button">
              <Link
                to={{
                  pathname: `/schedule/${date}/merchant-timecard/${locationId}/${locationcode}/${merchantObj.id}`,
                }}
              >
                <Cards aria-hidden="true" className="w-6 h-6" />
              </Link>
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      {data != null ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MerchantsLayout;
