import { createContext, useState } from "react";

const tabs = [{ name: "Item Prize" }, { name: "eVoucher Prize" }];

export const PrizeTabsContext = createContext();
export const PrizeCurrentTabContext = createContext();

export const PrizeContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <PrizeTabsContext.Provider value={{ tabs }}>
      <PrizeCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </PrizeCurrentTabContext.Provider>
    </PrizeTabsContext.Provider>
  );
};




