import DashboardCard from "../../components/dashboardCard";
import { roundToTwo } from "../../../../utils/custom/helper";

export default function CurrentMonthBillCard({ data }) {
  return (
    <DashboardCard>         

      <h3 className="text-xl">Current Month Total Billable</h3>
   
      {data.total_revenue.total_revenue ? <h2 className="text-yellow-800 text-4xl font-bold mb-4">${roundToTwo(data.total_revenue.total_revenue)}</h2> : <h2 className="text-yellow-800 text-4xl font-bold mb-4">$0</h2>}
        
      
      <h3 className="text-xl">{"Current Month Billing"}</h3>
      <div>
        {data.total_revenue.revenue_stream.map((revenue) => (
          <div key={revenue.type}>
            <div className="flex justify-between mt-4 space-x-8">
              <div className="text-sm ">{revenue.type}</div>

              <div className="flex flex-grow gap-x-4 justify-end">
                <div className=" text-sm text-gray-500">
                  ${roundToTwo(revenue.revenue)}
                </div>
              </div>
            </div>
            <div className="w-full border-t border-gray-300 mt-2"></div>
          </div>
        ))}
      </div>
    </DashboardCard>
  );
}
