import { createContext, useState } from "react";
const tabs = [
  { name: "All" },
  { name: "Supplier-Goods" },
  { name: "Supplier-Services" },
];
export const OrdersTabsContext = createContext();
export const OrdersCurrentTabContext = createContext();
export const OrdersContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };
  return (
    <OrdersTabsContext.Provider value={{ tabs }}>
      <OrdersCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </OrdersCurrentTabContext.Provider>
    </OrdersTabsContext.Provider>
  );
};
