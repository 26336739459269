import { createContext, useState } from "react";

// const tabs = [
//   { name: "Non-Expired" },
//   { name: "Expired"},
// ];

const tabs = [
    { name: "Unexpired", value: 0 },
    { name: "Expired", value: 1 },
  ];

export const eVoucherTabsContext = createContext();
export const eVoucherCurrentTabContext = createContext();
export const EVoucherContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <eVoucherTabsContext.Provider value={{ tabs }}>
      <eVoucherCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </eVoucherCurrentTabContext.Provider>
    </eVoucherTabsContext.Provider>
  );
};
