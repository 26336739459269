import { useState, useContext, useEffect } from "react";
import { subHours, isAfter, isBefore, isSameDay } from "date-fns";
import { useNavigate } from "react-router";
import { MonthlyBody, MonthlyDay, MonthlyCalendar, MonthlyNav, DefaultMonthlyEventItem } from "@zach.codes/react-calendar";
import ScheduleTabs from "../components/scheduleTabs";
import { ScheduleCurrentTabContext } from "../context/scheduleContext";
import "@zach.codes/react-calendar/dist/calendar-tailwind.css";
import axios from "axios";
import commonConfigs from "../../../config/config";
import ScheduleDate from "../pages/scheduleDateLayout";

const ScheduleCalendar = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [timeInData, setTimeInData] = useState([]);
  const [timeInMerchantData, setTimeInMerchantData] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [load, setLoad] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState('');

  const [locationList, setLocationList] = useState([]);

  const month = new Date(currentMonth);
  const Month = month.getMonth() + 1;

  console.log("Month", Month);

  async function fetchTimeInCalendar() {
    const GetTimeIn = commonConfigs.apiUrls.GetCalendar(Month, 0, selectedLocation);

    await axios
      .get(GetTimeIn, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setTimeInData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function fetchTimeInMerchantCalendar() {
    const GetTimeInMerchants = commonConfigs.apiUrls.GetCalendar(Month, 1, selectedLocation);

    await axios
      .get(GetTimeInMerchants, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setTimeInMerchantData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  

  async function fetchLeaveCalendar() {
    const GetAllLeaveTimecards = commonConfigs.apiUrls.GetAllLeaveTimecards();

    await axios
      .get(GetAllLeaveTimecards, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setLeaveData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function fetchLocations() {

    const GetLocations = commonConfigs.apiUrls.GetLocations();

    await axios
    .get(GetLocations, { headers: commonConfigs.apiHeader })
    .then((res) => {
      console.log(res.data.result.data);
      setLocationList(res.data.result.data.filter(item => item.is_deleted === 0));
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    if (load) {
      fetchLocations().catch((err) => {
        console.log("err: " + err);
      });
      fetchTimeInCalendar().catch((err) => {
        console.log("err: " + err);
      });
      fetchTimeInMerchantCalendar().catch((err) => {
        console.log("err: " + err);
      });
      fetchLeaveCalendar().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load, selectedLocation]);

  useEffect(() => {
    setLoad(true);
  }, [currentMonth]);

  function schedule(type) {
    if (type === "Maintenance") {
      return "bg-red-200";
    } else if (type === "Employee Time In/Out") {
      return "bg-gray-500";
    } else if (type === "Merchant Time In/Out") {
      return "bg-gray-400";
    }
  }

  const navigate = useNavigate();

  const handleClick = (date, id) => {
    const formattedDate = date.toISOString().substr(0, 10);
    if (id === "Leave") {
      navigate("/schedule/Leave");
    } else {
      const location = selectedLocation;
      navigate(`/schedule/date/${formattedDate}/${location}`);
    }
  };

  const { currentTab } = useContext(ScheduleCurrentTabContext);

  const currentDate = new Date();

  const combinedLeaveData = [];
  const leaveDates = new Set();

  leaveData.forEach((item) => {
    const date = new Date(item.timecard_date).toISOString().substring(0, 10);
    if (!leaveDates.has(date)) {
      leaveDates.add(date);
      combinedLeaveData.push({
        id: "Leave",
        date: new Date(date),
        attendance_current: 0,
        attendance_total: 0,
      });
    }
  });

  const convertedTimeInData = [
    ...timeInData.map((item) => ({
      id: "Employee Time In/Out",
      date: new Date(item.date),
      attendance_current: item.attendance_current === '-' ? 0 : item.attendance_current,
      attendance_total: item.attendance_total,
    })),
    ...timeInMerchantData.map((item) => ({
      id: "Merchant Time In/Out",  // Set the ID as "Merchant Time In/Out"
      date: new Date(item.date),
      attendance_current: item.attendance_current === '-' ? 0 : item.attendance_current,
      attendance_total: item.attendance_total,
    })),
    ...combinedLeaveData,
  ];
  

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <ScheduleTabs />
          {console.log("Current Tab", currentTab.name)}
        </div>

        <div style={{ alignSelf: "flex-end", marginRight: '60px', width: '200px' }}>
          <select
            style={{ width: '130%', padding: '8px', borderRadius: '16px' }}
            onChange={(e) => {
              const selectedValue = e.target.value;
              setSelectedLocation(selectedValue === "all" ? "" : selectedValue);
              setLoad(true); // Set load to true to trigger the data fetching immediately
            }}
            value={selectedLocation === "" ? "all" : selectedLocation} // Map empty string to "all" for the dropdown
          >
            <option value="all">All</option>
            {locationList.map((location) => (
              <option key={location.location_id} value={location.location_id}>
                {location.location_name}
              </option>
            ))}
          </select>
        </div>
      
      </div>

      {console.log("Current Tab", currentTab.name)}

      <div style={{ marginTop: '10px' }}>
        <MonthlyCalendar currentMonth={currentMonth} onCurrentMonthChange={(date) => setCurrentMonth(date)}>
          <MonthlyNav />
          <MonthlyBody events={convertedTimeInData.filter((item) => item.id === currentTab.name || currentTab.name === "All")}>
            <MonthlyDay
              renderDay={(data) =>
                data.map((item) => {
                  const isCurrentDate = isSameDay(item.date, currentDate);
                  const buttonColor =
                    isCurrentDate && item.id == "Employee Time In/Out"
                      ? "bg-teal-500"
                      : isCurrentDate && item.id == "Merchant Time In/Out" 
                      ? "bg-teal-400"
                      : item.id === "Leave"
                      ? "bg-red-400"
                      : schedule(item.id);

                      let buttonText = "";

                      if (item.id === "Merchant Time In/Out") {
                        buttonText = `Merchant Attendance: ${item.attendance_current}/${item.attendance_total}`;
                      } else if (item.id === "Employee Time In/Out") {
                        buttonText = `Employee Attendance: ${item.attendance_current}/${item.attendance_total}`;
                      } else if (item.id === "Leave") {
                        buttonText = "Pending Leave Request";
                      }
                      
                  return (
                    <button
                      key={item.id}
                      type="button"
                      className={`w-full text-white text-xs rounded shadow-md ${buttonColor}`}
                      onClick={() => handleClick(item.date, item.id)}
                    >
                      <DefaultMonthlyEventItem title={buttonText} />
                    </button>
                  );
                })
              }
            />
          </MonthlyBody>
        </MonthlyCalendar>
      </div>
    </div>
  );
};

export default ScheduleCalendar;
