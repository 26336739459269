import DishwareLocationsLayout from "./dishwareLocationsLayout";
import { DishwareLocationsContextProvider } from "../context/dishwareLocationsContext";

const DishwareLocationsPage = () => {
  return (
    <DishwareLocationsContextProvider>
      <DishwareLocationsLayout />
    </DishwareLocationsContextProvider>
  );
};

export default DishwareLocationsPage;
