import HPBLayout from "./hpbLayout";
import { HPBContextProvider } from "../context/hpbContext";


const HPBPage = () => {
  return (
  <HPBContextProvider>
    <HPBLayout />
  </HPBContextProvider>
  );
};

export default HPBPage;
