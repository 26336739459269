const OrderItems = (props) => {
  return (
    <>
      {props.data.map((items, index) => (
        <>
          <div key={index}>
            <p>{items.product_qty}x {items.product_name} ${items.product_price}</p>
            <br />
            {items.add_ons.length !== 0 ? <p>Add Ons:</p> : <></>}
            {items.add_ons && items.add_ons.map((addon) => (
              <>
                {addon.add_on_qty !== 0 ? <p key={addon.product_id} style={{ paddingLeft: '20px' }}>{addon.add_on_qty}x {addon.product_name} ${addon.add_on_price}</p>:<></>}
              </>
            ))}

            {items.product_pref !== "" ? <p>Prefs:</p> : <></>}
            {items.product_pref !== "" ? <p>{items.product_pref}</p> : <></>}
    
            {items.remarks !== "" ? <p>Remarks: {items.remarks}</p> : <></>}
            <br />
            <hr/>
          </div>
        </>
      ))}
    </>
  );
};

export default OrderItems;
