import { Box, Drawer, List, ListItemButton, ListItemIcon, Stack, Toolbar } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import docRoutes from "../routes/routes";
import LayoutConfig from "../styles/layoutConfig";
import SidebarItem from "./sidebarItem";
import SidebarItemCollapse from "./sidebarItemCollapse";
import logo from "../../../assets/food-canopy-logo 3.png"

const Sidebar = () => {
    return (
      <Drawer
        variant="permanent"
        sx={{
          width: LayoutConfig.sidebar.width,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: LayoutConfig.sidebar.width,
            boxSizing: "border-box",
            borderRight: "0px",
            backgroundColor: LayoutConfig.sidebar.bg,
            color: LayoutConfig.sidebar.color
          }
        }}
      >
        <List disablePadding>
          <Toolbar sx={{ marginBottom: "8px" }}>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center"
            >
              <Box component="img" src={logo} alt="food canopy logo" sx={{height: "63px", width: "190px", marginTop: "15px"}}/>
            </Stack>
          </Toolbar>
          <Provider store={store}>
          {docRoutes.map((route, index) => (
          route.sidebarProps ? (
            route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null
          ))}
          </Provider>
        </List>
      </Drawer>
    );
  };
  
  export default Sidebar;