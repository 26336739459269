import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { InfoCircle } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import axios from "axios";
import Spinner from "../../../../commons/components/spinner";

const user = JSON.parse(localStorage.getItem("user"));

// const getAccessRightsUrl = commonConfigs.apiUrls.AccessTypes();
const getRolesUrl = commonConfigs.apiUrls.GetAllAccessRoles();
const getEmpTypesUrl = commonConfigs.apiUrls.EmployeeTypes();
const getLocationsUrl = commonConfigs.apiUrls.GetLocations();
const getDeptUrl = commonConfigs.apiUrls.Departments();
const getCompUrl = commonConfigs.apiUrls.GetCompanies();

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function UpdateEmpModal({ setUpdateModal, row, load }) {
  const [open, setOpen] = useState(true);
  const [empTypes, setEmpTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [dept, setDept] = useState([]);
  const [comp, setComp] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [locationDisplay, setLocationDisplay] = useState(true);

  const [empTypeDisplay, setEmpTypeDisplay] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(row.merchant_id);
  const [selectedEmpType, setSelectedEmpType] = useState(row.employee_type);
  const [merchants, setMerchants] = useState([]);
  // const [hourlyRate, setHourlyRate] = useState([]);
  // const [monthlyRate, setMonthlyRate] = useState([]);
  // const [overtimeRate, setOvertimeRate] = useState([]);

  const [lazyData, setLazyData] = useState({});
  // setSelectedMerchant()
  // setSelectedEmpType
  const id = row.account_id;

  const employee_type = row.employee_type;

  useEffect(() => {
    if (employee_type === 'Merchant_Employee' || employee_type === 'Merchant_Supervisor') {
      setEmpTypeDisplay(true);
    } else {
      setEmpTypeDisplay(false);
    }
    if (employee_type === 'Web_Admin') {
      setLocationDisplay(false);
    } else {
      setLocationDisplay(true);
    }
  }, [employee_type]);
// console.log(row)
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue
  } = useForm({
    defaultValues: {
      account_username: row.account_username,
      account_email: row.account_email,
      account_mobile: row.account_mobile,
      company_id: row.company_id,
      job_title: row.job_title,
      // merchant_id: row.merchant_id,
      employee_type_sys_code:selectedEmpType,
      employment_type: 
      {
        value: (row.employee_details && row.employee_details.length > 0) ? row.employee_details[0].employment_type : '',
        label: (row.employee_details && row.employee_details.length > 0) ? row.employee_details[0].employment_type_name: '',
      },

      leave_entitlement_tier: {
        value: row.leave_entitlement_tier,
        label: (row.employee_details && row.employee_details.length > 0) ? 
        ((row.employee_details[0].leave_entitlement_tier === 1) ? 'Entry-Level' : 
        ((row.employee_details[0].leave_entitlement_tier === 2) ? 'Mid-Level' : 
        ((row.employee_details[0].leave_entitlement_tier === 3) ? 'Executive-Level' : ''))) : ''
      ,
      },

      shift_name: (row.schedule && row.schedule.shift && row.schedule.shift.shift_name) ? row.schedule.shift.shift_name : '',

      shift_start: (row.schedule && row.schedule.shift && row.schedule.shift.shift_start) ? row.schedule.shift.shift_start : '',
      shift_end: (row.schedule && row.schedule.shift && row.schedule.shift.shift_end) ? row.schedule.shift.shift_end : '',
      
      date_start: (row.schedule && row.schedule.date_start) ? row.schedule.date_start : '',
      date_end: (row.schedule && row.schedule.date_end) ? row.schedule.date_end : '',

      hourly_pay_rate: (row.employee_details && row.employee_details.length > 0) ? row.employee_details[0].hourly_pay_rate : '',
      monthly_pay_rate: (row.employee_details && row.employee_details.length > 0) ? row.employee_details[0].monthly_pay_rate : '',
      overtime_pay_rate: (row.employee_details && row.employee_details.length > 0) ? row.employee_details[0].overtime_pay_rate : '',
      work_days_per_week: (row.employee_details && row.employee_details.length > 0) ? row.employee_details[0].work_days_per_week : '',
    },
  });

  function formatData(data) {
    var account_id = id
    console.log(data);
    // var location_id = data.location.value;
    var company_id = data.company.value;
    var employee_type_sys_code = data.employee_type_sys_code.value; 
    if (employee_type_sys_code !== 0) {
      var location_id = data.location.value;  
    }
    var role_id = data.role.value;
    var department_id = data.department.value;
    var employment_type = data.employment_type.value; 
    var leave_entitlement_tier = data.leave_entitlement_tier.value; 

    var newData = data;

    // if(empTypeDisplay == false)
    // {
    //   newData.merchant_id = '';
    // }
    // else
    // {
    //   newData.merchant_id = data.merchant_id.value;
    // }

    newData.account_id = account_id;
    // newData.merchant_id = selectedMerchant;
    newData.location_id = location_id;
    newData.company_id = company_id;
    // newData.company_uen = company_id;
    newData.employee_type_sys_code = employee_type_sys_code;
    newData.role_id = role_id;
    newData.department_id = department_id;
    newData.employment_type = employment_type;
    newData.leave_entitlement_tier = leave_entitlement_tier;
    newData.updated_by = user.account_id;

    return newData;
  }

  const onSubmit = (data) => {
    setIsDisabled(true); // Disable the submit button
    formatData(data);
    console.log(data)

    axios
      .put(commonConfigs.apiUrls.UpdateEmployee(id), data, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setUpdateModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);sg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);sg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  async function getEmpTypes() {
    await axios
      .get(getEmpTypesUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setEmpTypes(res.data.result.data);

        res.data.result.data.forEach((empType) => {
          // console.log(empType.sys_code_desc.replace(' ','_'))
          // console.log(row.employee_type)
          if (empType.sys_code_desc.replace(' ','_') == row.employee_type) {
            // setSelectedEmpType(empType.sys_code_id);
            setLazyData(
              (lazyData["employee_type_sys_code"] = {
                value: empType.sys_code_id,
                label: empType.sys_code_desc,
              })
            );
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getMerchants() {
    const getMerchantsUrl = commonConfigs.apiUrls.Merchants();

    await axios
      .get(getMerchantsUrl, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
          setMerchants(res.data.result.data);
          
          res.data.result.data.forEach((merchants) => {
            if (merchants.merchant_id == row.merchant_id) {
              setLazyData(
                (lazyData["merchant_id"] = {
                  value: merchants.merchant_id,
                  label: merchants.merchant_name,
                })
              );
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getPayRates() {
    
    const GetAnEmployee = commonConfigs.apiUrls.GetAnEmployee(id);

    await axios
      .get(GetAnEmployee, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log("jihugytr",res.data.result.data.employee_details.hourly_pay_rate);
        setHourlyRate(res.data.result.data.employee_details.hourly_pay_rate);
        setMonthlyRate(res.data.result.data.employee_details.monthly_pay_rate);
        setOvertimeRate(res.data.result.data.employee_details.overtime_pay_rate);

      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getLocations() {
    await axios
      .get(getLocationsUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setLocations(res.data.result.data.filter(item => item.is_deleted === 0));

        res.data.result.data.filter(item => item.is_deleted === 0).forEach((location) => {
          if (location.location_name == row.location) {
            setLazyData(
              (lazyData["location"] = {
                value: location.location_id,
                label: location.location_name,
              })
            );
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getDept() {
    await axios
      .get(getDeptUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setDept(res.data.result.data);

        res.data.result.data.forEach((department) => {
          if (department.department_name == row.department) {
            setLazyData(
              (lazyData["department"] = {
                value: department.department_id,
                label: department.department_name,
              })
            );
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function getComp() {
    await axios
      .get(getCompUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setComp(res.data.result.data);

        res.data.result.data.forEach((company) => {
          // console.log(company);
          if (company.company_id == row.company_id) {
            setLazyData(
              (lazyData["company"] = {
                value: company.company_id,
                label: company.company_name,
              })
            );
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getRoles() {
    await axios
      .get(getRolesUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setRoles(res.data.result.data);

        res.data.result.data.forEach((role) => {
          if (role.role_id == row.role.role_id) {
            setLazyData(
              (lazyData["role"] = {
                value: role.role_id,
                label: role.role_name,
              })
            );
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const getSelectData = async () => {
      await getLocations().catch((err) => {
        console.log("err for locations: " + err);
      });
      await getEmpTypes().catch((err) => {
        console.log("err for emp types: " + err);
      });
      await getDept().catch((err) => {
        console.log("err for dept: " + err);
      });
      await getComp().catch((err) => {
        console.log("err for dept: " + err);
      });
      await getRoles().catch((err) => {
        console.log("err for roles: " + err);
      });
      await getMerchants().catch((err) => {
        console.log("err for roles: " + err);
      });
      // await getPayRates().catch((err) => {
      //   console.log("err for Rates" + err);
      // });
    };

    getSelectData()
      .then(() => {
        reset(lazyData);
      })
      .catch((err) => {
        console.log("err for roles: " + err);
      });
  }, []);

  const empTypesOptions = empTypes.map((d) => ({
    value: d.sys_code_id,
    label: d.sys_code_desc,
  }));

  const merchantOption = merchants.map((d) => ({
    value: d.merchant_id,
    label: d.merchant_name,
  }));

  const locationOption = locations.map((d) => ({
    value: d.location_id,
    label: d.location_name,
  }));

  const departmentOptions = dept.map((d) => ({
    value: d.department_id,
    label: d.department_name,
  }));
  const companyOptions = comp.map((d) => ({
    value: d.company_id,
    label: d.company_name,
  }));
  const rolesOptions = roles.map((d) => ({
    value: d.role_id,
    label: d.role_name,
  }));

  const employmentOption = [
    {value: 1, label: 'Full Time'}, 
    {value: 2, label: 'Part Time'},
  ];

  const tierOption = [
    {value: 1, label: 'Entry-Level'}, 
    {value: 2, label: 'Mid-Level'}, 
    {value: 3, label: 'Executive-Level'},
  ];

  // const [selectedEmploymentType, setSelectedEmploymentType] = useState(null);

  // const handleEmploymentTypeChange = (selectedOption) => {
  //   setSelectedEmploymentType(selectedOption);
  // };

  const handleToggle = (e) => {
    console.log(e)
    console.log("Selected value:", e.value); 
    setValue("employee_type_sys_code", {value:e.value, label:e.label})
    var employeeType = e.value;
    if (employeeType == 1 || employeeType == 2 ) {
      setEmpTypeDisplay(true);  
    } else {
      setEmpTypeDisplay(false);  
    }
    if (employeeType == 0) {
      setLocationDisplay(false);  
    } else {
      setLocationDisplay(true);  
    }
    setSelectedEmpType(e.value);
  }

  // const handleMerchant = (e) => {
  //   var merchant = e.value;
  //   setSelectedMerchant(merchant);
  // }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setUpdateModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              {/* <div className="flex flex-wrap flex-row place-items-center">
                <div className="flex place-content-center w-full">
                  <Spinner className="w-20 h-20" />
                </div>
              </div> */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Update Employee
                    </Dialog.Title>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="employee_type_sys_code"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Employee Type
                      </label>
                      <Controller
                        name="employee_type_sys_code"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={empTypesOptions}
                            isSearchable={true}
                            placeholder="Select the employee type"
                            className="text-sm"
                            onChange={(e)=>handleToggle(e)}
                          />
                        )}
                      />
                    </div>

                    {empTypeDisplay ?
                      <div className="mt-2 sm:col-span-6">
                        <label
                          htmlFor="merchant_id"
                          className="block text-sm font-medium text-yellow-800"
                        >
                          Merchants
                        </label>
                        <Controller
                          name="merchant_id"
                          control={control}
                          render={({ field }) => (
                            <Select
                              isClearable
                              {...field}
                              options={merchantOption}
                              isSearchable={true}
                              placeholder="Select a Merchant"
                              className="text-sm"
                              // onChange={(e)=>handleMerchant(e)}
    
                            />
                          )}
                        />
                      </div>
                   : <></>}

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="employment_type"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Employment Type
                      </label>
                      <Controller
                        name="employment_type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={employmentOption}
                            isSearchable={true}
                            placeholder="Select a Type"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="hourly_pay_rate"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Hourly Rate
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          step="0.01"
                          name="hourly_pay_rate"
                          id="hourly_pay_rate"
                          className={
                            !errors.hourly_pay_rate
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Hourly Pay Rate"
                          {...register("hourly_pay_rate", { required: true })}
                        />
                        {errors.hourly_pay_rate?.type === "required" && errorIcon}
                      </div>
                    </div>

                
                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="monthly_pay_rate" className="block text-sm font-medium text-yellow-800">
                        Monthly Rate
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          step="0.01"
                          name="monthly_pay_rate"
                          id="monthly_pay_rate"
                          className={
                            !errors.monthly_pay_rate
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Monthly Pay Rate"}
                          {...register("monthly_pay_rate", { required: true })}
                        />
                        {errors.monthly_pay_rate?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="overtime_pay_rate"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Overtime Rate
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          step="0.01"
                          name="overtime_pay_rate"
                          id="overtime_pay_rate"
                          className={
                            !errors.overtime_pay_rate
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Overtime Pay Rate"}
                          {...register("overtime_pay_rate", { required: true })}
                        />
                        {errors.overtime_pay_rate?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="work_days_per_week"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Working Days Per Week
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          step="0.01"
                          name="work_days_per_week"
                          id="work_days_per_week"
                          className={
                            !errors.work_days_per_week
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Working Days Per Week"}
                          {...register("work_days_per_week", { required: true })}
                        />
                        {errors.work_days_per_week?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="leave_entitlement_tier"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Leave Entitlement Tier
                      </label>
                      <Controller
                        name="leave_entitlement_tier"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={tierOption}
                            isSearchable={true}
                            placeholder="Enter Tier"
                            className="text-sm"
                          />
                        )}
                      />
                    </div> 
                    {locationDisplay ? 
                      <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="location"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Location
                      </label>
                      <Controller
                        name="location"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={locationOption}
                            isSearchable={true}
                            placeholder="Select a Location"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                    : <></>}
                    
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="account_username"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="account_username"
                          id="account_username"
                          className={
                            !errors.account_username
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Name"}
                          {...register("account_username", { required: true })}
                        />
                        {errors.account_username?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="account_email"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Email
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="email"
                          name="account_email"
                          id="account_email"
                          className={
                            !errors.account_email
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Email"}
                          {...register("account_email", { required: true })}
                          // readOnly 
                        />
                        {errors.account_email?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="account_mobile"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Mobile Number
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="tel"
                          name="account_mobile"
                          id="account_mobile"
                          maxLength={8}
                          className={
                            !errors.account_mobile
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Mobile Number"}
                          {...register("account_mobile", { required: true })}
                        />
                        {errors.account_mobile?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    {/* <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="company_id"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Comapany Uen
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="company_id"
                          id="company_id"
                          className={
                            !errors.company_id
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Company Uen"}
                          {...register("company_id", { required: true })}
                        />
                        {errors.company_id?.type === "required" &&
                          errorIcon}
                      </div>
                    </div> */}
                   <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="company"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Company
                      </label>
                      <Controller
                        name="company"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={companyOptions}
                            isSearchable={true}
                            placeholder="Select the company the employee works in"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="shift_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Shift Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="shift_name"
                          id="shift_name"
                          className={
                            !errors.shift_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Shift Name"}
                          {...register("shift_name", { required: true })}
                          // pattern="^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"

                        />
                        {errors.shift_name?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="shift_start" className="block text-sm font-medium text-yellow-800">
                        Shift Start
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="time"
                          name="shift_start"
                          id="shift_start"
                          className={
                            !errors.shift_start
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Shift Start Time (HH:MM:am/pm)"}
                          {...register("shift_start", {
                            required: true,
                            pattern: {
                              value: /^([01][0-9]|2[0-3]):[0-5][0-9]$/,
                              message: "Please enter a valid time in the format 'HH:MM'",
                            },
                          })}
                    
                        />
                        {errors.shift_start?.type === "required" && errorIcon}
                        {errors.shift_start?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.shift_start?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="shift_end" className="block text-sm font-medium text-yellow-800">
                        Shift End
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="time"
                          name="shift_end"
                          id="shift_end"
                          className={
                            !errors.shift_end
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Shift End Time (HH:MM:am/pm)"}
                          {...register("shift_end", {
                            required: true,
                            pattern: {
                              value: /^([01][0-9]|2[0-3]):[0-5][0-9]$/,
                              message: "Please enter a valid time in the format 'HH:MM'",
                            },
                          })}
               
                        />
                        {errors.shift_end?.type === "required" && errorIcon}
                        {errors.shift_end?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.shift_end?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="date_start" className="block text-sm font-medium text-yellow-800">
                        Date Start
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="date"
                          name="date_start"
                          id="date_start"
                          className={
                            !errors.date_start
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Start Date (YYYY-MM-DD)"}
                          {...register("date_start", {
                            required: true,
                            pattern: {
                              value: /^\d{4}-\d{2}-\d{2}$/,
                              message: "Please enter a valid date in the format 'YYYY-MM-DD'",
                            },
                          })}
                        />
                        {errors.date_start?.type === "required" && errorIcon}
                        {errors.date_start?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.date_start?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="date_end" className="block text-sm font-medium text-yellow-800">
                        Date End
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="date"
                          name="date_end"
                          id="date_end"
                          className={
                            !errors.date_end
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter End Date (YYYY-MM-DD)"}
                          {...register("date_end", {
                            required: false,
                            pattern: {
                              value: /^\d{4}-\d{2}-\d{2}$/,
                              message: "Please enter a valid date in the format 'YYYY-MM-DD'",
                            },
                          })}
                        />
                        {errors.date_end?.type === "required" && errorIcon}
                        {errors.date_end?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.date_end?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="job_title"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Job Title
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="job_title"
                          id="job_title"
                          className={
                            !errors.job_title
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Job Title"}
                          {...register("job_title", { required: true })}
                        />
                        {errors.job_title?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="role"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Role
                      </label>
                      <Controller
                        name="role"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={rolesOptions}
                            isSearchable={true}
                            placeholder="Select at least one option"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="department"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Department
                      </label>
                      <Controller
                        name="department"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={departmentOptions}
                            isSearchable={true}
                            placeholder="Select the department the employee works in"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setUpdateModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
