import Breadcrumbs from "../../../../../commons/components/breadcrumbs";
import NotificationTabs from "../components/notificationTabs";
import { NotificationCurrentTabContext } from "../context/notificationContext";
import { useContext } from "react";
import PushNotification from "./Layout/pushNotificationLayout";
import PullNotification from "./Layout/pullNotificationLayout";

const BroadcastsLayout = () => {

  const pages = [
    { name: "Marketing", href: "/marketing/loyaltyprogramme", current: true },
    { name: "Broadcasts", href: "/marketing/broadcasts", current: true, },
  ];

  const { currentTab } = useContext(NotificationCurrentTabContext);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:ml-2 mobile:pt-4">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      <div className="mt-8 ">
        <NotificationTabs/>
      </div>
      <div className="mt-8 ">
        {currentTab.name == "Push Notification" ? (
          <PushNotification/>
        ) : (
          <PullNotification/>
        )}
      </div>
    </div>
  );
};

export default BroadcastsLayout;
