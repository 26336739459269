import axios from "axios";
import { useParams } from "react-router-dom";
import MainMenuLayout from "./mainMenuLayout";
import commonConfigs from "../../../../config/config";
import { useState, useEffect } from "react";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";

const MerchantsMenuLayout = () => {
  const [merchantName, setMerchantName] = useState("");
  let { merchantId } = useParams();
  let { merchantname } = useParams();
  let { locationId } = useParams();
  let { locationcode } = useParams();
  let { viewMoreType } = useParams();
  console.log(useParams())
  const pages = [
    { name: "Hierarchy View", href: "/dashboard/hierarchy", current: false },
    { name: "Locations", href: `/dashboard/hierarchy/merchantsales`, current: false },
    { name: locationcode, href: `/dashboard/hierarchy/merchantsales/${locationcode}/${locationId}`, current: true },
    {
      name: merchantname,
      href: `/dashboard/hierarchy/merchantsales/${locationcode}/${locationId}/${merchantname}/${merchantId}`,
      current: true,
    },
  ];
  // Urls.
  // const getMerchantsSalesUrl =
  //   commonConfigs.apiUrls.GetMerchantsSales(merchantId);
  const getMerchantsSalesUrl =
    commonConfigs.apiUrls.GetMerchantsSales(merchantId);
  // Data.
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);

  // async function getMerchantName() {
  //   await axios
  //     .get(commonConfigs.apiUrls.getMerchantsSalesUrl(merchantId), {
  //       headers: commonConfigs.apiHeader,
  //     })
  //     // .get(commonConfigs.apiUrls.GetAMerchant(merchantId), {
  //     //   headers: commonConfigs.apiHeader,
  //     // })
  //     .then((res) => {
  //       setMerchantName(res.data.result.data.merchant_name);
  //       setLoad(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  async function getEMenuData() {
    await axios
      .get(getMerchantsSalesUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        // console.log(res.data.result.data);
        setData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  useEffect(() => {
    if (load) {
      // getMerchantName().catch((err) => {
      //   console.log("merchant err: " + err);
      // });
      // getEMenuData().catch((err) => {
      //   console.log("emenu err: " + err);
      // });
    }
  }, [load]);

  return (
    <div className="w-full desktop:p-6 mobile:p-2 ">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
        <MainMenuLayout  load={load} setLoad={setLoad} />
    </div>
  );
};

export default MerchantsMenuLayout;
