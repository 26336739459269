import IncidentsLocationsLayout from "./incidentsLocationsLayout";
import { IncidentsLocationsContextProvider } from "../context/incidentsLocationsContext";

const IncidentsLocationsPage = () => {
  return (
    <IncidentsLocationsContextProvider>
      <IncidentsLocationsLayout />
    </IncidentsLocationsContextProvider>
  );
};

export default IncidentsLocationsPage;
