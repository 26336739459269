import React, { useEffect, useState, useMemo } from "react";
import SupplierTabs from "../components/supplierTabs";
import { SupplierCurrentTabContext } from "../context/supplierContext";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import CreateSupplierModal from "../components/createSupplierModal";
import UpdateSupplierModal from "../components/updateSupplierModal";
import DeleteModal from "../../../../commons/components/deleteModal";
import Table from "../../../../commons/components/table";
import { Additem, Edit, Trash } from "iconsax-react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import { format } from "date-fns";
import Spinner from "../../../../commons/components/spinner";

const getSuppliersUrl = commonConfigs.apiUrls.Suppliers();

const pages = [
  { name: "Configurations", href: "#", current: false },
  { name: "Suppliers", href: "/configurations/suppliers", current: true },
];

const SupplierLayout = () => {
  const [isOpen, setOpen] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  useEffect(() => {
    async function getData() {
      await axios
        .get(getSuppliersUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          setData(res.data.result.data.filter(item => item.is_deleted === 0));
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  // Use a custom filter function
  function customFilterFunction(rows, id, filterValue) {
    if (filterValue !== "All") {
      console.log("!=" + rows);
      if (filterValue === "Goods") {
        return rows.filter(
          (row) => row.original.merchant_type.sys_code_desc === "Supplier-Goods"
        );
      } else {
        return rows.filter(
          (row) =>
            row.original.merchant_type.sys_code_desc === "Supplier-Services"
        );
      }
    } else {
      return rows;
    }
  }

  const columns = useMemo(() => [
    {
      Header: "Supplier Company Name",
      accessor: "merchant_name",
    },
    {
      Header: "Supplier Type",
      accessor: "sys_code_desc",
      filter: customFilterFunction,
      Cell: ({ row }) => {
        let supplierType = row.original.merchant_type.sys_code_desc;
        row.values.sys_code_desc = supplierType;

        return <div className="text-sm text-gray-900">{supplierType}</div>;
      },
    },
    {
      Header: "Address",
      accessor: "merchant_address",
    },
    {
      Header: "Postal Code",
      accessor: "merchant_zip_code",
    },
    {
      Header: "POC Name",
      accessor: "merchant_poc",
    },
    {
      Header: "POC Contact",
      accessor: "merchant_mobile",
    },
    {
      Header: "Cut Off Time",
      accessor: ({ merchant_gpo_cut_off_time }) => merchant_gpo_cut_off_time && merchant_gpo_cut_off_time !== "-" ? merchant_gpo_cut_off_time.substring(11, 16) : "-",
    }, 
    {
      Header: "GPO Delivery Lead",
      accessor: "merchant_gpo_lead_time",
      // Cell: ({ row }) => {
      //   row.values.merchant_gpo_lead_time = format(
      //     new Date(row.values.merchant_gpo_lead_time),
      //     "dd MMM yyyy HH:mm"
      //   );
      //   var date = row.values.merchant_gpo_lead_time;

      //   if (date) {
      //     return <p className="text-sm text-gray-900">{date}</p>;
      //   }
      // },
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            {localStorage.getItem("roleid") == 2 || localStorage.getItem("roleid") == 5 ?
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
            : <></> }
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:ml-2 mobile:pt-4">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="desktop:mt-4 flex mobile:pt-2  mobile:pb-4 mobile:space-x-2">
          <button
            type="button"
            className="ml-3 inline-flex items-center text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setOpen(true)}
          >
            Create Supplier <Additem className="ml-3" />
          </button>
        </div>
      </div>
      <SupplierTabs />

      {isOpen && <CreateSupplierModal setOpenModal={setOpen} load={setLoad} />}
      {data != null ? (
        <SupplierCurrentTabContext.Consumer>
          {(value) => (
            <Table
              columns={columns}
              data={data}
              filters={columns.map((column) => column.accessor)}
              initialValue={value.currentTab.name}
              initialFilter="sys_code_desc"
            />
          )}
        </SupplierCurrentTabContext.Consumer>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      {isUpdateModal && (
        <UpdateSupplierModal
          setOpenModal={setUpdateModal}
          load={setLoad}
          row={rowToEdit}
        />
      )}
      {isDeleteModal && (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          load={setLoad}
          url={commonConfigs.apiUrls.UpdateSupplier(rowToEdit.merchant_id)}
        />
      )}
    </div>
  );
};

export default SupplierLayout;
