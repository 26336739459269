import { useContext } from "react";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import EMENUTabs from "../components/eMenuTabs";
import { EMENUCurrentTabContext } from "../context/eMenuContext";
import EMenuItemLayout from "./layouts/eMenuItemLayout";
import MerchantCatLayout from "./layouts/merchantCatLayout";



const EMenuLayout = () => {
  const pages = [
  { name: "Configurations", href: "#", current: false },
  {
    name: "eMenu Category",
    href: "/configurations/emenucat",
    current: true,
  },
];

const { currentTab } = useContext(EMENUCurrentTabContext);

  return (
    <div>
      <div className="w-full p-6">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <Breadcrumbs pages={pages} />
          </div>
        </div>
        <div className="mt-8 ">
          <div>
              <EMENUTabs/>
            {currentTab.name === "eMenu Item" ? (
            <div>
              <EMenuItemLayout/>
            </div>
            ) : (
            <div>
              <MerchantCatLayout/>
            </div>

            )}
          </div> 
        </div>
      </div>
    </div>
  );
};

export default EMenuLayout;
