import axios from "axios";
import { format } from "date-fns";
import { Link} from "react-router-dom";
import { Cards } from "iconsax-react";
import commonConfigs from "../../../../../config/config";
import CompanyTabs from "../../components/companyTabs";
import Table from "../../../../../commons/components/table";
import React, { useState, useMemo, useEffect } from "react";
import Breadcrumbs from "../../../../../commons/components/breadcrumbs";
import { CompanyCurrentTabContext } from "../../context/companyContext";
import Spinner from "../../../../../commons/components/spinner";
import { Edit, Trash, Additem } from "iconsax-react";
import CreateCompanyModal from "../../components/createCompanyModal";
import UpdateCompanyModal from "../../components/updateCompanyModal";
import DeleteModal from "../../../../../commons/components/deleteModal";

const getCompaniesUrl = commonConfigs.apiUrls.GetCompanies();

const pages = [
  { name: "Configurations", href: "#", current: false },
  { name: "Companies", href: "/configurations/companies", current: true },
];

const CompanyLayout = () => {
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [rowToEdit, setRowToEdit] = useState();


  useEffect(() => {
    async function getData() {
      await axios
        .get(getCompaniesUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          setData(res.data.result.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Name",
      accessor: "company_name",
    },
    {
      Header: "Address",
      accessor: "company_address",
    },
    {
      Header: "Zip Code",
      accessor: "company_zip_code",
    },
    {
      Header: "Telephone",
      accessor: "company_mobile",
    },
    {
      Header: "Half Day Hours",
      accessor: "company_half_day_hours",
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        let companyObj = {
          companyName: row.original.company_name,
          id: row.original.company_id,
        };
        return (
          <div className=" text-gray-900  flex space-x-1 ">
            <button type="button">
              <Link
                to={{
                  pathname: `/configurations/companies/${companyObj.companyName}/${companyObj.id}`,
                }}
              >
                <Cards aria-hidden="true" className="w-6 h-6" />
              </Link>
            </button>

            <button
              type="button"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" className="w-6 h-6" />
            </button>
            <button
              type="button"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" className="w-6 h-6" />
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="mt-4 flex mobile:pt-4  mobile: pb-4 mobile:space-x-2">
          <button
            type="button"
            className="ml-3 inline-flex items-center px-4 py-2 text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setOpen(true)}
          >
            Create Company <Additem className="ml-3" />
          </button>
          {isOpen && <CreateCompanyModal setOpenModal={setOpen} load={setLoad} />}
        </div>
        {isUpdateModal && (
        <UpdateCompanyModal
          setUpdateModal={setUpdateModal}
          load={setLoad}
          row={rowToEdit}
        />
        )}
        {isDeleteModal && (
          <DeleteModal
            setDeleteModal={setDeleteModal}
            load={setLoad}
            url={commonConfigs.apiUrls.DeleteCompany(rowToEdit.company_id)}
          />
        )}
      </div>
      {/* <CompanyTabs /> */}
      {data != null ? (
        <CompanyCurrentTabContext.Consumer>
          {(value) => (
            <Table
              columns={columns}
              data={data}
              filters={columns.map((column) => column.accessor)}
              initialValue={value.currentTab.name}
              // initialFilter="division"
            />
          )}
        </CompanyCurrentTabContext.Consumer>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyLayout;
