import React, { useEffect, useState, useMemo } from "react";
import { Additem, Edit, Alarm } from "iconsax-react";
import Table from "../../../../../../commons/components/table";
import Spinner from "../../../../../../commons/components/spinner";
import CreatePullNotificationModal from "../../components/createPullNotificationModal";
import UpdatePullNotificationModal from "../../components/updatePullNotificationModal";
import { format } from "date-fns";

const getData = () => [
  {
    id: 1,
    name: "Rice",
    updated_by: "yison",
    updated_on: "2022-09-23T08:36:42.000Z",
  },
  {
    id: 2,
    name: "Rice",
    updated_by: "yison",
    updated_on: "2022-09-23T08:36:42.000Z",
  },
  {
    id: 1,
    name: "Rice",
    updated_by: "yison",
    updated_on: "2022-09-23T08:36:42.000Z",
  },
  {
    id: 2,
    name: "Rice",
    updated_by: "yison",
    updated_on: "2022-09-23T08:36:42.000Z",
  },
  {
    id: 1,
    name: "Rice",
    updated_by: "yison",
    updated_on: "2022-09-23T08:36:42.000Z",
  },
  {
    id: 2,
    name: "Rice",
    updated_by: "yison",
    updated_on: "2022-09-23T08:36:42.000Z",
  }
];

const PullNotification = () => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  // const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  const data = React.useMemo(() => getData(), []);

  const columns = useMemo(() => [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "name",
      accessor: "name",
    },
    {
      Header: "updated_by",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            {/* <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Alarm aria-hidden="true" />
            </button> */}

            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>

          </div>
        );
      },
    },
  ]);

  return (
    <div>
      <div className=" flex flex-row-reverse">
        <button
          type="button"
          className="ml-3 inline-flex items-center px-4 py-2 text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
          onClick={() => setCreateModalOpen(true)}
        >
          Create Pull Notification <Additem className="ml-3" />
        </button>
      </div>
      {isCreateModalOpen && <CreatePullNotificationModal setCreateModal={setCreateModalOpen} load={setLoad} />}
      {data != null ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}

      {isUpdateModalOpen && (
        <UpdatePullNotificationModal
          setUpdateModal={setUpdateModalOpen}
          load={setLoad}
          row={rowToEdit}
        />
      )}

    </div>
  );
};

export default PullNotification;
