import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { InfoCircle } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import axios from "axios";
import { useUploadFile } from "../../../../commons/components/uploadFileCard";
import { AZURE_BLOB_CONTAINER_PRODUCTS } from "../../../../constants";

const getSupplierUrl = commonConfigs.apiUrls.Suppliers();
// const getTypeUrl = commonConfigs.apiUrls.MerchantType();
const user = JSON.parse(localStorage.getItem("user"));

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function UpdateOrderingModal({ setOpenModal, row, load }) {
  const [open, setOpen] = useState(true);
  // const [menuTypes, setType] = useState([]);
  // const [supplier, setSupplier] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const [suppliername, setsuppliername] = useState('');
  const [suppliertype, setsuppliertype] = useState([]);

  // const [lazyData, setLazyData] = useState({});

  const id = row.product_id;

  const [file, fileCard, uploadFile] = useUploadFile({
    container: AZURE_BLOB_CONTAINER_PRODUCTS,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      product_name: row.product_name,
      product_sku: row.product_sku,
      product_desc: row.product_desc,
      product_price: row.product_price,
      product_upc: row.product_upc,
      product_type_sys_code: {
        value: row.product_type_sys_code,
        label: row.product_type_sys_code == 0 
        ? "Generic" : row.product_type_sys_code == 1 
        ? "Goods" : row.product_type_sys_code == 2 
        ? "Services" : null,
      },
      is_visible: {
        value: row.is_visible,
        label: row.is_visible == 1 ? "True" : "False",
      },
      product_img_url: row.product_img_url,
    },
  });

  function formatData(data) {
    var supplier = row.merchant_id;
    // var menu_type = data.menu_type.value;
    var is_visible = data.is_visible.value;
    var product_type_sys_code = data.product_type_sys_code.value;

    var newData = data;

    newData.supplier = supplier;
    // newData.menu_type = menu_type;
    newData.is_visible = is_visible;
    newData.product_type_sys_code = product_type_sys_code;
    newData.updated_by = user.account_id;
    // newData.product_img_url = null;

    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button
    const newData = formatData(data);
    console.log("formatted", newData);

    console.log('product image name',`${row.product_id}/${file.name}`);
    if (file) {
      const image_url = await uploadFile(`${row.product_id}/${file.name}`);

      if (image_url) 
      {
        newData.product_img_url = image_url;
      }
    }

    axios
      .put(commonConfigs.apiUrls.UpdateGroupPurchase(id), newData, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setOpenModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  // async function getTypeOptions() {
  //   await axios
  //     .get(getTypeUrl, { headers: commonConfigs.apiHeader })
  //     .then((res) => {
  //       setType(res.data.result.data);

  //       res.data.result.data.forEach((type) => {
  //         if (type.merchant_type == row.menu_type) {
  //           setLazyData(
  //             (lazyData["menu_type"] = {
  //               value: type.sys_code_id,
  //               label: type.merchant_type,
  //             })
  //           );
  //         }
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  async function getSupplierOptions() {
    await axios
      .get(getSupplierUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        // setSupplier(res.data.result.data);
        res.data.result.data.filter(item => item.is_deleted === 0).forEach((supplier) => {
          // console.log(row.supplier_name);
          // console.log(supplier.merchant_name);
          if (supplier.merchant_name == row.supplier_name) {
            // console.log(supplier.merchant_id);
            setsuppliertype(supplier.merchant_type);
            setsuppliername(supplier.merchant_name);
            // setLazyData(
            //   (lazyData["supplier"] = {
            //     value: supplier.merchant_id,
            //     label: supplier.merchant_name,
            //   })
            // );
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const getSelectData = async () => {
      await getSupplierOptions().catch((err) => {
        console.log("err for supplier: " + err);
      });
      // await getTypeOptions().catch((err) => {
      //   console.log("err for menu_type: " + err);
      // });
    };

    getSelectData()
      .then(() => {
        // reset(lazyData);
      })
      .catch((err) => {
        console.log("err for menu_type: " + err);
      });
  }, []);

  const visibilityOptions = [
    { value: 1, label: "True" },
    { value: 0, label: "False" },
  ];

  const productTypeOptions = [
    { value: 0, label: "Generic" },
    { value: 1, label: "Goods" },
    { value: 2, label: "Services" },
  ];

  // const menuTypesOptions = menuTypes.slice().map((d) => ({
  //   value: d.sys_code_id,
  //   label: d.merchant_type,
  // }));

  // const supplierOptions = supplier.map((d) => ({
  //   value: d.merchant_id,
  //   label: d.merchant_name,
  // }));

  // console.log("menuTypesOptions",menuTypesOptions);
  // console.log("supplierOptions",supplierOptions);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setOpenModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Update Ordering Menu
                    </Dialog.Title>

                    {/* <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="supplier"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Supplier
                      </label>
                      <Controller
                        name="supplier"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={supplierOptions}
                            isSearchable={true}
                            placeholder="Select a Supplier"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="menu_type"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Menu Type
                      </label>
                      <Controller
                        name="menu_type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={menuTypesOptions}
                            isSearchable={true}
                            placeholder="Select a Type"
                            className="text-sm"
                          />
                        )}
                      />
                    </div> */}

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="supplier"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Supplier
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="supplier"
                          id="supplier"
                          style={{ backgroundColor: '#ececec' }}
                          className="block w-full pr-10 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                          readOnly
                          value={suppliername}
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="menu_type"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Supplier Type
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="menu_type"
                          id="menu_type"
                          style={{ backgroundColor: '#ececec' }}
                          className="block w-full pr-10 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                          readOnly
                          value={suppliertype.sys_code_desc}
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_name"
                          id="product_name"
                          className={
                            !errors.product_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Name"}
                          {...register("product_name", { required: true })}
                        />
                        {errors.product_name?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_type_sys_code"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Type
                      </label>
                      <Controller
                        name="product_type_sys_code"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={productTypeOptions}
                            isSearchable={true}
                            placeholder="Select the Item Type"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_sku"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item SKU
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_sku"
                          id="product_sku"
                          className={
                            !errors.product_sku
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item SKU"}
                          {...register("product_sku", { required: true })}
                        />
                        {errors.product_sku?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_upc"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item UPC
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_upc"
                          id="product_upc"
                          className={
                            !errors.product_upc
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item UPC"}
                          {...register("product_upc", { required: true })}
                        />
                        {errors.product_upc?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="itemDesc"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Description
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_desc"
                          id="product_desc"
                          className={
                            !errors.product_desc
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Description"}
                          {...register("product_desc", { required: true })}
                        />
                        {errors.product_desc?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_price"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Price
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="0"
                          name="product_price"
                          id="product_price"
                          className={
                            !errors.product_price
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Price"}
                          {...register("product_price", {
                            required: true,
                            pattern : /^(?:\d*\.\d{1,2}|\d+)$/,
                          })}
                        />
                        {errors.product_price?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="is_visible"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Visibility
                      </label>
                      <Controller
                        name="is_visible"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={visibilityOptions}
                            isSearchable={true}
                            placeholder="Select the Visibility"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                    <div className="sm:col-span-6 mt-2">
                      <label
                        htmlFor="upload_file"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Upload Image
                      </label>
                      {fileCard}
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
