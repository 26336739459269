import { Link, useParams } from "react-router-dom";

export default function SuccessPage() {
    const { title, subTitle } = useParams();
    return (
        <div className="min-h-screen pt-16 pb-12 flex flex-col ">
            <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-shrink-0 flex justify-center">

                </div>
                <div className="py-16">
                    <div className="text-center">
                        <p className="text-sm font-semibold text-yellow-600 uppercase tracking-wide">
                            Success
                        </p>
                        <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                            {title ? decodeURIComponent(title) : 'Unable to display message'}
                        </h1>
                        <p className="mt-2 text-base text-gray-500">
                            {subTitle ? decodeURIComponent(subTitle) : 'Unable to display message'}
                        </p>
                        <div className="mt-6">
                            <Link
                                to={"/"}
                                className="text-base font-medium text-yellow-600 hover:text-yellow-500"
                            >
                                Go back home<span aria-hidden="true"> &rarr;</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
