import axios from "axios";
import { useParams } from "react-router-dom";
import MainMenuLayout from "./locationsLayout";
import commonConfigs from "../../../../config/config";
import {useState, useEffect } from "react";


const LocationsLayout = () => {
  const [merchantName, setMerchantName] = useState("");
  let { merchantId } = useParams();
  console.log(merchantName);
  // Urls.
  const getMerchantEMenuUrl =
    commonConfigs.apiUrls.GetAllEmployeeLocationTimecards();

  // Data.
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);

  async function getMerchantName() {
    await axios
      .get(commonConfigs.apiUrls.GetAMerchant(merchantId), {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        setMerchantName(res.data.result.data.merchant_name);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getEMenuData() {
    await axios
      .get(getMerchantEMenuUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (load) {
      getMerchantName().catch((err) => {
        console.log("merchant err: " + err);
      });
      getEMenuData().catch((err) => {
        console.log("emenu err: " + err);
      });
    }
  }, [load]);


  return (
    <div className="w-full desktop:p-6 mobile:p-2 ">
      {/* <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
        {getButtons(currentTab.name)}
      </div> */}
      {/* <MerchantsMenuTabs /> */}

      {/* {currentTab.name == "Main Items" && ( */}
        <MainMenuLayout data={data} load={load} setLoad={setLoad} />
      {/* )} */}
      {/* {currentTab.name == "Add Ons" && (
        <AddonLayout data={addOnsData} load={load} setLoad={setLoad} />
      )}
      {currentTab.name == "Preferences" && (
        <PreferenceLayout
          data={prefsData}
          load={load}
          setLoad={setLoad}
          merchantId={merchantId}
        />
      )} */}
      {/* {isMenuModalOpen && (
        <CreateMenuModal
          setCreateModal={setMenuModal}
          load={setLoad}
          id={merchantId}
        />
      )}

      {isAddOnModalOpen && (
        <CreateAddOnModal
          setCreateModal={setAddOnModal}
          load={setLoad}
          id={merchantId}
        />
      )}

      {isPrefsModalOpen && (
        <CreatePrefsModal
          setCreateModal={setPrefsModal}
          load={setLoad}
          id={merchantId}
        />
      )} */}
    </div>
  );
};

export default LocationsLayout;
