import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

export const options = {
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        boxWidth:8,
      }
    },
  },
  responsive: true,
  scales: {
    x: 
    {
      stacked: true,
    },
    y: 
    {
      stacked: true,
    },
  },
};

const DashboardKisokBarChart = ({ data }) => {
  console.log(data);
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const ds = [
      // {
      //   label: 'Issued',
      //   data: [],
      //   backgroundColor: '#cd5c5c',
      // },
      {
        label: 'Redeemed',
        data: [],
        backgroundColor: '#5f9ea0',
      },
      {
        label: 'Non-Redeemed',
        data: [],
        backgroundColor: '#cd5c5c',
      }
    ];

    data.forEach(function (item, i) {
      // Inside value into bar chart.
      item.values.forEach((itemValue) => {
        ds.forEach((dataset) => {
          if (dataset.label == itemValue.name) {
            dataset.data.push(itemValue.value);
          }
        });
      });

      // Put 0 if no value.
      ds.forEach((dataset) => {
        if (dataset.data.length != i + 1) {
          dataset.data.push(0);
        }
      });
    });

    setGraphData({
      labels: data.map((item) => item.name),
      datasets: ds,
    });
  }, [data]);

  return <Bar data={graphData} options={options} />;
};

export default DashboardKisokBarChart;
