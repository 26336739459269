import axios from "axios";
import commonConfigs from "../../../../config/config";
import { createContext, useEffect, useState } from "react";

export const EmployeesTabsContext = createContext();
export const EmployeesCurrentTabContext = createContext();

export const EmployeesContextProvider = (props) => {
  const [currentTab, setTab] = useState({ name: "All" });
  const [tabs, setTabs] = useState([]);
  const contextValue = { currentTab, setTab };

  async function getEmpTypes() {
    await axios
      .get(commonConfigs.apiUrls.EmployeeTypes(), {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        const newTabs = [{ name: "All" }];
        res.data.result.data.forEach((type) => {
          newTabs.push({ name: type.sys_code_desc });
        });
        setTabs(newTabs);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getEmpTypes().catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <EmployeesTabsContext.Provider value={{ tabs }}>
      <EmployeesCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </EmployeesCurrentTabContext.Provider>
    </EmployeesTabsContext.Provider>
  );
};
