import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { InfoCircle } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import axios from "axios";
// import emailVerification from "../../../../utils/custom/emailService";
import emailService from "../../../../utils/custom/emailService";

const user = JSON.parse(localStorage.getItem("user"));

const getAccessRightsUrl = commonConfigs.apiUrls.AccessTypes();
const getRolesUrl = commonConfigs.apiUrls.GetAllAccessRoles();
const getEmpTypesUrl = commonConfigs.apiUrls.EmployeeTypes();
const getLocationsUrl = commonConfigs.apiUrls.GetLocations();
const getMerchantsUrl = commonConfigs.apiUrls.Merchants();
const getDeptUrl = commonConfigs.apiUrls.Departments();
const getCompUrl = commonConfigs.apiUrls.GetCompanies();
const createUrl = commonConfigs.apiUrls.Employees();
const domain = window.location.host;

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function CreateEmpModal({ setCreateModal, load }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(true);
  const [accessTypes, setAccessTypes] = useState([]);
  const [empTypes, setEmpTypes] = useState([]);
  const [verifyEmail, setVerifyEmail] = useState([]);
  const [locations, setLocations] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [dept, setDept] = useState([]);
  const [comp, setComp] = useState([]);
  const [roles, setRoles] = useState([]);
  const [empTypeDisplay, setEmpTypeDisplay] = useState(false);
  const [locationDisplay, setLocationDisplay] = useState(true);
  const [selectedEmpType, setSelectedEmpType] = useState('');
  const [selectedMerchant, setSelectedMerchant] = useState('');
  const [selectedVerifyEmail, setSelectedVerifyEmail] = useState(1);
  const [loadData, setLoadData] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({});

  function formatData(data) {
    if (selectedEmpType !== 0) {
      var location_id = data.location_id.value;
    }
    var employee_type_sys_code = selectedEmpType;
    var role_id = data.role_id.value;
    var department_id = data.department_id.value;
    var company_id = data.company_id.value;
    var verify_email = selectedVerifyEmail;
    var employment_type = selectedEmploymentType.value;
    var leave_entitlement_tier = data.leave_entitlement_tier.value;
    var monthly_pay_rate =
      data.monthly_pay_rate === "" || data.monthly_pay_rate === 0
        ? 0
        : data.monthly_pay_rate;

    // var date_end = data.date_end === "" ? null : data.date_end;

    // var shiftStart = new Date(`1970-01-01T${data.shift_start}`);
    // var shift_start = shiftStart.toTimeString().substring(0, 8);

    // var shiftEnd = new Date(`1970-01-01T${data.shift_end}`);
    // var shift_end = shiftEnd.toTimeString().substring(0, 8);

    var newData = data;
    if (selectedEmpType !== 0) {
      newData.location_id = location_id;
    }
    newData.merchant_id = selectedMerchant;
    newData.employee_type_sys_code = employee_type_sys_code;
    newData.role_id = role_id;
    newData.department_id = department_id;
    newData.company_id = company_id;
    newData.company_uen = company_id;
    newData.verify_email = verify_email;
    newData.employment_type = employment_type;
    newData.leave_entitlement_tier = leave_entitlement_tier;
    newData.monthly_pay_rate = monthly_pay_rate;
    newData.updated_by = user.account_id;
    // newData.date_start = data.date_start;
    // newData.date_end = null;
    // newData.shift_start = shift_start; 
    // newData.shift_end = shift_end; 

    return newData;
  }

  function extractDomain(url) {
    var domain;/*w    w w .  d   e m  o   2  s   .c   o   m*/
    //find & remove protocol (http, ftp, etc.) and get domain
    if (url.indexOf("://") > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    //find & remove port number
    domain = domain.split(':')[0];
    return domain;
  }
  const getRootHostName = (url) => {
    var parts = extractDomain(url).split('.');
    var partsLength = parts.length - 3;
    //parts.length-3 assuming root hostname is of type abc.xyz.pp
    for (var i = 0; i < partsLength; i++) {
      parts.shift(); //remove sub-domains one by one
    }
    var rootDomain = parts.join('.');
    return rootDomain;
  }

  const onSubmit = (data) => {
    setIsDisabled(true); // Disable the submit button
    const newData = formatData(data);

    console.log("Creating Employee Data", newData);
    console.log(data.account_email)
    // if (!data.account_email.endsWith(getRootHostName(domain))) {
    //   alert("Please enter email ending with correct domain. ");
    // } else {
    axios
      .post(createUrl, newData, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        if (selectedVerifyEmail == 1 && res.data.status == "success") {
          return emailService.emailVerification(data.account_email, data.account_mobile)
        } else {
          return res;
        }
      })
      .then((res) => {
        console.log(res);
        try {
          if (selectedVerifyEmail !== 1 && res.data.status != "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setCreateModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };
  const handleToggle = (e) => {
    var employeeType = e.value;
    if (employeeType == 1 || employeeType == 2 || employeeType == 11) {
      setEmpTypeDisplay(true);
    } else {
      setEmpTypeDisplay(false);
      if (employeeType == 0) {
        setLocationDisplay(false);
      } else {
        setLocationDisplay(true);
      }
    }
    setSelectedEmpType(e.value);
  }
  const handleVerify = (e) => {
    var verifyEmail = e.value;
    setSelectedVerifyEmail(verifyEmail);
  }
  const handleMerchant = (e) => {
    var merchant = e.value;
    setSelectedMerchant(merchant);
  }

  useEffect(() => {
    async function getAccessRights() {
      await axios
        .get(getAccessRightsUrl, {
          headers: commonConfigs.apiHeader,
        })
        .then((res) => {
          setAccessTypes(res.data.result.data);
          setLoadData(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getEmpTypes() {
      await axios
        .get(getEmpTypesUrl, {
          headers: commonConfigs.apiHeader,
        })
        .then((res) => {
          console.log(res.data.result.data);
          setEmpTypes(res.data.result.data);
          setLoadData(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getVerifyEmail() {
      var emailData = [
        {
          email_value: 0,
          email_label: "No"
        },
        {
          email_value: 1,
          email_label: "Yes"
        }
      ];
      setVerifyEmail(emailData);
      setLoadData(false);
    }
    async function getLocations() {
      await axios
        .get(getLocationsUrl, {
          headers: commonConfigs.apiHeader,
        })
        .then((res) => {
          setLocations(res.data.result.data.filter(item => item.is_deleted === 0));
          setLoadData(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getMerchants() {
      await axios
        .get(getMerchantsUrl, {
          headers: commonConfigs.apiHeader,
        })
        .then((res) => {
          setMerchants(res.data.result.data);
          setLoadData(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getDept() {
      await axios
        .get(getDeptUrl, {
          headers: commonConfigs.apiHeader,
        })
        .then((res) => {
          setDept(res.data.result.data);
          setLoadData(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getComp() {
      await axios
        .get(getCompUrl, {
          headers: commonConfigs.apiHeader,
        })
        .then((res) => {
          setComp(res.data.result.data);
          setLoadData(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getAccessRoles() {
      await axios
        .get(getRolesUrl, {
          headers: commonConfigs.apiHeader,
        })
        .then((res) => {
          setRoles(res.data.result.data);
          setLoadData(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }


    if (loadData) {
      getAccessRights().catch((err) => {
        console.log("err for access rights: " + err);
      });
      getEmpTypes().catch((err) => {
        console.log("err for emp types: " + err);
      });
      getVerifyEmail().catch((err) => {
        console.log("err for verify email: " + err);
      });
      getLocations().catch((err) => {
        console.log("err for locations: " + err);
      });
      getMerchants().catch((err) => {
        console.log("err for merchants: " + err);
      });
      getDept().catch((err) => {
        console.log("err for dept: " + err);
      });
      getComp().catch((err) => {
        console.log("err for comp: " + err);
      });
      getAccessRoles().catch((err) => {
        console.log("err for roles: " + err);
      });
    }
  }, []);

  const employemntOption = [
    { value: 1, label: 'Full Time' },
    { value: 2, label: 'Part Time' },
  ];

  const tierOption = [
    { value: 1, label: 'Entry-Level' },
    { value: 2, label: 'Mid-Level' },
    { value: 3, label: 'Executive-Level' },
  ];

  const [selectedEmploymentType, setSelectedEmploymentType] = useState(null);

  const handleEmploymentTypeChange = (selectedOption) => {
    setSelectedEmploymentType(selectedOption);
  };

  const accessRightsOptions = accessTypes.map((d) => ({
    value: d.access_id,
    label: d.access_name,
  }));

  const empTypesOptions = empTypes.map((d) => ({
    value: d.sys_code_id,
    label: d.sys_code_desc,
  }));

  const locationOption = locations.map((d) => ({
    value: d.location_id,
    label: d.location_name,
  }));

  const merchantOption = merchants.map((d) => ({
    value: d.merchant_id,
    label: d.merchant_name,
  }));

  const departmentOptions = dept.map((d) => ({
    value: d.department_id,
    label: d.department_name,
  }));
  const companyOptions = comp.map((d) => ({
    value: d.company_id,
    label: d.company_name,
  }));

  const rolesOptions = roles.map((d) => ({
    value: d.role_id,
    label: d.role_name,
  }));

  const verifyEmailOptions = verifyEmail.map((d) => ({
    value: d.email_value,
    label: d.email_label,
  }));

  console.log(departmentOptions);
  console.log(companyOptions);
  console.log(locationOption);
  console.log(merchantOption);
  console.log(empTypesOptions);
  console.log(accessRightsOptions);
  console.log(rolesOptions);
  console.log(verifyEmailOptions);


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setCreateModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Create New Employee
                    </Dialog.Title>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="employee_type_sys_code"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Employee Type
                      </label>
                      <Controller
                        name="employee_type_sys_code"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={empTypesOptions}
                            isSearchable={true}
                            placeholder="Select the employee type"
                            className="text-sm"
                            onChange={(e) => handleToggle(e)}

                          />
                        )}
                      />
                    </div>

                    {empTypeDisplay ?
                      <div className="mt-2 sm:col-span-6">
                        <label
                          htmlFor="merchant_id"
                          className="block text-sm font-medium text-yellow-800"
                        >
                          Merchants
                        </label>
                        <Controller
                          name="merchant_id"
                          control={control}
                          render={({ field }) => (
                            <Select
                              isClearable
                              {...field}
                              options={merchantOption}
                              isSearchable={true}
                              placeholder="Select a Merchant"
                              className="text-sm"
                              onChange={(e) => handleMerchant(e)}

                            />
                          )}
                        />
                      </div>
                      : <></>}

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="employment_type"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Employment Type
                      </label>
                      <Controller
                        name="employment_type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            onChange={handleEmploymentTypeChange}
                            options={employemntOption}
                            isSearchable={true}
                            placeholder="Select a Type"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="hourly_pay_rate"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Hourly Rate
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          step="0.01"
                          name="hourly_pay_rate"
                          id="hourly_pay_rate"
                          className={
                            !errors.hourly_pay_rate
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Hourly Pay Rate"}
                          {...register("hourly_pay_rate", { required: true })}
                        />
                        {errors.hourly_pay_rate?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    {selectedEmploymentType?.value === 1 && (
                      <div className="mt-2 sm:col-span-6">
                        <label htmlFor="monthly_pay_rate" className="block text-sm font-medium text-yellow-800">
                          Monthly Rate
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="number"
                            step="0.01"
                            name="monthly_pay_rate"
                            id="monthly_pay_rate"
                            className={
                              !errors.monthly_pay_rate
                                ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                                : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            }
                            placeholder={"Enter Monthly Pay Rate"}
                            {...register("monthly_pay_rate", { required: true })}
                          />
                          {errors.monthly_pay_rate?.type === "required" && errorIcon}
                        </div>
                      </div>
                    )}

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="overtime_pay_rate"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Overtime Rate
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          step="0.01"
                          name="overtime_pay_rate"
                          id="overtime_pay_rate"
                          className={
                            !errors.overtime_pay_rate
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Overtime Pay Rate"}
                          {...register("overtime_pay_rate", { required: true })}
                        />
                        {errors.overtime_pay_rate?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="work_days_per_week"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Working Days Per Week
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          step="0.01"
                          name="work_days_per_week"
                          id="work_days_per_week"
                          className={
                            !errors.work_days_per_week
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Working Days Per Week"}
                          {...register("work_days_per_week", { required: true })}
                        />
                        {errors.work_days_per_week?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="leave_entitlement_tier"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Leave Entitlement Tier
                      </label>
                      <Controller
                        name="leave_entitlement_tier"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={tierOption}
                            isSearchable={true}
                            placeholder="Enter Tier"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                    {locationDisplay ?
                      <div className="mt-2 sm:col-span-6">
                        <label
                          htmlFor="location_id"
                          className="block text-sm font-medium text-yellow-800"
                        >
                          Location
                        </label>
                        <Controller
                          name="location_id"
                          control={control}
                          render={({ field }) => (
                            <Select
                              isClearable
                              {...field}
                              options={locationOption}
                              isSearchable={true}
                              placeholder="Select a Location"
                              className="text-sm"

                            />
                          )}
                        />
                      </div>
                      : <></>}


                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="account_username"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="account_username"
                          id="account_username"
                          className={
                            !errors.account_username
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Name"}
                          {...register("account_username", { required: true })}
                        // pattern="^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"

                        />
                        {errors.account_username?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="account_email"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Email
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="email"
                          name="account_email"
                          id="account_email"
                          className={
                            !errors.account_email
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Email"}
                          {...register("account_email", { required: true })}

                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        />
                        {errors.account_email?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="verify_email"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Verify Email
                      </label>
                      <Controller
                        name="verify_email"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={verifyEmailOptions}
                            isSearchable={true}
                            placeholder="Select option"
                            className="text-sm"
                            onChange={(e) => handleVerify(e)}

                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="account_mobile"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Mobile Number
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="tel"
                          name="account_mobile"
                          id="account_mobile"
                          maxLength={8}
                          className={
                            !errors.account_mobile
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Mobile Number"}
                          {...register("account_mobile", { required: true })}
                          pattern="[0-9]{8}"

                        />
                        {errors.account_mobile?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    {/* <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="company_id"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Company Uen
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="company_id"
                          id="company_id"
                          className={
                            !errors.company_id
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Company Uen"}
                          {...register("company_id", { required: true })}
                        />
                        {errors.company_id?.type === "required" &&
                          errorIcon}
                      </div>
                    </div> */}
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="company_id"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Company
                      </label>
                      <Controller
                        name="company_id"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={companyOptions}
                            isSearchable={true}
                            placeholder="Select the company the employee works in"
                            className="text-sm"

                          />
                        )}
                      />
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="shift_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Shift Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="shift_name"
                          id="shift_name"
                          className={
                            !errors.shift_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Shift Name"}
                          {...register("shift_name", { required: true })}
                        // pattern="^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"

                        />
                        {errors.shift_name?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="shift_start" className="block text-sm font-medium text-yellow-800">
                        Shift Start
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="time"
                          name="shift_start"
                          id="shift_start"
                          className={
                            !errors.shift_start
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Shift Start Time (HH:MM:am/pm)"}
                          {...register("shift_start", {
                            required: true,
                            pattern: {
                              value: /^([01][0-9]|2[0-3]):[0-5][0-9]$/,
                              message: "Please enter a valid time in the format 'HH:MM'",
                            },
                          })}

                        />
                        {errors.shift_start?.type === "required" && errorIcon}
                        {errors.shift_start?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.shift_start?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="shift_end" className="block text-sm font-medium text-yellow-800">
                        Shift End
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="time"
                          name="shift_end"
                          id="shift_end"
                          className={
                            !errors.shift_end
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Shift End Time (HH:MM:am/pm)"}
                          {...register("shift_end", {
                            required: false,
                            pattern: {
                              value: /^([01][0-9]|2[0-3]):[0-5][0-9]$/,
                              message: "Please enter a valid time in the format 'HH:MM'",
                            },
                          })}

                        />
                        {errors.shift_end?.type === "required" && errorIcon}
                        {errors.shift_end?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.shift_end?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="date_start" className="block text-sm font-medium text-yellow-800">
                        Date Start
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="date"
                          name="date_start"
                          id="date_start"
                          className={
                            !errors.date_start
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Start Date (YYYY-MM-DD)"}
                          {...register("date_start", {
                            required: true,
                            pattern: {
                              value: /^\d{4}-\d{2}-\d{2}$/,
                              message: "Please enter a valid date in the format 'YYYY-MM-DD'",
                            },
                          })}
                        />
                        {errors.date_start?.type === "required" && errorIcon}
                        {errors.date_start?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.date_start?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="date_end" className="block text-sm font-medium text-yellow-800">
                        Date End
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="date"
                          name="date_end"
                          id="date_end"
                          className={
                            !errors.date_end
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter End Date (YYYY-MM-DD)"}
                          {...register("date_end", {
                            required: false,
                            pattern: {
                              value: /^\d{4}-\d{2}-\d{2}$/,
                              message: "Please enter a valid date in the format 'YYYY-MM-DD'",
                            },
                          })}
                        />
                        {errors.date_end?.type === "required" && errorIcon}
                        {errors.date_end?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.date_end?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="job_title"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Job Title
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="job_title"
                          id="job_title"
                          className={
                            !errors.job_title
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Job Title"}
                          {...register("job_title", { required: true })}
                        // pattern="^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"

                        />
                        {errors.job_title?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="role_id"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Access Role
                      </label>
                      <Controller
                        name="role_id"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={rolesOptions}
                            isSearchable={true}
                            placeholder="Select at least one option"
                            className="text-sm"
                            maxMenuHeight={130}

                          />
                        )}
                      />
                    </div>
                    {/* <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="accessRight"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Access Right
                      </label>
                      <Controller
                        name="accessRight"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={accessRightsOptions}
                            isSearchable={true}
                            placeholder="Select at least one option"
                            className="text-sm"
                          />
                        )}
                      />
                    </div> */}
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="department_id"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Department
                      </label>
                      <Controller
                        name="department_id"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={departmentOptions}
                            isSearchable={true}
                            placeholder="Select the department the employee works in"
                            className="text-sm"

                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setCreateModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
