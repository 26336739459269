import { LocationsContextProvider } from "../context/locationsContext";
import LocationLayout from "./locationsLayout";

const LocationsPage = () => {
  return (
    <LocationsContextProvider>
      <LocationLayout />
    </LocationsContextProvider>
  );
};

export default LocationsPage;
