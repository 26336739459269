import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Cards } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import Table from "../../../../commons/components/table";
import React, { useState, useMemo, useEffect } from "react";
import Spinner from "../../../../commons/components/spinner";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";

const LocationsLayout = () => {
  
  const pages = [
    { name: "Hierarchy View", href: "/dashboard/hierarchy", current: false },
    { name: "Locations", href: `/dashboard/hierarchy/merchantsales`, current: true },
  ];

  var StartDateData = sessionStorage.getItem('startdate');
  var EndDateData = sessionStorage.getItem('enddate');
  var PlatformData = sessionStorage.getItem('platform');

  const viewMoreType = useParams().viewMoreType;
  let { locationId } = useParams();
  console.log(viewMoreType);
  // const currentYear = (new Date()).getFullYear();
  // const years = Array.from(new Array(YEAR_RANGE), (val, index) => index + currentYear);
  
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1 to get the current month.

  // Calculate the first day of the current month.
  const firstDayOfMonth = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;

  // Calculate the last day of the current month.
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  const lastDayOfMonthFormatted = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${lastDayOfMonth.getDate()}`;

  sessionStorage.setItem('startdate', firstDayOfMonth);
  sessionStorage.setItem('enddate', lastDayOfMonthFormatted);

  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);

  const [isApplying, setIsApplying] = useState(false);
  const [isReseting, setIsReseting] = useState(false);

  const [startdate, setStartDate] = useState(firstDayOfMonth);
  const [enddate, setEndDate] = useState(lastDayOfMonthFormatted);

  const [startdateFilter, setStartDateFilter] = useState(startdate);
  const [enddateFilter, setEndDateFilter] = useState(enddate);

  const [loyaltyId, setLoyaltyId] = useState('all');
  const [loyaltyList, setLoyaltyList] = useState([]);

  const [platforms, setPlatforms] = useState('all');

  const [platformsName, setPlatformsName] = useState('all');

  const [platformList, setPlatformsList] = useState([]);

  const applyButtonEnabledClass = "bg-red-600 hover:bg-red-700";
  const applyButtonDisabledClass = "bg-red-400 cursor-not-allowed";

  var loyaltyFilter = '';

  let user = JSON.parse(localStorage.getItem("user"))

  if (loyaltyId !== "all") {
    loyaltyFilter = loyaltyId;
  }
  const getMerchantsSalesFilterUrl = commonConfigs.apiUrls.GetFilterSalesParams(locationId);
  
  async function getData() {
    var platformFilter = '';
    // var paymentFilter = '';
    // var loyaltyFilter = '';

    if (platforms !== "all") {
      platformFilter = platforms;
    }
    // if (payments !== "all") {
    //   paymentFilter = payments;
    // }
    // if (loyaltyId !== "all") {
    //   loyaltyFilter = loyaltyId;
    // }

    let arr = [];
    const getSalessUrl = commonConfigs.apiUrls.GetOutletSales('',startdateFilter, enddateFilter, 1, platformFilter);    
    await axios
      .get(getSalessUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        // console.log(res.data.result.data);
        let response = res.data.result.data;
        if(user.location_id && (user.access_multiple_location === 0 || !user.access_multiple_location)) {
          arr.push(response.find(({location_id}) => user.location_id === location_id))
          setData(arr.filter(item => item.is_deleted === 0)) 
        } else {
          setData(res.data.result.data);
        }
        setIsApplying(false);
        setIsReseting(false);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  
  
  const applyFilter = () => {
    setIsApplying(true); // Disable the "APPLY" button
    setLoad(true);
    ((StartDateData.length > 0)? setStartDateFilter(StartDateData): setStartDateFilter(startdate));
    ((EndDateData.length > 0)? setEndDateFilter(EndDateData): setEndDateFilter(startdate));
    // setStartDateFilter(startdate);
    // setEndDateFilter(enddate);
    ((PlatformData.length > 0)? setPlatforms(PlatformData): setPlatforms(platforms));
    setLoyaltyId(loyaltyId);
  }

  const resetFilter = () => {
    setIsReseting(true);
    setLoad(true);
    setStartDate('')
    setEndDate('');
    sessionStorage.setItem('startdate', '');
    sessionStorage.setItem('enddate', '');
    sessionStorage.setItem('platform', '');
    setStartDateFilter('')
    setEndDateFilter('');
    setLoyaltyId('all');

  }
  async function fetchSalesFilters() {
    await axios
      .get(getMerchantsSalesFilterUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        var response = res.data.result.data;
        var loyaltyProgrammes = response.loyalty_programmes;
        var platformTypes = response.sys_code_labels.PLATFORM_TYPE;

        setLoyaltyList(loyaltyProgrammes);
        setPlatformsList(platformTypes);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  useEffect(() => {   
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
      fetchSalesFilters
      ().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load, startdateFilter, enddateFilter]);

  const columns = useMemo(() => [
    {
      Header: "Location Name",
      accessor: "location_name",
      Cell: ({ row }) => {
        let location = row.original.location_name;
        row.values.location_name = row.original.location_name;
        return <p className="text-sm text-gray-900">{location}</p>;
      },
    },
    {
      Header: "PayEatFwd Total Donated Amount ($)",
      accessor: "payeatfwd_total_donated_amt",
      Cell: ({ row }) => {
        var sales = row.values.payeatfwd_total_donated_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
    {
      Header: "PayEatFwd Total Claimed Amount ($)",
      accessor: "payeatfwd_total_claimed_amt",
      Cell: ({ row }) => {
        var sales = row.values.payeatfwd_total_claimed_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
    {
      Header: "PayEatFwd Balance Amount ($)",
      accessor: "payeatfwd_balance",
      Cell: ({ row }) => {
        var sales = row.values.payeatfwd_balance;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
    
    {
      Header: "Discount Amount ($)",
      accessor: "discount_amt",
      Cell: ({ row }) => {
        var sales = row.values.discount_amt;
        if (sales == undefined || sales == null) {
          return <p className="text-sm text-gray-900">0</p>;
        } else {
          // return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
          var truncatedSales = Number(sales.toString().match(/^\d+(?:\.\d{0,3})?/)[0]); // Truncate extra decimal places
          return <p className="text-sm text-gray-900">{truncatedSales}</p>;
        }
      }
    },
    {
      Header: "Voucher Amount ($)",
      accessor: "voucher_amt",
      Cell: ({ row }) => {
        var sales = row.values.voucher_amt;
        if (sales == undefined || sales == null) {
          return <p className="text-sm text-gray-900">0</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
    {
      Header: "Loyalty Retention Amount ($)",
      accessor: "loyalty_programme_retention_amt",
      Cell: ({ row }) => {
        var sales = row.values.loyalty_programme_retention_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          // return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
          var truncatedSales = Number(sales.toString().match(/^\d+(?:\.\d{0,3})?/)[0]); // Truncate extra decimal places
          return <p className="text-sm text-gray-900">{truncatedSales}</p>;
        }
      }
    },
    {
      Header: "Loyalty Redemption Amount ($)",
      accessor: "loyalty_redemption_amt",
      Cell: ({ row }) => {
        var sales = row.values.loyalty_redemption_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">0</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
   
    {
      Header: "Total Sales ($)",
      accessor: "total_sales",
      Cell: ({ row }) => {
        var sales = row.values.total_sales;
        if (sales == undefined || sales == null) {
          return <p className="text-sm text-gray-900">0</p>;
        } else {
          // return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
          var truncatedSales = Number(sales.toString().match(/^\d+(?:\.\d{0,3})?/)[0]); // Truncate extra decimal places
          return <p className="text-sm text-gray-900">{truncatedSales}</p>;
        }
      }
    },
    {
      Header: "Total Nett Sales ($)",
      accessor: "total_deducted_sales",
      Cell: ({ row }) => {
        var totalsales = row.values.total_sales;

        var redemptionAmt = row.values.loyalty_redemption_amt;
        var voucherAmt = row.values.voucher_amt;
        var discountAmt = row.values.discount_amt;

        var totalDeductedSales = totalsales - redemptionAmt - voucherAmt - discountAmt;

        if (totalDeductedSales == undefined || totalDeductedSales == null) {
          return <p className="text-sm text-gray-900">0</p>;
        } else {
          // return <p className="text-sm text-gray-900">{ totalDeductedSales.toFixed(2)}</p>;
          var truncatedSales = Number(totalDeductedSales.toString().match(/^\d+(?:\.\d{0,3})?/)[0]); // Truncate extra decimal places
          return <p className="text-sm text-gray-900">{truncatedSales}</p>;
        }
      }
    },
    {
      Header: "Merchant Receivable Amount ($)",
      accessor: "merchant_receivable_amt",
      Cell: ({ row }) => {
        var sales = row.values.merchant_receivable_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          // return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
          var truncatedSales = Number(sales.toString().match(/^\d+(?:\.\d{0,3})?/)[0]); // Truncate extra decimal places
          return <p className="text-sm text-gray-900">{truncatedSales}</p>;
        }
      }
    },
    // {
    //   Header: "NETS Click MID",
    //   accessor: "merchant_mid_nets_click",
    //   Cell: ({ row }) => {
    //     var mid = row.values.merchant_mid_nets_click;
    //     if (mid == undefined || mid == null) {
    //       return <p className="text-sm text-gray-900">-</p>;
    //     } else {
    //       return <p className="text-sm text-gray-900">{ mid}</p>;

    //     }
    //   }
    // },
    // {
    //   Header: "NETS Click TID",
    //   accessor: "merchant_tid_nets_click",
    //   Cell: ({ row }) => {
    //     var tid = row.values.merchant_tid_nets_click;
    //     if (tid == undefined || tid == null) {
    //       return <p className="text-sm text-gray-900">-</p>;
    //     } else {
    //       return <p className="text-sm text-gray-900">{ tid}</p>;

    //     }
    //   }
    // },
    // {
    //   Header: "NETS QR MID",
    //   accessor: "merchant_mid_nets_qr",
    //   Cell: ({ row }) => {
    //     var mid = row.values.merchant_mid_nets_qr;
    //     if (mid == undefined || mid == null) {
    //       return <p className="text-sm text-gray-900">-</p>;
    //     } else {
    //       return <p className="text-sm text-gray-900">{ mid}</p>;

    //     }
    //   }
    // },
    // {
    //   Header: "NETS QR TID",
    //   accessor: "merchant_tid_nets_qr",
    //   Cell: ({ row }) => {
    //     var tid = row.values.merchant_tid_nets_qr;
    //     if (tid == undefined || tid == null) {
    //       return <p className="text-sm text-gray-900">-</p>;
    //     } else {
    //       return <p className="text-sm text-gray-900">{ tid}</p>;

    //     }
    //   }
    // },
    {
      id: "edit",
      Cell: ({ row }) => {
        let merchantObj = {
          id: row.original.location_id,
          name: row.original.location_name,
        };

        return (
          <div className=" text-gray-900  flex space-x-1 ">
            <button type="button">
              <Link
                to={{
                  pathname: `/dashboard/hierarchy/merchantsales/${merchantObj.name}/${merchantObj.id}`,
                }}
              >
                <Cards aria-hidden="true" className="w-6 h-6" />
              </Link>
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      
      {/* <div style={{backgroundColor:'#FFF', padding:'20px', borderRadius:'10px', margin:'20px 0 20px 0 '}}>
        <row style={{display:'flex'}}>
          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> Start Date </h6>
            </div>
            <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={startdate} onChange={(e) => {
              setStartDate(e.target.value);
            }}/>
          </div>
          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> End Date </h6>
            </div>
            <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={enddate} onChange={(e) => {
              setEndDate(e.target.value);
            }}/>
          </div>
          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> Platform Type </h6>
            </div>
            <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={platforms} onChange={(e) => {
              setPlatforms(e.target.value);
            }}>
              <option value='all'>All</option>
              {platform && platform.length > 0 && platform.map(
                (comm, index) => {
                  return <option key={index} value={comm}>{comm}</option>
                })}
            </select>
          </div>
          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> Payment Type </h6>
            </div>
            <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={payments} onChange={(e) => {
              setPayment(e.target.value);
            }}>
              <option value='all'>All</option>
              {payment && payment.length > 0 && payment.map(
                (comm, index) => {
                  return <option key={index} value={comm}>{comm}</option>
                })}
            </select>
          </div>
        </row>
        <div style={{ textAlign: 'end', paddingTop: '34px' }}>
          <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => {
              console.log(platforms);
              console.log(payments);
              console.log(startdate);
              console.log(enddate);
            //   setPlatforms(platform.filter((p) => {
            //     console.log(p)
            //     if (platform != 'all') {
            //       return platform
            //     } else {
            //       return p
            //     }
            //   }),
            //   setPayment(payment.filter((p) => {
            //     console.log(p)
            //     if (payment != 'all') {
            //       return payment
            //     } else {
            //       return p
            //     }
            //   })
            //   )
            // )
          }}>APPLY</button>
          <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => {
              setPlatforms('all')
              setPayment('all')
              setStartDate('')
              setEndDate('');
          }}>RESET</button>
        </div>
      </div> */}

      <div style={{backgroundColor:'#FFF', padding:'20px', borderRadius:'10px', margin:'20px 0 20px 0 '}}>
        <row style={{display:'flex'}}>
          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> Start Date </h6>
            </div>
            <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={startdate} onChange={(e) => {
              setStartDate(e.target.value);
              sessionStorage.setItem('startdate', e.target.value);
            }}/>
          </div>
          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> End Date </h6>
            </div>
            <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={enddate} onChange={(e) => {
              setEndDate(e.target.value);
              sessionStorage.setItem('enddate', e.target.value); 
            }}/>
          </div>
          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> Platform Type </h6>
            </div>
            <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={PlatformData} onChange={(e) => {
              sessionStorage.setItem('platform', e.target.value);
              setPlatforms(e.target.value), setPlatformsName(e.target.selectedOptions[0].text);
            }}>
              <option value='all'>All</option>
              {platformList && platformList.length > 0 && platformList.map(
                (comm, index) => {
                  return <option key={index} value={comm.sys_code_id}>{comm.sys_code_desc}</option>
                })}
            </select>
          </div>
          {/* <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> Loyalty Programme </h6>
            </div>
            <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={loyaltyId} onChange={(e) => {
              setLoyaltyId(e.target.value);
            }}>
              <option value='all'>All</option>
              {loyaltyList && loyaltyList.length > 0 && loyaltyList.map(
                (comm, index) => {
                  return <option key={index} value={comm.loyalty_id}>{comm.loyalty_name}</option>
                })}
            </select>
          </div> */}
        </row>
        <div style={{ textAlign: 'end', paddingTop: '34px' }}>
          <button
            data-inline="true"
            type="button"
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
              isApplying ? applyButtonDisabledClass : applyButtonEnabledClass
            }`}
            onClick={() => applyFilter()}
            disabled={isApplying} // Disable the button when isApplying is true
          >
            {isApplying ? "Applying..." : "APPLY"}
          </button>
          <button
            data-inline="true"
            type="button"
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
              isReseting ? applyButtonDisabledClass : applyButtonEnabledClass
            }`}
            onClick={() => resetFilter()}
            disabled={isReseting} // Disable the button when isReseting is true
          >
            {isReseting ? "Reseting..." : "Reset"}
          </button>
        </div>
      </div>

      {data != null ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationsLayout;
