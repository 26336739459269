import { useState, useContext, useEffect } from "react";
import ScheduleCard from "../components/scheduleCard";
import Breadcrumbs from "../../../commons/components/breadcrumbs";
import ScheduleSideRowCard from "../components/scheduleSideRowCard";
import TimeInOutTabs from "../components/timeInOutTabs";
import { EmployeeCurrentTabContext } from "../context/employeeTimeInOutContext";
import axios from "axios";
import commonConfigs from "../../../config/config";
import LocationsLayout from "./employee_timecard_details/locationsLayout";
import { useParams } from "react-router-dom";
import TEACard from "../components/teaCard";
import TMACard from "../components/tmaCard";

const ScheduleDate = () => {

  let { date } = useParams();
  let { location } = useParams();

  const { currentTab } = useContext(EmployeeCurrentTabContext);
  const pages = 
  [
    { name: "Calendar", href: "/schedule", current: false },
    { name: date, href: `/schedule/date/${date}/${location}`, current: true },
  ];

  const [tableLocationList, setTableLocationList] = useState([]);

  const [selectedTableLocation, setSelectedTableLocation] = useState(location);

  const [selectedMerchantTableLocation, setSelectedMerchantTableLocation] = useState(location);

  const [load, setLoad] = useState(true);

  async function fetchTableLocations() {

    const GetLocations = commonConfigs.apiUrls.GetLocations();

    await axios
    .get(GetLocations, { headers: commonConfigs.apiHeader })
    .then((res) => {
      console.log(res.data.result.data);
      setTableLocationList(res.data.result.data.filter(item => item.is_deleted === 0));
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    if (load) {
      fetchTableLocations().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load, selectedTableLocation, selectedMerchantTableLocation]);
  
  return (
    <div className="w-full desktop:p-6">

        <div className="flex-1 min-w-0 mb-0 p-8">
          <Breadcrumbs pages={pages} />
        </div>
        {/* <div style={{ display: 'grid', 'grid-template-areas': `'box4 box4 ''box4 box4 '`,'grid-template-columns': 'repeat(2, minmax(0, 2fr))' , gap: 4, padding:'20px', maxWidth: 'inherit' }}> */}

        {/* <div style={{ gridArea: 'box1', height: '100%' }}>
          <ScheduleCard>
            <TEACard/>
          </ScheduleCard>
        </div>

        <div style={{ gridArea: 'box2', height: '100%' }}>
          <ScheduleCard>
            <TMACard/>
          </ScheduleCard>
        </div> */}
          
          {/* <div style={{ gridArea: 'box4', height: '100%' }}> */}

          {/* <div className="mt-4"> */}
            {/* <h3 className="font-normal text-xl mb-4">Time In | Out</h3> */}

            {/* <div> */}
              {currentTab.name === "Employees" ? (
                <div>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <TimeInOutTabs />
                    </div>

                    <div style={{ alignSelf: "flex-end", marginRight: '60px', width: '200px' }}>
                      <select
                        style={{ width: '130%', padding: '8px', borderRadius: '16px' }}
                        onChange={(e) => {
                          const selectedTableValue = e.target.value;
                          setSelectedTableLocation(selectedTableValue === "all" ? "" : selectedTableValue);
                          setLoad(true); // Set load to true to trigger the data fetching immediately
                        }}
                        value={selectedTableLocation === "" ? "all" : selectedTableLocation} // Map empty string to "all" for the dropdown
                      >
                        <option value="all">All</option>
                        {tableLocationList.map((location) => (
                          <option key={location.location_id} value={location.location_id}>
                            {location.location_name}
                          </option>
                        ))}
                      </select>
                    </div>

                  </div>

                  <LocationsLayout date={date} location={selectedTableLocation}/>
                </div>
              ) : (
                <div>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <TimeInOutTabs />
                    </div>

                    <div style={{ alignSelf: "flex-end", marginRight: '60px', width: '200px' }}>
                      <select
                        style={{ width: '130%', padding: '8px', borderRadius: '16px' }}
                        onChange={(e) => {
                          const selectedMerchantValue = e.target.value;
                          setSelectedMerchantTableLocation(selectedMerchantValue);
                          setLoad(true); // Set load to true to trigger the data fetching immediately
                        }}
                        value={selectedMerchantTableLocation}
                      >
                        <option value="all">All</option>
                        {tableLocationList.map((location) => (
                          <option key={location.location_id} value={location.location_id}>
                            {location.location_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <ScheduleSideRowCard date={date}  location={selectedMerchantTableLocation}/>
                </div>
              )}

            {/* </div> */}
          {/* </div> */}
        </div>
      // </div>
    // </div>
  );
};

export default ScheduleDate;
