import { useSelector, useDispatch, Provider } from 'react-redux'
import Intro from '../components/views/introductionApi'
import styles from '../styles/styles.module.css'
import TestIntro from '../components/views/testIntro'
import UpdateOrderStatus from '../components/views/order_fulfillment_pages/updateOrderStatus'
import GetConsumerOrders from '../components/views/order_fulfillment_pages/getConsumerOrders'
import GetOrderbyId from '../components/views/order_fulfillment_pages/getOrderbyId'
import RequestDishware from '../components/views/cleaning_services_modules/requestDishware'
import GetDishwareRequest from '../components/views/cleaning_services_modules/getDishwareRequest'
import UpdateDishwareStatus from '../components/views/cleaning_services_modules/updateDishwareStatus'

const Documentation = () => {
  return (
    <div id="main" className={styles.row}>
    <Intro />
    
    {/* <div id="update-order-status">
      <UpdateOrderStatus/>
    </div>
    <div name="get-consumer-orders">
      <GetConsumerOrders/>
    </div>
    <a name="test">
      <GetOrderbyId/>
    </a>
    <RequestDishware/>
    <GetDishwareRequest/>
    <UpdateDishwareStatus/> */}
    </div>    
  )
}
export default Documentation