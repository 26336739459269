import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";

import DashboardKisokBarChart from "./dashboardKioskBarChart";

const user = JSON.parse(localStorage.getItem("user"))

export default function KPWIPCard() {
  const [isApplying, setIsApplying] = useState(false);
  const [isReseting, setIsReseting] = useState(false);

  const [kisokPrizeWheel, setKisokPrizeWheel] = useState(null);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1 to get the current month.

  // Calculate the first day of the current month.
  const firstDayOfMonth = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;

  // Calculate the last day of the current month.
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  const lastDayOfMonthFormatted = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${lastDayOfMonth.getDate()}`;

  const [kioskstartdate, setKioskStartDate] = useState(firstDayOfMonth);
  const [kioskenddate, setKioskEndDate] = useState(lastDayOfMonthFormatted);

  const [kioskstartdateFilter, setKioskStartDateFilter] = useState(kioskstartdate);
  const [kioskenddateFilter, setKioskEndDateFilter] = useState(kioskenddate);

  const [load, setLoad] = useState(true);

  const applyButtonEnabledClass = "bg-red-600 hover:bg-red-700";
  const applyButtonDisabledClass = "bg-red-400 cursor-not-allowed";

  async function fetchKisokPrizeWheel() {
    let getPrizeWheelStatistics = commonConfigs.apiUrls.GetPrizeWheelStatistics(
      kioskstartdateFilter,
      kioskenddateFilter
    );

    if(user.access_multiple_location == 0 && user.location_id) {
      getPrizeWheelStatistics = getPrizeWheelStatistics + `&location_id=${user.location_id}`
    }
  
    try {
      const res = await axios.get(getPrizeWheelStatistics, { headers: commonConfigs.apiHeader });
  
      console.log('Kiosk Prize Wheel Data', res.data.result.data);
  
      // Loop through the received data array
      const KisokPrizeWheelData = res.data.result.data.map((item) => ({
        location_name: item.location_name,
        Types: [
          {
            type: "Redeemed",
            Amt: item.voucher_qty_redeemed || 0, // If the value is null, use 0 as the default
          },
          {
            type: "Non-Redeemed",
            Amt: (item.voucher_qty_issued || 0) - (item.voucher_qty_redeemed || 0), // Use 0 as the default for null values
          },
        ],
      })).filter((item) => item.Types[0].Amt != 0 || item.Types[1].Amt != 0);
  
      setKisokPrizeWheel(KisokPrizeWheelData);
      setIsApplying(false);
      setIsReseting(false);
      console.log('Modified Data', KisokPrizeWheelData);
    } catch (err) {
      console.log(err);
    }
  }  
  

  const applyKioskFilter = () => {
    setIsApplying(true); // Disable the "APPLY" button
    setLoad(true);
    setKioskStartDateFilter(kioskstartdate);
    setKioskEndDateFilter(kioskenddate);
  }

  const resetKioskFilter = () => {
    setIsReseting(true);
    setLoad(true);
    setKioskStartDate(currentDate)
    setKioskEndDate(currentDate);
    setKioskStartDateFilter('')
    setKioskEndDateFilter('');
  }

  useEffect(() => {
    fetchKisokPrizeWheel().catch((err) => {
      console.log("err: " + err);
    });
  }, [load, kioskstartdateFilter, kioskenddateFilter]);


  return ( 
    <div>

      <h3 className="text-lg">Kiosk Prize Wheel Issued Prizes</h3>

      <div style={{backgroundColor:'#FFF', padding:'20px', borderRadius:'10px' }}>
        <div style={{'text-align': 'right', display:'flex', paddingBottom: '20px'}}>
          <div style={{ paddingTop: '10px', paddingBottom: '10px', padding:'10px' }}>
            <h6> Start Date: </h6>
          </div>
          <input data-inline="true" type="date" style={{width:'30%',borderRadius:'8px'}} value={kioskstartdate} onChange={(e) => {
                  setKioskStartDate(e.target.value);}}
          />
          <div style={{ paddingTop: '10px', paddingBottom: '10px', padding:'10px'  }}>
            <h6> End Date: </h6>
          </div>
          <input data-inline="true" type="date" style={{width:'30%',borderRadius:'8px'}} value={kioskenddate} onChange={(e) => {
                  setKioskEndDate(e.target.value);}}
          />
            <div style={{'text-align': 'right', display:'flex', padding:'10px' }}>
              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  isApplying ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => applyKioskFilter()}
                disabled={isApplying} // Disable the button when isApplying is true
              >
                {isApplying ? "Applying..." : "APPLY"}
              </button>
              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  isReseting ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => resetKioskFilter()}
                disabled={isReseting} // Disable the button when isReseting is true
              >
                {isReseting ? "Reseting..." : "Reset"}
              </button>
            </div>
        </div>
      </div> 

      <div className="mt-12  ">
        {kisokPrizeWheel && (
          <DashboardKisokBarChart
            data={kisokPrizeWheel.map((item) => ({
              name: item.location_name,
              values: item.Types.map((types) => ({
                name: types.type,
                value: types.Amt,
              })),
            }))}
          />
        )}
      </div>

    </div>
  );
}
