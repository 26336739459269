import { Edit } from "iconsax-react";
import React, { useEffect, useState, useMemo } from "react";
import Table from "../../../../commons/components/table";
import IncidentReportTabs from "../components/incidentReportTabs";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import { IncidentReportCurrentTabContext } from "../context/incidentReportContext";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import { format } from "date-fns";
import Spinner from "../../../../commons/components/spinner";
import UpdateIncidentModal from "../components/updateIncidentModal";
import { useParams, useLocation } from "react-router-dom";

const IncidentReportLayout = () => {

  const locationName = useParams().locationName;

  var locationIdApi = "";

  if (localStorage.getItem('locationid') == null) {
    const { locationId } = useLocation().state;

    localStorage.setItem('locationid', locationId);
    locationIdApi = locationId;
    console.log(locationIdApi);
  } else {
    locationIdApi = localStorage.getItem('locationid');
    console.log(locationIdApi);

  }

  const pages = [
    { name: "Action Items", href: "#", current: false },
    { name: "Locations", href: "/action/incidentslocations", current: false },
    {
      name: locationName, href: `/action/incidentslocations/${locationName}/incidents`, current: true,
    },
  ];

  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);

  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  async function getData() {

    const GetIncident = commonConfigs.apiUrls.GetIncident(locationIdApi);

    await axios
      .get(GetIncident, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Report Id",
      accessor: "incident_report_id",
    },
    {
      Header: "Location",
      accessor: "location_name",
    },
    {
      Header: "Title",
      accessor: "incident_title",
    },
    {
      Header: "Description",
      accessor: "incident_desc",
    },
    {
      Header: "Created On",
      accessor: "created_on",
      Cell: ({ row }) => {
        row.values.created_on = format(
          new Date(row.values.created_on),
          "dd MMM yyyy HH:mm"
        );
        var date = row.values.created_on;
        if (date) {
          return <div className="text-sm text-gray-900">{date}</div>;
        }
      },
    },
    {
      Header: "Status",
      accessor: "incident_status",
      Cell: ({ row }) => {
        let status = row.original.incident_status;
        if (status == '-' || status == 'Pending') {
          return <div className="text-sm text-orange-700">Pending</div>;
        } 
        else if (status == 'Resolved'){
          return <div className="text-sm text-green-700">Resolved</div>;
        }
        else {
          return <div className="text-sm text-red-700">Cancelled</div>;
        }
      },
    },
    {
      Header: "Remarks",
      accessor: "responder_remarks",
    },
    // {
    //   Header: "Responder",
    //   accessor: "responder_id",
    // },
    {
      Header: "Response On",
      accessor: "responded_on",
      Cell: ({ row }) => {
        if (row.values.responded_on) {
          const formattedDate = format(
            new Date(row.values.responded_on),
            "dd MMM yyyy HH:mm"
          );
          return <div className="text-sm text-gray-900">{formattedDate}</div>;
        } else {
          return <div className="text-sm text-gray-900">-</div>;
        }
      },
    },    
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalOpen(true);
                console.log(row.original);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      <IncidentReportTabs />

      {data != null ? (
        <IncidentReportCurrentTabContext.Consumer>
          {(value) => (
            <Table
              columns={columns}
              data={data}
              filters={columns.map((column) => column.accessor)}
              initialValue={value.currentTab.name}
              initialFilter = "incident_status"
            />
          )}
        </IncidentReportCurrentTabContext.Consumer>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      {isUpdateModalOpen && (
        <UpdateIncidentModal
          setUpdateModal={setUpdateModalOpen}
          load={setLoad}
          row={rowToEdit}
        />
      )}

    </div>
  );
};

export default IncidentReportLayout;
