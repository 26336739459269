import Card from "../../components/dashboardCard";
import LongCard from "../../components/dashboardLongCard";
import KMTCCard from "../components/kmtcCard";
import KPWIPCard from "../components/kpwipCard";
import LoyaltyCard from "../components/loyaltyCard";
import DashboardCard from "../../components/dashboardCard";
import KPWSCard from "../components/kpwsCard";
import SurveyCard from "../components/surveyCard";


const DashboardMarketingLayout = () => {

  return (
    <div>
      <div style={{display: 'grid', 'grid-template-areas':`'box1 box2 box3 box3''box5 box5 box6 box6'`,'grid-template-columns': 'repeat(4, minmax(0, 1fr))', gap:4, maxWidth: 'inherit'}}>
        
        <div style={{ gridArea: 'box1', height: '100%', minHeight: 0, minWidth: 0, overflow: 'visible' }}>
          <DashboardCard>
            <KPWSCard/>
          </DashboardCard>
        </div>
    
        <div style={{ gridArea: 'box2', height: '100%', minHeight: 0, minWidth: 0, overflow: 'visible' }}>
          <DashboardCard>
            <LoyaltyCard/>
          </DashboardCard>
        </div>
        
        <div style={{ gridArea: 'box3', height: '100%', minHeight: 0, minWidth: 0, overflow: 'visible' }}>
          <Card>
            <SurveyCard/>
          </Card>
        </div>

        <div style={{ gridArea: 'box5', height:'100%', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
          <LongCard>
            <KPWIPCard/>
          </LongCard>
        </div>

        <div style={{ gridArea: 'box6', height:'100%', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
          <LongCard>
            <KMTCCard/>
          </LongCard>
        </div>
        
    </div>
    </div>
  );
  
};

export default DashboardMarketingLayout;
