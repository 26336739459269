import axios from "axios";
import { format } from "date-fns";
import { Link, useParams, useLocation } from "react-router-dom";
import { Edit, Trash, Cards, DocumentDownload } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import { Additem, DocumentUpload } from "iconsax-react";
import Table from "../../../../commons/components/table";
import React, { useState, useMemo, useEffect } from "react";
import Spinner from "../../../../commons/components/spinner";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import CreatePrizeWheelSegmentModal from "../../prizeWheel_modules/components/createPrizeWheelSegmentModal";
import UpdatePrizeWheelSegmentModal from "../../prizeWheel_modules/components/updatePrizeWheelSegmentModal";
import DeleteModal from "../../../../commons/components/deleteModal";

//const getPrizeWheelsUrl = commonConfigs.apiUrls.GetAllPrizeWheel();

const PrizeWheelSegmentLayout = () => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();
  let { prizeWheelId } = useParams();

  const pages = [
    { name: "Configurations", href: "#", current: false },
    {
      name: "Prize Wheel Config",
      href: "/configurations/prizewheelconfig",
      current: false,
    },
    {
      name: "Prize Wheel",
      href: `/configurations/prizewheelconfig/${prizeWheelId}`,
      current: true,
    },
  ];

  const getPrizeWheelSegmentUrl =
    commonConfigs.apiUrls.GetPrizeWheelSegments(prizeWheelId);

  useEffect(() => {
    async function getData() {
      const prizeSegmentsResponse = await axios.get(getPrizeWheelSegmentUrl, {
        headers: commonConfigs.apiHeader,
      });

      const prizeSegments = prizeSegmentsResponse.data.result.data;

      const promises = prizeSegments.map(async (segment) => {
        const prizeId = segment.prize_id;

        try {
          const prizeResponse = await axios.get(
            commonConfigs.apiUrls.GetAPrize(prizeId),
            {
              headers: commonConfigs.apiHeader,
            }
          );

          const prizeLimitResponse = await axios.get(
            commonConfigs.apiUrls.GetAPrizeLimitByPrizeId(prizeId),
            {
              headers: commonConfigs.apiHeader,
            }
          );

          const prizeLimitQty =
            prizeLimitResponse.data.result.data.prize_qty_issued_limit;
          const voucherLimit =
            prizeLimitResponse.data.result.data.voucher_value_issued_limit;
          const prizeName = prizeResponse.data.result.data.prize_name;
          // const updated_on = prizeLimitResponse.data.result.data.updated_on;
          return {
            ...segment,
            prize_name: prizeName,
            prize_qty_issued_limit: prizeLimitQty,
            voucher_value_issued_limit: voucherLimit,
            // updated_on: updated_on,
          };
        } catch (error) {
          console.log("Error fetching prize:", error);
          return segment; // If there's an error, just return the segment without prize_name
        }
      });

      Promise.all(promises)
        .then((segmentsWithPrizeNameAndPrizeLimit) => {
          setData(segmentsWithPrizeNameAndPrizeLimit);
          setLoad(false);
        })
        .catch((err) => {
          console.log("Error fetching prize names:", err);
        });
    }

    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Prize Wheel Segment id",
      accessor: "prize_wheel_segment_id",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Prize Id",
      accessor: "prize_id",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Prize Name",
      accessor: "prize_name",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Prize Quantity Limit/Day",
      accessor: "prize_qty_issued_limit",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Prize Probability",
      accessor: "prize_probability",
      Cell: ({ value }) => (value ? value : "-"),
    },
    // {
    //   Header: "Last Updated By",
    //   accessor: "updated_by",
    //   Cell: ({ value }) => (value ? value : "-"),
    // },
    // {
    //   Header: "Last Updated On",
    //   accessor: "updated_on",
    //   Cell: ({ row }) => {
    //     if (row.values.updated_on) {
    //       row.values.updated_on = format(
    //         new Date(row.values.updated_on),
    //         "dd MMM yyyy HH:mm"
    //       );
    //       var date = row.values.updated_on;
    
    //       if (date) {
    //         return <div className="text-sm text-gray-900">{date}</div>;
    //       }
    //     }
    //     return <div className="text-sm text-gray-900">-</div>;
    //   },
    // }, 
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            type="button"
            className="ml-3 inline-flex items-center px-4 py-2 text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setCreateModalOpen(true)}
          >
            Create Prize Wheel Segment <Additem className="ml-3" />
          </button>
        </div>
      </div>
      {data != null ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      {isCreateModalOpen && (
        <CreatePrizeWheelSegmentModal
          setOpenModal={setCreateModalOpen}
          load={setLoad}
          prizeWheelId={prizeWheelId}
        />
      )}
      {isUpdateModalOpen && (
        <UpdatePrizeWheelSegmentModal
          setOpenModal={setUpdateModalOpen}
          load={setLoad}
          row={rowToEdit}
          prizeWheelId={prizeWheelId}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          setDeleteModal={setDeleteModalOpen}
          load={setLoad}
          url={commonConfigs.apiUrls.DeletePrizeWheelSegment(
            prizeWheelId,
            rowToEdit.prize_wheel_segment_id
          )}
        />
      )}
    </div>
  );
};

export default PrizeWheelSegmentLayout;
