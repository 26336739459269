import axios from "axios";
import { useParams } from "react-router-dom";
import MainDetailsLayout from "./mainDetailsLayout";
import commonConfigs from "../../../../config/config";
import { useState, useEffect } from "react";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";

const MerchantsDetailsLayout = () => {
  const [merchantName, setMerchantName] = useState("");
  let { merchantId } = useParams();
  let { locationcode } = useParams();
  let { locationId } = useParams();
  let { date } = useParams();

  const pages = [
    { name: "Calendar", href: "/schedule", current: false },
    { name: "Locations", href: `/schedule/${date}/merchant-timecard`, current: false },
    { name: locationcode, href: `/schedule/${date}/merchant-timecard/${locationId}/${locationcode}`, current: false },
    {
      name: merchantName,
      href: `/schedule/${date}/merchant-timecard/${locationId}/${locationcode}/${merchantId}`,
      current: true,
    },
  ];
  // Urls.
  const getMerchantEMenuUrl =
    commonConfigs.apiUrls.GetMerchantTimecards(merchantId);

  // Data.
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);

  async function getMerchantName() {
    await axios
      .get(commonConfigs.apiUrls.GetAMerchant(merchantId), {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        setMerchantName(res.data.result.data.merchant_name);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getEMenuData() {
    await axios
      .get(getMerchantEMenuUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  useEffect(() => {
    if (load) {
      getMerchantName().catch((err) => {
        console.log("merchant err: " + err);
      });
      getEMenuData().catch((err) => {
        console.log("emenu err: " + err);
      });
    }
  }, [load]);

  return (
    <div className="w-full desktop:p-6 mobile:p-2 ">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
        <MainDetailsLayout data={data} load={load} setLoad={setLoad} />
    </div>
  );
};

export default MerchantsDetailsLayout;
