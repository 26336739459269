import { createContext, useState } from "react";

const tabs = [{name: "All"}, { name: "Full Time" }, { name: "Part Time" }];

export const LeaveTabsContext = createContext();
export const LeaveCurrentTabContext = createContext();

export const LeaveContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <LeaveTabsContext.Provider value={{ tabs }}>
      <LeaveCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </LeaveCurrentTabContext.Provider>
    </LeaveTabsContext.Provider>
  );
};
