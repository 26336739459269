import axios from "axios";
// import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Cards } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import Table from "../../../../commons/components/table";
import React, { useState, useMemo, useEffect } from "react";
import Spinner from "../../../../commons/components/spinner";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";

const getMerchantsUrl = commonConfigs.apiUrls.GetAllMerchantLocationTimecards();

const LocationsLayout = () => {

  let { date } = useParams();

  const pages = [
    { name: "Calendar", href: "/schedule", current: false },
    { name: "Locations", href: `/schedule/${date}/merchant-timecard`, current: true },
  ];

  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    // localStorage.removeItem('locationcode');
    async function getData() {
      await axios
        .get(getMerchantsUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          setData(res.data.result.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Location Name",
      accessor: "location_name",
      Cell: ({ row }) => {
        let location = row.original.location_name;
        row.values.location_name = row.original.location_name;
        return <p className="text-sm text-gray-900">{location}</p>;
      },
    },
    {
      Header: "Current Attendance",
      accessor: "attendance_current",
    },
    {
      Header: "Month-To-Date Stall Manning",
      accessor: "attendance_total",
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        let merchantObj = {
          locationName: row.original.location_name,
          id: row.original.location_id,
        };

        return (
          <div className=" text-gray-900  flex space-x-1 ">
            <button type="button">
              <Link
                to={{
                  pathname: `/schedule/${date}/merchant-timecard/${merchantObj.id}/${merchantObj.locationName}`,
                }}
              >
                <Cards aria-hidden="true" className="w-6 h-6" />
              </Link>
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
      </div>

      {data != null ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationsLayout;
