import { useState, useEffect } from "react";
import Accordion from "../../../commons/components/accordianCard";
import { SearchNormal } from "iconsax-react";
import axios from "axios";
import commonConfigs from "../../../config/config";
import { Link } from "react-router-dom";

const getFaqUrl = commonConfigs.apiUrls.GetLandingFAQ();

const FAQLandingPage = () => {
  const [data, setData] = useState(null);
  const [dataShown, setDataShown] = useState(null);
  const [load, setLoad] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    async function getData() {
      await axios
        .get(getFaqUrl)
        .then((res) => {
          console.log(res.data.result.data);
          setData(res.data.result.data);
          setDataShown(res.data.result.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  useEffect(() => {
    if (data && searchValue !== "") {
      console.log(data);
      setDataShown(
        data.filter((row) =>
          row.faq_qn.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  }, [searchValue]);

  return (
    <div className=" bg-white ">
      <div className="mx-auto max-w-3xl items-center desktop:w-2/4 mobile:w-full text-center">
        <div className="mt-20">
          <p className="text-2xl mt-5 font-medium ">
            All Frequently Asked Questions
          </p>
        </div>
        <div className="mobile:flex mobile: justify-center">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <div className=" mt-8 relative desktop:w-full mobile:w-full ">
            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
              <SearchNormal className="h-5 w-5 mx-1" aria-hidden="true" />
            </div>
            <input
              id="search-field"
              className=" 
        block
        w-full
        px-3
        py-1.5
        pl-8
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-yellow-800 focus:outline-none"
              placeholder="Search"
              type="search"
              name="search"
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="mt-12">
        <div className="px-40 mobile:px-8">
          {dataShown &&
            data &&
            dataShown.map((d, index) => (
              <Accordion
                key={index}
                title={d.faq_qn}
                content={d.faq_ans}
                index={index + 1}
              />
            ))}
        </div>
      </div>
      <div className="mx-auto max-w-3xl items-center desktop:w-2/4 mobile:w-full text-center">
        <Link to="/landing" className="m-12 text-2xl">
          <button
            className="border bg-green-600 text-white text-lg px-6 py-2 rounded-md"
            type="button"
          >
            Return to Home
          </button>
        </Link>
      </div>
    </div>
  );
};

export default FAQLandingPage;
