import { colors } from "@mui/material";

const LayoutConfig = {
    sidebar: {
        bg: "#378805",
        color: "#eeeeee",
        hoverBg: "#26580F",
        activeBg: "#86DC3D",
        width: "300px"
    },
    mainBg: colors.grey["100"],
    topbar: {
        bg: "#fff",
        color: "#000"
    },
};

export default LayoutConfig;