import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import YTDRevenueCard from "./ytdRevenueCard";

const user = JSON.parse(localStorage.getItem("user"));

export default function YTDRCard() {

  const [divisionRevenueYTD, setDivisionRevenueYTD] = useState(null);

  const [load, setLoad] = useState(true);

  async function fetchDivisionRevenueYTD() {
    let getDivisionRevenueYTD = commonConfigs.apiUrls.GetYTDSales();
    if(user.access_multiple_location == 0 && user.location_id) {
      getDivisionRevenueYTD = getDivisionRevenueYTD + `&location_id=${user.location_id}`
    }
    await axios
      .get(getDivisionRevenueYTD, { headers: commonConfigs.apiHeader })
      .then((res) => {
        res.data.result.data.revenue_stream =
          res.data.result.data.revenue_stream.map((revenue_stream) => {
            revenue_stream.type = revenue_stream.division_type;
            return revenue_stream;
          });
        console.log(res.data.result.data);
        setDivisionRevenueYTD(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchDivisionRevenueYTD().catch((err) => {
      console.log("err: " + err);
    });
  }, [load]);


  return (
    <div style={{height:'inherit', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
      {divisionRevenueYTD && (
        <YTDRevenueCard title="YTD Sales"
        subtitle={(divisionRevenueYTD.revenue_stream.length == 0) ? "No Sales Today" : "Today's Sales" }
        data={divisionRevenueYTD} />
      )}
    </div>   
  );
}
