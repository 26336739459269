import { useState, useEffect } from "react";
import { ArrowRight } from "iconsax-react";
import OTP from "../components/otpModal";
import commonConfigs from "../../../config/config";
import axios from "axios";
import { useForm } from "react-hook-form";
import { InfoCircle } from "iconsax-react";
import firebaseConfig from '../../../config/firebaseConfig'
import { initializeApp } from 'firebase/app'
const ssoLogin = commonConfigs.apiUrls.ssoAuth();
const headers = commonConfigs.apiHeader;
const apiKey = headers["api-key"];
const domain = window.location.host;

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function Login() {
  const [isOpen, setOpen] = useState(false);
  const [data, setData] = useState();
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});

  const onSubmit = (data) => {
    // if (!data.email.endsWith(getRootHostName(domain))) {
    //   alert("Please enter email ending with correct domain. ");
    // } else {
    setEmail(data.email);
    setMobile(data.mobile);
    axios
      .post(ssoLogin, data, {
        headers: {
          "api-key": apiKey,
          Authorization: "Bearer whatever",
        },
      })
      .then((res) => {
        try {
          if (res.data.status != "success") {
            alert("danger");
          }
          setData(res.data.result);
          setOpen(true);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
      });
    // }
  };
  function extractDomain(url) {
    var domain;/*w    w w .  d   e m  o   2  s   .c   o   m*/
    //find & remove protocol (http, ftp, etc.) and get domain
    if (url.indexOf("://") > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    //find & remove port number
    domain = domain.split(':')[0];
    return domain;
  }
  const getRootHostName = (url) => {
    var parts = extractDomain(url).split('.');
    var partsLength = parts.length - 3;
    //parts.length-3 assuming root hostname is of type abc.xyz.pp
    for (var i = 0; i < partsLength; i++) {
      parts.shift(); //remove sub-domains one by one
    }
    var rootDomain = parts.join('.');
    return rootDomain;
  }
  console.log(domain);
  console.log(getRootHostName(domain));
  useEffect(() => {
    localStorage.clear();
    initializeApp(firebaseConfig)
  }, [])
  return (
    <>
      <div className="min-h-screen bg-white desktop:flex">
        <div className=" lg:block relative w-2/4">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
        <div className="flex-col flex justify-center py-12 px-4 sm:px-6 lg:flex-none  desktop:ml-12 lg:px-20 xl:px-24 desktop:w-2/5 mobile:w-full ">
          <div>
            {/* <img
              className="h-12 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
              alt="Workflow"
            /> */}
            <h2 className="mt-6 text-4xl font-extrabold text-gray-900 ">
              Login to Food Canopy Admin Panel
            </h2>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-2 sm:col-span-6">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-yellow-800"
                  >
                    Email*
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className={
                        !errors.email
                          ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                          : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                      }
                      placeholder={"Enter Email"}
                      {...register("email", { required: true })}
                    />
                    {errors.email?.type === "required" && errorIcon}
                  </div>
                </div>

                <div className="mt-2 sm:col-span-6 space-y-1">
                  <label
                    htmlFor="mobile"
                    className="block text-sm font-medium text-yellow-800"
                  >
                    Mobile*
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="text"
                      name="mobile"
                      id="mobile"
                      className={
                        !errors.mobile
                          ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                          : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                      }
                      placeholder={"Enter Mobile Number"}
                      {...register("mobile", { required: true })}
                    />
                    {errors.mobile?.type === "required" && errorIcon}
                  </div>
                </div>
                <div className="mt-5">
                  <button
                    type="submit"
                    className="space-x-2 items-center w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lime-500 hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span>Login</span>
                    <ArrowRight />
                  </button>
                </div>
              </form>
              <div className="text-grey text-sm py-6">
                Empowered by OpenApiPass Foundation and BevEat. All rights
                reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <OTP setOpenModal={setOpen} data={data} mobile={mobile} email={email} />
      )}
    </>
  );
}
