import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";

import StatusCard from "../components/statusCard";

const user = JSON.parse(localStorage.getItem("user"))

export default function OOSCard() {

  const [stalls, setStalls] = useState(null);
  
  async function fetchStallStatus() {

    let getOutletOnlineStalls = commonConfigs.apiUrls.GetOutletOnlineStalls();
    if(user.access_multiple_location == 0 && user.location_id) {
      getOutletOnlineStalls = getOutletOnlineStalls + `&location_id=${user.location_id}`
    }

    await axios
    .get(getOutletOnlineStalls, { headers: commonConfigs.apiHeader })
    .then((res) => {
      console.log(res.data.result.data);
      setStalls(res.data.result.data);
    })
    .catch((err) => {
      console.log(err);
    });

  }

  useEffect(() => {
    fetchStallStatus().catch((err) => {
      console.log("err: " + err);
    });
  }, []);

  return (
    <div>
      {stalls && (
        <StatusCard
          title={"Outlet Online Stalls"}
          data={stalls}
        />
      )}
    </div>   
  );
}
