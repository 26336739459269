import axios from "axios";
import { format } from "date-fns";
import commonConfigs from "../../../../config/config";
import EmployeesTabs from "../components/employeesTabs";
import Table from "../../../../commons/components/table";
import CreateEmpModal from "../components/createEmpModal";
import UploadEmpModal from "../components/uploadEmpModal";
import UpdateEmpModal from "../components/updateEmpModal";
import UpdateEmpModalMP from "../components/updateEmpModalMP";
import React, { useEffect, useState, useMemo } from "react";
import Spinner from "../../../../commons/components/spinner";
import DeleteModal from "../../../../commons/components/deleteModal";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import { Additem, DocumentUpload, Edit } from "iconsax-react";
import { EmployeesCurrentTabContext } from "../context/employeesContext";

// const getEmployeesUrl = commonConfigs.apiUrls.Employees();
const user = JSON.parse(localStorage.getItem("user"));


const pages = [
  { name: "Manage Users", href: "/users/employees", current: false },
  { name: "Employees", href: "/users/employees", current: true },
];

const tiers = [
  {value: 1, label: 'Entry-Level'}, 
  {value: 2, label: 'Mid-Level'}, 
  {value: 3, label: 'Executive-Level'},
];

const EmployeesLayout = () => {
  // Modals.
  const [isCreateModalOpen, setCreateModal] = useState(false);
  const [isUploadModalOpen, setUploadModal] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isUpdateModalMP, setUpdateModalMP] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); // Initialize current page
  const [totalData, setTotalData] = useState(0); 

  // Data.
  const [data, setData] = useState(null);

  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();


  async function getData(page) {
    console.log(page);
    await axios
      .get(commonConfigs.apiUrls.Employees(page), {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        console.log(res.data.result.data);
        setData(res.data.result.data.employees);
        setTotalData(res.data.result.data.total_pages);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    // if (load) {
      // console.log(currentPage);
      getData(currentPage).catch((err) => {
        console.log("err: " + err);
      });
      // getTotalData().catch((err) => {
      //   console.log("err: " + err);
      // });
    // }
  }, [load,currentPage]);
  
  const columns = useMemo(() => [
    {
      Header: "Name",
      accessor: "account_username",
    },
    {
      Header: "Email",
      accessor: "account_email",
    },
    {
      Header: "Mobile No",
      accessor: "account_mobile",
    },
    {
      Header: "Company Uen",
      accessor: "company_id",
    },
    {
      Header: "Emp Type",
      accessor: "employee_type",
    },
    {
      Header: "Role",
      accessor: "role",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900">
            <p>{row.original.role.role_name}</p>
          </div>
        );
      },
    },
    {
      Header: "Shift",
      accessor: (row) => row.schedule && row.schedule.shift && row.schedule.shift.shift_name ? row.schedule.shift.shift_name : '-'
    },
    {
      Header: "Shift Start",
      accessor: (row) => row.schedule && row.schedule.shift && row.schedule.shift.shift_start ? row.schedule.shift.shift_start : '-'
    },
    {
      Header: "Shift End",
      accessor: (row) => row.schedule && row.schedule.shift && row.schedule.shift.shift_end ? row.schedule.shift.shift_end : '-'
    },
    {
      Header: "Start Date",
      accessor: (row) => row.schedule && row.schedule.shift && row.schedule.date_start ? row.schedule.date_start : '-'
    },
    {
      Header: "End Date",
      accessor: (row) => row.schedule && row.schedule.shift && row.schedule.date_end ? row.schedule.date_end : '-'
    },
    {
      Header: "Department",
      accessor: "department",
    },
    {
      Header: "Job Title",
      accessor: "job_title",
    },
    {
      Header: "Work Location",
      accessor: "location",
    },
    {
      Header: "Employment Type",
      accessor: (row) => (row.employee_details && row.employee_details.length > 0) ? row.employee_details[0].employment_type_name : '-',
    },
    {
      Header: "Leave Entitlement Tier",
      accessor: (row) => {
        if (row.employee_details && row.employee_details.length > 0) {
          const leaveEntitlementTier = row.employee_details[0].leave_entitlement_tier;
          const tier = tiers.find((tier) => tier.value === leaveEntitlementTier);
          return tier ? tier.label : '-';
        }
        return '-';
      },
    },
    {
      Header: "Hourly Rates",
      accessor: (row) => (row.employee_details && row.employee_details.length > 0) ? row.employee_details[0].hourly_pay_rate : '-',
      
    },
    {
      Header: "Monthly Rates",
      accessor: (row) => (row.employee_details && row.employee_details.length > 0) ? row.employee_details[0].monthly_pay_rate : '-',
    },
    {
      Header: "Overtime Rates",
      accessor: (row) => (row.employee_details && row.employee_details.length > 0) ? row.employee_details[0].overtime_pay_rate : '-',
      
    },
    {
      Header: "Working Days Per Week",
      accessor: (row) => (row.employee_details && row.employee_details.length > 0) ? row.employee_details[0].work_days_per_week : '-',
      
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            {user.role_id == 46 ? <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalMP(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button> : <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>}
            {/* <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button> */}
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <Breadcrumbs pages={pages} />
        </div>
        {user.role_id == 46 ? <></> : <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            type="button"
            className="ml-3 inline-flex items-center px-4 py-2 text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setUploadModal(true)}
          >
            Upload Employees <DocumentUpload className="ml-3" />
          </button>
          <button
            type="button"
            className="ml-3 inline-flex items-center px-4 py-2 text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setCreateModal(true)}
          >
            Create Employee <Additem className="ml-3" />
          </button>
        </div>}
        
      </div>
      <EmployeesTabs />
      {data != null ? (
        <EmployeesCurrentTabContext.Consumer>
          {(value) => (
            <Table
              columns={columns}
              data={data}
              initialValue={value.currentTab.name}
              initialFilter="employee_type"
              filters={columns.map((column) => column.accessor)}
              pageChangeHandler={setCurrentPage} 
              totalData={totalData}
            />
          )}
        </EmployeesCurrentTabContext.Consumer>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      {isCreateModalOpen && (
        <CreateEmpModal setCreateModal={setCreateModal} load={setLoad} />
      )}
      {isUploadModalOpen && (
        <UploadEmpModal setUploadModal={setUploadModal} load={setLoad} />
      )}
      {isDeleteModal && (
        <DeleteModal
          important={true}
          setDeleteModal={setDeleteModal}
          load={setLoad}
          url={commonConfigs.apiUrls.UpdateEmployee(rowToEdit.account_id)}
          description="By deleting this employee it will delete all of its records. Are you sure you want to delete this employee?"
        />
      )}
    
      {isUpdateModal && (
        <UpdateEmpModal
          setUpdateModal={setUpdateModal}
          load={setLoad}
          row={rowToEdit}
        />
      )}

      {isUpdateModalMP && (
        <UpdateEmpModalMP
          setUpdateModalMP={setUpdateModalMP}
          load={setLoad}
          row={rowToEdit}
        />
      )}
    </div>
  );
};

export default EmployeesLayout;
