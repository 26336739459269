import { RouteType } from "./config"
import Documentation from "../pages/documentLayout"
import TestIntro from '../components/views/testIntro'
import UpdateOrderStatus from "../components/views/order_fulfillment_pages/updateOrderStatus";
import GetConsumerOrders from "../components/views/order_fulfillment_pages/getConsumerOrders";

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import IntroductionApi from "../components/views/introductionApi";
import GetOrderById from "../components/views/order_fulfillment_pages/getOrderbyId";
import RequestDishware from "../components/views/cleaning_services_modules/requestDishware";
import GetDishwareRequest from "../components/views/cleaning_services_modules/getDishwareRequest";
import UpdateDishwareStatus from "../components/views/cleaning_services_modules/updateDishwareStatus";
import GetTopFiveHealthy from "../components/views/food_nutrition_pages/getTopFiveHealthy";
import GetTopFiveUnhealthy from "../components/views/food_nutrition_pages/getTopUnhealthy";
import GetLineGraphs from "../components/views/food_nutrition_pages/getLineGraphs";
import GetPieChart from "../components/views/food_nutrition_pages/getPieChart";
import GetCaloriesTransactions from "../components/views/food_nutrition_pages/getCaloriesTransaction";

const docRoutes: RouteType[] = [
  {
    index: true,
    element: <Documentation/>,
    state: "home"
  },
  {
    path: "/documentation",
    element: <Documentation/>,
    state: "introduction",
    sidebarProps: {
      displayText: "Introduction",
      icon: <HomeOutlinedIcon />
    },
  },
  {
    path: "/documentation/order",
    element: <Documentation />,
    state: "order-fulfillment",
    sidebarProps: {
      displayText: "Order Fulfillment & Notice",
      icon: <MarkChatUnreadOutlinedIcon />
    },
    child: [
      {
        path: "/documentation/order/update-order-status",
        element: <UpdateOrderStatus />,
        state: "order-fulfillment.status",
        sidebarProps: {
          displayText: "Update Order Status"
        },
      },
      {
        path: "/documentation/order/get-consumer-orders",
        element: <GetConsumerOrders />,
        state: "order-fulfillment.consumer",
        sidebarProps: {
          displayText: "Get Consumer Orders"
        }
      },
      {
        path: "/documentation/order/get-orders-by-id",
        element: <GetOrderById />,
        state: "order-fulfillment.ordid",
        sidebarProps: {
          displayText: "Get Orders by ID"
        }
      }
    ]
  },
  // {
  //   path: "/documentation#test",
  //   element: <TestIntro/>,
  //   state: "e-payments",
  //   sidebarProps: {
  //     displayText: "ePayments",
  //     icon: <PaidOutlinedIcon />
  //   },
  // },
  {
    path: "/documentation/nutrition",
    element: <GetTopFiveHealthy />,
    state: "food-nutrition",
    sidebarProps: {
      displayText: "Food Nutrition",
      icon: <RestaurantMenuOutlinedIcon />
    },
    child: [
      {
        path: "/documentation/nutrition/get-top-5-healthy",
        element: <GetTopFiveHealthy />,
        state: "food-nutrition.gethealthy",
        sidebarProps: {
          displayText: "Get Top Healthy"
        },
      },
      {
        path: "/documentation/nutrition/get-top-5-unhealthy",
        element: <GetTopFiveUnhealthy />,
        state: "food-nutrition.getunhealthy",
        sidebarProps: {
          displayText: "Get Top Unhealthy"
        }
      },
      {
        path: "/documentation/nutrition/get-by-line-graphs",
        element: <GetLineGraphs />,
        state: "food-nutrition.getgraph",
        sidebarProps: {
          displayText: "Get by Line Graphs"
        }
      },
      {
        path: "/documentation/nutrition/get-by-pie-chart",
        element: <GetPieChart />,
        state: "food-nutrition.getchart",
        sidebarProps: {
          displayText: "Get by Pie Chart"
        }
      },
      {
        path: "/documentation/nutrition/get-calories-transactions",
        element: <GetCaloriesTransactions />,
        state: "food-nutrition.calorie",
        sidebarProps: {
          displayText: "Get Calorie Transactions"
        }
      }
    ]
  },
  // {
  //   path: "/documentation#test",
  //   element: <TestIntro/>,
  //   state: "digital-communications",
  //   sidebarProps: {
  //     displayText: "Digital Communications",
  //     icon: <ForumOutlinedIcon />
  //   },
  // },
  // {
  //   path: "/documentation#test",
  //   element: <TestIntro/>,
  //   state: "anonymized-identitu",
  //   sidebarProps: {
  //     displayText: "Anonymized Identity",
  //     icon: <PersonOutlinedIcon />
  //   },
  // },
  // {
  //   path: "/documentation#test",
  //   element: <TestIntro/>,
  //   state: "community-engagement",
  //   sidebarProps: {
  //     displayText: "Community Engagement",
  //     icon: <Diversity1OutlinedIcon />
  //   },
  // },
  // {
  //   path: "/documentation#test",
  //   element: <TestIntro/>,
  //   state: "integrated-merchant-service",
  //   sidebarProps: {
  //     displayText: "Integrated Merchant Service",
  //     icon: <StorefrontOutlinedIcon />
  //   },
  // },
  // {
  //   path: "/documentation#test",
  //   element: <TestIntro/>,
  //   state: "human-resource",
  //   sidebarProps: {
  //     displayText: "Human Resource",
  //     icon: <GroupsOutlinedIcon />
  //   },
  // },
  // {
  //   path: "/documentation#test",
  //   element: <TestIntro/>,
  //   state: "facilities",
  //   sidebarProps: {
  //     displayText: "Facilities",
  //     icon: <LocalConvenienceStoreOutlinedIcon />
  //   },
  // },
  {
    path: "/documentation/cleaning",
    element: <TestIntro />,
    state: "cleaning-services",
    sidebarProps: {
      displayText: "Cleaning services",
      icon: <CleaningServicesOutlinedIcon />
    },
    child: [
      {
        path: "/documentation/cleaning/request-dishware",
        element: <RequestDishware />,
        state: "cleaning-services.requestdishware",
        sidebarProps: {
          displayText: "Request Dishware"
        },
      },
      {
        path: "/documentation/cleaning/get-dishware-requests",
        element: <GetDishwareRequest />,
        state: "cleaning-services.getrequest",
        sidebarProps: {
          displayText: "Get Dishware Requests"
        }
      },
      {
        path: "/documentation/cleaning/update-dishware-status",
        element: <UpdateDishwareStatus />,
        state: "cleaning-services.updatestatus",
        sidebarProps: {
          displayText: "Update Dishware Status"
        }
      }
    ]
  },
];

export default docRoutes;