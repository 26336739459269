import { CompanyContextProvider } from "../context/companyContext";
import CompanyLayout from "./layouts/companyLayout";

const CompanyPage = () => {
  return (
    <CompanyContextProvider>
      <CompanyLayout />
    </CompanyContextProvider>
  );
};

export default CompanyPage;
