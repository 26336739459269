import axios from "axios";
import { format } from "date-fns";
import commonConfigs from "../../../../config/config";
import FeedbackTabs from "../components/feedbackTabs";
import Table from "../../../../commons/components/table";
import React, { useEffect, useMemo, useState } from "react";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import { FeedbackCurrentTabContext } from "../context/feedbackContext";
import { Edit } from "iconsax-react";
import Spinner from "../../../../commons/components/spinner";
import UpdateFeedbackModal from "../components/updateFeedbackModal";

const pages = [
  { name: "Action Items", href: "#", current: false },
  { name: "User Feedback", href: "/action/feedback", current: true },
];

const getFeedbacksUrl = commonConfigs.apiUrls.GetFeedbacks();

const FeedbackLayout = () => {

  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState();

  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);

  async function getFeedbacks() {
    await axios
      .get(getFeedbacksUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (load) {
      getFeedbacks().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Feedback Id",
      accessor: "user_feedback_id",
    },
    {
      Header: "Title",
      accessor: "user_feedback_title",
    },
    {
      Header: "Description",
      accessor: "user_feedback_desc",
    },
    {
      Header: "Email",
      accessor: "user_feedback_email",
    },
    {
      Header: "Mobile",
      accessor: "user_feedback_mobile",
    },
    {
      Header: "Status",
      accessor: "user_feedback_status",
      Cell: ({ row }) => {
        let status = row.original.user_feedback_status;
        if (status == 'Pending' || status == '-') {
          return <div className="text-sm text-orange-700">Pending</div>;
        } 
        else if (status == 'Resolved'){
          return <div className="text-sm text-green-700">Resolved</div>;
        }
        else {
          return <div className="text-sm text-red-700">Cancelled</div>;
        }
      },
    },
    // {
    //   Header: "Responder",
    //   accessor: "responder_id",
    // },
    {
      Header: "Responder Remarks",
      accessor: "responder_remarks",
    },
    {
      Header: "Created On",
      accessor: "created_on",
      Cell: ({ row }) => {
        row.values.created_on = format(
          new Date(row.values.created_on),
          "dd MMM yyyy HH:mm"
        );
        var date = row.values.created_on;
        if (date) {
          return <div className="text-sm text-gray-900">{date}</div>;
        }
      },
    },
    {
      Header: "Response On",
      accessor: "responded_on",
      Cell: ({ row }) => {
        if (row.values.responded_on) {
          const formattedDate = format(
            new Date(row.values.responded_on),
            "dd MMM yyyy HH:mm"
          );
          return <div className="text-sm text-gray-900">{formattedDate}</div>;
        } else {
          return <div className="text-sm text-gray-900">-</div>;
        }
      },
    },    
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalOpen(true);
                console.log(row.original);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      <FeedbackTabs />

      {data != null ? (
        <FeedbackCurrentTabContext.Consumer>
          {(value) => (
            <Table
              columns={columns}
              data={data}
              filters={columns.map((column) => column.accessor)}
              initialValue={value.currentTab.name}
              initialFilter = "user_feedback_status"
            />
          )}
        </FeedbackCurrentTabContext.Consumer>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      {isUpdateModalOpen && (
        <UpdateFeedbackModal
          setUpdateModal={setUpdateModalOpen}
          load={setLoad}
          row={rowToEdit}
        />
      )}
    </div>
  );
};

export default FeedbackLayout;
