import PrizeTabs from "../components/prizeTabs";
import { PrizeCurrentTabContext } from "../context/prizeContext";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import ItemPrizeLayout from "../context/itemPrizeLayout";
import EVoucherPrizeLayout from "../context/eVoucherPrizeLayout";
import { useContext } from "react";

const pages = [
  { name: "Configurations", href: "/configurations/prizes", current: false },
  {
    name: "Prizes",
    href: "/configurations/prizes",
    current: true,
  },
];

const PrizeLayout = () => {
  const { currentTab } = useContext(PrizeCurrentTabContext);

  return (
    <div>
      <div className="w-full p-6">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <Breadcrumbs pages={pages} />
          </div>
        </div>
        <div className="mt-8 ">
          <PrizeTabs />
          {currentTab.name == "Item Prize" ? (
            <ItemPrizeLayout />
          ) : (
            <EVoucherPrizeLayout />
          )}
        </div>
      </div>
    </div>
  );
};

export default PrizeLayout;
