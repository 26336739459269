import axios from "axios";
import { Link } from "react-router-dom";
import { Cards } from "iconsax-react";
import { Edit } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import Table from "./employeeTable";
import React, { useState, useMemo, useEffect } from "react";
import UpdateEmpTITO from "./updateEmpTITO";
import UpdateEmpTITOAM from "./updateEmpTITOAM";
import UpdateEmpTITOOM from "./updateEmpTITOOM";

const user = JSON.parse(localStorage.getItem("user"));

const LocationsLayout = ({ date, location }) => {
  console.log("date", date);
  console.log("location", location);

  // Check if location is undefined or empty and set it to an empty string if true
  if (typeof location === 'undefined' || location === '') {
    location = '';
  }

  const [load, setLoad] = useState(true);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isUpdateModalAM, setUpdateModalAM] = useState(false);
  const [isUpdateModalOM, setUpdateModalOM] = useState(false);
  const [rowToEdit, setRowToEdit] = useState();
  
  const [employeeTimecards, setEmployeeTimecards] = useState([]);

  async function fetchEmployeeTimecards() {
  
    const GetAllTimecards = commonConfigs.apiUrls.GetAllTimecards(date, location);

    await axios
      .get(GetAllTimecards, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log("fetchEmployeeTimecards", res.data.result.data);
        setEmployeeTimecards(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchEmployeeTimecards().catch((err) => {
      console.log("err: " + err);
      });
  }, [location]);

  const columns = useMemo(() => [
    {
      Header: "Employee Type",
      accessor: "employee_type_name",
    },
    {
      Header: "Name",
      accessor: "account_username",
    },
    {
      Header: "Shift Start",
      accessor: "shift_start",
    },
    {
      Header: "Shift End",
      accessor: "shift_end",
    },
    {
      Header: "Time In",
      accessor: ({ timestamp_in }) => timestamp_in && timestamp_in !== "-" ? timestamp_in.substring(11, 19) : "-",
    },    
    {
      Header: "Time Out",
      accessor: ({ timestamp_out }) => timestamp_out && timestamp_out !== "-" ? timestamp_out.substring(11, 19) : "-",
    },   
    {
      Header: "Duration",
      accessor: "attendance_duration",
    },
    {
      Header: "Attendance Status",
      Cell: ({ row }) => {
        const timeInDatetime = row.original.timestamp_in;
        const timeOutDatetime = row.original.timestamp_out;
        const attendanceDuration = row.original.attendance_duration;
        const leaveRequest = row.original.leave_request; // Assuming 'leave_request' is a property in the row data
        
        if (leaveRequest === '-') {
          if (timeInDatetime === '-') {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                Absent
              </div>
            );
          } else if (timeInDatetime !== '-' && timeOutDatetime === '-') {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                Pending
              </div>
            );
          } else if (attendanceDuration < 8) {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-amber-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                {"<8Hrs"}
              </div>
            );
          } else if (attendanceDuration >= 8) {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                {">8Hrs"}
              </div>
            );
          }
        }
        else{
          return (
            <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
              Leave
            </div>
          );
        }
      },
    },
    {
      Header: "Deduction",
      accessor: "minutes_deduction",
      cell: ({ value }) => value && value !== "-" ? value : 0
    },
    {
      Header: "Surplus",
      accessor: "minutes_addition",
      cell: ({ value }) => value && value !== "-" ? value : 0
    },  
    {
      Header: "Attendance Reason",
      accessor: "attendance_reason",
      Cell: ({ value }) => value ? value : '-'
    },
    {
      Header: "Hourly Rate",
      accessor: "hourly_pay_rate",
      Cell: ({ value }) => {
        if (!value || value === 0) {
          return "-";
        } else {
          const stringValue = String(value);
          const truncatedValue = stringValue.substring(0, stringValue.indexOf('.') + 4);
          return truncatedValue;
        }
      },
    },
    {
      Header: "Monthly Rate",
      accessor: "monthly_pay_rate",
      Cell: ({ value }) => {
        if (!value || value === 0) {
          return "-";
        } else {
          const stringValue = String(value);
          const truncatedValue = stringValue.substring(0, stringValue.indexOf('.') + 4);
          return truncatedValue;
        }
      },
    },
    {
      Header: "Overtime Rate",
      accessor: "overtime_pay_rate",
      Cell: ({ value }) => {
        if (!value || value === 0) {
          return "-";
        } else {
          const stringValue = String(value);
          const truncatedValue = stringValue.substring(0, stringValue.indexOf('.') + 4);
          return truncatedValue;
        }
      },
    },
    {
      Header: "Basic Salary",
      accessor: "basic_salary",
      Cell: ({ value }) => {
        if (!value || value === 0) {
          return "-";
        } else {
          const stringValue = String(value);
          const truncatedValue = stringValue.substring(0, stringValue.indexOf('.') + 4);
          return truncatedValue;
        }
      },
    },
    {
      Header: "Gross Salary",
      accessor: "gross_salary",
      Cell: ({ value }) => {
        if (!value || value === 0) {
          return "-";
        } else {
          const stringValue = String(value);
          const truncatedValue = stringValue.substring(0, stringValue.indexOf('.') + 4);
          return truncatedValue;
        }
      },
    },
    {
      Header: "Leave Requested",
      accessor: "leave_request",
    },  
    {
      Header: "status",
      accessor: "attendance_status",
      Cell: ({ row }) => {
        let status = row.original.attendance_status;
        if (status == 0 || status == '-') {
          return <div className="text-sm text-orange-700">Pending</div>;
        } 
        else if (status == 1){
          return <div className="text-sm text-green-700">Confirmed</div>;
        }
        else {
          return <div className="text-sm text-red-700">Declined</div>;
        }
      },
    },
    {
      Header: "Remarks",
      accessor: "supervisor_remarks",
    },  
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => value ? value : '-'
    },
    {
      Header: "Last Updated On",
      accessor: ({ updated_on }) => (updated_on) != "-" ? (updated_on.substring(0, 10) + " " + updated_on.substring(11, 16)) : "-",
    },
    {
      id: "details",
      Cell: ({ row }) => {
        let employeeObj = {
          id: row.original.account_id,
          name: row.original.account_username,
        };

        return (
          <div className=" text-gray-900  flex space-x-1 ">
            <button type="button">
              <Link
                to={{
                  pathname: `/schedule/date/${date}/${location}/employeedetails/${employeeObj.id}`,
                }}
              >
                <Cards aria-hidden="true" className="w-6 h-6" />
              </Link>
            </button>
          </div>
        );
      },
    },

    {
      id: "edit",
      Cell: ({ row }) => {
        if (row.original.activity === "Leave") {
          // Return null to hide the edit button
          return null;
        }
  
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ]);

  const columnsManager = useMemo(() => [
    {
      Header: "Employee Type",
      accessor: "employee_type_name",
    },
    {
      Header: "Name",
      accessor: "account_username",
    },
    {
      Header: "Shift Start",
      accessor: "shift_start",
    },
    {
      Header: "Shift End",
      accessor: "shift_end",
    },
    {
      Header: "Time In",
      accessor: ({ timestamp_in }) => timestamp_in && timestamp_in !== "-" ? timestamp_in.substring(11, 19) : "-",
    },    
    {
      Header: "Time Out",
      accessor: ({ timestamp_out }) => timestamp_out && timestamp_out !== "-" ? timestamp_out.substring(11, 19) : "-",
    },   
    {
      Header: "Duration",
      accessor: "attendance_duration",
    },
    {
      Header: "Attendance Status",
      Cell: ({ row }) => {
        const timeInDatetime = row.original.timestamp_in;
        const timeOutDatetime = row.original.timestamp_out;
        const attendanceDuration = row.original.attendance_duration;
        const leaveRequest = row.original.leave_request; // Assuming 'leave_request' is a property in the row data
        
        if (leaveRequest === '-') {
          if (timeInDatetime === '-') {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                Absent
              </div>
            );
          } else if (timeInDatetime !== '-' && timeOutDatetime === '-') {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                Pending
              </div>
            );
          } else if (attendanceDuration < 8) {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-amber-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                {"<8Hrs"}
              </div>
            );
          } else if (attendanceDuration >= 8) {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                {">8Hrs"}
              </div>
            );
          }
        }
        else{
          return (
            <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
              Leave
            </div>
          );
        }
      },
    },
    {
      Header: "Attendance Reason",
      accessor: "attendance_reason",
      Cell: ({ value }) => value ? value : '-'
    },
    {
      Header: "Leave Requested",
      accessor: "leave_request",
    },  
    {
      Header: "status",
      accessor: "attendance_status",
      Cell: ({ row }) => {
        let status = row.original.attendance_status;
        if (status == 0 || status == '-') {
          return <div className="text-sm text-orange-700">Pending</div>;
        } 
        else if (status == 1){
          return <div className="text-sm text-green-700">Confirmed</div>;
        }
        else {
          return <div className="text-sm text-red-700">Declined</div>;
        }
      },
    },
    {
      Header: "Remarks",
      accessor: "supervisor_remarks",
    },  
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => value ? value : '-'
    },
    {
      Header: "Last Updated On",
      accessor: ({ updated_on }) => (updated_on) != "-" ? (updated_on.substring(0, 10) + " " + updated_on.substring(11, 16)) : "-",
    },
    {
      id: "details",
      Cell: ({ row }) => {
        let employeeObj = {
          id: row.original.account_id,
          name: row.original.account_username,
        };

        return (
          <div className=" text-gray-900  flex space-x-1 ">
            <button type="button">
              <Link
                to={{
                  pathname: `/schedule/date/${date}/${location}/employeedetails/${employeeObj.id}`,
                }}
              >
                <Cards aria-hidden="true" className="w-6 h-6" />
              </Link>
            </button>
          </div>
        );
      },
    },

    {
      id: "edit",
      Cell: ({ row }) => {
        if (row.original.activity === "Leave") {
          // Return null to hide the edit button
          return null;
        }
        
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            {user.role_id == 47 ? <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalAM(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button> : <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalOM(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>}
            
          </div>
        );
      },
    },
  ]);

  console.log(user.role_id);
  if (user.role_id == 47) {
    return (
      <div className="w-full desktop:p-6">
        <Table
          columns={columnsManager}
          data={employeeTimecards}
          filters={columnsManager.map((column) => column.accessor)}
        />
        {isUpdateModalAM && (
          <UpdateEmpTITOAM
            setUpdateModalAM={setUpdateModalAM}
            load={setLoad}
            row={rowToEdit}
          />
        )}
      </div>
    );
  } else if (user.role_id == 48 || user.role_id == 44) {
    return (
      <div className="w-full desktop:p-6">
        <Table
          columns={columnsManager}
          data={employeeTimecards}
          filters={columnsManager.map((column) => column.accessor)}
        />
        {isUpdateModalOM && (
          <UpdateEmpTITOOM
            setUpdateModalOM={setUpdateModalOM}
            load={setLoad}
            row={rowToEdit}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="w-full desktop:p-6">
        <Table
          columns={columns}
          data={employeeTimecards}
          filters={columns.map((column) => column.accessor)}
        />
        {isUpdateModal && (
          <UpdateEmpTITO
            setUpdateModal={setUpdateModal}
            load={setLoad}
            row={rowToEdit}
          />
        )}
      </div>
    );  
  }
};

export default LocationsLayout;
