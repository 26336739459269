import { useState } from "react";
import { Edit, Trash } from "iconsax-react";
import commonConfigs from "../../../../../config/config";
import Table from "../../../../../commons/components/table";
import Spinner from "../../../../../commons/components/spinner";
import DeleteModal from "../../../../../commons/components/deleteModal";
import UpdatePreferenceModal from "../../components/updatePreferenceModal";

export default function PreferenceLayout({ data, load, setLoad, merchantId }) {
  console.log("PreferenceLayout");
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const columns = [
    {
      Header: "Preference Name",
      accessor: "preference_name",
    },
    {
      Header: "Preference Options",
      accessor: "preference_options",
      Cell: ({ row }) => {
        let preference_options = row.original.preference_options.map(
          (preference) => preference.preference_option_name
        );

        return (
          <div className="text-sm text-gray-900">
            {preference_options.join(", ")}
          </div>
        );
      },
    },
    {
      Header: "Tagged Products",
      accessor: "tagged_products",
      Cell: ({ row }) => {
        let tagged_products = row.original.tagged_products.map(
          (product) => product.product_name
        );

        return (
          <div className="text-sm text-gray-900">
            {tagged_products.join(", ")}
          </div>
        );
      },
    },
    {
      Header: "Items",
      id: "expander",
      Cell: ({ row }) => {
        return (
          <div>
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalOpen(true);
                setSelectedRow(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            {localStorage.getItem("roleid") == 2 || localStorage.getItem("roleid") == 5 ?
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModalOpen(true);
                setSelectedRow(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
            : <></> }
          </div>
        );
      },
    },
  ];

  if (data) {
    return (
      <>
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
        {isUpdateModalOpen && (
          <UpdatePreferenceModal
            merchantId={merchantId}
            setUpdateModal={setUpdateModalOpen}
            load={setLoad}
            row={selectedRow}
          />
        )}

        {isDeleteModalOpen && (
          <DeleteModal
            setDeleteModal={setDeleteModalOpen}
            load={setLoad}
            url={commonConfigs.apiUrls.UpdatePreferences(
              merchantId,
              selectedRow.preference_id
            )}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
        <div className="flex place-content-center w-full">
          <Spinner className="w-20 h-20" />
        </div>
      </div>
    );
  }
}
