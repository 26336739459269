import axios from "axios";
import {Edit, Trash, Additem } from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import commonConfigs from "../../../../config/config";
import Table from "../../../../commons/components/table";
import Spinner from "../../../../commons/components/spinner";
import CreateVoucherPrizeModal from "../components/createVoucherPrizeModal";
import DeleteModal from "../../../../commons/components/deleteModal";
import UpdateVoucherPrizeModal from "../components/updateVoucherPrizeModal"
import { format } from "date-fns";


const GetEVoucherPrizesUrl = commonConfigs.apiUrls.GetEVoucherPrizes();

const EVoucherPrizeLayout = () => {
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

    useEffect(() => {
      async function getData() {
        await axios
          .get(GetEVoucherPrizesUrl, { headers: commonConfigs.apiHeader })
          .then((res) => {
            console.log(res);
            setData(res.data.result.data);
            setLoad(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (load) {
        getData().catch((err) => {
          console.log("err: " + err);
        });
      }
    }, [load]);

  const columns = useMemo(() => [
    {
      Header: "eVoucher Code",
      accessor: "org_voucher.voucher_code",
    },
    {
      Header: "eVoucher Prize Name",
      accessor: "prize_name",
    },
    {
      Header: "Prize Instruction",
      accessor: "prize_instruction",
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
            {/* {isUpdateModalOpen && (
              <UpdateVoucherPrizeModal
                setOpenModal={setUpdateModalOpen}
                load={setLoad}
                row={rowToEdit}
              />
            )} */}
          </div>
        );
      },
    },
  ]);

  return (
    <div>
        <div className=" flex flex-row-reverse">
            <button
            type="button"
            className="ml-3 inline-flex items-center px-4 py-2 text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setCreateModalOpen(true)}
            >
            CREATE EVOUCHER PRIZE <Additem className="ml-3" />
            </button>
            {isCreateModalOpen && (
          <CreateVoucherPrizeModal
            setOpenModal={setCreateModalOpen}
            load={setLoad}
          ></CreateVoucherPrizeModal>
          )}
        </div>
      {data != null ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      {isUpdateModalOpen && (
        <UpdateVoucherPrizeModal
          setOpenModal={setUpdateModalOpen}
          load={setLoad}
          row={rowToEdit}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          setDeleteModal={setDeleteModalOpen}
          load={setLoad}
          url={commonConfigs.apiUrls.UpdatePrize(rowToEdit.prize_id)}
        />
      )}
    </div>
  );
};

export default EVoucherPrizeLayout;
