import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";

import BillingDonut from "../../hierarchy_modules/components/billingDonut";

const user = JSON.parse(localStorage.getItem("user"))

export default function ResponseCard() {
  const [isApplying, setIsApplying] = useState(false);

  const [responseTime, setResponseTime] = useState(null);

  const [selectedOption, setSelectedOption] = useState("8");

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1 to get the current month.

  // Calculate the first day of the current month.
  const firstDayOfMonth = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;

  // Calculate the last day of the current month.
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  const lastDayOfMonthFormatted = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${lastDayOfMonth.getDate()}`;

  const [startdate, setStartDate] = useState(firstDayOfMonth);
  const [enddate, setEndDate] = useState(lastDayOfMonthFormatted);

  const [startdateFilter, setStartDateFilter] = useState(startdate);
  const [enddateFilter, setEndDateFilter] = useState(enddate);

  const applyButtonEnabledClass = "bg-red-600 hover:bg-red-700";
  const applyButtonDisabledClass = "bg-red-400 cursor-not-allowed";

  const [load, setLoad] = useState(true);

  async function fetchResponseTime() {

    var syscodeid = 8;

    if (selectedOption !== 8) {
      syscodeid = selectedOption;
    }

    let getUserIncidentRespondtime = commonConfigs.apiUrls.GetUserIncidentRespondtime(syscodeid, startdateFilter, enddateFilter);
    if(user.access_multiple_location == 0 && user.location_id) {
      getUserIncidentRespondtime = getUserIncidentRespondtime + `&location_id=${user.location_id}`
    }

    await axios
    .get(getUserIncidentRespondtime, { headers: commonConfigs.apiHeader })
    .then((res) => {
      console.log(res.data.result.data);
      
      var response = res.data.result.data;

      var responseTime = {
        total_responses: response.total_responses,
        types: [
          {
            fb_type: "<= 3 working days",
            feedback: response.responses_within_3_days
          },
          {
            fb_type: "<= 5 working days",
            feedback: response.responses_within_5_days
          },
          {
            fb_type: "<= 21 working days",
            feedback: response.responses_within_21_days
          }
  
        ]
      }
      setResponseTime(responseTime);
      setIsApplying(false);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const ResponseOption = [
    { value: "8", label: "User Feedback" },
    { value: "9", label: "Incident Report" },
  ];

  const applyFilter = async () => {
    setIsApplying(true); // Disable the "APPLY" button
    setLoad(true);
    setStartDateFilter(startdate);
    setEndDateFilter(enddate);

    // Fetch data based on the selected option
    if (selectedOption === "8" || selectedOption === "9") {
      await fetchResponseTime();
    } else {
      // Handle other cases if needed
    }
  };

  useEffect(() => {
    fetchResponseTime().catch((err) => {
      console.log("err: " + err);
    });
  }, [load,selectedOption, startdateFilter, enddateFilter]);
 
  if (load) {
    return (
      <div>
        
        <h3 className="text-xl">
          User Feedback | Incident Report Response Time
        </h3>

        <div style={{ backgroundColor: '#FFF', padding: '20px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
            <div>
              <h6>Start Date:</h6>
              <input 
                data-inline="true" 
                type="date" 
                style={{ width: "100%", padding: "10px", borderRadius: "8px" }} 
                value={startdate} 
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </div>
            <div>
              <h6>End Date:</h6>
              <input 
                data-inline="true" 
                type="date" 
                style={{ width: "100%", padding: "10px", borderRadius: "8px" }}
                value={enddate} 
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </div>
            <div>
              <h6></h6>
              <select
                style={{ width: "120%", padding: "10px", borderRadius: "8px" }}
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                {ResponseOption.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div></div>
            <div></div>
            <div>
              <h6></h6>
              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  isApplying ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => applyFilter()}
                disabled={isApplying} // Disable the button when isApplying is true
              >
                {isApplying ? "Applying..." : "APPLY"}
              </button>
            </div>
          </div>
        </div>

        <div className="mt-6 mb-2">
          {console.log('donut data', responseTime)}
          {responseTime && (
            <BillingDonut
              key={selectedOption} // Add key prop here
              title="User Feedback | Incident Report Response Time"
              data={responseTime.types.map((item) => ({
                name: item.fb_type,
                value: item.feedback,
              }))}
              subtitle={responseTime.total_responses}
            />
          )}
        </div>

      </div>   
    );
  } 
  else {
    return (
      <div>
        loading...
      </div>
    );
  }
}
