import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";

import BillingDonut from "../../hierarchy_modules/components/billingDonut";

export default function LoyaltyCard() {

  const [totalLoyalty, setTotalLoyalty] = useState(null);
  const [loyaltyId, setLoyaltyId] = useState('all');
  const [loyaltyList, setLoyaltyList] = useState([]);

  const [load, setLoad] = useState(true);
  
  async function fetchSalesFilters() {

    var locationId = 'b7fb763c-eadb-478c-ae87-fa4f3a71b486';
    const getMerchantsSalesFilterUrl = commonConfigs.apiUrls.GetFilterSalesParams(locationId);

    await axios
      .get(getMerchantsSalesFilterUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        var response = res.data.result.data;
        var loyaltyProgrammes = response.loyalty_programmes;

        setLoyaltyList(loyaltyProgrammes);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function fetchTotalLoyalty() {
    var loyaltyFilter = 1;
  
    if (loyaltyId !== "all") {
      loyaltyFilter = loyaltyId;
    }

    const GetTotalLoyalty = commonConfigs.apiUrls.GetTotalLoyalty(loyaltyFilter);

    await axios
      .get(GetTotalLoyalty, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log("data",res.data.result.data);

        var arr = [
          {
            total: res.data.result.data.loyalty_points_awarded,
            types: [
              {
                type: "balance",
                points: res.data.result.data.loyalty_points_balance
              },
              {
                type: "expired",
                points: res.data.result.data.loyalty_points_expired
              },
              {
                type: "redeemed",
                points: res.data.result.data.loyalty_points_redeemed
              },
            ]
          }
        ];
          console.log("formated data", arr[0]);
          setTotalLoyalty(arr[0]);

      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchSalesFilters().catch((err) => {
      console.log("err: " + err);
    });
    fetchTotalLoyalty().catch((err) => {
      console.log("err: " + err);
    });
  }, [load, loyaltyId]);

  return (
    <div>
      
      <h3 className="text-xl">Total Loyalty Points</h3>

      <div style={{ width: '100%', padding: '10px' }}>
        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <h6 style={{ marginBottom: '0px' }}>Loyalty Programme</h6>
        </div>
        <select
          style={{ width: '100%', padding: '10px', borderRadius: '8px' }}
          name="selectedType"
          id="selectedComms"
          value={loyaltyId}
          onChange={(e) => {
            setLoyaltyId(e.target.value);
            const selectedLoyalty = loyaltyList.find((comm) => comm.loyalty_id === e.target.value);
            setTotalLoyalty(selectedLoyalty);
          }}
        >
          {loyaltyList && loyaltyList.length > 0 && loyaltyList.map(
            (comm, index) => {
              return <option key={index} value={comm.loyalty_id}>{comm.loyalty_name}</option>
            })}
        </select>
      </div>
      
      <div className="mt-8 mb-2">
        {totalLoyalty && (
          <BillingDonut
            title="Total Loyalty Points"
            data={totalLoyalty.types.map((item) => ({
              name: item.type,
              value: item.points,
            }))}
            subtitle={totalLoyalty.total ? totalLoyalty.total : 'No Data'}
          />
        )}
      </div>

    </div>   
  );
}
