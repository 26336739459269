import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";

const user = JSON.parse(localStorage.getItem("user"))

export default function KPWSCard() {

  const [prizeWheelSpins, setPrizeWheelSpins] = useState([]);
  
  async function fetchPrizeWheelSpins() {

    let getPrizeWheelStatistics = commonConfigs.apiUrls.GetPrizeWheelStatistics('', '');
    if(user.access_multiple_location == 0 && user.location_id) {
      getPrizeWheelStatistics = getPrizeWheelStatistics + `&location_id=${user.location_id}`
    }

    await axios
    .get(getPrizeWheelStatistics, { headers: commonConfigs.apiHeader })
    .then((res) => {
      console.log(res.data.result.data);
      setPrizeWheelSpins(res.data.result.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    fetchPrizeWheelSpins().catch((err) => {
      console.log("err: " + err);
    });
  }, []);

  return (
    <div>
      <div className="flex justify-between">
        <h3 className="text-lg">Kiosk Prize Wheel Spins</h3>
        <div className="text-xs text-gray-500">Today/Total</div>
      </div>

      <div style={{ marginTop: '10px' }}>
        {prizeWheelSpins.map((prizewheel) => (
          <div key={prizewheel.outlet}>
            <div className="flex justify-between mt-4 space-x-8">
              <div className="text-sm">{prizewheel.location_name}</div>
              <div className="flex flex-grow gap-x-4 justify-end">
                <div className="text-sm">
                  {prizewheel.daily_spins !== null && prizewheel.daily_spins !== 0
                    ? prizewheel.daily_spins
                    : 0}/{prizewheel.total_spins_to_date !== null && prizewheel.total_spins_to_date !== 0
                    ? prizewheel.total_spins_to_date
                    : 0}
                </div>
              </div>
            </div>
            <div className="w-full border-t border-gray-300 mt-4"></div>
          </div>
        ))}
      </div>

    </div>   
  );
}
