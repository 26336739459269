import React, { useState, useMemo } from "react";
import { Edit, Trash, Additem } from "iconsax-react";
import Table from "../../../../commons/components/table";
import CreateMaintenancesModal from "../components/createMaintenancesModal";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import DeleteModal from "../../../../commons/components/deleteModal";
import { Link, useParams, useLocation } from "react-router-dom";
import UpdateMaintenancesModal from "../components/updateMaintenancesModal";

const getData = () => [
  {
    location: "Bukit Canberra",
    title: "Inspection",
    dueIn: "Due In 3 Days ",
    dueOn: "date time here",
    status: "pending",
    remarks: "-",
    scheduleby: "Admin",
  },
  {
    location: "Bukit Canberra",
    title: "Inspection",
    dueIn: "Due In 5 Days ",
    dueOn: "date time here",
    status: "pending",
    remarks: "-",
    scheduleby: "Admin",
  },
];

const MaintenanceLayout = () => {
  
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  // const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  const locationName = useParams().locationName;

  const pages = [
    { name: "Action Items", href: "#", current: false },
    { name: "Locations", href: "/action/maintenanceslocations", current: false },
    {
      name: locationName, href: `/action/maintenanceslocations/${locationName}/maintenances`, current: true,
    },
  ];

  const [isOpen, setOpen] = useState(false);
  const data = React.useMemo(() => getData(), []);
  //   const [rowToEdit, setRowToEdit] = useState();

  const columns = useMemo(() => [
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Due In",
      accessor: "dueIn",
    },
    {
      Header: "Due On",
      accessor: "dueOn",
    },
    {
      Header: "Completion Status",
      accessor: "status",
    },
    {
      Header: "Remarks",
      accessor: "remarks",
    },
    {
      Header: "Scheduled By",
      accessor: "scheduleby",
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            {localStorage.getItem("roleid") == 2 || localStorage.getItem("roleid") == 5 ?
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
            : <></> }
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="mt-4 flex mobile:pt-4  mobile: pb-4 mobile:space-x-2">
          <button
            type="button"
            className="ml-3 inline-flex items-center text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setOpen(true)}
          >
            Schedule Inspection <Additem className="ml-3" />
          </button>
        </div>
      </div>
      {isOpen && <CreateMaintenancesModal setOpenModal={setOpen} />}
      {data != null ? (
        <Table
            columns={columns}
            data={data}
            filters={columns.map((column) => column.accessor)}
          />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      {isUpdateModalOpen && (
        <UpdateMaintenancesModal
          setUpdateModal={setUpdateModalOpen}
          load={setLoad}
          row={rowToEdit}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          setDeleteModal={setDeleteModalOpen}
          load={setLoad}
          // url={commonConfigs.apiUrls.UpdateLeaveEntitlement(rowToEdit.leave_entitlement_id)}
        />
      )}
    </div>
  );
};

export default MaintenanceLayout;
