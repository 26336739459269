import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";

import BillingDonut from "./billingDonut";

export default function DivisionCard() {

  const [divisionData, setDivisionData] = useState(null);

  const [divisionId, setDivisionId] = useState('all');
  const [divisionTypeList, setDivisionTypeList] = useState([]);

  const [load, setLoad] = useState(true);

  async function fetchDivisionList() {

    const getDivisions = commonConfigs.apiUrls.getDivisions();


    await axios
      .get(getDivisions, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setDivisionTypeList(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function fetchDivision() {

    var DivisionFilter = 1;
  
    if (divisionId !== "all") {
      DivisionFilter = divisionId;
    }

    const getDivisionsData = commonConfigs.apiUrls.getDivisionsData(DivisionFilter);

    await axios
      .get(getDivisionsData, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);

        var DivisionData = {
          division_name: res.data.result.data.division_name,
          DivisionAmt: [
            {
              Type: "GPO Total Amt",
              Amt: res.data.result.data.gpo_total_amt
            },
            {
              Type: "Nets Total Amt",
              Amt: res.data.result.data.nets_total_amt
            },
            {
              Type: "Dishwashing Total Amt",
              Amt: res.data.result.data.dishwashing_total_amt
            },
          ]
        };

        setDivisionData(DivisionData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchDivisionList().catch((err) => {
      console.log("err: " + err);
    });
    fetchDivision().catch((err) => {
      console.log("err: " + err);
    });
  }, [load, divisionId]);
 
  if (load) {
    return (
      <div>
        
        <h3 className="text-xl">Billing Overview</h3>
                
        <div style={{ width: '100%', padding: '10px' }}>
          <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <h6 style={{ marginBottom: '0px' }}>Divisions:</h6>
          </div>
          <select
            style={{ width: '100%', padding: '10px', borderRadius: '8px' }}
            name="selectedType"
            id="selectedComms"
            value={divisionId}
            onChange={(e) => {
              setDivisionId(e.target.value);
              const selectedDivision = divisionTypeList.find((comm) => comm.division_id === e.target.value);
              setDivisionData(selectedDivision);
            }}
          >
            {divisionTypeList && divisionTypeList.length > 0 && divisionTypeList.map(
              (comm, index) => {
                return <option key={index} value={comm.division_id}>{comm.division_name}</option>
              })}
          </select>
        </div>

        {divisionData && divisionData.division_name ? (
          <div className="mt-8 mb-2">
            <BillingDonut
              title="Billing Overview"
              subtitle={
                divisionData.DivisionAmt.every((item) => item.Amt === 0)
                  ? 'No Data'
                  : divisionData.division_name
              }
              data={divisionData.DivisionAmt.map((item) => ({
                name: item.Type,
                value: item.Amt,
              }))}
            />
          </div>
        ) : (
          <div>Loading...</div>
        )}

      </div>   
    );
  } 
  else {
    return (
      <div>
        loading...
      </div>
    );
  }
}
