import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import commonConfigs from "../../../../config/config";
import axios from "axios";
import { useForm } from "react-hook-form";
import { InfoCircle } from "iconsax-react";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

const createFaqUrl = commonConfigs.apiUrls.CreateFAQ();
const user = JSON.parse(localStorage.getItem("user"));

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function CreateFaqModal({ setCreateModal, load }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});

  const onSubmit = (data) => {
    setIsDisabled(true); // Disable the submit button

    data.updated_by = user.account_id;
    console.log(data);
    axios
      .post(createFaqUrl, data, { headers: commonConfigs.apiHeader })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setCreateModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };


  //     .then((res) => {
  //       try {
  //         if (res.data.status != "success") {
  //           window.location.reload();
  //           store.addNotification({
  //             title: "Error!",
  //             message: res.data.status,
  //             type: "danger",
  //             insert: "top",
  //             container: "top-left",
  //             animationIn: ["animate__animated", "animate__fadeIn"],
  //             animationOut: ["animate__animated", "animate__fadeOut"],
  //             dismiss: {
  //               duration: 8000,
  //               onScreen: true,
  //             },
  //           });
  //           alert("danger");
  //           setCreateModal(false);
  //         } else {
  //           store.addNotification({
  //             title: "Success!",
  //             message: res.data.status,
  //             type: "success",
  //             insert: "top",
  //             container: "top-left",
  //             animationIn: ["animate__animated", "animate__fadeIn"],
  //             animationOut: ["animate__animated", "animate__fadeOut"],
  //             dismiss: {
  //               duration: 3000,
  //               onScreen: true,
  //             },
  //           });
  //           window.location.reload();
  //         }
  //         alert("success");
  //         setCreateModal(false);
  //         load(true);
  //       } catch (err) {
  //         window.location = "/configurations/faq";
  //         store.addNotification({
  //           title: "Error!",
  //           message: res.data.status,
  //           type: "danger",
  //           insert: "top",
  //           container: "top-center",
  //           animationIn: ["animate__animated", "animate__fadeIn"],
  //           animationOut: ["animate__animated", "animate__fadeOut"],
  //           dismiss: {
  //             duration: 5000,
  //             onScreen: true,
  //           },
  //         });
  //         console.log(err);
  //       }
  //     })
  //     .catch((err) => {
  //       const err_msg = JSON.stringify(err.response.data.result.message);
        // alert(`Error code : ${err.response.status} , ${err_msg}`);
  //     });
  // };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setCreateModal(false)}
            />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Create FAQ
                    </Dialog.Title>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="faq_qn"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Question
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="faq_qn"
                          id="faq_qn"
                          className={
                            !errors.faq_qn
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Question"
                          {...register("faq_qn", { required: true })}
                        />
                        {errors.faq_qn?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="faq_ans"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Answer
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="faq_ans"
                          id="faq_ans"
                          className={
                            !errors.faq_ans
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Answer"
                          {...register("faq_ans", { required: true })}
                        />
                        {errors.faq_ans?.type === "required" && errorIcon}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setCreateModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
