import styles from '../../../styles/styles.module.css';

const GetOrderById = () => {
    return (
        <div style={{display: 'grid', 'gridTemplateAreas':`'details details code`,'gridTemplateColumns': 'repeat(3, minmax(0, 1fr))', gap:3, maxWidth: 'inherit'}}> 
        
        {/* Details component */}
        <div style={{gridArea: 'details', marginBottom: '0px'}}>
            <div className={styles.cardbod}>
                <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#378805'}}>Get Order by ID</h3>
                <hr />
                <p style={{marginTop: '10px'}}> This API retrieves data based on a specific order number. </p>

                {/* API Parameters */}
                <div style={{marginTop: '20px'}}>
                    <h6 style={{textDecoration: 'underline'}}>Parameters</h6>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a. consumer_order_id : Order account ID</p>
                    </div>

                    <h6 style={{textDecoration: 'underline', marginTop: '10px'}}>Output data</h6>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a. consumer order id</p>
                        <p>b. org name</p>
                        <p>c. merchant name</p>
                        <p>d. merchant img URL</p>
                        <p>e. payment id</p>
                        <p>f. order details</p>
                    </div>
                </div>
            </div>
        </div>

        {/* Code component */}
        <div style={{gridArea: 'code', marginBottom: '0px', backgroundColor: '#3f3f3f'}}>
            <div style={{backgroundColor: '#378805', color: 'white', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', paddingTop: '5px' , paddingBottom: '5px'}}>
                Get Order by ID API
            </div>

            <div>
                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'15px', color: 'white'}}>Request (GET)</h6>
                    <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                        <code className={styles.code}>/api/v1/transactions/orders/{"{"}consumer_order_id{"}"}/orders</code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'10px', color: 'white'}}>Example</h6>
                    <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                        <code className={styles.code}>/api/v1/transactions/orders/80</code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'10px', color: 'white'}}>Header</h6>
                    <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                    <code>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        <span className={styles.codestyle2}>"authorization":</span> <span className={styles.codestyle3}>"Bearer example"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>dy1UG9zWBfLRVNTQtWQr</span>
                        <br />
                        <span className={styles.codestyle1}>{"}"}</span>
                    </code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response</h6>
                    <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                        <code>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        
                        <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        <span className={styles.codestyle2}>"data"</span><span className={styles.codestyle1}>:</span>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br/>

                        {/* start of data */}
                        <div style={{marginLeft: '15px'}}>
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"consumer_order_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 80<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"org_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "FoodCanopy"<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Ah Ming's Fried Chicken"<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_img_url"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "https://live.staticflickr.com/6119/6427879747_725294b3fd_b.jpg"<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"payment_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 1<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"order_no"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "0002"<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"order_total_amt"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 25.8<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"order_type"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 6<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"order_status"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Pending"<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"order_datetime"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "2022-06-06T07:11:27.000Z"<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"order_platform"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Mobile"<span className={styles.codestyle1}>,</span></span>
                            <br />
                            
                            {/* second array in code */}
                            <span className={styles.codestyle2}>"order_detail"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br />
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"{"}</span>
                            <br/>

                            {/* Sapporo Ramen */}
                            <div style={{marginLeft: '10px'}}>
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"product_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Sapporo Miso Ramen"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"product_price"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 9.9<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"product_qty"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 1<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"product_total_amt"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 9.9<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"product_pref"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Extra Chilli, Less Rice"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"remarks"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle3}> null<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"product_stock"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 894<span className={styles.codestyle1}>,</span></span>
                                <br />

                                {/* third array data */}
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"add_ons"</span><span className={styles.codestyle1}>:</span>
                                <span className={styles.codestyle1}>{"["}</span>
                                <br />
                                <span className={styles.codestyle1} style={{marginLeft: '35px'}}>{"{"}</span>
                                <br/>
                                <div style={{marginLeft: '10px'}}>
                                    <span className={styles.codestyle2} style={{marginLeft: '20px'}}>"product_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Onsen Egg"<span className={styles.codestyle1}>,</span></span>
                                    <br />
                                    <span className={styles.codestyle2} style={{marginLeft: '20px'}}>"addon_qty"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 1<span className={styles.codestyle1}>,</span></span>
                                    <br />
                                    <span className={styles.codestyle2} style={{marginLeft: '20px'}}>"addon_price"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 1<span className={styles.codestyle1}>,</span></span>
                                    <br />
                                </div>
                                <span className={styles.codestyle1} style={{marginLeft: '35px'}}>{"}"}</span>
                                <br/>
                                <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span>
                                <br />
                            <span className={styles.codestyle1} style={{marginLeft: '15px'}}>{"}"}</span>
                            <br/>
                            </div>
                        </div>
                        {/* ending brackets */}
                        <span className={styles.codestyle1} style={{marginLeft: '30px'}}>{"]"}</span>
                        <br/>
                        <span className={styles.codestyle1} style={{marginLeft: '23px'}}>{"}"}</span>
                        <br/>
                        <span className={styles.codestyle1} style={{marginLeft: '14px'}}>{"}"}</span>
                        <br/>
                        <span className={styles.codestyle1}>{"}"}</span>
                        </code>
                    </blockquote>   
                </div>

            </div>
        </div>
        </div>
    );
  };
  
export default GetOrderById;