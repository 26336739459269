import NavbarWrapper from "./commons/components/navbarWrapper";
import { Route, Navigate } from "react-router-dom";
import { isLogin } from "./authenticate";

// export function ProtectedRoute() {
//   return (
//       <Route path="/" element={<NavbarWrapper />}>
//         {routes.map(({ path, element }) => (
//           <Route path={path} element={element}></Route>
//         ))}
//       </Route>
//   );
// }

export function PrivateRoute({ children }) {
  return isLogin() ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: "/login",
      }}
    />
  );
}
