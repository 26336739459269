import axios from "axios";
import { Additem } from "iconsax-react";
import { useParams } from "react-router-dom";
import commonConfigs from "../../../../../config/config";
import { useContext, useState, useEffect } from "react";
import DivisionsLayout from "../layouts/divisionsLayout";
import DepartmentsLayout from "../layouts/departmentLayout";
import CreateDivisionsModal from "../../components/createDivisionModal";
import CreateDepartmentsModal from "../../components/createDepartmentModal";
import CompanyItemTabs from "../../components/companyItemTab";
import Breadcrumbs from "../../../../../commons/components/breadcrumbs";
import { CompanyItemCurrentTabContext } from "../../context/companyItemContext";

const CompanyItemLayout = () => {
  const { currentTab } = useContext(CompanyItemCurrentTabContext);
  // const [companyName, setCompanyName] = useState("");
  
  let { companyId } = useParams();
  let { companyName } = useParams();

  const pages = [
    { name: "Configurations", href: "#", current: false },
    {
      name: "Companies",
      href: "/configurations/companies",
      current: false,
    },
    {
      name: companyName,
      href: `/configurations/companies/${companyName}/${companyId}`,
      current: true,
    },
   
  ];
  // Urls.
  const getDivisionsUrl = commonConfigs.apiUrls.GetAllDivisions(companyId);
  const getDepartmentsUrl = commonConfigs.apiUrls.GetAllDepartments(companyId);

  console.log(getDivisionsUrl);
  console.log(getDepartmentsUrl);

  // Data.
  const [divisionsData, setDivisionsData] = useState(null);
  const [departmentsData, setDepartmentsData] = useState(null);
  const [load, setLoad] = useState(true);

  // Modals.
  const [isDivisionModalOpen, setDivisionModal] = useState(false);
  const [isDepartmentModalOpen, setDepartmentModal] = useState(false);

  async function getDivisionsData() {
    await axios
      .get(getDivisionsUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res);
        setDivisionsData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getDepartmentsData() {
    await axios
      .get(getDepartmentsUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res);
        setDepartmentsData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (load) {
      getDivisionsData().catch((err) => {
        console.log("err for divisions: " + err);
      });

      getDepartmentsData().catch((err) => {
        console.log("err for departments: " + err);
      });
    }
  }, [load]);

  function getButtons(name) {
    if (name === "Divisions") {
      return (
        <div className="desktop:mt-4 flex mobile:pt-4  mobile:pb-4 mobile:space-x-2">
          <button
            type="button"
            className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setDivisionModal(true)}
          >
            Create Division <Additem className="ml-3" />
          </button>
        </div>
      );
    } else {
      return (
        <div className="desktop:mt-4 flex mobile:pt-4  mobile:pb-4 mobile:space-x-2">
          <button
            type="button"
            className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setDepartmentModal(true)}
          >
            Create Department <Additem className="ml-3" />
          </button>
        </div>
      );
    }
  }

  return (
    <div className="w-full desktop:p-6 mobile:p-2 ">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
        {getButtons(currentTab.name)}
      </div>
      <CompanyItemTabs />

      {currentTab.name == "Divisions" && (
        <DivisionsLayout
          data={divisionsData}
          load={load}
          setLoad={setLoad}
          companyId={companyId}
        />
      )}
      {currentTab.name == "Departments" && (
        <DepartmentsLayout
          data={departmentsData}
          load={load}
          setLoad={setLoad}
          companyId={companyId}
        />
      )}

      {isDivisionModalOpen && (
        <CreateDivisionsModal
          setCreateModal={setDivisionModal}
          load={setLoad}
          id={companyId}
        />
      )}

      {isDepartmentModalOpen && (
        <CreateDepartmentsModal
          setCreateModal={setDepartmentModal}
          load={setLoad}
          id={companyId}
        />
      )}
    </div>
  );
};

export default CompanyItemLayout;
