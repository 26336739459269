import { useState, useMemo, useEffect } from "react";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import { Additem } from "iconsax-react";
import CreateAccessModal from "../components/createAccessModal";
import UpdateAccessModal from "../components/updateAccessModal";
import DeleteModal from "../../../../commons/components/deleteModal";
import Table from "../../../../commons/components/table";
import { Edit, Trash } from "iconsax-react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import Spinner from "../../../../commons/components/spinner";

const pages = [
  { name: "Configurations", href: "#", current: false },
  { name: "Navigation Page Access", href: "/configurations/access", current: true },
];

const GetAllAccessUrl = commonConfigs.apiUrls.AccessTypes();

const AccessLayout = () => {
  const [isCreateModal, setCreateModal] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  useEffect(() => {
    async function getData() {
      await axios
        .get(GetAllAccessUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          //   console.log(res.data.result.data);
          setData(res.data.result.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);
  const columns = useMemo(() => [
    {
      Header: "Web Portal Sidebar",
      accessor: "access_name",
      Cell: ({ row }) => {
        let accessName = row.original.access_name;
        return <div className="text-sm text-gray-900">{accessName}</div>;
      },
    },
    {
      Header: "Web Portal Sidebar Dropdown",
      accessor: "access_name_option",
      Cell: ({ row }) => {
        let accessNameOption = row.original.access_name_option;
        return (
          <div className="text-sm text-gray-900">{accessNameOption ? accessNameOption : '-'}</div>
        );
      },
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="mt-4 flex mobile:pt-4  mobile: pb-4 mobile:space-x-2">
          <button
            type="button"
            className="ml-3 inline-flex items-center text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setCreateModal(true)}
          >
            Create Navigation Page Access<Additem className="ml-3" />
          </button>
        </div>
      </div>
      {isCreateModal && (
        <CreateAccessModal setOpenModal={setCreateModal} load={setLoad} />
      )}
      {isUpdateModal && (
        <UpdateAccessModal
          setUpdateModal={setUpdateModal}
          load={setLoad}
          row={rowToEdit}
        />
      )}
      {isDeleteModal && (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          load={setLoad}
          url={commonConfigs.apiUrls.deleteAccess(rowToEdit.access_id)}
        />
      )}
      {data != null ? (
        <div className="mt-5">
          <Table
            columns={columns}
            data={data}
            filters={columns.map((column) => column.accessor)}
          />
        </div>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
    </div>
  );
};

export default AccessLayout;
