// import { useState } from "react";
import { format } from "date-fns";
import React, { useState, useMemo, useEffect } from "react";
import { Edit, Trash, CardTick, CardRemove } from "iconsax-react";
import commonConfigs from "../../../../../config/config";
import Table from "../../../../../commons/components/table";
import UpdateDivisionModal from "../../components/updateDivisionModal";
import Spinner from "../../../../../commons/components/spinner";
import DeleteModal from "../../../../../commons/components/deleteModal";
import MultipleString from "../../../../../commons/components/multipleString";


export default function DivisionsLayout({ data, load, setLoad, companyId }) {
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const columns = [
    {
      Header: "Company ID",
      accessor: "company_id",
    },
    {
      Header: 'Division Name',
      accessor: 'division_name',
    },
    {
      Header: "",
      id: "expander",
      Cell: ({ row }) => {
        return (
          <div>
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalOpen(true);
                setSelectedRow(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModalOpen(true);
                setSelectedRow(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ];

  if (data) {
    return (
      <>
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
        {isUpdateModalOpen && (
          <UpdateDivisionModal
            setUpdateModal={setUpdateModalOpen}
            load={setLoad}
            row={selectedRow}
            companyId={companyId}
          />
        )}

        {isDeleteModalOpen && (
          <DeleteModal
            setDeleteModal={setDeleteModalOpen}
            load={setLoad}
            url={commonConfigs.apiUrls.DeleteDivision(companyId,selectedRow.division_id)}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
        <div className="flex place-content-center w-full">
          <Spinner className="w-20 h-20" />
        </div>
    </div>
    );
  }
}
