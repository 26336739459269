import { MarketingContextProvider } from "../context/marketingContext";
import MarketingLayout from "./marketingLayout";

const Marketing = () => {
  return (
    <MarketingContextProvider>
      <MarketingLayout />
    </MarketingContextProvider>
  );
};

export default Marketing;
