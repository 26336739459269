import axios from "axios";
import Select from "react-select";
import { InfoCircle } from "iconsax-react";
import { useForm, Controller } from "react-hook-form";
import commonConfigs from "../../../../config/config";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { getRandomUUID } from "../../../../utils/custom/helper";
import { AZURE_BLOB_CONTAINER_PRODUCTS } from "../../../../constants";
import { useUploadFile } from "../../../../commons/components/uploadFileCard";


const user = JSON.parse(localStorage.getItem("user"));

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);
const options = [
  { value: 0, label: "False" },
  { value: 1, label: "True" },
];

export default function CreateRefundModal({ setCreateModal, load, merchantid }) {

  const [open, setOpen] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
  });

  function formatData(data) {
    var newData = data;
    newData.ret_ref_num = data.ret_ref_num;
    newData.amt_in_dollars = data.amt_in_dollars;
    newData.refund_remarks = data.refund_remarks;
    newData.order_status = 7;
    newData.refunded_by = user.account_id;
    newData.merchant_id = merchantid
    return newData;
}
  
  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button
    var newdata = formatData(data);
    console.log(newdata);
    // var postdata = {
    //   ret_ref_num: ret_ref_num,
    //   amount_in_dollars: amount_in_dollars,
    //   refunded_by: localStorage.getItem('userid'),
    //   order_status: 6,
    //   refund_remarks: ""
    // }
    axios
      .post(commonConfigs.apiUrls.initiateRefund(), newdata, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setCreateModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };
  useEffect(() => {
    console.log(merchantid);
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setCreateModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Create Refund
                    </Dialog.Title>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="ret_ref_num"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Retrieval Reference Number
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="ret_ref_num"
                          id="ret_ref_num"
                          className={
                            !errors.ret_ref_num
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Retrieval Reference Number"}
                          {...register("ret_ref_num", { required: true })}
                        // pattern="^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"

                        />
                        {errors.ret_ref_num?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="amt_in_dollars"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Refund Amount
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="0"
                          step="any"
                          name="amt_in_dollars"
                          id="amt_in_dollars"
                          className={
                            !errors.amt_in_dollars
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Amount"}
                          {...register("amt_in_dollars", {
                            required: true,
                            valueAsNumber: true,
                            pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                            // validate: (number) => parseInt(number) > 0,
                          })}
                          pattern="^(?:\d*\.\d{1,2}|\d+)$"

                        />
                        {errors.amt_in_dollars?.type === "required" && errorIcon}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="refund_remarks"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Remarks
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="refund_remarks"
                          id="refund_remarks"
                          className={
                            !errors.remarks
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Remarks"}
                          {...register("refund_remarks", { required: true })}
                        // pattern="^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"

                        />
                        {errors.refund_remarks?.type === "required" && errorIcon}
                      </div>
                    </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setCreateModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
