import { FeedbackContextProvider } from "../context/feedbackContext";
import FeedbackLayout from "./feedbackLayout";

const FeedbackPage = () => {
  return (
    <FeedbackContextProvider>
      <FeedbackLayout />
    </FeedbackContextProvider>
  );
};

export default FeedbackPage;
