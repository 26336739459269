import axios from "axios";
// import { format } from "date-fns";
import { Link, useParams } from "react-router-dom";
import { Cards } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import Table from "../../../../commons/components/merchantTable";
import React, { useState, useMemo, useEffect } from "react";
import Spinner from "../../../../commons/components/spinner";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import { DocumentDownload } from "iconsax-react";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Excel from "exceljs";
// const getMerchantsUrl = commonConfigs.apiUrls.Merchants();

const MerchantsLayout = () => {

  var StartDateData = sessionStorage.getItem('startdate');
  var EndDateData = sessionStorage.getItem('enddate');
  var PlatformData = sessionStorage.getItem('platform');
  var PaymentData = sessionStorage.getItem('payment');
  var LoyaltyData = sessionStorage.getItem('loyalty');

  let { locationId } = useParams();
  let { locationcode } = useParams();
  let { viewMoreType } = useParams();

  console.log(viewMoreType);
  console.log(locationId);
  
  const pages = [
    { name: "Hierarchy View", href: "/dashboard/hierarchy", current: false },
    { name: "Locations", href: `/dashboard/hierarchy/merchantsales`, current: false },
    { name: locationcode, href: `/dashboard/hierarchy/merchantsales/${locationcode}/${locationId}`, current: true },
  ];
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [exportData, setExportData] = useState(null);

  const [isApplying, setIsApplying] = useState(false);
  const [isReseting, setIsReseting] = useState(false);
  const applyButtonEnabledClass = "bg-red-600 hover:bg-red-700";
  const applyButtonDisabledClass = "bg-red-400 cursor-not-allowed";

  const [startdate, setStartDate] = useState('');
  const [enddate, setEndDate] = useState('');

  const [error, setError] = useState('');

  const [startdateFilter, setStartDateFilter] = useState(StartDateData);
  const [enddateFilter, setEndDateFilter] = useState(EndDateData);

  const [platforms, setPlatforms] = useState('all');
  const [payments, setPayment] = useState('all');
  const [loyaltyId, setLoyaltyId] = useState('all');

  const [platformsName, setPlatformsName] = useState('all');
  const [paymentsName, setPaymentName] = useState('all');
  const [loyaltyIdName, setLoyaltyIdName] = useState('all');
  const [platformList, setPlatformsList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [loyaltyList, setLoyaltyList] = useState([]);


  const getMerchantsSalesFilterUrl = commonConfigs.apiUrls.GetFilterSalesParams(locationId);

  const downloadFiles = () => {
    const workbook = new Excel.Workbook();
    console.log(exportData);
  
    const highlightColor = "F892FFFF";
    const fillStyle = Excel.Fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: highlightColor },
    };
    const worksheet = workbook.addWorksheet("Merchant", {
      properties: { defaultColWidth: 30 },
    });
  
    // Title
    worksheet.unMergeCells("A1:S1");
  
    worksheet.mergeCells("A1:S1");
    worksheet.getCell("A1").font = { size: 18, bold: true, underline: true };
    worksheet.getCell("A1").alignment = { vertical: "middle", horizontal: "center" };
    worksheet.getCell("A1").value = locationcode;
  
    // PO Info
    worksheet.getCell("S3").font = { size: 14, bold: true };
    worksheet.getCell("S3").value = `Start Date: ${startdateFilter}`;
    worksheet.getCell("S4").font = { size: 14, bold: true };
    worksheet.getCell("S4").value = `End Date: ${enddateFilter}`;
    worksheet.getCell("S5").font = { size: 14, bold: true };
    worksheet.getCell("S5").value = `Platform Type: ${platformsName}`;
    worksheet.getCell("S6").font = { size: 14, bold: true };
    worksheet.getCell("S6").value = `Payment Type: ${paymentsName}`;
    worksheet.getCell("S7").font = { size: 14, bold: true };
    worksheet.getCell("S7").alignment = { wrapText: true };
    worksheet.getCell("S7").value = `Loyalty Programme: ${loyaltyIdName}`;
  
    // Items
    worksheet.getRow(9).font = { size: 14, bold: true };
    worksheet.getRow(9).alignment = { vertical: "middle", horizontal: "center", wrapText: true };
    worksheet.getRow(9).values = [
      "MERCHANT NAME",
      "PAYEATFWD DONATED AMT (SGD)",
      "PAYEATFWD CLAIMED AMT (SGD)",
      "PAYEATFWD BALANCE AMT (SGD)",
      "DISCOUNT AMT (SGD)",
      "TOTAL VOUCHER AMT (SGD)",
      "TOTAL GOVERNMENT VOUCHER AMT (SGD)",
      "GRAND TOTAL VOUCHER AMT (SGD)",
      "LOYALTY RETENTION AMT (SGD)",
      "LOYALTY REDEMPTION AMT (SGD)",
      "TOTAL SALES (SGD)",
      "Mobile (Total Nett Sales)",  // New column
      "Kiosk (Total Nett Sales)",   // New column
      "MPOS (Total Nett Sales)",    // New column
      "TOTAL NETT SALES (SGD)",
      "MERCHANT RECEIVABLE AMT (SGD)",
      "TOTAL ROUNDING AMT (SGD)",
      "NETS CLICK MID",
      "NETS CLICK TID",
      "NETS QR MID",
      "NETS QR TID",
    ];
    for (let i = 1; i <= 19; i++) {  // Updated loop condition
      worksheet.getRow(9).getCell(i).fill = fillStyle;
    }

    exportData.sort((a, b) => a.merchant_name.localeCompare(b.merchant_name));

    let row = 10;
    for (const item of exportData) {
      worksheet.getRow(row).alignment = { vertical: "middle", horizontal: "center", wrapText: true };
      worksheet.getRow(row).font = { size: 14 };
      worksheet.getRow(row).values = [
        item.merchant_name,
        item.payeatfwd_total_donated_amt,
        item.payeatfwd_total_claimed_amt,
        item.payeatfwd_balance,
        item.total_discount_amt,
        item.total_voucher_amt,
        item.total_govt_voucher_amt,
        item.grand_total_voucher_amt,
        item.total_loyalty_programme_retention_amt,
        item.total_loyalty_redemption_amt,
        item.total_sales,
        "",  // Empty cell for "Mobile" column
        "",  // Empty cell for "Kiosk" column
        "",  // Empty cell for "MPOS" column
        item.total_nett_sales,
        item.total_merchant_receivable_amt,
        item.total_rounding_amt,
        item.nets_click_mid,
        item.nets_click_tid,
        item.nets_qr_mid,
        item.nets_qr_tid,
      ];

      let hasMobileData = false;
      let hasKioskData = false;
      let hasMPOSData = false;

      // Find the sub_total_nett_sales for each platform
      for (const subTotal of item.sub_totals) {
        if (subTotal.platform_name === "Mobile") {
          worksheet.getRow(row).getCell(11).value = subTotal.sub_total_nett_sales;
          hasMobileData = true;
        } else if (subTotal.platform_name === "Kiosk") {
          worksheet.getRow(row).getCell(12).value = subTotal.sub_total_nett_sales;
          hasKioskData = true;
        } else if (subTotal.platform_name === "mPOS") {
          worksheet.getRow(row).getCell(13).value = subTotal.sub_total_nett_sales;
          hasMPOSData = true;
        }
      }

      // Add '-' if no data available for a platform
      if (!hasMobileData) {
        worksheet.getRow(row).getCell(11).value = "-";
      }
      if (!hasKioskData) {
        worksheet.getRow(row).getCell(12).value = "-";
      }
      if (!hasMPOSData) {
        worksheet.getRow(row).getCell(13).value = "-";
      }

      row++;

      // Add borders
      for (let j = 1; j <= 19; j++) {  // Updated loop condition
        worksheet.getRow(row - 1).getCell(j).border = getBorderStyle();
      }
    }

    const fileName = `${locationcode}_TransactionSales.xlsx`;
    const filePath = `${fileName}`;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: fileType });
      FileSaver.saveAs(blob, filePath);
    });
    return { filePath, fileName };

    function getBorderStyle() {
      return {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    }
  }

  const downloadMerchantFiles = () => {
    const workbook = new Excel.Workbook();
    console.log(exportData);
    
    exportData.sort((a, b) => a.merchant_name.localeCompare(b.merchant_name));

    for (const item of exportData) {
      
      const highlightColor = "F892FFFF";
      const fillStyle = Excel.Fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: highlightColor },
      };
      const worksheet = workbook.addWorksheet(item.merchant_name, {
        properties: { defaultColWidth: 30 },
      });
    
      // Title
      worksheet.unMergeCells("A1:K1");

      worksheet.mergeCells("A1:K1");
      worksheet.getCell("A1").font = { size: 18, bold: true, underline: true };
      worksheet.getCell("A1").alignment = { vertical: "middle", horizontal: "center" };
      worksheet.getCell("A1").value = locationcode;
  
      // Address
      worksheet.getCell("A3").font = { size: 16, bold: true };
      worksheet.getCell("A3").value = item.merchant_name;
      worksheet.getCell("A4").value = "NETS CLICK MID: " + item.nets_click_mid;
      worksheet.getCell("A5").value = "NETS CLICK TID: " + item.nets_click_tid;
      worksheet.getCell("A6").value = "NETS QR MID: " + item.nets_qr_mid;
      worksheet.getCell("A7").value = "NETS QR TID: " + item.nets_qr_tid;
  
      // PO Info
      worksheet.getCell("K3").font = { size: 14, bold: true };
      worksheet.getCell("K3").value = `Start Date: ${StartDateData}`;
      worksheet.getCell("K4").font = { size: 14, bold: true };
      worksheet.getCell("K4").value = `End Date: ${EndDateData}`;
      worksheet.getCell("K5").font = { size: 14, bold: true };
      worksheet.getCell("K5").value = `Platform Type: ${platformsName}`;
      worksheet.getCell("K6").font = { size: 14, bold: true };
      worksheet.getCell("K6").value = `Payment Type: ${paymentsName}`;
      worksheet.getCell("K7").font = { size: 14, bold: true };
      worksheet.getCell("K7").alignment = { wrapText: true };
      worksheet.getCell("K7").value = `Loyalty Programme: ${loyaltyIdName}`;
      
      // Items
      worksheet.getRow(9).font = { size: 14, bold: true };
      worksheet.getRow(9).alignment = { vertical: "middle", horizontal: "center", wrapText:'trues' };
      worksheet.getRow(9).values = [
        // "MERCHANT NAME",
        "PAYEATFWD DONATED AMT (SGD)",
        "PAYEATFWD CLAIMED AMT (SGD)",
        "PAYEATFWD BALANCE AMT (SGD)",
        "DISCOUNT AMT (SGD)",
        "TOTAL VOUCHER AMT (SGD)",
        "TOTAL GOVERNMENT VOUCHER AMT (SGD)",
        "GRAND TOTAL VOUCHER AMT (SGD)",
        "LOYALTY RETENTION AMT (SGD)",
        "LOYALTY REDEMPTION AMT (SGD)",
        "TOTAL SALES (SGD)",
        "TOTAL NETT SALES (SGD)",
        "MERCHANT RECEIVABLE AMT (SGD)",
        "TOTAL ROUNDING AMT (SGD)",
      ];
      for (let i = 1; i <= 11; i++) {
        worksheet.getRow(9).getCell(i).fill = fillStyle;
      }
  
      let row = 10;
        worksheet.getRow(row).alignment = { vertical: "middle", horizontal: "center", wrapText: 'true' };
        worksheet.getRow(row).font = { size: 14 };
        worksheet.getRow(row).values = [
          item.payeatfwd_total_donated_amt,
          item.payeatfwd_total_claimed_amt,
          item.payeatfwd_balance,
          item.total_discount_amt,
          item.total_voucher_amt,
          item.total_govt_voucher_amt,
          item.grand_total_voucher_amt,
          item.total_loyalty_programme_retention_amt,
          item.total_loyalty_redemption_amt,
          item.total_sales,
          item.total_nett_sales,
          item.total_merchant_receivable_amt,
          item.total_rounding_amt,
        ];

      var totalTableRows = 0;
      var totalProductsCategories = exportData.length;

      totalTableRows = totalProductsCategories;
      console.log(totalTableRows);

      var productStartRow = totalTableRows + 10;
      
        for (let j = 1; j <= 11; j++) {
          worksheet.getRow(10).getCell(j).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }

      var totalStartRow = 7;


      // Comments/Instructions
      worksheet.unMergeCells("A"+(totalStartRow+5)+":K"+(totalStartRow+5));
      worksheet.mergeCells("A"+(totalStartRow+5)+":K"+(totalStartRow+5));
      worksheet.getCell("A"+(totalStartRow+5)).font = { size: 14, bold: true };
      worksheet.getCell("A"+(totalStartRow+5)).fill = { ...fillStyle, fgColor: { argb: "FFBAB5BF" } };
      worksheet.getCell("A"+(totalStartRow+5)).value = "Comments or Special Instructions";
      worksheet.unMergeCells("A"+(totalStartRow+6)+":K"+(totalStartRow+9));
      worksheet.mergeCells("A"+(totalStartRow+6)+":K"+(totalStartRow+9));
  
      // Footer
      worksheet.unMergeCells("A"+(totalStartRow+11)+":K"+(totalStartRow+11));
      worksheet.mergeCells("A"+(totalStartRow+11)+":K"+(totalStartRow+11));
      worksheet.getCell("A"+(totalStartRow+11)).alignment = { vertical: "middle", horizontal: "center" };
      worksheet.getCell("A"+(totalStartRow+11)).value = "If you have any questions , please contact";
      worksheet.unMergeCells("A"+(totalStartRow+12)+":K"+(totalStartRow+12));
      worksheet.mergeCells("A"+(totalStartRow+12)+":K"+(totalStartRow+12));
      worksheet.getCell("A"+(totalStartRow+12)).alignment = { vertical: "middle", horizontal: "center" };
      worksheet.getCell("A"+(totalStartRow+12)).value = "management office";
      workbook.worksheets.unshift(worksheet);
    }
  
      const fileName = `${locationcode}_TransactionSales.xlsx`;
      const filePath = `${fileName}`;
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], { type: fileType }); 
        FileSaver.saveAs(blob, filePath);
      });
      return { filePath, fileName };

  }

  const applyFilter = () => {
    setIsApplying(true); // Disable the "APPLY" button
    // console.log(startdate, enddate);
    setLoad(true);
    ((StartDateData.length > 0)? setStartDateFilter(StartDateData): setStartDateFilter(startdate));
    ((EndDateData.length > 0)? setEndDateFilter(EndDateData): setEndDateFilter(startdate));
    // setStartDateFilter(startdate);
    // setEndDateFilter(enddate);
    // setPlatforms(platforms);
    // setPayment(payments);
    // setLoyaltyId(loyaltyId);
    ((PlatformData.length > 0)? setPlatforms(PlatformData): setPlatforms(platforms));
    ((PaymentData.length > 0)? setPayment(PaymentData): setPayment(payments));
    ((LoyaltyData.length > 0)? setLoyaltyId(LoyaltyData): setLoyaltyId(loyaltyId));

    setPlatformsName(platformsName);
    setPaymentName(paymentsName);
    setLoyaltyIdName(loyaltyIdName);
  }
  const resetFilter = () => {
    setIsReseting(true);
    setLoad(true);
    setStartDate('')
    setEndDate('');
    sessionStorage.setItem('startdate', '');
    sessionStorage.setItem('enddate', '');
    sessionStorage.setItem('platform', '');
    sessionStorage.setItem('payment', '');
    sessionStorage.setItem('loyalty', '');
    setStartDateFilter('')
    setEndDateFilter('');
    setPlatforms('all');
    setPayment('all');
    setLoyaltyId('all');
    setPlatformsName('all');
    setPaymentName('all');
    setLoyaltyIdName('all');
  }


  async function getData() {
    var platformFilter = '';
    var paymentFilter = '';
    var loyaltyFilter = '';
  
    if (platforms !== "all") {
      platformFilter = platforms;
    }
    if (payments !== "all") {
      paymentFilter = payments;
    }
    if (loyaltyId !== "all") {
      loyaltyFilter = loyaltyId;
    }
    const getLocationsSales = commonConfigs.apiUrls.GetOutletLocationSales(locationId, platformFilter, paymentFilter, loyaltyFilter, startdateFilter, enddateFilter);
    // console.log(getLocationsSales);
    await axios
      // .get(getMerchantsUrl, { headers: commonConfigs.apiHeader })
      .get(getLocationsSales, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        if (res.data.result.data !== null) {
          setData(res.data.result.data);
          setExportData(res.data.result.data);
          setIsApplying(false);
          setIsReseting(false);
          setLoad(false);  
        } else {
          setError(res.data.result.data.message);
          setIsApplying(false);
          setIsReseting(false);
          // setExportData([]);
          setLoad(false);  
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function fetchSalesFilters() {
    await axios
      .get(getMerchantsSalesFilterUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        var response = res.data.result.data;
        var loyaltyProgrammes = response.loyalty_programmes;
        var paymentTypes = response.sys_code_labels.ORDER_PAYMENT_TYPE;
        var platformTypes = response.sys_code_labels.PLATFORM_TYPE;

        setLoyaltyList(loyaltyProgrammes);
        setPlatformsList(platformTypes);
        setPaymentList(paymentTypes);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  useEffect(() => {
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
      fetchSalesFilters
      ().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load,startdateFilter,enddateFilter, platforms, payments, loyaltyId]);

  const columns = useMemo(() => [
    {
      Header: "Merchant Name",
      accessor: "merchant_name",
      width: 10,
      Cell: ({ value }) => {
        return (
          <div className="flex items-center">
            {/* <div className="flex-shrink-0 h-10 w-10">
              <img src={row.original.merchant_img_url} />
            </div> */}
            <div className="ml-4">
              <p className="text-sm text-black">{value}</p>
            </div>
          </div>
        );
      },
    },
    {
      Header: "PayEatFwd Total Donated Amount ($)",
      accessor: "payeatfwd_total_donated_amt",
      Cell: ({ row }) => {
        var sales = row.values.payeatfwd_total_donated_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
    {
      Header: "PayEatFwd Total Claimed Amount ($)",
      accessor: "payeatfwd_total_claimed_amt",
      Cell: ({ row }) => {
        var sales = row.values.payeatfwd_total_claimed_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
    {
      Header: "PayEatFwd Balance Amount ($)",
      accessor: "payeatfwd_balance",
      Cell: ({ row }) => {
        var sales = row.values.payeatfwd_balance;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
        }
      }
    },
    {
      Header: "Discount Amount ($)",
      accessor: "total_discount_amt",
      Cell: ({ row }) => {
        var sales = row.values.total_discount_amt;
        if (sales == undefined || sales == null) {
          return <p className="text-sm text-gray-900">0</p>;
        } else {
          var truncatedSales = Number(sales.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]); // Truncate and handle negative numbers
          return <p className="text-sm text-gray-900">{truncatedSales}</p>;
        }
      }
    },  
    {
      Header: "Voucher Amount ($)",
      accessor: "total_voucher_amt",
      Cell: ({ row }) => {
        var sales = row.values.total_voucher_amt;
        if (sales == undefined || sales == null) {
          return <p className="text-sm text-gray-900">0</p>;
        } else {
          return <p className="text-sm text-gray-900">{sales.toFixed(2)}</p>;

        }
      }
    },
    {
      Header: "Government Voucher Amount ($)",
      accessor: "total_govt_voucher_amt",
      Cell: ({ row }) => {
        var sales = row.values.total_govt_voucher_amt;
        if (sales == undefined || sales == null) {
          return <p className="text-sm text-gray-900">0</p>;
        } else {
          return <p className="text-sm text-gray-900">{sales.toFixed(2)}</p>;

        }
      }
    },
    {
      Header: "Total Voucher Amount ($)",
      accessor: "grand_total_voucher_amt",
      Cell: ({ row }) => {
        var sales = row.values.grand_total_voucher_amt;
        if (sales == undefined || sales == null) {
          return <p className="text-sm text-gray-900">0</p>;
        } else {
          return <p className="text-sm text-gray-900">{sales.toFixed(2)}</p>;

        }
      }
    },
    {
      Header: "Loyalty Retention Amount ($)",
      accessor: "total_loyalty_programme_retention_amt",
      Cell: ({ row }) => {
        var sales = row.values.total_loyalty_programme_retention_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          // return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
          var truncatedSales = Number(sales.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]); // Truncate extra decimal places
          return <p className="text-sm text-gray-900">{truncatedSales}</p>;
        }
      }
    },
    {
      Header: "Loyalty Redemption Amount ($)",
      accessor: "total_loyalty_redemption_amt",
      Cell: ({ row }) => {
        var sales = row.values.total_loyalty_redemption_amt;
        if (sales == undefined || sales == null) {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;

        }
      }
    },
  
    {
      Header: "Total Sales ($)",
      accessor: "total_sales",
      Cell: ({ row }) => {
        var sales = row.values.total_sales;
        if (sales == undefined || sales == null) {
          return <p className="text-sm text-gray-900">0</p>;
        } else {
          // return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
          var truncatedSales = Number(sales.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]); // Truncate extra decimal places
          return <p className="text-sm text-gray-900">{truncatedSales}</p>;
        }
      }
    },
    {
      Header: "Total Nett Sales ($)",
      accessor: "total_nett_sales",
      Cell: ({ row }) => {
        var sales = row.values.total_nett_sales;
        if (sales == undefined || sales == null) {
          return <p className="text-sm text-gray-900">0</p>;
        } else {
          // return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
          var truncatedSales = Number(sales.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]); // Truncate extra decimal places
          return <p className="text-sm text-gray-900">{truncatedSales}</p>;
        }
      }
    },
    {
      Header: "Merchant Receivable Amount ($)",
      accessor: "total_merchant_receivable_amt",
      Cell: ({ row }) => {
        var sales = row.values.total_merchant_receivable_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          // return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
          var truncatedSales = Number(sales.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]); // Truncate extra decimal places
          return <p className="text-sm text-gray-900">{truncatedSales}</p>;
        }
      }
    },
    {
      Header: "Total Rounding Amount ($)",
      accessor: "total_rounding_amt",
      Cell: ({ row }) => {
        var sales = row.values.total_rounding_amt;
        if (sales == undefined || sales == null || sales == "-") {
          return <p className="text-sm text-gray-900">-</p>;
        } else {
          // return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
          var truncatedSales = Number(sales.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]); // Truncate extra decimal places
          return <p className="text-sm text-gray-900">{truncatedSales}</p>;
        }
      }
    },
    {
      Header: "NETS Click MID",
      accessor: "nets_click_mid",
      // Cell: ({ row }) => {
      //   var mid = row.values.merchant_mid_nets_click;
      //   if (mid == undefined || mid == null) {
      //     return <p className="text-sm text-gray-900">-</p>;
      //   } else {
      //     return <p className="text-sm text-gray-900">{ mid}</p>;

      //   }
      // }
    },
    {
      Header: "NETS Click TID",
      accessor: "nets_click_tid",
      // Cell: ({ row }) => {
      //   var tid = row.values.merchant_tid_nets_click;
      //   if (tid == undefined || tid == null) {
      //     return <p className="text-sm text-gray-900">-</p>;
      //   } else {
      //     return <p className="text-sm text-gray-900">{ tid}</p>;

      //   }
      // }
    },
    {
      Header: "NETS QR MID",
      accessor: "nets_qr_mid",
      // Cell: ({ row }) => {
      //   var mid = row.values.merchant_mid_nets_qr;
      //   if (mid == undefined || mid == null) {
      //     return <p className="text-sm text-gray-900">-</p>;
      //   } else {
      //     return <p className="text-sm text-gray-900">{ mid}</p>;

      //   }
      // }
    },
    {
      Header: "NETS QR TID",
      accessor: "nets_qr_tid",
      // Cell: ({ row }) => {
      //   var tid = row.values.merchant_tid_nets_qr;
      //   if (tid == undefined || tid == null) {
      //     return <p className="text-sm text-gray-900">-</p>;
      //   } else {
      //     return <p className="text-sm text-gray-900">{ tid}</p>;

      //   }
      // }
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        var name = "";
        if (row.original.merchant_name.includes("#")) {
          name = row.original.merchant_name.split('#')[1];
        } else {
          name = row.original.merchant_name;
        }
        let merchantObj = {
          merchantName: name,
          id: row.original.merchant_id,
        };

        return (
          <div className=" text-gray-900  flex space-x-1 ">
            <button type="button">
              <Link
                to={{
                  pathname: `/dashboard/hierarchy/merchantsales/${locationcode}/${locationId}/${merchantObj.merchantName}/${merchantObj.id}`,
                }}
                state={{startDate: startdate, endDate: enddate}}
              >
                <Cards aria-hidden="true" className="w-6 h-6" />
              </Link>
            </button>
          </div>
        );
      },
    },
  ]);

  return (


    
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
     
      {/* <disv> */}
      {/* <div className="md:flex md:items-center md:justify-between" style={{float:'right'}}>
        <button
          type="button"
          className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
          onClick={() => downloadFiles()}
        >
          Export Data <DocumentDownload className="ml-3" />
        </button>
      </div> */}
      
{/* div  */}

      <div style={{backgroundColor:'#FFF', padding:'20px', borderRadius:'10px', margin:'20px 0 20px 0 '}}>
        <row style={{display:'flex'}}>
          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> Start Date </h6>
            </div>
            <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={StartDateData} onChange={(e) => {
              sessionStorage.setItem('startdate', e.target.value);
              setStartDate(e.target.value);
            }}/>
          </div>
          <div style={{width:'100%',padding:'10px'}}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <h6 style={{ marginBottom: '0px' }}> End Date </h6>
            </div>
            <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={EndDateData} onChange={(e) => {
              sessionStorage.setItem('enddate', e.target.value);
              setEndDate(e.target.value);
          }}/>
          </div>
          <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> Platform Type </h6>
                </div>
                <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={PlatformData} onChange={(e) => {
              sessionStorage.setItem('platform', e.target.value);
              setPlatforms(e.target.value), setPlatformsName(e.target.selectedOptions[0].text)
                }}>
                  <option value='all'>All</option>
                  {platformList && platformList.length > 0 && platformList.map(
                    (comm, index) => {
                      return <option key={index} value={comm.sys_code_id}>{comm.sys_code_desc}</option>
                    })}
                </select>
              </div>
              <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> Payment Type </h6>
                </div>
                <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={payments} onChange={(e) => {
                  sessionStorage.setItem('payment', e.target.value);
                  setPayment(e.target.value), setPaymentName(e.target.selectedOptions[0].text)
                }}>
                  <option value='all'>All</option>
                  {paymentList && paymentList.length > 0 && paymentList.map(
                    (comm, index) => {
                      return <option key={index} value={comm.sys_code_id}>{comm.sys_code_desc}</option>
                    })}
                </select>
                </div>
                <div style={{width:'100%',padding:'10px'}}>
                  <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <h6 style={{ marginBottom: '0px' }}> Loyalty Programme </h6>
                  </div>
                  <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={loyaltyId} onChange={(e) => {
                    sessionStorage.setItem('loyalty', e.target.value);
                    setLoyaltyId(e.target.value), setLoyaltyIdName(e.target.selectedOptions[0].text)
                  }}>
                    <option value='all'>All</option>
                    {loyaltyList && loyaltyList.length > 0 && loyaltyList.map(
                      (comm, index) => {
                        return <option key={index} value={comm.loyalty_id}>{comm.loyalty_name}</option>
                      })}
                  </select>
                </div>
        </row>
        <div style={{ textAlign: 'end', paddingTop: '34px' }}>
          <button
            data-inline="true"
            type="button"
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
              isApplying ? applyButtonDisabledClass : applyButtonEnabledClass
            }`}
            onClick={() => applyFilter()}
            disabled={isApplying} // Disable the button when isApplying is true
          >
            {isApplying ? "Applying..." : "APPLY"}
          </button>
          <button
            data-inline="true"
            type="button"
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
              isReseting ? applyButtonDisabledClass : applyButtonEnabledClass
            }`}
            onClick={() => resetFilter()}
            disabled={isReseting} // Disable the button when isReseting is true
          >
            {isReseting ? "Reseting..." : "Reset"}
          </button>
        </div>
      </div>

      <div className="md:flex md:items-center md:justify-end" style={{ position: 'relative', width: '100%' }}>
        <div className="flex">
          <button
            type="button"
            className="ml-3 inline-flex items-center text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => downloadFiles()}
            style={{ marginRight: '10px' }}
          >
            Download Data <DocumentDownload className="ml-3" />
          </button>
          <button
            type="button"
            className="ml-3 inline-flex items-center text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => downloadMerchantFiles()}
            style={{ marginRight: '10px' }}
          >
            Export for Merchant<DocumentDownload className="ml-3" />
          </button>
        </div>
      </div>




      {console.log(data)}
      {data != null && data.status_code !== 404 ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : <>
          {console.log(error)}
          {/* data.status_code == 404 */}
          { data == null  ? <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
          <Spinner className="w-20 h-20" />
          </div>
        </div> : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-baseline">
          <div className="flex place-content-center w-full">
            No Transaction Record Found
          </div>
        </div>
      )}
      </>}
    </div>
  );
};

export default MerchantsLayout;
