const Footer = () => {
  return (
    <div className="flex justify-center items-center h-20 bg-green-600 text-white">
      <p className="font-medium mobile:text-center">
        Empowered by OpenApiPass Foundation and Bev.Eat Pte. Ltd. All Rights
        Reserved.
      </p>
    </div>
  );
};

export default Footer;
