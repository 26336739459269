import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import OrderCard from "../components/orderCard";

const user = JSON.parse(localStorage.getItem("user"))

export default function DishwashingCard() {

  const [dishwashingServices, setDishwashingServices] = useState(null);

  async function fetchDishwashingServices() {

    let getDishwashingServices = commonConfigs.apiUrls.getDishwashingServices();
    if(user.access_multiple_location == 0 && user.location_id) {
      getDishwashingServices = getDishwashingServices + `&location_id=${user.location_id}`
    }

    await axios
      .get(getDishwashingServices, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setDishwashingServices(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchDishwashingServices().catch((err) => {
      console.log("err: " + err);
    });
  }, []);


  return (
    <div style={{height:'inherit', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
      {dishwashingServices && (
        <OrderCard
          title="Dishwashing Services"
          subtitle={`$${dishwashingServices.reduce((acc, item) => acc + item.total_fee, 0)}`}
          data={dishwashingServices.map((item) => ({
            name: item.location_name,
            value: item.total_fee,
          }))}
        />
      )}
    </div>   
  );
}
