import React, { useState, useMemo, useEffect } from "react";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import { Additem } from "iconsax-react";
import CreateAccessRolesModal from "../components/createAccessRolesModal";
import UpdateAccessRolesModal from "../components/updateAccessRolesModal";
import DeleteModal from "../../../../commons/components/deleteModal";
import Table from "../../../../commons/components/table";
import { Edit, Trash } from "iconsax-react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import MultipleString from "../../../../commons/components/multipleString.jsx";
import { format } from "date-fns";
import Spinner from "../../../../commons/components/spinner";

const pages = [
  { name: "Configurations", href: "#", current: false },
  { name: "Access Roles", href: "/configurations/accessroles", current: true },
];

const GetAllAccessRolesUrl = commonConfigs.apiUrls.GetAllAccessRoles();

const AccessRolesLayout = () => {
  const [isCreateModal, setCreateModal] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  useEffect(() => {
    async function getData() {
      await axios
        .get(GetAllAccessRolesUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          setData(res.data.result.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);
  const columns = useMemo(() => [
    {
      Header: "Role Name",
      accessor: "role_name",
      Cell: ({ row }) => {
        let roleName = row.original.role_name;
        return <div className="text-sm text-gray-900">{roleName}</div>;
      },
    },
    {
      Header: "Access Type",
      accessor: "access_rights",
      Cell: ({ row }) => {
        let accessType = [];
        if (row.original.access_rights.length > 0) {
          accessType = row.original.access_rights.map((obj) => obj.access_name_option ? obj.access_name + ' [' + obj.access_name_option + ']' : obj.access_name);
        }
        return (
          <div className="text-sm text-gray-900">
            <MultipleString data={accessType} />
          </div>
        );
      },
    },
    {
      Header: "Access Multiple Location",
      accessor: "access_multiple_location",
      Cell: ({ row }) => {
        let accessMultipleLocation = row.original.access_multiple_location ? "True" : row.original.access_multiple_location == null ? "-" : "False";
        return <div className="text-sm text-gray-900">{accessMultipleLocation}</div>;
      },
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;

          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="mt-4 flex mobile:pt-4  mobile: pb-4 mobile:space-x-2">
          <button
            type="button"
            className="ml-3 inline-flex items-center text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setCreateModal(true)}
          >
            Create Access Roles <Additem className="ml-3" />
          </button>
        </div>
      </div>
      {isCreateModal && (
        <CreateAccessRolesModal setOpenModal={setCreateModal} load={setLoad} />
      )}
      {isUpdateModal && (
        <UpdateAccessRolesModal
          setUpdateModal={setUpdateModal}
          load={setLoad}
          row={rowToEdit}
        />
      )}
      {isDeleteModal && (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          load={setLoad}
          url={commonConfigs.apiUrls.UpdateAccessRoles(rowToEdit.role_id)}
        />
      )}
      {data != null ? (
        <div className="mt-5">
          <Table
            columns={columns}
            data={data}
            filters={columns.map((column) => column.accessor)}
          />
        </div>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
    </div>
  );
};

export default AccessRolesLayout;
