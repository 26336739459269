import { CompanyItemContextProvider } from "../context/companyItemContext";
import CompanyItemLayout from "../pages/layouts/companyItemLayout";

const MerchantsMenuPage = () => {
  return (
    <CompanyItemContextProvider>
      <CompanyItemLayout />
    </CompanyItemContextProvider>
  );
};

export default MerchantsMenuPage;
