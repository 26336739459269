import { current } from "@reduxjs/toolkit";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import React, { useEffect, useState, useMemo } from "react";

/**
 * @desc Function for generating table pagination
 * @param {*} props Consisting state, pageOptions, goToPage, nextPage, previousPage, setPage size
 * @returns A UI Component of pagination for table
 */

function Pagination(props) {
  const {
    state,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageChangeHandler
  } = props;

  const [currentPage, setCurrentPage] = useState(0);

  const maxButton = 5;
  const onNext = (page) => {
    state.pageIndex = page + 1
    setCurrentPage(page)
    nextPage();
  };

  const onPrevious = (page) => {
    state.pageIndex = page - 1
    setCurrentPage(page)
    previousPage();
  };

  const onGoToPage = (page) => {
    // console.log(page);
    // console.log(state.pageIndex)
    state.pageIndex = page
    setCurrentPage(page)
    gotoPage();
  };

  useEffect(() => {
    if (pageChangeHandler) { 
      pageChangeHandler(currentPage) 
    }
  },[currentPage])

  return (
    <>
      <nav className="my-3 px-4 flex  flex-col items-center justify-between sm:px-0">
        <div className="hidden md:-mt-px md:flex">
          <button
            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => onPrevious(currentPage)}
          >
            <ArrowLeft2 className="h-5 w-5" aria-hidden="true" />
          </button>
          {/* {pageOptions.map((page) => {
            if (state.pageIndex === page) {
              return (
                <button
                  key={page}
                  onClick={() => onGoToPage(page)}
                  className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {page + 1}
                </button>
              );
            }
            if (pageOptions.length <= maxButton) {
              return (
                <button
                  key={page}
                  onClick={() => onGoToPage(page)}
                  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {page + 1}
                </button>
              );
            } else if ((state.pageIndex <= 2 && page < maxButton) || (state.pageIndex >= pageOptions.length - 2 && page >= pageOptions.length - maxButton)) {
              return (
                <button
                  key={page}
                  onClick={() => onGoToPage(page)}
                  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {page + 1}
                </button>
              );
            } else if (state.pageIndex + 2 >= page && state.pageIndex - 2 <= page) {
              return (
                <button
                  key={page}
                  onClick={() => onGoToPage(page)}
                  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {page + 1}
                </button>
              );
            }
          })} */}
          {/* {console.log(state.pageIndex)} */}
          {/* {pageOptions.slice(start, end).map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => onGoToPage(pageNumber)}
              className={`${
                state.pageIndex === pageNumber ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
              } relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
            >
              {pageNumber + 1}
            </button>
          ))} */}
           {/* {pageOptions.slice(currentPage - 3, currentPage + 2).map((num, index) =>  (
              <button
                  onClick={() => onGoToPage(currentPage - 3 + index)}
                  className={`${
                      currentPage === num ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                  } relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
              >
                  {num}
              </button>
          ))} */}
            {pageOptions.slice(
                  Math.max(0, Math.min(currentPage - 2, pageOptions.length - 5)),
                  Math.min(pageOptions.length, Math.max(currentPage + 3, 5))
              ).map((num, index) => (
                  <button
                      onClick={() => onGoToPage(Math.max(0, Math.min(currentPage - 2, pageOptions.length - 5)) + index)}
                      className={`${
                        num === currentPage ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                      } relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                  >
                      {num+1}
                  </button>
              ))}
          <button
            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => onNext(currentPage)}
          >
            <ArrowRight2 className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </nav>
      {/* <nav className="px-4 flex items-center justify-between sm:px-0 my-2">
        <div className="mt-px w-0 flex-1 flex justify-end ">
          <select
            value={state.pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="w-32"
          >
            {[1, 5, 10, 20].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </nav> */}
    </>
  );
}

export default Pagination;
