import { Link } from "react-router-dom";
import { ArrowRight } from "iconsax-react";
import Logo from "../../../assets/food-canopy-logo.png";
import Mobile from "../../../assets/mobileImage.png";
import Background from "../../../assets/bitmap.png";
import GooglePlayBadge from "../../../assets/google-play-badge.png";
import AppStoreBadge from "../../../assets/app-store-badge.svg";

const MarketingApp = () => {
  if (window.matchMedia("(max-width: 767px)").matches) {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.location.replace(
        "https://apps.apple.com/sg/app/food-canopy/id1622158900"
      );
    } else {
      window.location.replace(
        "https://play.google.com/store/apps/details?id=sg.com.foodcanopy"
      );
    }
  }
  return (
    <div
      className=" w-full bg-hero bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className=" bg-white bg-opacity-80 ">
        <div className="ls:flex desktop:items-center desktop:justify-between p-10 flex justify-between">
          <img
            src={Logo}
            className="object-scale-down min-w-0 w-36 h-36"
            alt="logo"
          ></img>
          <div className="mt-4 flex md:mt-0 md:ml-4 justify-between desktop:visible mobile:invisible">
          <div style={{marginRight: '15px'}}>
              <Link to="/documentation">
                <button
                  type="submit"
                  className=" inline-flex items-center  text-xl font-medium text-yellow-800 focus:outline-none hover:text-yellow-900 mt-50"
                >
                  <span className="underline">Open API</span>
                </button>
              </Link>
            </div>
            <div>
              <Link to="/feedback">
                <button
                  type="submit"
                  className=" inline-flex items-center  text-xl font-medium text-yellow-800 focus:outline-none hover:text-yellow-900 mt-50"
                >
                  <span className="underline">Feedback</span>
                </button>
              </Link>
            </div>
            <div className="mt-4 flex md:mt-0 md:ml-4 justify-end desktop:visible mobile:invisible">
              <Link to="/login">
                <button
                  type="submit"
                  className=" inline-flex items-center  text-xl font-medium text-yellow-800 focus:outline-none hover:text-yellow-900 mt-50"
                >
                  <span className="underline">Employee Login</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex min-h-screen desktop:pl-8 mobile:pl-4">
          <div className="flex-col flex mt-12 py-2 px-4  desktop:w-2/4 mobile:w-full">
            <h1 className="text-5xl text-yellow-800 font-bold">
              Bringing Healthier Eating & Living to the next level
            </h1>
            <p className="text-2xl font-medium text-black my-10">
              The foods you choose make a difference!
            </p>
            <div className="mt-24">
              <a href="http://foodcanopy.com.sg/fcv1/">
                <button
                  type="submit"
                  className="space-x-2 items-center w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lime-500 hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span>Find Out More</span> <ArrowRight />
                </button>
              </a>
            </div>
            <h2 className="text-3xl mt-20 font-semibold">
              Install our food ordering app
            </h2>
            <div className="inline-flex">
              <img
                src={GooglePlayBadge}
                className="object-scale-down min-w-0 w-36 h-36"
                alt="googlePlayBadge"
              />
              <img
                src={AppStoreBadge}
                className="object-scale-down min-w-0 w-36 h-36"
                alt="appStoreBadge"
              />
            </div>
          </div>
          <div className=" lg:block relative desktop:w-2/4 mobile:w-1/5 desktop:visible mobile:invisible">
            <img
              className="absolute inset-0 h-full w-full object-fit p-10"
              src={Mobile}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingApp;
