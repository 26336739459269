import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";

export const options = {
  maintainAspectRatio: false,
  aspectRatio:2,
  elements: {
    point:{
        radius: 0
    },
  },
  responsive: true,
  plugins: {
    legend: false
  },
  scales: {
    y: {
      beginAtZero: true,
      type: 'linear',
      grace: '5%',
      grid: {
        display:false
      }
    }
  },
};

const DashboardLineGraph = ({ data }) => {
  console.log(data);
  const [lineData, setLineData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {

    setLineData({
      labels: data.map((item) => item.label),
      datasets: [
        {
          label: "Sales for Day",
          data: data.map((item) => item.value),
          fill: false,
          borderColor: "#7B4620",
          
        },
      ],
      
    });
  }, [data]);

  return (
    <div>
      <Line options={options} data={lineData} style={{height:'250px'}} />
    </div>
  );
};
export default DashboardLineGraph;
