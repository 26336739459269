import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import OrderCard from "../../hierarchy_modules/components/orderCard";

const user = JSON.parse(localStorage.getItem("user"));

export default function TOCard() {

  const [orderTypesTodayOrder, setOrderTypesTodayOrder] = useState(null);
  const [load, setLoad] = useState(true);

  async function fetchOrderTypesTodayOrder() {

    let getOrderTypesTodayOrder = commonConfigs.apiUrls.GetOrderTypesTodayOrder();
    if(user.access_multiple_location == 0 && user.location_id) {
      getOrderTypesTodayOrder = getOrderTypesTodayOrder + `&location_id=${user.location_id}`
    }

    await axios
      .get(getOrderTypesTodayOrder, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log("fetchOrderTypesTodayOrder", res.data.result.data);
        setOrderTypesTodayOrder(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
      
  }

  useEffect(() => {
      fetchOrderTypesTodayOrder().catch((err) => {
        console.log("err: " + err);
      });
  }, [load]);


  return (
    <div style={{height:'inherit', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
      {orderTypesTodayOrder && (
        <OrderCard
          title="Today's Orders"
          subtitle={
            orderTypesTodayOrder && orderTypesTodayOrder.length > 0 
              ? orderTypesTodayOrder[0].total_orders + "\nOrders"
              : "No Orders"
          }
          data={orderTypesTodayOrder.map((item) => ({
            name: item.order_platform_types.order_platform_type,
            value: item.order_platform_types.orders,
          }))}
        />
      )}
    </div>   
  );
}
