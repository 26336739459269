import axios from "axios";
import Select from "react-select";
import { InfoCircle } from "iconsax-react";
import { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import commonConfigs from "../../../../config/config";
import { Dialog, Transition } from "@headlessui/react";
import { AZURE_BLOB_CONTAINER_PRODUCTS } from "../../../../constants";
import { useUploadFile } from "../../../../commons/components/uploadFileCard";

const user = JSON.parse(localStorage.getItem("user"));

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function UpdateAddOnMenuModal({
  setUpdateModal,
  row,
  load,
  isAddonModal,
  isDisabledEditPrice,
}) {
  const merchant_id = row.merchant_id;
  const product_id = row.product_id;

  const getHpbUrl = commonConfigs.apiUrls.HPB();
  const getEMenuCatUrl = commonConfigs.apiUrls.GetEMenuCategory();
  const getAddOnsUrl = commonConfigs.apiUrls.GetAllAddOns(merchant_id);
  const getMerchantEMenuUrl =
    commonConfigs.apiUrls.GetMerchantEMenu(merchant_id);

  const [file, fileCard, uploadFile] = useUploadFile({
    container: AZURE_BLOB_CONTAINER_PRODUCTS,
  });

  const [open, setOpen] = useState(true);
  const [hpb, setHpb] = useState([]);
  const [menus, setMenus] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  // Multi select.
  const [category, setCategory] = useState([]);
  const [addOns, setAddOns] = useState([]);

  const [lazyData, setLazyData] = useState({});
  // console.log(row.tagged_products[0].add_on_upper_limit)

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      product_name: row.product_name,
      product_sequence: row.product_sequence,
      product_sku: row.product_sku,
      product_upc: row.product_upc,
      product_code: row.product_code,
      product_desc: row.product_desc,
      product_price: row.product_price,
      product_price_change: row.product_price_change,
      calorie_count: row.calorie.calorie_count,
      product_preference: row.product_preference,
      // add_on_upper_limit: isAddonModal ? (row.tagged_products[0].add_on_upper_limit !== undefined ? row.tagged_products[0].add_on_upper_limit : 0) : "",
      add_on_upper_limit: isAddonModal ? (row.tagged_products.length !== 0 ? row.tagged_products[0].add_on_upper_limit : 0) : "",
      is_visible: {
        value: row.is_visible,
        label: row.is_visible == 1 ? "True" : "False",
      },
      product_img_url: row.product_img_url,
      is_add_on: {
        value: row.is_add_on,
        label: row.is_add_on == 1 ? "True" : "False",
      },
    },
  });



  async function getEMenuCat() {
    await axios
      .get(getEMenuCatUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setCategory(res.data.result.data);

        const categoriesFound = res.data.result.data.filter((category) =>
          row.product_cat.find(
            ({ product_cat_id }) => category.product_cat_id == product_cat_id
          )
        );

        var formattedCategories = [];

        categoriesFound.forEach((tag) => {
          formattedCategories.push({
            value: tag.product_cat_id,
            label: tag.product_cat_name,
          });
        });

        setLazyData((lazyData["product_cat"] = formattedCategories));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function getHpb() {
    await axios
      .get(getHpbUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setHpb(res.data.result.data);
        res.data.result.data.forEach((hpbCategory) => {
          if (hpbCategory.hpb_cat_id == row.hpb_health_choice.hpb_cat_id) {
            setLazyData(
              (lazyData["hpb_health_choice"] = {
                value: hpbCategory.hpb_cat_id,
                label: hpbCategory.hpb_cat_name,
              })
            );
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getAddOns() {
    await axios
      .get(getAddOnsUrl, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        setAddOns(res.data.result.data);

        const addOnsFound = res.data.result.data.filter((addOn) =>
          row.add_ons.find(({ product_id }) => addOn.product_id == product_id)
        );

        var formattedAddOns = [];

        addOnsFound.forEach((addOn) => {
          formattedAddOns.push({
            value: addOn.product_id,
            label: addOn.product_name,
          });
        });

        setLazyData((lazyData["add_ons"] = formattedAddOns));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getMerchantData() {
    await axios
      .get(getMerchantEMenuUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setMenus(res.data.result.data);

        const taggedProductsFound = res.data.result.data.filter(
          (taggedProduct) =>
            row.tagged_products.find(
              ({ product_id }) => taggedProduct.product_id == product_id
            )
        );

        var formattedTaggedProducts = [];

        taggedProductsFound.forEach((taggedProduct) => {
          formattedTaggedProducts.push({
            value: taggedProduct.product_id,
            label: taggedProduct.product_name,
          });
        });
        setLazyData((lazyData["tagged_products"] = formattedTaggedProducts));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const getSelectData = async () => {
      if (isAddonModal) {
        await getMerchantData().catch((err) => {
          console.log("err: " + err);
        });
      } else {
        await getAddOns().catch((err) => {
          console.log("err for add ons: " + err);
        });
      }
      await getEMenuCat().catch((err) => {
        console.log("err for category: " + err);
      });
      await getHpb().catch((err) => {
        console.log("err for hpb: " + err);
      });
    };

    getSelectData()
      .then(() => {
        reset(lazyData);
      })
      .catch((err) => {
        console.log("err for roles: " + err);
      });
  }, []);

  const options = [
    { value: 0, label: "False" },
    { value: 1, label: "True" },
  ];

  const categoryOptions = category.map((d) => ({
    value: d.product_cat_id,
    label: d.product_cat_name,
  }));

  const hpbOptions = hpb.map((d) => ({
    value: d.hpb_cat_id,
    label: d.hpb_cat_name,
  }));

  const menuOptions = menus.map((d) => ({
    value: d.product_id,
    label: d.product_name,
  }));

  const addOnOptions = addOns.map((d) => ({
    value: d.product_id,
    label: d.product_name,
  }));
  function formatData(data) {
    var is_visible = data.is_visible.value;
    var is_add_on = data.is_add_on.value;
    var hpb_health_choice = data.hpb_health_choice ? data.hpb_health_choice.value : null;
    // var hpb_health_choice = data.hpb_health_choice.value;
    console.log("hpb_health_choice", hpb_health_choice);
    var product_start_time = data.product_start_time;
    var product_end_time = data.product_end_time;

    var taggedProductIds = [];
    var addOnIds = [];
    console.log("this is the addon upper limit" + data.add_on_upper_limit);
    console.log(data.tagged_products);
    console.log(isAddonModal);
    if (isAddonModal)
      taggedProductIds = data.tagged_products.map((product) => {
        return { product_id: product.value, add_on_upper_limit: data.add_on_upper_limit };
      });
    else
      addOnIds = data.add_ons.map((addon) => {
        return addon.value;
      });

    var categoryIds = data.product_cat.map((category) => {
      return category.value;
    });

    var product_visible_days = "";
    if (data.product_visible_days) {
      if (data.product_visible_days.length > 0) {
        product_visible_days = data.product_visible_days.map((item) => item.value).join("");
      }
    }
    var newData = data;

    if (isAddonModal) newData.tagged_products = taggedProductIds;
    else newData.add_ons = addOnIds;
    console.log("taggedProductIds", taggedProductIds);
    newData.calorie_color_sys_code = 1;
    newData.is_visible = is_visible;
    newData.product_visible_days = product_visible_days;
    newData.product_start_time = product_start_time;
    newData.product_end_time = product_end_time;
    newData.is_add_on = is_add_on;
    newData.hpb_health_choice = hpb_health_choice;
    newData.product_cat = categoryIds;
    newData.product_price_change = data.product_price;
    newData.account_id = user.account_id;
    newData.add_on_upper_limit = data.add_on_upper_limit
    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button
    // console.log(data);
    // const formatted_data = formatData(data);
    // console.log("formatData(data)", formatted_data);
    
    
    // if (file) {
    //   const image_url = await uploadFile(`products/${row.product_id}`);

    //   if (image_url) data.product_img_url = image_url;
    // }

    const formatteddata = formatData(data);
    console.log("formatData(data)", formatteddata);
    
    console.log('product image name',`${row.product_id}/${file.name}`);
    if (file) {
      const image_url = await uploadFile(`${row.product_id}/${file.name}`);

      if (image_url) 
      {
        formatteddata.product_img_url = image_url;
      }
    }

    // TODO: Need to be change back to update tagged products. Now api is not ready.
    var url = "";
    if (isAddonModal)
      url = commonConfigs.apiUrls.UpdateAddOnItem(merchant_id, product_id);
    else url = commonConfigs.apiUrls.UpdateMenu(merchant_id, product_id);
    // var url = commonConfigs.apiUrls.UpdateMenu(merchant_id, product_id);

    console.log(url);

    axios
      .put(url, formatteddata, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setUpdateModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setUpdateModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Update Add On Item
                    </Dialog.Title>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_cat"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Category
                      </label>
                      <Controller
                        name="product_cat"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={categoryOptions}
                            isSearchable={true}
                            placeholder="Select a Category"
                            className="text-sm"
                            isMulti={true}
  
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_name"
                          id="product_name"
                          className={
                            !errors.product_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Product Name"}
                          {...register("product_name", { required: true })}

                        />
                        {errors.product_name?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_sequence"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Sequence
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_sequence"
                          id="product_sequence"
                          className={
                            !errors.product_sequence
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Sequence"}
                          {...register("product_sequence", { required: true })}

                        />
                        {errors.product_sequence?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_sku"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item SKU
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_sku"
                          id="product_sku"
                          className={
                            !errors.product_sku
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item SKU"}
                          {...register("product_sku", { required: true })}

                        />
                        {errors.product_sku?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_upc"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item UPC
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_upc"
                          id="product_upc"
                          className={
                            !errors.product_upc
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item UPC"}
                          {...register("product_upc", { required: true })}

                        />
                        {errors.product_upc?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_code"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Product Code (H365)
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_code"
                          id="product_code"
                          className={
                            !errors.product_code
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Product Code (H365)"}
                          {...register("product_code", { required: true })}

                        />
                        {errors.product_code?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_desc"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Description
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_desc"
                          id="product_desc"
                          className={
                            !errors.product_desc
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Description"}
                          {...register("product_desc", { required: false })}

                        />
                        {errors.product_desc?.type === "required" && errorIcon}
                      </div>
                    </div>
                    {!isDisabledEditPrice ? <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_price"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Price
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="-9999999" // Set a lower bound for negative numbers
                          // max="9999999" // Set an upper bound for positive numbers
                          // min="0"
                          step="any"
                          name="product_price"
                          id="product_price"
                          className={
                            !errors.product_price
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Price"}
                          {...register("product_price", {
                            required: true,
                            valueAsNumber: true,
                            pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                            // validate: (number) => parseInt(number) > 0,
                          })}
                        />
                        {errors.product_price?.type === "required" && errorIcon}
                      </div>
                    </div> : <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_price"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Price
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                            min="0"
                            step="any"
                          name="product_price"
                            id="product_price"
                            style={{backgroundColor:'#ececec'}}
                          className={
                            !errors.product_price
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Price"}
                          {...register("product_price", {
                            // required: true,
                            // valueAsNumber: true,
                            pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                            // disabled:true
                            // validate: (number) => parseInt(number) > 0,
                          })}
                          readOnly
                        />
                        {/* {errors.product_price?.type === "required" && errorIcon} */}
                      </div>
                    </div>}
                    
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="product_price_change"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Item Price Change
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="product_price_change"
                          id="product_price_change"
                          style={{backgroundColor:'#ececec'}}
                          className={
                            !errors.product_price_change
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={0}
                          {...register("product_price_change", {
                            // required: true,
                            valueAsNumber: true,
                            pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                            // disabled:true
                            // validate: (number) => parseInt(number) > 0,
                          })}
                          readOnly
                        />
                        {/* {errors.product_price?.type === "required" && errorIcon} */}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="calorie_count"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Calorie Count
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          name="calorie_count"
                          id="calorie_count"
                          // min="0"
                          className={
                            !errors.calorie_count
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Calorie Count"}
                          {...register("calorie_count", {
                            required: true,
                            valueAsNumber: true,
                          })}

                        />
                        {errors.calorie_count?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="hpb_health_choice"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        HPB Healthier Choice
                      </label>
                      <Controller
                        name="hpb_health_choice"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={hpbOptions}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
  
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="is_add_on"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Is this product an add on?
                      </label>
                      <Controller
                        name="is_add_on"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={options}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
  
                          />
                        )}
                      />
                    </div>
                    {!isAddonModal ? (
                      <div className="mt-2 sm:col-span-6">
                        <label
                          htmlFor="add_ons"
                          className="block text-sm font-medium text-yellow-800"
                        >
                          Add Ons
                        </label>
                        <Controller
                          name="add_ons"
                          control={control}
                          render={({ field }) => (
                            <Select
                              isClearable
                              {...field}
                              options={addOnOptions}
                              isSearchable={true}
                              placeholder="Select an option"
                              className="text-sm"
                              isMulti={true}
    
                            />
                          )}
                        />
                      </div>
                    ) : (
                      <div className="mt-2 sm:col-span-6">
                        <label
                          htmlFor="tagged_products"
                          className="block text-sm font-medium text-yellow-800"
                        >
                          Tagged Products
                        </label>
                        <Controller
                          name="tagged_products"
                          control={control}
                          render={({ field }) => (
                            <Select
                              isClearable
                              {...field}
                              options={menuOptions}
                              isSearchable={true}
                              placeholder="Select an option"
                              className="text-sm"
                              isMulti={true}
    
                            />
                          )}
                        />
                      </div>
                    )}
                    {!isAddonModal ? (
                      <>
                      </>
                    ) : (
                      < div className="mt-2 sm:col-span-6">
                        <label
                          htmlFor="add_on_upper_limit"
                          className="block text-sm font-medium text-yellow-800"
                        >
                          Add On Upper Limit
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="number"
                            name="add_on_upper_limit"
                            id="add_on_upper_limit"
                            className={
                              !errors.add_on_upper_limit
                                ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                                : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            }
                            placeholder={"Enter Add On Upper Limit"}
                            {...register("add_on_upper_limit", {
                              required: true,
                              valueAsNumber: true,
                              min: 0,
                              // max: 5,
                            })}
                          />
                          {errors.add_on_upper_limit?.type === "required" &&
                            errorIcon}
                        </div>
                      </div>)}
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="is_visible"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Is Visible?
                      </label>
                      <Controller
                        name="is_visible"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={options}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
  
                          />
                        )}
                      />
                    </div>

                    <div className="sm:col-span-6 mt-2">
                      <label
                        htmlFor="upload_file"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Upload file
                      </label>
                      {fileCard}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setUpdateModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
