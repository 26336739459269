import styles from '../../../styles/styles.module.css';

const GetDishwareRequest = () => {
    return (
        <div style={{display: 'grid', 'gridTemplateAreas':`'details details code`,'gridTemplateColumns': 'repeat(3, minmax(0, 1fr))', gap:3, maxWidth: 'inherit'}}> 
        
        {/* Details component */}
        <div style={{gridArea: 'details', marginBottom: '0px'}}>
            <div className={styles.cardbod}>
                <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#378805'}}>Get Dishware Requests</h3>
                <hr />
                <p style={{marginTop: '10px'}}> This API retrieves dishware requests. </p>

                {/* API Parameters */}
                <div style={{marginTop: '20px'}}>
                    <h6 style={{textDecoration: 'underline'}}>Parameters</h6>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a. merchant_id : merchant's ID</p>
                        <p>b. org_id : organisation's ID</p>
                        <p>c. start : start period</p>
                        <p>d. end : end period</p>
                        <p>e. status : status</p>
                    </div>

                    <h6 style={{textDecoration: 'underline', marginTop: '10px'}}>Output data</h6>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a. request dishware id</p>
                        <p>b. dishwashing total fee</p>
                        <p>c. request status</p>
                        <p>d. completed on</p>
                        <p>e. requested on</p>
                        <p>f. details : array of details below</p>
                        <div style={{marginLeft: '15px', color: '#378805'}}>
                            <p>i. id </p>
                            <p>ii. dishware id</p>
                            <p>iii. qty</p>
                            <p>iv. name</p>
                            <p>v. price</p>
                            <p>vi. img</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Code component */}
        <div style={{gridArea: 'code', marginBottom: '0px', backgroundColor: '#3f3f3f'}}>
            <div style={{backgroundColor: '#378805', color: 'white', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', paddingTop: '5px' , paddingBottom: '5px'}}>
                Get Dishware Requests API
            </div>

            <div>
                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'15px', color: 'white'}}>Request (GET)</h6>
                    <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                        <code className={styles.code}>/api/v1/transactions/requests/dishwares/{"{"}merchant_id{"}"}/{"{"}org_id{"}"}/{"{"}start{"}"}/{"{"}end{"}"}/{"{"}status{"}"}</code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'10px', color: 'white'}}>Example</h6>
                    <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                        <code className={styles.code}>/api/v1/transactions/requests/dishwares/7550eeb0-3a79-4d85-a244-7fc109966fa7/b7ad3a7e-513d-4f5b-a7fe-73363a3e8699/2023-03-29/2023-03-30/0?page=0</code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'10px', color: 'white'}}>Header</h6>
                    <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                    <code>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        <span className={styles.codestyle2}>"authorization":</span> <span className={styles.codestyle3}>"Bearer example"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>dy1UG9zWBfLRVNTQtWQr</span>
                        <br />
                        <span className={styles.codestyle1}>{"}"}</span>
                    </code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response</h6>
                    <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                        <code>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        
                        <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        <span className={styles.codestyle2}>"data"</span><span className={styles.codestyle1}>:</span>
                        <span className={styles.codestyle1}>{"["}</span>
                        <br />
                        <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"{"}</span>
                        <br/>

                        {/* start of data */}
                        <div style={{marginLeft: '15px'}}>
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"request_dishware_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 88<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"dishwashing_total_fee"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 20<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"request_status"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 0<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"completed_on"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "2023-05-03T07:10:14.740Z"<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"requested_on"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> 2023-05-03T07:10:14.740Z<span className={styles.codestyle1}>,</span></span>
                            <br />
                            
                            {/* second array in code */}
                            <span className={styles.codestyle2}>"details"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br />
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"{"}</span>
                            <br/>

                            {/* Sapporo Ramen */}
                            <div style={{marginLeft: '10px'}}>
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 645<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"dishware_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 4<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"qty"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 27<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "SPOONS"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"price"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 40<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"img"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "https://live.staticflickr.com/6119/6427879747_725294b3fd_b.jpg"</span>
                                <br />
                                
                            <span className={styles.codestyle1} style={{marginLeft: '20px'}}>{"}"}</span>
                            <br/>
                            </div>
                        </div>
                        <span className={styles.codestyle1} style={{marginLeft: '40px'}}>{"]"}</span>
                        <br/>
                        <span className={styles.codestyle1} style={{marginLeft: '35px'}}>{"}"}</span>
                        <br />
                        {/* ending brackets */}
                        <span className={styles.codestyle1} style={{marginLeft: '26px'}}>{"]"}</span>
                        <br/>
                        <span className={styles.codestyle1} style={{marginLeft: '20px'}}>{"}"}</span>
                        <br/>
                        <span className={styles.codestyle1} style={{marginLeft: '15px'}}>{"}"}</span>
                        </code>
                    </blockquote>   
                </div>

            </div>
        </div>
        </div>
    );
  };
  
  export default GetDishwareRequest;
