import { useContext } from "react";
import { classNames } from "../../../../utils/custom/helper";
import {
  EMENUTabsContext,
  EMENUCurrentTabContext,
} from "../context/eMenuContext";

const EMENUTabs = () => {
  const { tabs } = useContext(EMENUTabsContext);
  const { currentTab, setTab } = useContext(EMENUCurrentTabContext);
  return (
    <>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={currentTab.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              onClick={() => setTab(tab)}
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.name == currentTab.name
                  ? "bg-yellow-800 text-white"
                  : "text-gray-500 hover:text-yellow-800",
                "px-3 py-2 font-medium text-sm rounded-md"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </>
  );
};

export default EMENUTabs;
