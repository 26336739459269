import axios from "axios";
import Select from "react-select";
import { InfoCircle } from "iconsax-react";
import { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import commonConfigs from "../../../../config/config";
import { Dialog, Transition } from "@headlessui/react";
import { getRandomUUID } from "../../../../utils/custom/helper";
import { AZURE_BLOB_CONTAINER_MERCHANT } from "../../../../constants";
import { useUploadFile } from "../../../../commons/components/uploadFileCard";

const createUrl = commonConfigs.apiUrls.CreateMerchant();
const getLocationsUrl = commonConfigs.apiUrls.GetLocations();
// const getMerchantsTags = commonConfigs.apiUrls.MerchantsCategories();
const getMerchantsTags = commonConfigs.apiUrls.CategoriesTags();
const getMerchantTypeUrl = commonConfigs.apiUrls.MerchantType();


const user = JSON.parse(localStorage.getItem("user"));

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function CreateMerchantModal({ setCreateModal, load }) {
  const [open, setOpen] = useState(true);
  const [locations, setLocations] = useState([]);
  // const [merchantUen, setMerchantUen] = useState('');
  const [foodTypes, setFoodTypes] = useState([]);
  const [optionLoad, setOptionLoad] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [merchantTypes, setMerchantTypes] = useState([]);

  const [file, fileCard, uploadFile] = useUploadFile({
    container: AZURE_BLOB_CONTAINER_MERCHANT,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      merchant_operating_days: "1234567",
      merchant_start_time: "23:30:00.0000000",
      merchant_end_time: "15:30:00.0000000",
      merchant_is_visible: {
        value: 1,
        label: "True",
      },
      is_operating: {
        value: 0,
        label: "False",
      },
    }
  });

  function formatData(data) {
    var location_id = data.location_id.value;
    var merchant_name = data.merchant_name;
    var merchant_uen = data.merchant_uen;
    var merchant_lat = data.merchant_lat;
    var merchant_lng = data.merchant_lng;
    var location_name = data.location_id.label;
    var is_visible = data.merchant_is_visible.value;
    var is_operating = data.is_operating.value;
    var dishwashing_txn_fee = parseInt(data.dishwashing_txn_fee); // Parse the value as an integer
    var merchant_gpo_commission_fee = parseInt(data.merchant_gpo_commission_fee); // Parse the value as an integer
    var merchant_gpo_commission_fee_rate = data.merchant_gpo_commission_fee_rate; // Parse the value as an integer
    var break_start_time = data.break_start_time;
    var break_end_time = data.break_end_time;
    var merchant_discount_start_time = data.merchant_discount_start_time;
    var merchant_discount_end_time = data.merchant_discount_end_time;
    var nets_click_mid = data.nets_click_mid; 
    var nets_click_tid = data.nets_click_tid; 
    var nets_qr_mid = data.nets_qr_mid; 
    var nets_qr_tid = data.nets_qr_tid; 
    var sys_code = data.merchant_type_sys_code.value;
    var merchant_dns_url = data.merchant_dns_url;

    var tags = [];

    if (data.tags && data.tags.length > 0) {
      data.tags.forEach((element) => {
        tags.push(element.value);
      });
    } 

    if (merchant_name && merchant_name.endsWith(' ')) {
      merchant_name = merchant_name.trimEnd();
    }

    var newData = data;

    newData.merchant_discount_quota = data.merchant_discount_quota;
    newData.location_id = location_id;
    newData.merchant_uen = merchant_uen;
    newData.merchant_lat = merchant_lat;
    newData.merchant_lng = merchant_lng;
    newData.merchant_name = merchant_name;
    newData.tags = tags;
    newData.location_name = location_name;
    newData.merchant_img_url = "";
    newData.updated_by = user.account_id;
    newData.merchant_operating_days = data.merchant_operating_days;
    newData.merchant_start_time = data.merchant_start_time;
    newData.merchant_end_time = data.merchant_end_time;
    newData.break_start_time = break_start_time;
    newData.break_end_time = break_end_time;
    newData.merchant_discount_start_time = merchant_discount_start_time;
    newData.merchant_discount_end_time = merchant_discount_end_time;
    newData.merchant_is_visible = is_visible;
    newData.is_operating = is_operating;
    newData.dishwashing_txn_fee = dishwashing_txn_fee;
    newData.merchant_gpo_commission_fee = merchant_gpo_commission_fee;
    newData.merchant_gpo_commission_fee_rate = merchant_gpo_commission_fee_rate;
    newData.nets_click_mid = nets_click_mid;
    newData.nets_click_tid = nets_click_tid;
    newData.nets_qr_mid = nets_qr_mid;
    newData.nets_qr_tid = nets_qr_tid;
    newData.merchant_type_sys_code = sys_code;
    newData.merchant_dns_url = merchant_dns_url;

    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button

    var FormattedData = formatData(data);
    console.log('formatted data',FormattedData);

    console.log('merchant image name',`${FormattedData.merchant_code}/${file.name}`);
    if (file) {
      const url = await uploadFile(`${FormattedData.merchant_code}/${file.name}`);

      if (url) {
        FormattedData.merchant_img_url = url;
      }
    }
    
    console.log(createUrl);
    console.log(FormattedData);
    console.log(commonConfigs.apiHeader);

    axios
      .post(createUrl, FormattedData, { headers: commonConfigs.apiHeader })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setCreateModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  useEffect(() => {
    async function getLocations() {
      await axios
        .get(getLocationsUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          setLocations(res.data.result.data.filter(item => item.is_deleted === 0));
          setOptionLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getFoodTypes() {
      await axios
        .get(getMerchantsTags, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          setFoodTypes(res.data.result.data);
          setOptionLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getMerchantType() {
      await axios
        .get(getMerchantTypeUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          setMerchantTypes(res.data.result.data);
          setOptionLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (optionLoad) {
      getLocations().catch((err) => {
        console.log("err for locations: " + err);
      });
      getFoodTypes().catch((err) => {
        console.log("err for food types: " + err);
      });
      getMerchantType().catch((err) => {
        console.log("err for merchant type: " + err);
      });
    }
  }, []);
  const options = [
    { value: 0, label: "False" },
    { value: 1, label: "True" },
  ];
  const operatingOptions = [
    { value: 0, label: "False" },
    { value: 1, label: "True" },
  ];
  const locationOption = locations.map((d) => ({
    value: d.location_id,
    label: d.location_name,
  }));

  const foodTypesOption = foodTypes.map((d) => ({
    value: d.tag_cat_id,
    label: d.tag_cat_name,
  }));
  const merchantTypeOptions = merchantTypes.map((d) => ({
    value: d.sys_code_id,
    label: d.merchant_type,
  }));
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setCreateModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Create Merchant
                    </Dialog.Title>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="location_id"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Location
                      </label>
                      <Controller
                        name="location_id"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={locationOption}
                            isSearchable={true}
                            placeholder="Select a Location"
                            className="text-sm"
  
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Merchant Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_name"
                          id="merchant_name"
                          pattern="[A-Za-z0-9\s]+"
                          className={
                            !errors.merchant_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Merchant Name"}
                          {...register("merchant_name", { required: true })}
                          // pattern="^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"

                        />
                        {errors.merchant_name?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_email"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Merchant Email
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="email"
                          name="merchant_email"
                          id="merchant_email"
                          className={
                            !errors.merchant_email
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Merchant Email"}
                          {...register("merchant_email", { required: true })}
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"

                        />
                        {errors.merchant_name?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_email"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Merchant Domain
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_dns_url"
                          id="merchant_dns_url"
                          className={
                            !errors.merchant_dns_url
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Merchant Domain"}
                          {...register("merchant_dns_url", { required: true })}
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"

                        />
                        {errors.merchant_name?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_code"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Merchant Code
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_code"
                          id="merchant_code"
                          className={
                            !errors.merchant_code
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Request Merchant Code From Beveat"}
                          {...register("merchant_code", {
                            required: true,
                            pattern: {
                              value: /@/, // Regular expression to require the '@' special character
                              message: "The Merchant Code must contain the '@' special character",
                            },
                          })}
                        />
                        {errors.merchant_code?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_type_sys_code"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Merchant Type
                      </label>
                      <Controller
                        name="merchant_type_sys_code"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={merchantTypeOptions}
                            isSearchable={true}
                            placeholder="Select a Type"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Merchant Mobile
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          name="merchant_mobile"
                          id="merchant_mobile"
                          className={
                            !errors.merchant_mobile
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Merchant Mobile"}
                          {...register("merchant_mobile", { required: true })}
                          pattern="[89][0-9]{8}"

                        />
                        {errors.merchant_mobile?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="tags"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Food Type
                      </label>
                      <Controller
                        name="tags"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={foodTypesOption}
                            isSearchable={true}
                            isMulti={true}
                            placeholder="Select a Food Type"
                            className="text-sm"
  
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_poc"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Licensee name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_poc"
                          id="merchant_poc"
                          className={
                            !errors.merchant_poc
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Licensee Name"
                          {...register("merchant_poc", { required: true })}
                          // pattern="^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"

                        />
                        {errors.merchant_poc?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_uen"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Company UEN
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_uen"
                          id="merchant_uen"
                          className={
                            !errors.merchant_uen
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Company UEN"
                          {...register("merchant_uen", { required: true })}

                        />
                        {errors.merchant_uen?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_unit_no"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Unit No.
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_unit_no"
                          id="merchant_unit_no"
                          className={
                            !errors.merchant_unit_no
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Unit No."
                          {...register("merchant_unit_no", { required: true })}

                        />
                        {errors.merchant_unit_no?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_lat"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Latitude
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_lat"
                          id="merchant_lat"
                          className={
                            !errors.merchant_unit_no
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Merchant Latitude"
                          {...register("merchant_lat", { required: false })}

                        />
                        {errors.merchant_lat?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_lng"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Longitude
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_lng"
                          id="merchant_lng"
                          className={
                            !errors.merchant_unit_no
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Merchant Longitude"
                          {...register("merchant_lng", { required: false })}

                        />
                        {errors.merchant_lng?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="dishwashing_txn_fee"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Dishwashing Txn Fee
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="0"
                          step="1"
                          name="dishwashing_txn_fee"
                          id="dishwashing_txn_fee"
                          // style={{backgroundColor:'#ececec'}}
                          className={
                            !errors.dishwashing_txn_fee
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Dishwashing Txn Fee"}
                          {...register("dishwashing_txn_fee", {
                            pattern: /^[0-9]*$/,
                          })}                      
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_gpo_commission_fee"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        GPO Commission Fee
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          // min="-9999999" // Set a lower bound for negative numbers
                          // max="9999999" // Set an upper bound for positive numbers
                          min="0"
                          step="any"
                          name="merchant_gpo_commission_fee"
                          id="merchant_gpo_commission_fee"
                          className={
                            !errors.merchant_gpo_commission_fee
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter GPO Commission Fee"}
                          {...register("merchant_gpo_commission_fee", {
                            required: true,
                            valueAsNumber: true,
                            pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                          })}
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_gpo_commission_fee_rate"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        GPO Commission Fee Rate
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          // min="-9999999" // Set a lower bound for negative numbers
                          // max="9999999" // Set an upper bound for positive numbers
                          min="0"
                          step="any"
                          name="merchant_gpo_commission_fee_rate"
                          id="merchant_gpo_commission_fee_rate"
                          className={
                            !errors.merchant_gpo_commission_fee_rate
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter GPO Commission Fee Rate"}
                          {...register("merchant_gpo_commission_fee_rate", {
                            required: true,
                            valueAsNumber: true,
                            pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                          })}
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="nets_click_mid"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        NETS CLICK MID
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          step="1"
                          name="nets_click_mid"
                          id="nets_click_mid"
                          className={
                            !errors.nets_click_mid
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter NETS CLICK MID"}
                          {...register("nets_click_mid", {
                            pattern: /^[0-9]*$/,
                          })}                      
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="nets_click_tid"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        NETS CLICK TID
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="0"
                          step="1"
                          name="nets_click_tid"
                          id="nets_click_tid"
                          // style={{backgroundColor:'#ececec'}}
                          className={
                            !errors.nets_click_tid
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter NETS CLICK TID"}
                          {...register("nets_click_tid", {
                            pattern: /^[0-9]*$/,
                          })}                      
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="nets_qr_mid"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        NETS QR MID
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="0"
                          step="1"
                          name="nets_qr_mid"
                          id="nets_qr_mid"
                          // style={{backgroundColor:'#ececec'}}
                          className={
                            !errors.nets_qr_mid
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter NETS QR MID"}
                          {...register("nets_qr_mid", {
                            pattern: /^[0-9]*$/,
                          })}                      
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="nets_qr_tid"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        NETS QR TID
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="0"
                          step="1"
                          name="nets_qr_tid"
                          id="nets_qr_tid"
                          // style={{backgroundColor:'#ececec'}}
                          className={
                            !errors.nets_qr_tid
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter NETS QR TID"}
                          {...register("nets_qr_tid", {
                            pattern: /^[0-9]*$/,
                          })}                      
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_operating_days"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Operating Days
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          name="merchant_operating_days"
                          id="merchant_operating_days"
                          className={
                            !errors.merchant_operating_days
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Operating Days"
                          {...register("merchant_operating_days", { required: true })}
                        />
                        {errors.merchant_operating_days?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_start_time"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Start Time (24-Hr format)
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_start_time"
                          id="merchant_start_time"
                          className={
                            !errors.merchant_start_time
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Start Time"
                          {...register("merchant_start_time", { required: true })}
                        />
                        {errors.merchant_start_time?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_end_time"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        End Time (24-Hr format)
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_end_time"
                          id="merchant_end_time"
                          className={
                            !errors.merchant_end_time
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter End Time"
                          {...register("merchant_end_time", { required: true })}
                        />
                        {errors.merchant_end_time?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="break_start_time" className="block text-sm font-medium text-yellow-800">
                        Break Start Time
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="time"
                          name="break_start_time"
                          id="break_start_time"
                          className={
                            !errors.break_start_time
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Break Start Time (HH:MM:SS:am/pm)"}
                          {...register("break_start_time", {
                            required: false,
                            pattern: {
                              value: /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
                              message: "Please enter a valid time in the format 'HH:MM:SS'",
                            },
                          })}
                          step="1"
                        />
                        {errors.break_start_time?.type === "required" && errorIcon}
                        {errors.break_start_time?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.break_start_time?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="break_end_time" className="block text-sm font-medium text-yellow-800">
                        Break End Time 
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="time"
                          name="break_end_time"
                          id="break_end_time"
                          className={
                            !errors.break_end_time
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Break End Time (HH:MM:SS:am/pm)"}
                          {...register("break_end_time", {
                            required: false,
                            pattern: {
                              value: /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
                              message: "Please enter a valid time in the format 'HH:MM:SS'",
                            },
                          })}
                          step="1"
                        />
                        {errors.break_end_time?.type === "required" && errorIcon}
                        {errors.break_end_time?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.break_end_time?.message}
                          </span>
                        )}
                      </div>
                    </div> 

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_discount_quota"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Merchant Discount Quota
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="0"
                          step="1"
                          name="merchant_discount_quota"
                          id="merchant_discount_quota"
                          // style={{backgroundColor:'#ececec'}}
                          className={
                            !errors.merchant_discount_quota
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Merchant Discount Quota"}
                          {...register("merchant_discount_quota", {
                            pattern: /^[0-9]*$/,
                          })}                      
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="merchant_discount_start_time" className="block text-sm font-medium text-yellow-800">
                        Discount Start Time
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="datetime-local"
                          name="merchant_discount_start_time"
                          id="merchant_discount_start_time"
                          className={
                            !errors.merchant_discount_start_time
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Discount Start Time"}
                          {...register("merchant_discount_start_time", {
                            required: false,
                            // Add your pattern validation for date and time if needed
                          })}
                        />
                        {errors.merchant_discount_start_time?.type === "required" && errorIcon}
                        {errors.merchant_discount_start_time?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.merchant_discount_start_time?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label htmlFor="merchant_discount_end_time" className="block text-sm font-medium text-yellow-800">
                        Discount End Time 
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="datetime-local"
                          name="merchant_discount_end_time"
                          id="merchant_discount_end_time"
                          className={
                            !errors.merchant_discount_end_time
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Discount End Time"}
                          {...register("merchant_discount_end_time", {
                            required: false,
                            // Add your pattern validation for date and time if needed
                          })}
                        />
                        {errors.merchant_discount_end_time?.type === "required" && errorIcon}
                        {errors.merchant_discount_end_time?.type === "pattern" && (
                          <span className="text-red-600 text-sm">
                            {errors.merchant_discount_end_time?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_is_visible"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Is Visible?
                      </label>
                      <Controller
                        name="merchant_is_visible"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={options}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="is_operating"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Is Operating?
                      </label>
                      <Controller
                        name="is_operating"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={operatingOptions}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                    <div className="sm:col-span-6 mt-2">
                      <label
                        htmlFor="upload_file"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Upload Image
                      </label>
                      {fileCard}
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setCreateModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
