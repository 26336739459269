import { MerchantMenuContextProvider } from "../context/merchantMenuContext";
import MerchantsMenuLayout from "./merchantsMenuLayout";

const MerchantsMenuPage = () => {
  return (
    <MerchantMenuContextProvider>
      <MerchantsMenuLayout />
    </MerchantMenuContextProvider>
  );
};

export default MerchantsMenuPage;
