import { useContext } from "react";
import DashboardTabs from "../components/dashboardTabs";
import HierarchyRevenueLayout from "./hierarchyRevenueLayout";
import HierarchyBillingLayout from "./hierarchyBillingLayout";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import { DashboardCurrentTabContext } from "../context/dashboardContext";

const pages = [
  {
    name: "Hierarchy View",
    href: "/dashboard/hierarchy",
    current: true,
  },
];

const HierarchyLayout = () => {
  const { currentTab } = useContext(DashboardCurrentTabContext);
  console.log(currentTab);
  return (
    <div className="bg-white p-4 space-x-2">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mb-8">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            type="button"
            className="ml-3 inline-flex items-center px-4 py-2 text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
          >
            Export
          </button>
        </div>
      </div>
      <div className="mb-4">
        <DashboardTabs />
      </div>
      {currentTab.name == "Revenue" ? (
        <HierarchyRevenueLayout />
      ) : (
        <HierarchyBillingLayout />
      )}
    </div>
  );
};

export default HierarchyLayout;
