import { createContext, useState } from "react";

const tabs = [{ name: "All" }, { name: "Pending" }, { name: "Resolved" }, { name: "Cancelled" }];

export const IncidentReportTabsContext = createContext();
export const IncidentReportCurrentTabContext = createContext();

export const IncidentReportContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <IncidentReportTabsContext.Provider value={{ tabs }}>
      <IncidentReportCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </IncidentReportCurrentTabContext.Provider>
    </IncidentReportTabsContext.Provider>
  );
};
