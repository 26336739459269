import React, { useEffect, useState, useMemo } from "react";
import LeaveTabs from "../components/leaveTabs";
import { LeaveCurrentTabContext } from "../context/leaveContext";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import CreateLeaveModal from "../components/createLeaveModal";
import UpdateLeaveModal from "../components/updateLeaveModal";
import DeleteModal from "../../../../commons/components/deleteModal";
import Table from "../../../../commons/components/table";
import { Additem, Edit, Trash } from "iconsax-react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import { format } from "date-fns";
import Spinner from "../../../../commons/components/spinner";

const pages = [
  { name: "Configurations", href: "#", current: false },
  { name: "Leave Entitlement", href: "/configurations/leave", current: true },
];

const tiers = [
  {value: 1, label: 'Entry-Level'}, 
  {value: 2, label: 'Mid-Level'}, 
  {value: 3, label: 'Executive-Level'},
];

const LeaveLayout = () => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  async function getData() {

    const GetLeaveEntitlement = commonConfigs.apiUrls.GetLeaveEntitlement();

    await axios
      .get(GetLeaveEntitlement, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Leave",
      accessor: "leave_name",
    },
    {
      Header: "Entitlement",
      accessor: "leave_entitlement",
    },
    {
      Header: "Tier",
      accessor: (row) => {
        const tier = tiers.find((t) => t.value === row.leave_entitlement_tier);
        return tier ? tier.label : "";
      },
    },
    {
      Header: "Type",
      accessor: "employment_type_name",
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => value ? value : '-'
    },
    {
      Header: "Last Updated On",
      accessor: ({ updated_on }) => {
        if (updated_on) {
          return updated_on.substring(0, 10) + " " + updated_on.substring(11, 16);
        } else {
          return "-";
        }
      },
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            {localStorage.getItem("roleid") == 2 || localStorage.getItem("roleid") == 5 ?
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
            : <></> }
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full p-6">
      <div className="md:flex md:items-center md:justify-between">
      <div className="flex-1 min-w-0">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="desktop:mt-4 flex mobile:pt-2  mobile:pb-4 mobile:space-x-2">
          <button
            type="button"
            className="ml-3 inline-flex items-center text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setCreateModalOpen(true)}
          >
            Create Leave <Additem className="ml-3" />
          </button>
        </div>
      </div>

      <div className="mt-1">
        <LeaveTabs />
      </div>
      

      {isCreateModalOpen && <CreateLeaveModal setCreateModal={setCreateModalOpen} load={setLoad} />}
      {data != null ? (
        <LeaveCurrentTabContext.Consumer>
          {(value) => (
            <div className="mt-3 ">
              <Table
                columns={columns}
                data={data}
                filters={columns.map((column) => column.accessor)}
                initialValue={value.currentTab.name}
                initialFilter = "employment_type_name"
              />
            </div>
          )}
        </LeaveCurrentTabContext.Consumer>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      {isUpdateModalOpen && (
        <UpdateLeaveModal
          setUpdateModal={setUpdateModalOpen}
          load={setLoad}
          row={rowToEdit}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          setDeleteModal={setDeleteModalOpen}
          load={setLoad}
          url={commonConfigs.apiUrls.UpdateLeaveEntitlement(rowToEdit.leave_entitlement_id)}
        />
      )}
    </div>
  );
};

export default LeaveLayout;
