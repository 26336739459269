import { useContext } from "react";
import { classNames } from "../../../../../utils/custom/helper";
import { 
  eVoucherTabsContext, 
  eVoucherCurrentTabContext, 
} from "../context/eVoucherContext";

const EVoucherMenuTabs = () => {
  const { tabs } = useContext(eVoucherTabsContext);
  const { currentTab, setTab } = useContext(eVoucherCurrentTabContext);
  return (
    <>
      {/* <div className="sm:hidden mobile:ml-2 mb-4 ">
        <label htmlFor="tabs" className="sr-only ">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className=" block mobile:h-12 mobile:w-4/12 desktop:w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={currentTab.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div> */}
      <div className=" sm:block pb-4">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              onClick={() => setTab(tab)}
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.name == currentTab.name
                  ? "underline text-black font-bold absolu"
                  : "text-gray-500 font-bold hover:text-yellow-800",
                "px-2 py-2 font-medium text-sm rounded-md"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </>
  );
};

export default EVoucherMenuTabs;
