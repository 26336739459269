import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import commonConfigs from "../../../../config/config";
import axios from "axios";
import Select from "react-select";
import { useForm, Controller  } from "react-hook-form";
import { InfoCircle } from "iconsax-react";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { AZURE_BLOB_CONTAINER_DISHWARES } from "../../../../constants";
import { useUploadFile } from "../../../../commons/components/uploadFileCard";

const user = JSON.parse(localStorage.getItem("user"));
const getLocationsUrl = commonConfigs.apiUrls.GetLocations();

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function UpdateDishwareModal({ setUpdateModal, row, load, locationCodeApi, locationIdApi }) {
  const [open, setOpen] = useState(true);
  const [locations, setLocations] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const [lazyData, setLazyData] = useState({});

  console.log(locationCodeApi);
  console.log(locationIdApi);

  const id = row.org_dishware_id;

  const [dishwareFile, dishwareFileCard, uploaddishwareFile] = useUploadFile({
    container: AZURE_BLOB_CONTAINER_DISHWARES,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      dishware_name: row.dishware_name,
      dishware_code: row.dishware_code,
      dishware_price: row.dishware_price,
    },
  });


  function formatData(data) {
    var newData = data;

    newData.location_id = data.location.value;
    newData.updated_by = user.account_id;
    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button
    var FormattedData = formatData(data);
    console.log('formatted data',FormattedData);

    console.log('dishware image name',`${locationCodeApi}/${dishwareFile.name}`);
    if (dishwareFile) {
      const url = await uploaddishwareFile(`${locationCodeApi}/${dishwareFile.name}`);

      if (url) {
        FormattedData.dishware_img_url = url;
      }
    }
    
    axios
      .put(commonConfigs.apiUrls.UpdateDishware(id), FormattedData, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setUpdateModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  async function getLocations() {
    await axios
      .get(getLocationsUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setLocations(res.data.result.data.filter(item => item.is_deleted === 0));

        res.data.result.data.filter(item => item.is_deleted === 0).forEach((location) => {
          if (location.location_id == locationIdApi) {
            setLazyData(
              (lazyData["location"] = {
                value: location.location_id,
                label: location.location_name,
              })
            );
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const getSelectData = async () => {
      await getLocations().catch((err) => {
        console.log("err for locations: " + err);
      });
    };

    getSelectData()
      .then(() => {
        console.log("getSelectData success");
        console.log(lazyData);
        reset(lazyData);
      })
      .catch((err) => {
        console.log("err for roles: " + err);
      });
  }, []);

  const locationOption = locations.map((d) => ({
    value: d.location_id,
    label: d.location_name,
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setUpdateModal(false)}
            />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Update Dishware
                    </Dialog.Title>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="location"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Location
                      </label>
                      <Controller
                        name="location"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={locationOption}
                            isSearchable={true}
                            placeholder="Select a Location"
                            className="text-sm"
  
                          />
                        )}
                      />
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="dishware_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Dishware Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="dishware_name"
                          id="dishware_name"
                          className={
                            !errors.dishware_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Dishware Name"
                          {...register("dishware_name", { required: true })}
                        />
                        {errors.dishware_name?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="dishware_code"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Dishware Code
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          name="dishware_code"
                          id="dishware_code"
                          className={
                            !errors.dishware_code
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Dishware Code"
                          {...register("dishware_code", { required: true })}
                        />
                        {errors.dishware_code?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="dishware_price"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Dishware Price
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="0"
                          name="dishware_price"
                          id="dishware_price"
                          className={
                            !errors.dishware_price
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Dishware Price"
                          {...register("dishwashing_price", {
                            required: true,
                            pattern : /^(?:\d*\.\d{1,2}|\d+)$/,
                            valueAsNumber: true,
                          })}
                        />
                        {errors.dishware_price?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="sm:col-span-6 mt-2">
                      <label
                        htmlFor="upload_file"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Upload Dishware Image
                      </label>
                      {dishwareFileCard}
                    </div>

                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setUpdateModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
