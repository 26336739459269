import axios from "axios";
import { format } from "date-fns";
import { Additem } from "iconsax-react";
import { Edit, Trash } from "iconsax-react";
import { AZURE_URL } from "../../../../constants";
import commonConfigs from "../../../../config/config";
import LocationsTabs from "../components/locationsTabs";
import Table from "../../../../commons/components/table";
import React, { useState, useMemo, useEffect } from "react";
import LocationsModal from "../components/createLocationsModal";
import UpdateLocationModal from "../components/updateLocationModal";
import DeleteModal from "../../../../commons/components/deleteModal";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import { LocationsCurrentTabContext } from "../context/locationsContext";
import Spinner from "../../../../commons/components/spinner";

const getLocationsUrl = commonConfigs.apiUrls.GetLocations();

const pages = [
  { name: "Configurations", href: "#", current: false },
  { name: "Locations", href: "/configurations/locations", current: true },
];

const LocationsLayout = () => {
  const [isCreateModal, setCreateModal] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();
  let user = JSON.parse(localStorage.getItem("user"))

  useEffect(() => {
    async function getData() {
      let arr = []
      await axios
        .get(getLocationsUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          let response = res.data.result.data;
          if(user.location_id && (user.access_multiple_location === 0 || !user.access_multiple_location)) {
            arr.push(response.find(({location_id}) => user.location_id === location_id))
            setData(arr.filter(item => item.is_deleted === 0)) 
          } else {
            setData(res.data.result.data.filter(item => item.is_deleted === 0));
          }
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Location Outlet",
      accessor: "location_name",
      Cell: ({ row, value }) => {
        const src = row.original.location_img_url;
        console.log(src);
        return (
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img src={src} />
            </div>
            <div className="ml-4">
              <div className="text-sm text-black">{value}</div>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Division Type",
      accessor: "division",
      filter: customFilterFunction,
    },
    {
      Header: "Location Code",
      accessor: "location_code",
    },
    {
      Header: "Address",
      accessor: "location_address",
    },
    {
      Header: "Postal Code",
      accessor: "location_zip_code",
    },
    {
      Header: "Total Slots",
      accessor: "location_outlet_total_stalls",
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            {localStorage.getItem("roleid") == 2 || localStorage.getItem("roleid") == 5 ?
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
            : <></> }
          </div>
        );
      },
    },
  ]);

  // Use a custom filter function
  function customFilterFunction(rows, id, filterValue) {
    if (filterValue !== "All") {
      return rows.filter((row) => row.original.division === filterValue);
    } else {
      return rows;
    }
  }

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="mt-4 flex mobile:pt-4  mobile: pb-4 mobile:space-x-2">
          <button
            type="button"
            className="ml-3 inline-flex items-centertext-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setCreateModal(true)}
          >
            Create Location Outlet <Additem className="ml-3" />
          </button>
        </div>
      </div>
      <LocationsTabs />
      {isCreateModal && (
        <LocationsModal setOpenModal={setCreateModal} load={setLoad} />
      )}
      {data != null ? (
        <LocationsCurrentTabContext.Consumer>
          {(value) => (
            <Table
              columns={columns}
              data={data}
              filters={columns.map((column) => column.accessor)}
              initialValue={value.currentTab.name}
              initialFilter="division"
            />
          )}
        </LocationsCurrentTabContext.Consumer>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      {isUpdateModal && (
        <UpdateLocationModal
          setOpenModal={setUpdateModal}
          load={setLoad}
          row={rowToEdit}
        />
      )}
      {isDeleteModal && (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          load={setLoad}
          url={commonConfigs.apiUrls.UpdateLocation(rowToEdit.location_id)}
        />
      )}
    </div>
  );
};

export default LocationsLayout;
