import axios from "axios";
import { Fragment, useState } from "react";
import commonConfigs from "../../../../config/config";
import { Dialog, Transition } from "@headlessui/react";


export default function RefundModal({
  setRefundModal,
  row,
  load,
}) {
  const ret_ref_num = row.payment_id;
  const amt_in_dollars = row.order_total_amt;
  const order_no = row.order_no;

  const [open, setOpen] = useState(true);
  const [loading, setLoad] = useState(false);

  const user = localStorage.getItem('user');
  const onSubmit = async () => {

    setLoad(true);
    var url = commonConfigs.apiUrls.initiateRefund();
    var data = {
      ret_ref_num: ret_ref_num,
      amt_in_dollars: amt_in_dollars,
      refunded_by: user.account_id,
      order_status: 6,
      refund_remarks: "",
      merchant_id:""
    }
    axios
      .post(url, data, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        try {
          if (res.data.status != "success") {
            alert("danger");
            load(true);
            setLoad(false);

            setRefundModal(false);
          }
          alert("success");
          load(true);
          setLoad(false);

          setRefundModal(false);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setRefundModal(false)}
            />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={onSubmit}>
                  <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                  Confirm Refund?
                </Dialog.Title>
                <div className="mt-2 sm:col-span-6" >
                      <label
                        htmlFor="product_cat"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Order Num: {order_no}
                  </label>
                  <label
                        htmlFor="product_cat"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Order Amount: ${amt_in_dollars}
                      </label>
                    </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  { !loading ? <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  >
                    Confirm
                  </button>
                     : <label
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  >
                    Loading...
                  </label>
                }
                  
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setRefundModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
