import axios from "axios";
import { format } from "date-fns";
import { Edit, Additem, Trash } from "iconsax-react";
import { useState, useMemo, useEffect } from "react";
import commonConfigs from "../../../../config/config";
import Table from "../../../../commons/components/table";
import UpdateBillingModal from "../components/updateBillingModal";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import Spinner from "../../../../commons/components/spinner";
import CreateBillingModal from "../components/createBillingModal";
import DeleteModal from "../../../../commons/components/deleteModal";

const getBillingUrl = commonConfigs.apiUrls.GetBillingConfig();

const pages = [
  { name: "Configurations", href: "#", current: false },
  {
    name: "Billing",
    href: "/configurations/billing",
    current: true,
  },
];

const BillingLayout = () => {
  const [isOpen, setOpen] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  useEffect(() => {
    async function getData() {
      await axios
        .get(getBillingUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          setData(res.data.result.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Billing Type",
      accessor: "billing_type",
    },
    {
      Header: "Rate Type",
      accessor: "billing_rate_type",
    },
    {
      Header: "Rate",
      accessor: "billing_rate",
    },
    {
      Header: "Billing Item",
      accessor: "billing_item",
    },
    {
      Header: "Billing Mode",
      accessor: "billing_mode",
    },
    {
      Header: "Is Tax Included?",
      accessor: "is_txn_included",
      Cell: ({ row }) => {
        let visibility = row.original.is_txn_included;
        row.values.is_txn_included = visibility == 0 ? "No" : "Yes";
        if (visibility === 0) {
          return <div className="text-sm text-gray-900">No</div>;
        } else {
          return <div className="text-sm text-gray-900">Yes</div>;
        }
      },
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      {isUpdateModal && (
        <UpdateBillingModal
          setUpdateModal={setUpdateModal}
          load={setLoad}
          row={rowToEdit}
        />
      )}
      {isDeleteModal && (
      <DeleteModal
        setDeleteModal={setDeleteModal}
        load={setLoad}
        url={commonConfigs.apiUrls.DeleteBilling(rowToEdit.billing_config_id)}
      />
      )}
       <div className=" flex flex-row-reverse">
        <button
          type="button"
          className="ml-3 inline-flex items-center px-4 py-2 text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
          onClick={() => setOpen(true)}
        >
          Create Billing Item <Additem className="ml-3" />
        </button>
        {isOpen && <CreateBillingModal setOpenModal={setOpen} load={setLoad} />}
      </div>
    
      {data != null ? (
        <div className="mt-5">
          <Table
            columns={columns}
            data={data}
            filters={columns.map((column) => column.accessor)}
          /> 
        </div>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingLayout;
