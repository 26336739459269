import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import YTDRevenueCard from "../../hierarchy_modules/components/ytdRevenueCard";

const user = JSON.parse(localStorage.getItem("user"));

export default function YTDSCard() {

  const [orderTypesRevenueYTD, setOrderTypesRevenueYTD] = useState(null);
  const [load, setLoad] = useState(true);

  async function fetchOrderTypesRevenueYTD() {

    let getOrderTypesRevenueYTD = commonConfigs.apiUrls.GetYTDSales();
    if(user.access_multiple_location == 0 && user.location_id) {
      getOrderTypesRevenueYTD = getOrderTypesRevenueYTD + `&location_id=${user.location_id}`
    }

    await axios
      .get(getOrderTypesRevenueYTD, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res);
        setOrderTypesRevenueYTD(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
      fetchOrderTypesRevenueYTD().catch((err) => {
        console.log("err: " + err);
      });
  }, [load]);


  return (
    <div style={{height:'inherit', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
      {orderTypesRevenueYTD && (
        <YTDRevenueCard title="YTD Sales"
        subtitle={(orderTypesRevenueYTD.revenue_stream.length == 0) ? "No Sales Today" : "Today's Sales" }
        data={orderTypesRevenueYTD} />
      )}
    </div>   
  );
}
