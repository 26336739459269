import axios from "axios";
import { useParams } from "react-router-dom";
import MainDetailsLayout from "./mainDetailsLayout";
import commonConfigs from "../../../../config/config";
import { useState, useEffect } from "react";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";

const MerchantsDetailsLayout = () => {
  let { locationId } = useParams();
  let { locationcode } = useParams();
  
  const pages = [
    { name: "Calendar", href: "/schedule", current: false },
    { name: "Date", href: "/schedule/date", current: false },
    { name: "Locations", href: `/schedule/date`, current: false },
    { name: locationcode, href: `/schedule/date/employee-timecard/${locationId}/${locationcode}`, current: false },
  ];
  // Urls.
  const getMerchantEMenuUrl = commonConfigs.apiUrls.GetLocationEmployeeTimecards(locationId);

  // Data.
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);


  async function getEMenuData() {
    // var data = [
    //   {
    //     employee_name: "Adam",
    //     employee_email: "adam@test.com",
    //     employee_job_title: "Executive",
    //     date:"11/17/2022",
    //     time_in_datetime: "09:00",
    //     time_out_datetime: "18:00",
    //     history: [
    //       {
    //         date:"11/14/2022",
    //         time_in_datetime: "09:00",
    //         time_out_datetime: "18:00",
    //       },
    //       {
    //         date:"11/15/2022",
    //         time_in_datetime: "09:00",
    //         time_out_datetime: "18:00",
    //       },
    //       {
    //         date:"11/16/2022",
    //         time_in_datetime: "09:00",
    //         time_out_datetime: "18:00",
    //       },
    //     ],
    //     edit: ""
    //   },
    //   {
    //     employee_name: "Sarah",
    //     employee_email: "sarah@test.com",
    //     employee_job_title: "Manager",
    //     date:"11/17/2022",
    //     time_in_datetime: "09:00",
    //     time_out_datetime: "18:00",
    //     history: [
    //       {
    //         date:"11/14/2022",
    //         time_in_datetime: "09:00",
    //         time_out_datetime: "18:00",
    //       },
    //       {
    //         date:"11/15/2022",
    //         time_in_datetime: "09:00",
    //         time_out_datetime: "18:00",
    //       },
    //       {
    //         date:"11/16/2022",
    //         time_in_datetime: "09:00",
    //         time_out_datetime: "18:00",
    //       },
    //     ],
    //     edit: ""
    //   },
    // ];
    // setData(data);
    await axios
      .get(getMerchantEMenuUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  useEffect(() => {
    if (load) {
      getEMenuData().catch((err) => {
        console.log("emenu err: " + err);
      });
    }
  }, [load]);

  return (
    <div className="w-full desktop:p-6 mobile:p-2 ">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
        <MainDetailsLayout data={data} load={load} setLoad={setLoad} />
    </div>
  );
};

export default MerchantsDetailsLayout;
