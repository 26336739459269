import { DashboardContextProvider } from "../../context/dashboardContext";

import DashboardLayout from "./dashboardRevenueLayout";

const DashboardPage = () => {
  return (
    <DashboardContextProvider>
      <DashboardLayout />
    </DashboardContextProvider>
  );
};

export default DashboardPage;
