import { createContext, useState } from "react";

const tabs = [
  { name: "All" },
  { name: "Food Court" },
  { name: "Food Shop" },
  { name: "Staff Canteen" },
  { name: "Hawker" },
];

export const DishwareLocationsTabsContext = createContext();
export const DishwareLocationsCurrentTabContext = createContext();

export const DishwareLocationsContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <DishwareLocationsTabsContext.Provider value={{ tabs }}>
      <DishwareLocationsCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </DishwareLocationsCurrentTabContext.Provider>
    </DishwareLocationsTabsContext.Provider>
  );
};
