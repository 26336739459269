import { DashboardContextProvider } from "../context/kpiContext";
import DashboardKPILayout from "./dashboardKPILayout";

const DashboardKPIPage = () => {
  return (
    <DashboardContextProvider>
      <DashboardKPILayout />
    </DashboardContextProvider>
  );
};

export default DashboardKPIPage;
