// import { useState } from "react";
import { format } from "date-fns";
import React, { useState, useMemo, useEffect } from "react";
import { Edit, Trash, CardTick, CardRemove } from "iconsax-react";
import commonConfigs from "../../../../../config/config";
import Table from "../../../../../commons/components/table";
import UpdateMenuModal from "../../components/updateMenuModal";
import ApprovePriceModal from "../../components/approvePriceModal";
import DeclinePriceModal from "../../components/declinePriceModal";
import Spinner from "../../../../../commons/components/spinner";
import DeleteModal from "../../../../../commons/components/deleteModal";
import MultipleString from "../../../../../commons/components/multipleString";


export default function MainMenuLayout({ data, load, setLoad }) {
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isApproveModalOpen, setApproveModalOpen] = useState(false);
  const [isDeclineModalOpen, setDeclineModalOpen] = useState(false);
  const [isDisableEditPrice, setDisableEditPrice] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  // const [showSpinner, setShowSpinner] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowSpinner(false);
  //   }, 10000); // 10 seconds in milliseconds

  //   return () => clearTimeout(timer);
  // }, []);

  // const days = [
  //   { value: 1, label: 'Monday' },
  //   { value: 2, label: 'Tuesday' },
  //   { value: 3, label: 'Wednesday' },
  //   { value: 4, label: 'Thursday' },
  //   { value: 5, label: 'Friday' },
  //   { value: 6, label: 'Saturday' },
  //   { value: 7, label: 'Sunday' }
  // ];

  const columns = [
    {
      Header: "Item Name",
      accessor: "product_name",
      Cell: ({ row, value }) => {
        return (
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img src={row.original.product_img_url} />
            </div>
            <div className="ml-4">
              <p className="text-sm text-black">{value}</p>
            </div>
          </div>
        );
      },
    },
    {
      Header: 'Item SKU',
      accessor: 'product_sku',
      Cell: ({ value }) => (value ? value : '-'), // If value is empty or null, return '-'
    },
    {
      Header: "Item UPC",
      accessor: "product_upc",
      Cell: ({ value }) => (value ? value : '-'), // If value is empty or null, return '-'
    },
    {
      Header: "Product Code (H365)",
      accessor: "product_code",
      Cell: ({ value }) => (value ? value : '-'), // If value is empty or null, return '-'
    },
    {
      Header: "Item Description",
      accessor: "product_desc",
      Cell: ({ value }) => (value ? value : '-'), // If value is empty or null, return '-'
    },
    {
      Header: "Item Price",
      accessor: "product_price",
      Cell: ({ value }) => {
        return <div className="text-sm text-gray-900">${value}</div>;
      },
    },
    {
      Header: "Item Price Change",
      accessor: "product_price_change",
      Cell: ({ value }) => {
        if (value == null || value == "") {
          return <div className="text-sm text-gray-900">-</div>;  
        } else {
          return <div className="text-sm text-gray-900">${value}</div>;
        }
        
      },
    },
    {
      Header: "Discount Value",
      accessor: "product_discount_value",
      Cell: ({ value }) => (value ? value : '-'), // If value is empty or null, return '-'
    },
    {
      Header: "Discount Percent",
      accessor: "product_discount_percent",
      Cell: ({ value }) => (value ? value : '-'), // If value is empty or null, return '-'
    },
    {
      Header: "Discount Description",
      accessor: "product_discount_desc",
      Cell: ({ value }) => (value ? value : '-'), // If value is empty or null, return '-'
    },
    {
      Header: "Category",
      accessor: "product_cat",
      Cell: ({ row }) => {
        let item_description = [];
        if (row.original.product_cat.length > 0) {
          item_description = row.original.product_cat.map(
            (obj) => obj.product_cat_name
          );
        }
        return (
          <div className="text-sm text-gray-900">
            <MultipleString data={item_description} />
          </div>
        );
      },
    },
    {
      Header: "Calorie Count",
      accessor: "calorie",
      Cell: ({ row }) => {
        let calorieCount = row.original.calorie.calorie_count;
        let calorieIndicator = row.original.calorie.calorie_color_sys_code;

        return (
          <div className="text-sm text-gray-900">
            <div
              className={`w-20 h-8 rounded-md text-center text-white p-2 `}
              style={{ backgroundColor: `${calorieIndicator}` }}
            >
              {calorieCount} kcals
            </div>
          </div>
        );
      },
    },
    {
      Header: "HPB Healthier Choice",
      accessor: "hpb_health_choice",
      Cell: ({ row }) => {
        let hpb = row.original.hpb_health_choice.hpb_cat_name;
        return <div className="text-sm text-gray-900">{hpb}</div>;
      },
    },
    {
      Header: "Stock Status",
      accessor: "product_stock_status",
    },
    {
      Header: "Stock Quantity",
      accessor: "product_stock_qty",
    },
    {
      Header: "Visibility",
      accessor: "is_visible",
      Cell: ({ row }) => {
        let visibility = row.original.is_visible;
        row.values.is_visible = row.original.is_visible == 1 ? "Yes" : "No";
        if (visibility === 0) {
          return <div className="text-sm text-gray-900">No</div>;
        } else {
          return <div className="text-sm text-gray-900">Yes</div>;
        }
      },
    },
    {
      Header: "Visible Days",
      accessor: "product_visible_days",
      // Cell: ({ value }) => {
      //   const selectedDays = days.filter(day => value.includes(day.value)).map(day => day.label);
      //   return <p className="text-sm text-gray-900">{selectedDays.join(", ")}</p>;
      // }
    },    
    {
      Header: "Start Time",
      accessor: "product_start_time",
      // Cell: ({ value }) => {
      //   // Assuming `value` contains the time value
      //   const startTime = new Date(`1970-01-01T${value}Z`);
      //   startTime.setHours(startTime.getHours() + 9);

      //   const formattedStartTime = startTime.toLocaleTimeString([], { timeStyle: 'short' });
    
      //   return formattedStartTime;
      // },
    },
    {
      Header: "End Time",
      accessor: "product_end_time",
      // Cell: ({ value }) => {
      //   // Assuming `value` contains the time value
      //   const startTime = new Date(`1970-01-01T${value}Z`);
      //   startTime.setHours(startTime.getHours() + 9);
      //   const formattedStartTime = startTime.toLocaleTimeString([], { timeStyle: 'short' });
    
      //   return formattedStartTime;
      // },
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      Header: "Change Requests Remarks",
      accessor: "product_price_remarks",
      Cell: ({ value }) => {
        if (value !== null && value !== "Approved") { 
          return <p className="text-sm text-gray-900">{value}</p>;
        } else {
          return <p className="text-sm text-gray-900">-</p>;
        }
          
        
      },
    },
    {
      Header: "Change Requests",
      id: "requests",
      Cell: ({ row }) => {
        if (row.values.product_price !== row.values.product_price_change && row.values.product_price_change !== 0 && row.values.product_price_change !== "" && row.values.product_price_change !== null  && (row.values.product_price_remarks == null || row.values.product_price_remarks !== "Approved")) { 
          return (
            <div>
              <button
                type="button"
                className="m-1"
                onClick={() => {
                  setApproveModalOpen(true);
                  setSelectedRow(row.original);
                }}
              >
                <CardTick aria-hidden="true" />
              </button>
              <button
                type="button"
                className="m-1"
                onClick={() => {
                  setDeclineModalOpen(true);
                  setSelectedRow(row.original);
                }}
              >
                <CardRemove aria-hidden="true" />
              </button>
            </div>
          );
        } else {
          return <p className="text-sm text-gray-900">-</p>;
        }
          
        
      },
    },
    {
      Header: "Items",
      id: "expander",
      Cell: ({ row }) => {
        if (row.values.product_price !== row.values.product_price_change && row.values.product_price_change !== 0 && row.values.product_price_change !== "" && row.values.product_price_change !== null && (row.values.product_price_remarks == null || row.values.product_price_remarks == "Approved")) { 
          return (
            <div>
              {/* <button
                  className="underline"
                  onClick={() => {
                    row.toggleAllRowsExpanded(false);
                    row.row.toggleRowExpanded();
                  }}
                >
                  <Cards aria-hidden="true" />
                </button> */}
              
              <button
                type="button"
                className="m-1"
                onClick={() => {
                  setUpdateModalOpen(true);
                  setDisableEditPrice(true);
                  setSelectedRow(row.original);
                }}
              >
                <Edit aria-hidden="true" />
              </button>
              {localStorage.getItem("roleid") == 2 || localStorage.getItem("roleid") == 5 ?              <button
                type="button"
                className="m-1"
                onClick={() => {
                  setDeleteModalOpen(true);
                  setSelectedRow(row.original);
                }}
              >
                <Trash aria-hidden="true" />
              </button>
              : <></> }
            </div>
          );
        } else {
          return (
            <div>
              {/* <button
                  className="underline"
                  onClick={() => {
                    row.toggleAllRowsExpanded(false);
                    row.row.toggleRowExpanded();
                  }}
                >
                  <Cards aria-hidden="true" />
                </button> */}
              
              <button
                type="button"
                className="m-1"
                onClick={() => {
                  setDisableEditPrice(false);
                  setUpdateModalOpen(true);
                  setSelectedRow(row.original);
                }}
              >
                <Edit aria-hidden="true" />
              </button>
              {localStorage.getItem("roleid") == 2 || localStorage.getItem("roleid") == 5 ?
              <button
                type="button"
                className="m-1"
                onClick={() => {
                  setDeleteModalOpen(true);
                  setSelectedRow(row.original);
                }}
              >
                <Trash aria-hidden="true" />
              </button>
              : <></> }
            </div>
          );
        }
        
      },
    },
  ];

  if (data) {
    return (
      <>
        <Table
          columns={columns}
          data={data.filter((row) => row.is_add_on == 0)}
          filters={columns.map((column) => column.accessor)}
        />
        {isUpdateModalOpen && (
          <UpdateMenuModal
            setUpdateModal={setUpdateModalOpen}
            load={setLoad}
            row={selectedRow}
            isAddonModal={false}
            isDisabledEditPrice={isDisableEditPrice}
          />
        )}
          {isApproveModalOpen && (
          <ApprovePriceModal
            setApproveModal={setApproveModalOpen}
            load={setLoad}
            row={selectedRow}
            isAddonModal={false}
          />
        )}

          {isDeclineModalOpen && (
          <DeclinePriceModal
            setDeclineModal={setDeclineModalOpen}
            load={setLoad}
            row={selectedRow}
            isAddonModal={false}
          />
        )}

        {isDeleteModalOpen && (
          <DeleteModal
            setDeleteModal={setDeleteModalOpen}
            load={setLoad}
            url={commonConfigs.apiUrls.UpdateMenu(
              selectedRow.merchant_id,
              selectedRow.product_id
            )}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
        <div className="flex place-content-center w-full">
          <Spinner className="w-20 h-20" />
        </div>
    </div>
    );
  }
}
