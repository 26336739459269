import axios from "axios";
import { format } from "date-fns";
import { Link, useParams, useLocation } from "react-router-dom";
import { Additem, Edit, Trash, Cards } from "iconsax-react";
import { useEffect, useMemo, useState, useContext } from "react";
import DeleteModal from "../../../../../commons/components/deleteModal";
import Spinner from "../../../../../commons/components/spinner";
import Table from "../../../../../commons/components/table";
import commonConfigs from "../../../../../config/config";
import CreateEVoucherModal from "../components/createEVoucherModal";
import UpdateEVoucherModal from "../components/UpdateEVoucherModal";
import { eVoucherCurrentTabContext } from "../context/eVoucherContext";
import EVoucherMenuTabs from "../components/eVoucherMenuTab";

const EVoucherLayout = () => {
  const { currentTab } = useContext(eVoucherCurrentTabContext);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [unexpiredData, setUnexpiredData] = useState(null);
  const [expiredData, setExpiredData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();
  const [currentPage, setCurrentPage] = useState(0); // Initialize current page
  const [totalUnexpiredData, setTotalUnexpiredData] = useState(0); 
  const [totalExpired, setTotalExpired] = useState(0);
  
  async function getUnexpired(page) {
    await axios
      .get(commonConfigs.apiUrls.GetVouchers(0, page), { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data.total_pages)
        setUnexpiredData(res.data.result.data.vouchers);
        setTotalUnexpiredData(res.data.result.data.total_pages);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getExpired(page) {
    await axios
      .get(commonConfigs.apiUrls.GetVouchers(1, page), { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data.vouchers)
        setExpiredData(res.data.result.data.vouchers);
        setTotalExpired(res.data.result.data.total_pages);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // async function getData(page) {
  //   await axios
  //     .get(commonConfigs.apiUrls.GetVouchers(page), { headers: commonConfigs.apiHeader })
  //     .then((res) => {
  //       console.log(res.data.result.data)
  //       setData(res.data.result.data.vouchers);
  //       setTotalData(res.data.result.data.total_pages);
  //       setLoad(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  useEffect(() => { 
    // if (load) {
      // getData(currentPage).catch((err) => {
      //   console.log("err: " + err);
      // });
      getExpired(currentPage).catch((err) => {
        console.log("err: " + err);
      });
      getUnexpired(currentPage).catch((err) => {
        console.log("err: " + err);
      });
    // }
  }, [load, currentPage]);

  // function serialisedVoucherSeries(voucherCode, voucherQty) {
  //   let serialisedCode = [];
  //   if (voucherCode != null && voucherQty != null) {
  //     for (let i = 0; i < voucherQty; i++) {
  //       let randomString = Math.random().toString(36).substring(2, 6);

  //       serialisedCode.push(voucherCode.concat(randomString));
  //     }
  //   }
  // }

  const columns = useMemo(() => [
    {
      Header: "Voucher Code",
      accessor: "voucher_code",
    },
    {
      Header: "Voucher Type",
      accessor: "voucher_type",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900">
            {row.original.voucher_type == 1
              ? "Non-Serialised"
              : row.original.voucher_type == 2
              ? "Serialised"
              : row.original.voucher_type == 4
              ? "Product"
              : "None"}
          </div>
        );
      },
    },
    // {
    //   Header: "Voucher Series",
    //   accessor: "voucher_series",
    //   Cell: ({ row }) => {
    //     let voucher_series = row.original.voucher_series;

    //     return (
    //       <div className="text-sm text-gray-900">
    //         {voucher_series.join(", ")}
    //       </div>
    //     );
    //   },
    // },
    {
      Header: "Voucher Qty",
      accessor: "voucher_qty",
    },
    {
      Header: "Voucher Amount",
      accessor: "voucher_value",
    },
    {
      Header: "Voucher Entitlment Qty",
      accessor: "entitlement_qty",
    },
    {
      Header: "Voucher Expiry",
      accessor: "voucher_expiry",
      Cell: ({ row }) => {
        row.original.voucher_expiry = format(
          new Date(row.original.voucher_expiry),
          "dd MMM yyyy HH:mm"
        );
        var date = row.original.voucher_expiry;

        if (date) {
          return <p className="text-sm text-gray-900">{date}</p>;
        }
      },
    },
    {
      Header: "Claimable Location",
      accessor: "claimable_locations",
      Cell: ({ row }) => {
        let claimable_locations = row.original.claimable_locations.map(
          (location) => location.location_name
        );

        return (
          <div className="text-sm text-gray-900">
            {claimable_locations.join(", ")}
          </div>
        );
      },
    },
    {
      Header: "Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      id: "edit",
      Cell: ({ row }) => {
        let voucherObj = {
          id: row.original.voucher_id,
          voucher_type: row.original.voucher_type,
          voucher_code: row.original.voucher_code,
        };
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button type="button">
              <Link
                to={{
                  // pathname: ``,
                  pathname: `/marketing/loyaltyprogramme/voucher/${voucherObj.id}/${voucherObj.voucher_type}/${voucherObj.voucher_code}`,
                }}
              >
                <Cards aria-hidden="true" className="w-6 h-6" />
              </Link>
            </button>
            <button
              type="button"
              className="m-1 "
              onClick={() => {
                setUpdateModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            {localStorage.getItem("roleid") == 2 || localStorage.getItem("roleid") == 5 ?
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModalOpen(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
            : <></> }
          </div>
        );
      },
    },
  ]);
  console.log("Evoucherdata");
  console.log(expiredData, unexpiredData);
  return (
    <div>
      <div className=" flex flex-row-reverse">
        <button
          type="button"
          className="ml-3 inline-flex items-center px-4 py-2 text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
          onClick={() => setCreateModalOpen(true)}
        >
          Create eVoucher <Additem className="ml-3" />
        </button>
        {isCreateModalOpen && (
          <CreateEVoucherModal
            setOpenModal={setCreateModalOpen}
            load={setLoad}
          ></CreateEVoucherModal>
        )}
      </div>
      <div>
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <EVoucherMenuTabs />
        </div>
        {currentTab.name == "Unexpired" && (
        <>
         {unexpiredData != null ? (
          <Table
            columns={columns}
            data={unexpiredData}
            filters={columns.map((column) => column.accessor)}
            pageChangeHandler={setCurrentPage}
            totalData={totalUnexpiredData}
          />
        ) : (
          <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
            <div className="flex place-content-center w-full">
              <Spinner className="w-20 h-20" />
            </div>
          </div>
          )}
          </>
      )}
      {currentTab.name == "Expired" && (
        <>
          {expiredData != null ? (
          <Table
            columns={columns}
            data={expiredData}
            filters={columns.map((column) => column.accessor)}
            pageChangeHandler={setCurrentPage}
            totalData={totalExpired}
          />
        ) : (
          <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
            <div className="flex place-content-center w-full">
              <Spinner className="w-20 h-20" />
            </div>
          </div>
        )}
        </>
      )} 
        {isUpdateModalOpen && (
          <UpdateEVoucherModal
            setOpenModal={setUpdateModalOpen}
            load={setLoad}
            row={rowToEdit}
          />
        )}
        {isDeleteModalOpen && (
          <DeleteModal
            setDeleteModal={setDeleteModalOpen}
            load={setLoad}
            url={commonConfigs.apiUrls.UpdateVouchers(rowToEdit.voucher_id)}
          />
        )}
      </div>
    </div>
  );
};

export default EVoucherLayout;
