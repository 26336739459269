import { Link } from "react-router-dom";

export default function NoAccess() {
  return (
    <div className="min-h-screen pt-16 pb-12 flex flex-col ">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">

        <div className="py-16">
          <div className="text-center">
            {/* <p className="text-sm font-semibold text-yellow-600 uppercase tracking-wide">
              403 error
            </p> */}
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              No Access
            </h1>
            <p className="mt-2 text-base text-gray-500">
              Sorry, you do not have access to this portal.
            </p>
            <div className="mt-6">
              <Link
                to={"/"}
                className="text-base font-medium text-yellow-600 hover:text-yellow-500"
              >
                Go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <a
            href="#"
            className="text-sm font-medium text-gray-500 hover:text-gray-600"
          >
            Contact Support
          </a>
          <span
            className="inline-block border-l border-gray-300"
            aria-hidden="true"
          />
          <a
            href="#"
            className="text-sm font-medium text-gray-500 hover:text-gray-600"
          >
            Status
          </a>
          <span
            className="inline-block border-l border-gray-300"
            aria-hidden="true"
          />
          <a
            href="#"
            className="text-sm font-medium text-gray-500 hover:text-gray-600"
          >
            Twitter
          </a>
        </nav>
      </footer>
    </div>
  );
}
