import React from "react";
import Footer from "../components/footer";
import MarketingPage from "../components/marketingApp";
import Survey from "../components/survey";
import FAQ from "../components/faq";

const LandingPage = () => {
  return (
    <>
      <MarketingPage />
      <Survey />
      <FAQ />
      <Footer />
    </>
  );
};

export default LandingPage;
