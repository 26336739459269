import { useState, useMemo, useEffect } from "react";
import HPBModal from "../../components/createMerchantCodesModal";
import UpdateModal from "../../components/updateMerchantCodesModal";
import DeleteModal from "../../../../../commons/components/deleteModal";
import Table from "../../../../../commons/components/table";
import { Additem, Edit, Trash } from "iconsax-react";
import axios from "axios";
import commonConfigs from "../../../../../config/config";
import { format } from "date-fns";
import Spinner from "../../../../../commons/components/spinner";

const HPBMerchantCodes = commonConfigs.apiUrls.GetHPBMerchantCodes();

const HPBMerchantCodesLayout = () => {
  const [isOpen, setOpen] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  useEffect(() => {
    async function getData() {
      await axios
        .get(HPBMerchantCodes, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          setData(res.data.result.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Merchant HPB Id",
      accessor: "merchant_hpb_id",
    },
    {
      Header: "Merchant HPB Name",
      accessor: "merchant_hpb_name",
    },
    {
      Header: "Merchant Brand Code",
      accessor: "merchant_brand_code",
    },
    {
      Header: "Merchant Outlet Code",
      accessor: "merchant_outlet_code",
    },
    {
      Header: "Merchant Terminal Code",
      accessor: "merchant_terminal_code",
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div>
      <div className=" flex flex-row-reverse">
        <button
          type="button"
          className="ml-3 inline-flex items-center px-4 py-2 text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
          onClick={() => setOpen(true)}
        >
          Create Merchant Code <Additem className="ml-3" />
        </button>
        {isOpen && <HPBModal setOpenModal={setOpen} load={setLoad} />}
      </div>

      {data != null ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}

      {isUpdateModal && (
        <UpdateModal
          setUpdateModal={setUpdateModal}
          load={setLoad}
          row={rowToEdit}
        />
      )}
      
      {isDeleteModal && (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          load={setLoad}
          url={commonConfigs.apiUrls.UpdateHPBMerchantCodes(rowToEdit.merchant_hpb_id)}
        />
      )}
    </div>
  );
};

export default HPBMerchantCodesLayout;
