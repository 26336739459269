import BroadcastsLayout from "./broadcastsLayout";
import { NotificationContextProvider } from "../context/notificationContext";

const Broadcasts = () => {

  return (
    <NotificationContextProvider>
      <BroadcastsLayout />
    </NotificationContextProvider>
  );
};

export default Broadcasts;
