import { createContext, useState } from "react";

const tabs = [
  { name: "All" },
  { name: "Employee Time In/Out" },
  { name: "Merchant Time In/Out" },
  { name: "Maintenance" },
  { name: "Leave" },
];

export const ScheduleTabsContext = createContext();
export const ScheduleCurrentTabContext = createContext();

export const ScheduleContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <ScheduleTabsContext.Provider value={{ tabs }}>
      <ScheduleCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </ScheduleCurrentTabContext.Provider>
    </ScheduleTabsContext.Provider>
  );
};
