import { getLocalAccessToken } from "../services/tokenService";

export const baseUrl = `https://stg.${process.env.REACT_APP_PROJECT_NAME}.openapipaas.com/api/v1/web`; // transactional db
export const baseNonWebUrl = `https://stg.${process.env.REACT_APP_PROJECT_NAME}.openapipaas.com/api/v1`; // transactional db

//Stg
export const baseCommonUrl = "https://stg.beveat.openapipaas.com/api/v1/common"; // common lookup tables
export const hubCommonUrl = "https://stg.beveat.openapipaas.com/api/v1"; // common lookup tables
export const ssoUrl = "https://stg.sso.openapipass.org/api/v1/auth/business";
export const redirectUrl = `https://stg-${process.env.REACT_APP_PROJECT_NAME}-web.azurewebsites.net/login`;

//Prod
// export const baseUrl = `https://${process.env.REACT_APP_PROJECT_NAME}.openapipaas.com/api/v1/web`; // transactional db
// export const baseNonWebUrl = `https://${process.env.REACT_APP_PROJECT_NAME}.openapipaas.com/api/v1`; // transactional db
// export const baseCommonUrl = "https://beveat.openapipaas.com/api/v1/common"; // common lookup tables
// export const hubCommonUrl = "https://beveat.openapipaas.com/api/v1"; // common lookup tables
// export const ssoUrl = "https://sso.openapipass.org/api/v1/auth/business";
// export const redirectUrl = `https://prod-${process.env.REACT_APP_PROJECT_NAME}-web.azurewebsites.net/login`;

// FC orgid
export const org_id = `${process.env.REACT_APP_ORG_ID}`;
export const company_id = `${process.env.REACT_APP_COMPANY_ID}`;
export const org_uen = `${process.env.REACT_APP_ORG_UEN}`;


// if (process.env.REACT_APP_ENV == "development") {
//   baseUrl = "http://localhost:8080/api/v1/web";
//   baseCommonUrl = "/api/v1/common";
//   redirectUrl = "http://localhost:8080/";
// }

const commonConfigs = {
  redirectUrl: redirectUrl,
  apiHeader: {
    Authorization: `Bearer ${getLocalAccessToken()}`,
    "api-key": `${process.env.REACT_APP_API_KEY}`,
    "project-id": `${process.env.REACT_APP_PROJECT_ID}`,
    "platform-syscode": `${process.env.REACT_APP_PLATFORM_SYSCODE}`

    // sghawkers
    // "api-key": "eSBHznc5ZyQu1Fsh", //stg
    // "api-key": "lvtxIDMmW7vhXDI3", //prod
    // "api-key": "7eWbYpq1dfnKDTSH", //sso prod
    // "project-id": "8aj3f671-e4b2-4d15-b752-fcf77cdf39a0"
  },
  apiUrls: {
    ssoAuth: () => `${ssoUrl}`,
    login: () => `${baseUrl}/auth/login`,

    /**
     * Manage Users Api
     */
    Employees: (page) => `${baseUrl}/org/${org_id}/companies/${company_id}/employees?page=${page}`,
    GetAnEmployee: (account_id) =>
      `${baseNonWebUrl}/companies/${company_id}/employees/${account_id}`,
    UploadEmployees: () => `${baseUrl}/org/${org_id}/companies/${company_id}/employees/list`,
    UpdateEmployee: (account_id) =>
      `${baseNonWebUrl}/companies/${company_id}/employees/${account_id}`,
    EmployeeTypes: () => `${baseUrl}/org/${org_id}/companies/${company_id}/employees/types`,
    Departments: () => `${baseUrl}/org/${org_id}/util/departments`,

    Members: (page) => `${baseNonWebUrl}/users/members?page=${page}`,
    UpdateMember: (account_id) =>
      `${baseNonWebUrl}/users/members/${account_id}`,
    /*
     *
     *
     * Operations Api
     *
     */
    // GetOrders: (location_id) =>
    //   `${baseUrl}/org/${org_id}/operations-support/location/${location_id}/orders`,
    GetOrders: (location_id, status) =>
      `${baseUrl}/org/${org_id}/operations/locations/${location_id}/orders?order_status=${status}`,
    /**
     * Configurations Apis
     *
     */
    GetAMerchant: (merchant_id) =>
      `${baseCommonUrl}/org/${org_id}/merchants/${merchant_id}`,
    GetLocationMerchants: (location_id) =>
      `${baseUrl}/org/${org_id}/merchants/locations/${location_id}`,
    // `${baseCommonUrl}/org/${org_id}/locations/outlets/merchants?location_code=${location_code}`,

    Merchants: () => `${baseUrl}/org/${org_id}/merchants`,
    CreateMerchant: () => `${baseCommonUrl}/org/${org_id}/merchants`,
    MerchantsCategories: () =>
      `${baseCommonUrl}/org/${org_id}/categories/tags/merchants`,
    CategoriesTags: () => `${baseCommonUrl}/org/${org_id}/categories/tags`,
    UpdateMerchants: (merchant_id) =>
      `${baseCommonUrl}/org/${org_id}/merchants/${merchant_id}`,
    GetMerchantEMenu: (merchant_id) =>
      `${baseUrl}/org/${org_id}/merchants/${merchant_id}/products`,
    MerchantType: () => `${baseCommonUrl}/org/${org_id}/utilities/syscodes/merchants`,

    GetItemPrize: () => `${baseCommonUrl}/prizes/item-prizes`,
    GetEVoucherPrize: () => `${baseCommonUrl}/prizes/evoucher-prizes`,

    // Main Menu Apis
    CreateMenu: (merchant_id) =>
      `${baseCommonUrl}/org/${org_id}/merchants/${merchant_id}/products`,
    UpdateMenu: (merchant_id, product_id) =>
      `${baseCommonUrl}/org/${org_id}/merchants/${merchant_id}/products/${product_id}`,

    //Add Ons Apis
    GetAllAddOns: (merchant_id) =>
      `${baseUrl}/org/${org_id}/merchants/${merchant_id}/products/add-ons`,
    GetAddOnsItems: (merchant_id, product_id) =>
      `${baseUrl}/org/${org_id}/merchants/${merchant_id}/products/add-ons/${product_id}`,
    UpdateAddOnItem: (merchant_id, product_id) =>
      `${baseCommonUrl}/org/${org_id}/merchants/${merchant_id}/products/add-ons/${product_id}`,
    CreateAddOn: (merchant_id) =>
      `${baseCommonUrl}/org/${org_id}/merchants/${merchant_id}/products/add-ons`,

    //Preferences Apis
    GetPreferencesApis: (merchant_id) =>
      `${baseCommonUrl}/org/${org_id}/merchants/${merchant_id}/products/preferences`,
    CreatePreferences: (merchant_id) =>
      `${baseCommonUrl}/org/${org_id}/merchants/${merchant_id}/products/preferences`,
    UpdatePreferences: (merchant_id, preference_id) =>
      `${baseCommonUrl}/org/${org_id}/merchants/${merchant_id}/products/preferences/${preference_id}`,

    //Access Roles Apis
    GetAllAccessRoles: () => `${baseUrl}/org/${org_id}/roles`,

    // Locations Apis
    GetOutletTypes: () => `${baseCommonUrl}/org/${org_id}/locations/outlets/types`,
    GetLocations: () => `${baseUrl}/org/${org_id}/locations/outlets`,
    CreateLocation: () => `${baseUrl}/org/${org_id}/locations/outlets`,
    UpdateLocation: (location_id) =>
      `${baseUrl}/org/${org_id}/locations/${location_id}/outlets`,

    // FAQs Apis
    GetLandingFAQ: () => `${baseCommonUrl}/org/${org_id}/faq`,
    FAQs: () => `${baseUrl}/org/${org_id}/faq`,
    CreateFAQ: () => `${baseCommonUrl}/org/${org_id}/faq`,
    UpdateFAQ: (faq_id) => `${baseCommonUrl}/org/${org_id}/faq/${faq_id}`,

    // Health Promotion Board (HPB) Apis
    HPB: () => `${baseCommonUrl}/hpb/categories`,
    CreateHPB: () => `${baseCommonUrl}/hpb/categories`,
    UpdateHPB: (hpb_cat_id) => `${baseCommonUrl}/hpb/categories/${hpb_cat_id}`,

    GetHPBMerchantCodes: () => `${baseUrl}/org/${org_id}/hpb/merchants`,
    CreateHPBMerchantCodes: () => `${baseCommonUrl}/hpb/rdcs/merchants`,
    UpdateHPBMerchantCodes: (merchant_hpb_id) =>
      `${baseCommonUrl}/hpb/rdcs/merchants/${merchant_hpb_id}`,

    // Location Division Api
    Divisions: () => `${baseUrl}/org/${org_id}/divisions`,

    // Suppliers Apis
    Suppliers: () => `${baseUrl}/org/${org_id}/merchants/suppliers/gpo`,
    CreateSupplier: () => `${baseCommonUrl}/org/${org_id}/merchants/suppliers/gpo`,
    UpdateSupplier: (merchant_id) =>
      `${baseCommonUrl}/org/${org_id}/merchants/suppliers/${merchant_id}`,

    // Key Performance indicator Apis
    GetOuletOccupancy: () => `${baseUrl}/org/${org_id}/charts?chart_type=table&data=kpi&data_points=location_outlet_occupancy`,
    GetOperatingStalls: () => `${baseUrl}/org/${org_id}/charts?chart_type=table&data=kpi&data_points=location_operational_stalls`,
    GetOutletOnlineStalls: () => `${baseUrl}/org/${org_id}/charts?chart_type=table&data=kpi&data_points=location_online_stalls`,
    GetUserIncidentRespondtime: (report_type_sys_code, start_date, end_date) =>
      `${baseUrl}/org/${org_id}/charts?chart_type=donut&data=report&data_points=response-times&report_type_sys_code=${report_type_sys_code}&start_date=${start_date}&end_date=${end_date}`,

    // Group Purchase Apis
    GroupPurchase: () => `${baseUrl}/org/${org_id}/merchants/suppliers/gpo/products`,
    CreateGroupPurchase: () =>
      `${baseCommonUrl}/org/${org_id}/merchants/suppliers/gpo/products`,
    UpdateGroupPurchase: (product_id) =>
      `${baseCommonUrl}/org/${org_id}/merchants/suppliers/gpo/products/${product_id}`,

    // EMenu Category Apis
    GetEMenuCategory: () =>
      `${baseCommonUrl}/org/${org_id}/categories/products`,
    CreateEMenuCategory: () =>
      `${baseCommonUrl}/org/${org_id}/categories/products`,
    DeleteEMenuCategory: () =>
      `${baseCommonUrl}/org/${org_id}/categories/products`,
    UpdateEMenuCategory: (product_cat_id) =>
      `${baseCommonUrl}/org/${org_id}/categories/products/${product_cat_id}`,
    CreateMerchantCategory: () =>
      `${baseCommonUrl}/org/${org_id}/categories/tags`,
    UpdateMerchantCategory: (tag_cat_id) =>
      `${baseCommonUrl}/org/${org_id}/categories/tags/${tag_cat_id}`,
    DeleteMerchantCategory: (tag_cat_id) =>
      `${baseCommonUrl}/org/${org_id}/categories/tags/${tag_cat_id}`,
    // `${baseUrl}/org/${org_id}/categories/product-categories`,

    //Dashboard Apis
    GetYTDSales: () =>
      `${baseUrl}/org/${org_id}/charts?chart_type=table&data=revenue&data_points=revenue_streams`,

    GetTodayOrders: () =>
      `${baseUrl}/org/${org_id}/revenue/order-types/end-of-day`,
    GetTotalSales: () =>
      `${baseUrl}/org/${org_id}/charts?chart_type=line&data=revenue&data_points=total_revenue`,
    GetGPOSales: () =>
      `${baseUrl}/org/${org_id}/revenue/order-types/grouped-revenue-data`,

    // Access Roles Apis
    AccessRoles: () => `${baseUrl}/org/${org_id}/roles`,
    UpdateAccessRoles: (role_id) => `${baseUrl}/org/${org_id}/roles/${role_id}`,

    // Access Apis
    AccessTypes: () => `${baseUrl}/org/${org_id}/access`,
    createAccess: () => `${baseUrl}/org/${org_id}/access`,
    updateAccess: (access_id) => `${baseUrl}/org/${org_id}/access/${access_id}`,
    deleteAccess: (access_id) => `${baseUrl}/org/${org_id}/access/${access_id}`,

    // Newsletter Apis
    Broadcasts: () => `${baseCommonUrl}/org/${org_id}/broadcasts/advertisements`,
    GetBroadcastsByLocationId: (location_id) =>
      `${baseCommonUrl}/org/${org_id}/broadcasts/advertisements?location_id=${location_id}`,
    GetBroadcastsByAdvertType: (syscode_id) =>
      `${baseCommonUrl}/syscodes/${syscode_id}`,
    CreateSiteMap: () => `${baseCommonUrl}/org/${org_id}/locations/sitemaps`,

    // Dishware Apis
    Dishware: (dishware_id, location_id) =>
      `${baseUrl}/org/${org_id}/dishwares?org_dishware_id=${dishware_id}&location_id=${location_id}`,
    CreateDishware: () => `${baseCommonUrl}/org/${org_id}/dishwares`,
    UpdateDishware: (dishware_id) =>
      `${baseCommonUrl}/org/${org_id}/dishwares?org_dishware_id=${dishware_id}`,

    // User Feedback Report Apis
    GetFeedbacks: () => `${baseNonWebUrl}/reports/feedback`,
    UpdateFeedback: (user_feedback_id) =>
      `${baseNonWebUrl}/reports/feedback/${user_feedback_id}`,
    CreateUserFeedback: () => `${baseNonWebUrl}/reports/feedback`,

    // User Incident Report Apis
    GetIncident: (location_id) => `${baseNonWebUrl}/reports/incidents/locations/${location_id}`,
    UpdateIncident: (incident_report_id) =>
      `${baseNonWebUrl}/reports/incidents/${incident_report_id}`,

    // Vouchers Apis
    GetVouchers: (is_expired, page) =>
      `${baseUrl}/org/${org_id}/marketing/vouchers?voucher_sn_code=0&is_expired=${is_expired}&page=${page}`,
    CreateVouchers: () => `${baseCommonUrl}/org/${org_id}/vouchers`,
    UpdateVouchers: (voucher_id) =>
      `${baseCommonUrl}/org/${org_id}/vouchers/${voucher_id}`,
    eVoucherStatistics: (start_date, end_date, is_serialized) =>
      `${baseUrl}/org/${org_id}/charts?chart_type=bar&data=revenue&data_points=vouchers_redemption&start_date=${start_date}&end_date=${end_date}&is_serialized=${is_serialized}`,
    getVoucherRedemptionDetails: (voucher_id, voucher_type) =>
      `${baseNonWebUrl}/vouchers/${voucher_id}/redemptions?org_id=${org_id}&voucher_type=${voucher_type}`,
    //Loyalty Programme
    // GetLoyaltyProgramme: (loyalty_id) => `${baseUrl}/org/${org_id}/marketing/loyalties/${loyalty_id}/programmes`,
    GetLoyaltyProgramme: () => `${baseUrl}/org/${org_id}/marketing/loyalties`,
    GetALoyaltyProgramme: (loyalty_id) =>
      `${baseUrl}/org/${org_id}/marketing/loyalties/${loyalty_id}`,
    CreateLoyaltyProgramme: () =>
      `${baseUrl}/org/${org_id}/marketing/loyalties`,
    UpdateLoyaltyProgramme: (loyalty_id) =>
      `${baseUrl}/org/${org_id}/marketing/loyalties/${loyalty_id}`,

    GetLoyaltyPointsRedeemed: (loyalty_id) =>
      `${baseUrl}/org/${org_id}/marketing/loyalties/${loyalty_id}/redemptions`,
    GetTotalLoyalty: (loyalty_id) =>
      `${baseNonWebUrl}/loyalties/${loyalty_id}/redemption-points`,

    //kisok prize wheel
    GetPrizeWheelStatistics: (start_date, end_date) =>
      `${baseUrl}/org/${org_id}/charts?chart_type=bar&data=marketing&data_points=issued_prizes&start_date=${start_date}&end_date=${end_date}`,

    // Billing Apis
    BillingTable: () => `${baseUrl}/org/${org_id}/billing/merchants`,

    GetBillingConfig: () => `${baseUrl}/org/${org_id}/billing/configurations`,
    CreateBilling: () => `${baseCommonUrl}/org/${org_id}/billing/configurations`,
    UpdateBilling: (billing_config_id) => `${baseCommonUrl}/org/${org_id}/billing/configurations/${billing_config_id}`,
    DeleteBilling: (billing_config_id) => `${baseCommonUrl}/org/${org_id}/billing/configurations/${billing_config_id}`,

    GetCompanies: (company_id) => `${baseUrl}/org/${org_id}/companies/${company_id}`,
    CreateCompany: (company_id) => `${baseUrl}/org/${org_id}/companies/${company_id}`,
    UpdateCompany: (company_id) => `${baseUrl}/org/${org_id}/companies/${company_id}`,
    DeleteCompany: (company_id) => `${baseUrl}/org/${org_id}/companies/${company_id}`,

    GetAllDivisions: (company_id) => `${baseUrl}/org/${org_id}/companies/${company_id}/divisions`,
    CreateDivision: (company_id) => `${baseUrl}/org/${org_id}/companies/${company_id}/divisions`,
    UpdateDivision: (company_id, division_id) => `${baseUrl}/org/${org_id}/companies/${company_id}/divisions/${division_id}`,
    DeleteDivision: (company_id, division_id) => `${baseUrl}/org/${org_id}/companies/${company_id}/divisions/${division_id}`,

    GetAllDepartments: (company_id) => `${baseUrl}/org/${org_id}/companies/${company_id}/departments`,
    CreateDepartment: (company_id) => `${baseUrl}/org/${org_id}/companies/${company_id}/departments`,
    UpdateDepartment: (company_id, department_id) => `${baseUrl}/org/${org_id}/companies/${company_id}/departments/${department_id}`,
    DeleteDepartment: (company_id, department_id) => `${baseUrl}/org/${org_id}/companies/${company_id}/departments/${department_id}`,

    // GetOrderTypesRevenueYTD: () =>
    //   `${baseUrl}/org/${org_id}/revenue/platform-types/year-to-date`,
    GetOrderTypesTodayOrder: () =>
      `${baseUrl}/org/${org_id}/charts?chart_type=donut&data=revenue&data_points=today_orders`,

    GetOrderTypesTotalRevenueDataPoints: (start_date, end_date) =>
      `${baseUrl}/org/${org_id}/charts?chart_type=line&data=revenue&data_points=total_revenue&start_date=${start_date}&end_date=${end_date}`,
    // GetOrderTypesGroupRevenueData: () =>
    //   `${baseUrl}/org/${org_id}/revenue/order-types/grouped-revenue-data`,

    GetCompanySalesPercentage: () =>
    //not using currently
      `${baseUrl}/org/${org_id}/revenue/companies/sales-percentage`, 
    GetDivisionSalesPercentage: () =>
    //not using currently
      `${baseUrl}/org/${org_id}/revenue/divisions/sales-percentage`, 
    GetMerchantsBillings: () => `${baseUrl}/org/${org_id}/billing/merchants`,

    GetGPODonut: () => `${baseUrl}/org/${org_id}/charts?chart_type=donut&data=billing&data_points=gpo`,
    GetCurrentMonthBill: () => `${baseUrl}/org/${org_id}/billing/month-to-date`,

    GetDivisionRevenueYTD: () =>
      `${baseUrl}/org/${org_id}/revenue/divisions/year-to-date`, //
    GetOutletsRevenueSales: () =>
      `${baseUrl}/org/${org_id}/charts?chart_type=bar&data=revenue&data_points=outlet_sales`,
    GetMerchantsRevenue: () => `${baseUrl}/org/${org_id}/revenue/merchants`,

    GetGPOBySupplier: () => `${baseNonWebUrl}/transactions/gpo/merchants/suppliers?org_id=${org_id}`,
    DownloadPOOrderById: (po_order_id) =>
      `${baseNonWebUrl}/transactions/gpo/suppliers/po/${po_order_id}?org_id=${org_id}`,
    ConfirmGPOBySupplierId: () =>
      `${baseNonWebUrl}/transactions/gpo/suppliers/po`,
    DeclineGPOByMerchantDetailId: (merchant_details_id) =>
      `${baseNonWebUrl}/transactions/gpo/merchants/${merchant_details_id}/status`,

    GetGPOByLocationSupplierGoods: () =>
      `${baseNonWebUrl}/transactions/gpo/merchants/locations?org_id=${org_id}&type=2`,

    GetGPOByLocationSupplierServices: () =>
      `${baseNonWebUrl}/transactions/gpo/merchants/locations?org_id=${org_id}&type=1`,

    GetBillingLocationsT1: (division_id, start_date, end_date) =>
      `${baseUrl}/org/${org_id}/billing/divisions/${division_id}/outlets?start_date=${start_date}&end_date=${end_date}`,
    GetBillingMerchantsT2: (location_id, start_date, end_date) =>
      `${baseUrl}/org/${org_id}/billing/outlets/locations/${location_id}/merchants?start_date=${start_date}&end_date=${end_date}`,

    //Hierarchy API
    GetGPOTable: () => `${baseUrl}/org/${org_id}/billing/all`,

    GetOutletSales: (
      type,
      start_date,
      end_date,
      loyalty_id,
      platform_type_sys_code
    ) =>
      `${baseUrl}/org/${org_id}/charts?chart_type=bar&data=revenue&data_points=outlet_sales&type=${type}&start_date=${start_date}&end_date=${end_date}&loyalty_id=${loyalty_id}&platform_type_sys_code=${platform_type_sys_code}`,
    GetCompanySales: () =>
      `${baseUrl}/org/${org_id}/revenue/companies/sales?org_uen=${org_uen}`,
    GetDivisionSales: () =>
      `${baseUrl}/org/${org_id}/charts?chart_type=donut&data=revenue&data_points=division_sales&org_uen=${org_uen}`,
    // GetOutletSales: (loyalty_id, start_date, end_date) =>
    //   `${baseUrl}/org/${org_id}/revenue/outlets/sales?start_date=${start_date}&end_date=${end_date}&loyalty_id=${loyalty_id}`,
    GetOutletLocationSales: (
      location_id,
      platform_type_sys_code,
      payment_type,
      loyalty_id,
      start_date,
      end_date
    ) =>
      `${baseUrl}/org/${org_id}/revenue/outlet-locations/${location_id}/sales?platform_type_sys_code=${platform_type_sys_code}&payment_type=${payment_type}&loyalty_id=${loyalty_id}&start_date=${start_date}&end_date=${end_date}`,
    GetMerchantsSales: (
      merchant_id,
      platform_type_sys_code,
      payment_type,
      loyalty_id,
      start_date,
      end_date
    ) =>
      `${baseUrl}/org/${org_id}/revenue/merchants/${merchant_id}/transactions?platform_type_sys_code=${platform_type_sys_code}&payment_type=${payment_type}&loyalty_id=${loyalty_id}&start_date=${start_date}&end_date=${end_date}`,
    GetRefundsSales: (
      merchant_id,
      platform_type_sys_code,
      payment_type,
      start_date,
      end_date
    ) =>
      `${baseUrl}/org/${org_id}/refunds/merchants/${merchant_id}?platform_type_sys_code=${platform_type_sys_code}&payment_type=${payment_type}&start_date=${start_date}&end_date=${end_date}`,
    GetMerchantFilterSales: (
      merchant_id,
      platform_type_sys_code,
      payment_type,
      loyalty_id,
      start_date,
      end_date
    ) =>
      `${baseNonWebUrl}/revenue/merchants/${merchant_id}/sales?platform_type_sys_code=${platform_type_sys_code}&payment_type=${payment_type}&loyalty_id=${loyalty_id}&start_date=${start_date}&end_date=${end_date}`,
    GetFilterSalesParams: (location_id) =>
      `${baseNonWebUrl}/drop-down-lists/revenue?location_id=${location_id}`,
    getDishwashingServices: () =>
      `${baseUrl}/org/${org_id}/charts?chart_type=donut&data=billing&data_points=dishwashing`,
    getDivisions: () => `${baseUrl}/org/${org_id}/divisions`,
    getDivisionsData: (division_id) =>
      `${baseUrl}/org/${org_id}/billing/divisions/${division_id}`,

    //export emenu
    GetAllLocationProducts: (location_id) =>
      `${baseCommonUrl}/org/${org_id}/merchants/locations/${location_id}/products`,

    // calendar API
    GetCalendar: (month, calendar_type, location_id) =>
      `${baseUrl}/org/${org_id}/companies/${company_id}/calendars?month=${month}&calendar_type=${calendar_type}&location_id=${location_id}`,

    //Merchant Timein/out API
    GetAllMerchantLocationTimecards: () =>
      `${baseUrl}/org/${org_id}/companies/${company_id}/timecards/locations/attendance/merchants`,
    GetLocationMerchantTimecards: (location_id, start_date) =>
      `${baseUrl}/org/${org_id}/companies/${company_id}/timecards/locations/${location_id}/attendance/merchants?start_date=${start_date}`,
    GetMerchantTimecards: (merchant_id) =>
      `${baseUrl}/org/${org_id}/companies/${company_id}/timecards/merchants/${merchant_id}/attendance/month-to-date `,
    GetAllTimecards: (start_date, location_id) =>
      `${baseUrl}/org/${org_id}/companies/${company_id}/timecards?start_date=${start_date}&location_id=${location_id}`,
    GetAllLeaveTimecards: () =>
      `${baseUrl}/org/${org_id}/companies/${company_id}/timecards/leave`,

    //Employee Timein/out API
    GetAllEmployeeLocationTimecards: () =>
      `${baseUrl}/org/${org_id}/companies/${company_id}/timecards/locations/attendance/employees`,
    GetLocationEmployeeTimecards: (location_id) =>
      `${baseUrl}/org/${org_id}/companies/${company_id}/timecards/locations/${location_id}/attendance/employees`,
    GetEmployeeTimecards: (account_id) =>
      `${baseUrl}/org/${org_id}/companies/${company_id}/timecards/employees/${account_id}/attendance/month-to-date`,
    UpdateEmployeeTimecards: (timecard_id) =>
      `${baseUrl}/org/${org_id}/companies/${company_id}/timecards/${timecard_id}`,
    GetIndividualTimecardDetails: (account_id, start_date, end_date) =>
      `${baseUrl}/org/${org_id}/companies/${company_id}/timecards/employees/${account_id}?start_date=${start_date}&end_date=${end_date}`,
    DeleteLeaveTimecard: (timecard_id) =>
      `${baseUrl}/org/${org_id}/timecards/${timecard_id}`,

    // Calendar Donuts
    GetEmployeeAttendance: () =>
      `${baseUrl}/org/${org_id}/companies/${company_id}/timecards/locations/attendance/employees`,
    GetMerchantsAttendance: () =>
      `${baseUrl}/org/${org_id}/companies/${company_id}/timecards/locations/attendance/merchants`,

    //Leave API
    GetUserLeaveBalance: (account_id) =>
      `${baseNonWebUrl}/leave/balance/employees/${account_id}`,
    GetUserLeaveEntitlement: (account_id) =>
      `${baseNonWebUrl}/leave/entitlement/employees/${account_id}`,
    GetLeaveEntitlement: () =>
      `${baseNonWebUrl}/leave/entitlement?employment_type=`,
    CreateLeaveEntitlement: () => `${baseNonWebUrl}/leave/entitlement`,
    UpdateLeaveEntitlement: (leave_id) =>
      `${baseNonWebUrl}/leave/entitlement/${leave_id}`,

    //Prize Wheel API
    GetWheelConfig: (location_id, prize_wheel_id) =>
      `${baseCommonUrl}/org/${org_id}/marketing/locations/${location_id}/prize-wheels/${prize_wheel_id}`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/marketing/locations/${location_id}/prize-wheels/${prize_wheel_id}`,
    UpdateSpinWheel: (location_id, prize_wheel_id) =>
      `${baseCommonUrl}/org/${org_id}/marketing/locations/${location_id}/prize-wheels/${prize_wheel_id}`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/marketing/locations/${location_id}/prize-wheels/${prize_wheel_id}`,
    GetPrizeWheelRecords: (location_id, prize_wheel_id) =>
      `${baseCommonUrl}/org/${org_id}/marketing/locations/${location_id}/prize-wheels/${prize_wheel_id}/records`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/marketing/locations/${location_id}/prize-wheels/${prize_wheel_id}/records`,
    UpdatePrizeWheelRecords: (location_id, prize_wheel_id) =>
      `${baseCommonUrl}/org/${org_id}/marketing/locations/${location_id}/prize-wheels/${prize_wheel_id}/records`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/marketing/locations/${location_id}/prize-wheels/${prize_wheel_id}/records`,
    GetPrizeDetails: (location_id, prize_wheel_id, prize_id) =>
      `${baseCommonUrl}/org/${org_id}/marketing/locations/${location_id}/prize-wheels/${prize_wheel_id}/prizes/${prize_id}`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/marketing/locations/${location_id}/prize-wheels/${prize_wheel_id}/prizes/${prize_id}`,
    UpdatePrizeIssued: (location_id, prize_wheel_id, prize_id) =>
      `${baseCommonUrl}/org/${org_id}/marketing/locations/${location_id}/prize-wheels/${prize_wheel_id}/prizes/${prize_id}`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/marketing/locations/${location_id}/prize-wheels/${prize_wheel_id}/prizes/${prize_id}`
    GetAllPrizeWheel: () =>
      `${baseUrl}/org/${org_id}/marketing/prize-wheels/prizes/configurations`,
    // `http://localhost:8080/api/v1/common/org/:org_id/prize-wheels/prizes/configurations`
    CreatePrizeWheel: () => `${baseCommonUrl}/org/${org_id}/prize-wheels`,
    // `http://localhost:8080/api/v1/common/org/:org_id/prize-wheels`
    UpdatePrizeWheel: (prize_wheel_id) =>
      `${baseCommonUrl}/org/${org_id}/prize-wheels/${prize_wheel_id}`,
    // `http://localhost:8080/api/v1/common/org/:org_id/prize-wheels`
    DeletePrizeWheel: (prize_wheel_id) =>
      `${baseCommonUrl}/org/${org_id}/prize-wheels/${prize_wheel_id}`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/prize-wheels/${prize_wheel_id}`
    GetLocationByPrizeWheelId: (prize_wheel_id) =>
      `${baseCommonUrl}/org/${org_id}/prize-wheels/${prize_wheel_id}/locations`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/prize-wheels/${prize_wheel_id}/location,
    GetAPrizeLimitByPrizeId: (prize_id) =>
      `${baseNonWebUrl}/prize-wheels/locations/:location_id/prize-limits/${prize_id}`,
    // `http://localhost:8081/api/v1//prize-wheels/locations/:location_id/prize-limit/${prize_id},
    //Payments - Refund
    Refund: () => `${baseCommonUrl}/payments/nets-click/refund`,
    initiateRefund: () => `${baseUrl}/org/${org_id}/refunds`,

    //Prizes API
    GetItemPrizes: () => `${baseUrl}/org/${org_id}/marketing/prizes/items`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/prizes/items,
    GetEVoucherPrizes: () => `${baseUrl}/org/${org_id}/marketing/prizes/vouchers`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/prizes/vouchers,
    GetPrizes: () => `${baseCommonUrl}/org/${org_id}/prizes`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/prizes,
    CreatePrize: () => `${baseCommonUrl}/org/${org_id}/prizes`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/prizes,
    GetAPrize: (prize_id) =>
      `${baseCommonUrl}/org/${org_id}/prizes/${prize_id}`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/prizes/${prize_id},
    UpdatePrize: (prize_id) =>
      `${baseCommonUrl}/org/${org_id}/prizes/${prize_id}`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/prizes/${prize_id},
    DeletePrize: (prize_id) =>
      `${baseCommonUrl}/org/${org_id}/prizes/${prize_id}`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/prizes/${prize_id},

    //Prize Segment API
    GetPrizeWheelSegments: (prize_wheel_id) =>
      `${baseCommonUrl}/org/${org_id}/prize-wheels/${prize_wheel_id}/segments`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/prize-wheels/${prize_wheel_id}/segments,
    CreatePrizeWheelSegment: (prize_wheel_id) =>
      `${baseCommonUrl}/org/${org_id}/prize-wheels/${prize_wheel_id}/segments`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/prize-wheels/${prize_wheel_id}/segments,
    UpdatePrizeWheelSegment: (prize_wheel_id, prize_wheel_segment_id) =>
      `${baseCommonUrl}/org/${org_id}/prize-wheels/${prize_wheel_id}/segments/${prize_wheel_segment_id}`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/prize-wheels/${prize_wheel_id}/segments/${prize_wheel_segment_id},
    DeletePrizeWheelSegment: (prize_wheel_id, prize_wheel_segment_id) =>
      `${baseCommonUrl}/org/${org_id}/prize-wheels/${prize_wheel_id}/segments/${prize_wheel_segment_id}`,
    // `http://localhost:8080/api/v1/common/org/${org_id}/prize-wheels/${prize_wheel_id}/segments/${prize_wheel_segment_id},

    VerifyMobile: (mobileNo) =>
      `${baseNonWebUrl}/accounts/verification/mobile/${mobileNo}`,
    // `http://localhost:8081/api/v1/accounts/verification/mobile/${mobileNo}`,
    // Payment Option API
    GetPaymentOptions: (merchant_id) => 
      `${baseCommonUrl}/org/${org_id}/merchants/${merchant_id}/payments/options`,
    UpdatePaymentOption: (merchant_id) => 
      `${baseCommonUrl}/org/${org_id}/merchants/${merchant_id}/payments/options`,
    CreatePaymentOptions: (merchant_id) => 
      `${baseCommonUrl}/org/${org_id}/merchants/${merchant_id}/payments/options`,
    DeletePaymentOption: (merchant_id) => 
      `${baseCommonUrl}/org/${org_id}/merchants/${merchant_id}/payments/options`,
  },
};
// const configs = Object.assign(commonConfigs, envConfigs);
export default commonConfigs;
