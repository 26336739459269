import OSCard from "../components/osCard";
import DSCard from "../components/dsCard";
import CSCard from "../components/csCard";
import YTDRCard from "../components/ytdrCard";
import LongCard from "../../components/dashboardLongCard";

const HierarchyRevenueLayout = () => {
  
  return (
    <div>
      <div style={{ display: 'grid', 'gridTemplateAreas': `'box1 box1 box3 box3 box5 box5 box5 box5''box1 box1 box3 box3 box5 box5 box5 box5'`,'grid-template-columns': 'repeat(8, minmax(0, 2fr))', gap: 2}}>

        <div style={{ gridArea: 'box1', height:'100%',  minHeight: 0, minWidth: 0, overflow: 'visible'}}>
          <YTDRCard/>
        </div>
          
        {/* <div style={{ gridArea: 'box2', height: '100%', minHeight: 0, minWidth: 0, overflow: 'visible' }}>
          <CSCard/>
        </div> */}

        <div style={{ gridArea: 'box3', height: '100%', minHeight: 0, minWidth: 0, overflow: 'visible' }}>
          <DSCard/>
        </div>
        
        <div style={{ gridArea: 'box5', height:'100%', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
          <LongCard>
            <OSCard/>
          </LongCard>
        </div>

    </div>
    </div>
  );
};

export default HierarchyRevenueLayout;
