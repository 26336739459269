import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";

const user = JSON.parse(localStorage.getItem("user"))

export default function OOCard() {

  const [outletOcc, setOutletOcc] = useState([]);
  
  async function fetchOutletOcc() {
    
    let getOutletOccupancy = commonConfigs.apiUrls.GetOuletOccupancy();
    if(user.access_multiple_location == 0 && user.location_id) {
      getOutletOccupancy = getOutletOccupancy + `&location_id=${user.location_id}`
    }

    await axios
    .get(getOutletOccupancy, { headers: commonConfigs.apiHeader })
    .then((res) => {
      console.log(res.data.result.data);
      setOutletOcc(res.data.result.data);
    })
    .catch((err) => {
      console.log(err);
    });

  }

  useEffect(() => {
    fetchOutletOcc().catch((err) => {
      console.log("err: " + err);
    });
  }, []);

  return (
    <div>
      <h3 className="text-lg">Outlets Occupancy</h3>

      <div style={{marginTop:'30px'}}>
        {outletOcc.map((question) => (
          <div key={question.outlet}>
            <div className="flex justify-between mt-4 space-x-8">
              <div className="text-sm">{question.location_name}</div>
              <div className="flex flex-grow gap-x-4 justify-end">
              <div className="text-sm">{question.total_stalls_occupied}/{question.location_outlet_total_stalls}</div>
            </div>
            </div>
          
            <div className="w-full border-t border-gray-300 mt-4"></div>
          </div>
        ))}
      </div>
    </div>   
  );
}
