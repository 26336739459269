import axios from "axios";
import { format } from "date-fns";
import { Edit } from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import commonConfigs from "../../../../config/config";
import Table from "../../../../commons/components/table";
import Spinner from "../../../../commons/components/spinner";
import UpdateMemberModal from "../components/updateMemberModal";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";

// const getMembersUrl = commonConfigs.apiUrls.Members();

const pages = [
  { name: "Manage Users", href: "/users/employees", current: false },
  { name: "Members", href: "/users/members", current: true },
];

const MembersLayout = () => {
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  const [currentPage, setCurrentPage] = useState(0); // Initialize current page
  const [totalData, setTotalData] = useState(0); 

  useEffect(() => {
    async function getData(page) {
      console.log(page);
      await axios
        .get(commonConfigs.apiUrls.Members(page), {
          headers: commonConfigs.apiHeader,
        })
        .then((res) => {
          console.log(res.data.result.data);
          setData(res.data.result.data.members);
          setTotalData(res.data.result.data.total_pages)
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
   
    // if (load) {
      getData(currentPage).catch((err) => {
        console.log("err: " + err);
      });
    // }
  }, [load, currentPage]);

  const columns = useMemo(() => [
    {
      Header: "Name",
      accessor: "account_username",
    },
    {
      Header: "Email",
      accessor: "consumer_email",
    },
    {
      Header: "Mobile No",
      accessor: "consumer_mobile",
    },
    {
      Header: "Balance Points",
      accessor: "balance_points",
    },
    {
      Header: "status",
      accessor: "account_status",
      Cell: ({ row }) => {
        let status = row.original.account_status;
        row.values.account_status = status == 0 ? "Inactive" : "Active";
        if (status === 0) {
          return <div className="text-sm text-red-700">Inactive</div>;
        } else {
          return <div className="text-sm text-green-700">Active</div>;
        }
      },
    },
    {
      Header: "Points Consumed",
      accessor: "total_points_consumed",
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      {data != null ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
          pageChangeHandler={setCurrentPage}
          totalData={totalData}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      {isUpdateModal && (
        <UpdateMemberModal
          setUpdateModal={setUpdateModal}
          load={setLoad}
          row={rowToEdit}
        />
      )}
    </div>
  );
};

export default MembersLayout;
