import { createContext, useState } from "react";

const tabs = [
  { name: "Current" },
  { name: "Completed" },
];

export const MerchantMenuTabsContext = createContext();
export const MerchantMenuCurrentTabContext = createContext();

export const MerchantMenuContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <MerchantMenuTabsContext.Provider value={{ tabs }}>
      <MerchantMenuCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </MerchantMenuCurrentTabContext.Provider>
    </MerchantMenuTabsContext.Provider>
  );
};
