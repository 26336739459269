import { useState, useMemo, useEffect } from "react";
import ENETSCreateModal from "../components/eNETS/createENetsPaymentOptionModal";
import ENETSUpdateModal from "../components/eNETS/updateENetsPaymentOptionModal";
import ENETSDeleteModal from "../components/eNETS/deleteENetsPaymentOption";
import Table from "../../../../commons/components/table";
import { Additem, Edit, Trash } from "iconsax-react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import Spinner from "../../../../commons/components/spinner";


const eNETSLayout = (props) => {
    const geteNETSPaymentOptionUrl = commonConfigs.apiUrls.GetPaymentOptions(props.merchantId);

    const [isOpen, setOpen] = useState(false);
    const [availablePaymentMode, setAvailablePaymentMode] = useState([])
    const [isUpdateModal, setUpdateModal] = useState(false);
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [data, setData] = useState(null);
    const [load, setLoad] = useState(true);
    const [rowToEdit, setRowToEdit] = useState();

    useEffect(() => {
        async function getData() {
            await axios
                .get(geteNETSPaymentOptionUrl, { headers: commonConfigs.apiHeader })
                .then((res) => {
                    console.log(res.data.result.data);
                    let result = res.data.result.data;
                    let formattedDataArray = [];
                    if (result.payment_credentials[0].payment_mode == 'Primary' && Object.keys(result.payment_credentials[0]).length > 1) {
                        const {
                            merchant_mid_enets,
                            merchant_tid_enets,
                            merchant_scrt_key_enets,
                            merchant_api_key_enets
                          } = result.payment_credentials[0];
                        const allNull = [merchant_mid_enets, merchant_tid_enets, merchant_scrt_key_enets, merchant_api_key_enets].every(value => value === null || value === undefined);
                        if(!allNull) {
                            formattedDataArray.push({
                                payment_mode: result.payment_credentials[0].payment_mode,
                                merchant_mid_enets: result.payment_credentials[0].merchant_mid_enets,
                                merchant_tid_enets: result.payment_credentials[0].merchant_tid_enets,
                                merchant_scrt_key_enets: result.payment_credentials[0].merchant_scrt_key_enets,
                                merchant_api_key_enets: result.payment_credentials[0].merchant_api_key_enets,
                            })
                        } else {
                            setAvailablePaymentMode(oldArr => [...oldArr, result.payment_credentials[0].payment_mode])
                        }
                    } else {
                        setAvailablePaymentMode(oldArr => [...oldArr, result.payment_credentials[0].payment_mode])
                    }
                    if (result.payment_credentials[1].payment_mode == 'Alternate' && Object.keys(result.payment_credentials[1]).length > 1) {
                        const {
                            merchant_mid_enets,
                            merchant_tid_enets,
                            merchant_scrt_key_enets,
                            merchant_api_key_enets
                          } = result.payment_credentials[1];
                        const allNull = [merchant_mid_enets, merchant_tid_enets, merchant_scrt_key_enets, merchant_api_key_enets].every(value => value === null || value === undefined);
                        if(!allNull) {
                            formattedDataArray.push({
                                payment_mode: result.payment_credentials[1].payment_mode,
                                merchant_mid_enets: result.payment_credentials[1].merchant_mid_enets,
                                merchant_tid_enets: result.payment_credentials[1].merchant_tid_enets,
                                merchant_scrt_key_enets: result.payment_credentials[1].merchant_scrt_key_enets,
                                merchant_api_key_enets: result.payment_credentials[1].merchant_api_key_enets,
                            })
                        } else {
                            setAvailablePaymentMode(oldArr => [...oldArr, result.payment_credentials[1].payment_mode])
                        }
                    } else {
                        setAvailablePaymentMode(oldArr => [...oldArr, result.payment_credentials[1].payment_mode])
                    }
                    setData(formattedDataArray);
                    setLoad(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        if (load) {
            getData().catch((err) => {
                console.log("err: " + err);
            });
        }
    }, [load]);

    const columns = useMemo(() => [
        {
            Header: "Payment Mode",
            accessor: "payment_mode",
        },
        {
            Header: "eNETS MID",
            accessor: "merchant_mid_enets",
            Cell: ({ value }) => (value ? value : "-"),
        },
        {
            Header: "eNETS TID",
            accessor: "merchant_tid_enets",
            Cell: ({ value }) => (value ? value : "-"),
        },
        {
            id: "edit",
            Cell: ({ row }) => {
                if (row.original.payment_mode == "Primary" && data[0].payment_mode == "Primary") {
                    row.original.merchant_scrt_key_enets = data[0].merchant_scrt_key_enets
                    row.original.merchant_api_key_enets= data[0].merchant_api_key_enets
                } else if (row.original.payment_mode == "Alternate") {
                    if(data.length == 1) {
                        row.original.merchant_scrt_key_enets = data[0].merchant_scrt_key_enets
                        row.original.merchant_api_key_enets = data[0].merchant_api_key_enets
                    } else {
                        row.original.merchant_scrt_key_enets = data[1].merchant_scrt_key_enets
                        row.original.merchant_api_key_enets = data[1].merchant_api_key_enets
                    }
                }
                return (
                    <div className="text-sm text-gray-900 flex justify-center">
                        <button
                            type="button"
                            className="m-1"
                            onClick={() => {
                                setUpdateModal(true);
                                setRowToEdit(row.original);
                            }}
                        >
                            <Edit aria-hidden="true" />
                        </button>
                        <button
                            type="button"
                            className="m-1"
                            onClick={() => {
                                setDeleteModal(true);
                                setRowToEdit(row.original);
                            }}
                        >
                            <Trash aria-hidden="true" />
                        </button>
                    </div>
                );
            },
        },
    ]);

    return (
        <div>
            <div className=" flex flex-row-reverse">
                <button
                    type="button"
                    className="ml-3 inline-flex items-center px-4 py-2 text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
                    onClick={() => setOpen(true)}
                >
                    Create Payment Option <Additem className="ml-3" />
                </button>
                {isOpen && <ENETSCreateModal setOpenModal={setOpen} load={setLoad} merchantId={props.merchantId} availablePaymentMode={availablePaymentMode} />}
            </div>

            {data != null ? (
                <Table
                    columns={columns}
                    data={data}
                    filters={columns.map((column) => column.accessor)}
                />
            ) : (
                <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
                    <div className="flex place-content-center w-full">
                        <Spinner className="w-20 h-20" />
                    </div>
                </div>
            )}
            {isUpdateModal && (
                <ENETSUpdateModal
                    setUpdateModal={setUpdateModal}
                    load={setLoad}
                    row={rowToEdit}
                    merchantId={props.merchantId}
                />
            )}
            {isDeleteModal && (
                <ENETSDeleteModal
                    setDeleteModal={setDeleteModal}
                    load={setLoad}
                    url={commonConfigs.apiUrls.DeletePaymentOption(props.merchantId)}
                    body={rowToEdit}
                />
            )}
        </div>
    );
};

export default eNETSLayout;
