import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import commonConfigs from "../../../../config/config";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { InfoCircle } from "iconsax-react";
import { useUploadFile } from "../../../../commons/components/uploadFileCard";
import { getRandomUUID } from "../../../../utils/custom/helper";
import { org_id } from "../../../../config/config";

const getPrizeUrl = commonConfigs.apiUrls.GetPrizes();

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function CreatePrizeWheelSegmentModal({
  setOpenModal,
  prizeWheelId,
  load,
}) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(true);
  const [loadData, setLoadData] = useState(true);
  const [prizes, setPrize] = useState([]);
  const [locationId, setLocationId] = useState("");

  const createPrizeWheelSegmentUrl =
    commonConfigs.apiUrls.CreatePrizeWheelSegment(prizeWheelId);
  const getLocationByPrizeWheelUrl =
    commonConfigs.apiUrls.GetLocationByPrizeWheelId(prizeWheelId);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({});

  function formatData(data) {
    var newData = data;
    var prize_id = data.prize_id.value;
    newData.prize_id = prize_id;
    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button
    const newData = formatData(data);

    data.prize_wheel_id = prizeWheelId;
    data.location_id = locationId;

    console.log("data:" + JSON.stringify(newData));
    axios
      .post(createPrizeWheelSegmentUrl, newData, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setOpenModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  async function getPrizes() {
    await axios
      .get(getPrizeUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setPrize(res.data.result.data);
        setLoadData(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getLocationByPrizeWhelId() {
    await axios
      .get(getLocationByPrizeWheelUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setLocationId(res.data.result.data.location_id);
        setLoadData(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (loadData) {
      getPrizes().catch((err) => {
        console.log("err for prizes: " + err);
      });
      getLocationByPrizeWhelId().catch((err) => {
        console.log("err for location by prize wheel id: " + err);
      });
    }
  }, []);

  const prizeOption = prizes.map((p) => ({
    value: p.prize_id,
    label: p.prize_name,
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setOpenModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg leading-6 font-medium text-gray-900"
                    >
                      Create Prize Wheel Segment
                    </Dialog.Title>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="prize_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Prize Name
                      </label>
                      <Controller
                        name="prize_id"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={prizeOption}
                            isSearchable={true}
                            placeholder="Select a prize"
                            className="text-sm"
                            maxMenuHeight={100}
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="location_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Prize Probability
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          name="prize_probability"
                          id="prize_probability"
                          className={
                            !errors.prize_probability
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Prize Probability"}
                          {...register("prize_probability", {
                            required: true,
                            min: 0,
                            max: 100,
                          })}
                        />
                        {errors.prize_probability?.type === "required" &&
                          errorIcon}
                        {errors.prize_probability?.type === "min" && (
                          <span className="text-red-900">
                            Prize probability cannot be less than 0.
                          </span>
                        )}
                        {errors.prize_probability?.type === "max" && (
                          <span className="text-red-900">
                            Prize probability cannot be greater than 100.
                          </span>
                        )}
                      </div>
                      <div className="mt-2 sm:col-span-6">
                        <label
                          htmlFor="prize_qty_issued_limit"
                          className="block text-sm font-medium text-yellow-800"
                        >
                          Prize Quantity Limit/Day
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="number"
                            name="prize_qty_issued_limit"
                            id="prize_qty_issued_limit"
                            className={
                              !errors.prize_qty_issued_limit
                                ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                                : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            }
                            placeholder={"Enter Prize Quantity Limit"}
                            {...register("prize_qty_issued_limit", {
                              required: true,
                            })}
                          />
                          {errors.prize_qty_issued_limit?.type === "required" &&
                            errorIcon}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
