import DashboardBarChart from "./dashboardStatusBarChart";
import { FaRegCalendar } from "react-icons/fa";

export default function StatusCard2({ title, data }) {

  const OperatingStores = data.map((item) => ({
    location_name: item.location_name,
    info: [
      {
        status: 'Open',
        status_value: item.total_stalls_opened,
      },
      {
        status: 'Closed',
        status_value: item.total_stalls_closed,
      }
    ]
  }));

  return (
    // <DashboardLongCard>
    <>
      <h3 className="text-xl">{title} </h3>
      {/* <div>
        <button style={{backgroundColor:"#7B4620",padding:'4px', width:'35px',height:'32px',borderRadius:'5px', outline:'none', marginTop: '10px', textAlign:'-webkit-center'}}>
          <FaRegCalendar color="FFF" width={50} />
        </button>
        <buttongroup style={{ float: 'right', marginRight: '10px', marginTop: '10px' }}>
          {
            ['10Y', '5Y', '1Y','6M','1M'].map(value => (
              <button
                color="#80ba27"
                backgroundColor="primary"
                key={value}
                className="mx-0"
                value={value}
                style={{marginLeft:'5px', backgroundColor:'#E3DEDF', borderRadius:'5px',padding:'4px', width:'35px',height:'32px', outline:'none', fontSize:'13px'}}
              >
                {value}
              </button>
            ))
        }
        </buttongroup>
      </div> */}
      <div className="mt-12  ">
      <DashboardBarChart
          data={OperatingStores.map((item) => ({
            name: item.location_name,
            values: item.info.map((infos) => ({
              name: infos.status,
              value: infos.status_value,
            })),
          }))}
    />
      </div>
    </>
  );
}
