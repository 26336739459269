import { LeaveContextProvider } from "../context/leaveContext";
import LeaveLayout from "./leaveLayout";

const LeavePage = () => {
  return (
    <LeaveContextProvider>
      <LeaveLayout />
    </LeaveContextProvider>
  );
};

export default LeavePage;
