import { createContext, useState } from "react";
const roleId = localStorage.getItem('roleid');
var tabs = [];

if (roleId == 43) {
  tabs = [{ name: "Revenue" }, { name: "Marketing" }];
} 
// else if (roleId == 44) {
//   tabs = [{ name: "Marketing" }, { name: "KPI" }];
// } 
else {
  tabs = [{ name: "Revenue" }, { name: "Marketing" }, { name: "KPI" }];
}


export const DashboardTabsContext = createContext();
export const DashboardCurrentTabContext = createContext();

export const DashboardContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };
  console.log("contextValue", contextValue);

  return (
    <DashboardTabsContext.Provider value={{ tabs }}>
      <DashboardCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </DashboardCurrentTabContext.Provider>
    </DashboardTabsContext.Provider>
  );
};
