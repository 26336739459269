import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";

import DashboardBarChart from "./dashboardPointsBarChart";

const user = JSON.parse(localStorage.getItem("user"))

export default function KMTCCard() {
  const [isApplying, setIsApplying] = useState(false);
  const [isReseting, setIsReseting] = useState(false);

  const [mobileTotalSales, setMobileTotalSales] = useState(null); 
  const [kisokTotalSales, setKisokTotalSales] = useState(null); 

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1 to get the current month.

  // Calculate the first day of the current month.
  const firstDayOfMonth = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;

  // Calculate the last day of the current month.
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  const lastDayOfMonthFormatted = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${lastDayOfMonth.getDate()}`;

  const [startdate, setStartDate] = useState(firstDayOfMonth);
  const [enddate, setEndDate] = useState(lastDayOfMonthFormatted);

  const [startdateFilter, setStartDateFilter] = useState(startdate);
  const [enddateFilter, setEndDateFilter] = useState(enddate);

  const [load, setLoad] = useState(true);
  
  const applyButtonEnabledClass = "bg-red-600 hover:bg-red-700";
  const applyButtonDisabledClass = "bg-red-400 cursor-not-allowed";

  const applyTSFilter = () => {
    setIsApplying(true); // Disable the "APPLY" button
    setLoad(true);
    setStartDateFilter(startdate); // Update the date filter variables
    setEndDateFilter(enddate); // Update the date filter variables
    fetchMobileTotalSales().catch((err) => {
      console.log("err: " + err);
    });
    fetchKisokTotalSales().catch((err) => {
      console.log("err: " + err);
    });
  }

  const resetTSFilter = () => {
    setIsReseting(true);
    setLoad(true);
    setStartDate(currentDate)
    setEndDate(currentDate);
    setStartDateFilter('')
    setEndDateFilter('');
  }

  async function fetchMobileTotalSales() {

    let getOutletSales = commonConfigs.apiUrls.GetOutletSales('',startdateFilter, enddateFilter, '', 1);    
    if(user.access_multiple_location == 0 && user.location_id) {
      getOutletSales = getOutletSales + `&location_id=${user.location_id}`
    }

    await axios
      .get(getOutletSales, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log("mobile",res.data.result.data);
        setMobileTotalSales(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function fetchKisokTotalSales() {

    let getOutletSales = commonConfigs.apiUrls.GetOutletSales('',startdateFilter, enddateFilter, '', 2);    
    if(user.access_multiple_location == 0 && user.location_id) {
      getOutletSales = getOutletSales + `&location_id=${user.location_id}`
    }

    await axios
      .get(getOutletSales, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log("Kisok", res.data.result.data);
        setKisokTotalSales(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const combinedData = [];

  if (kisokTotalSales && mobileTotalSales) {
    for (let i = 0; i < kisokTotalSales.length; i++) {
      const kisokItem = kisokTotalSales[i];
      const mobileItem = mobileTotalSales.find((item) => item.location_name === kisokItem.location_name);

      const combinedItem = {
        location_name: kisokItem.location_name,
        txn: [
          {
            name: "Mobile",
            value: mobileItem ? mobileItem.total_txns : 0,
          },
          {
            name: "Kisok",
            value: kisokItem.total_txns,
          },
        ],
      };

      combinedData.push(combinedItem);
    }
  }

  // Filter array to remove outlet with no kiosk or mobile orders
  let filteredArray = combinedData.filter((item) => item.txn[0].value != 0 || item.txn[1].value != 0)
  // Output the filtered data
  // console.log('filteredArray' ,filteredArray);

  useEffect(() => {
    if ((kisokTotalSales != null) && (mobileTotalSales != null))
  {
    setIsApplying(false);
    setIsReseting(false);
  }
  }, [kisokTotalSales, mobileTotalSales]);

  useEffect(() => {
    fetchMobileTotalSales().catch((err) => {
      console.log("err: " + err);
    });
    fetchKisokTotalSales().catch((err) => {
      console.log("err: " + err);
    });
  }, [load, startdateFilter, enddateFilter]);

  return (
    <div>
      
      <h3 className="text-xl">Kiosk | Mobile Transaction Counts</h3>

      <div style={{backgroundColor:'#FFF', padding:'20px', borderRadius:'10px' }}>
        <div style={{'text-align': 'right', display:'flex', paddingBottom: '20px'}}>
          <div style={{ paddingTop: '10px', paddingBottom: '10px', padding:'10px' }}>
            <h6> Start Date: </h6>
          </div>
          <input data-inline="true" type="date" style={{width:'30%',borderRadius:'8px'}} value={startdate} onChange={(e) => {
                  setStartDate(e.target.value);}}
          />
          <div style={{ paddingTop: '10px', paddingBottom: '10px', padding:'10px'  }}>
            <h6> End Date: </h6>
          </div>
          <input data-inline="true" type="date" style={{width:'30%',borderRadius:'8px'}} value={enddate} onChange={(e) => {
                  setEndDate(e.target.value);}}
          />
            <div style={{'text-align': 'right', display:'flex', padding:'10px' }}>
              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  isApplying ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => applyTSFilter()}
                disabled={isApplying} // Disable the button when isApplying is true
              >
                {isApplying ? "Applying..." : "APPLY"}
              </button>
              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  isReseting ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => resetTSFilter()}
                disabled={isReseting} // Disable the button when isReseting is true
              >
                {isReseting ? "Reseting..." : "Reset"}
              </button>
            </div>
        </div>
      </div> 

      <div className="mt-8">
        <DashboardBarChart
          data={filteredArray.map((item) => ({
            name: item.location_name,
            values: item.txn.map((txnItem) => ({
              name: txnItem.name,
              value: txnItem.value,
            })),
          }))}
        />
      </div>

    </div>   
  );
}
