import { createContext, useState } from "react";

const tabs = [
  { name: "HPB Category" }, 
  { name: "HPB Merchant Codes" },
];

export const HPBTabsContext = createContext();
export const HPBCurrentTabContext = createContext();

export const HPBContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <HPBTabsContext.Provider value={{ tabs }}>
      <HPBCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </HPBCurrentTabContext.Provider>
    </HPBTabsContext.Provider>
  );
};
