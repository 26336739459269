import MaintenancesLocationsLayout from "./maintenancesLocationsLayout";
import { MaintenancesLocationsContextProvider } from "../context/maintenancesLocationsContext";

const MaintenancesLocationsPage = () => {
  return (
    <MaintenancesLocationsContextProvider>
      <MaintenancesLocationsLayout />
    </MaintenancesLocationsContextProvider>
  );
};

export default MaintenancesLocationsPage;
