import { DashboardContextProvider } from "../../context/dashboardContext";
import DashboardMarketingLayout from "./dashboardMarketingLayout";

const DashboardMarketingPage = () => {
  return (
    <DashboardContextProvider>
      <DashboardMarketingLayout />
    </DashboardContextProvider>
  );
};

export default DashboardMarketingPage;
