import { useContext } from "react";
import { classNames } from "../../../../utils/custom/helper";
import {
  LocationsTabsContext,
  LocationsCurrentTabContext,
} from "../context/locationsContext";

const LocationsTabs = () => {
  const { tabs } = useContext(LocationsTabsContext);
  const { currentTab, setTab } = useContext(LocationsCurrentTabContext);
  return (
    <>
      <div className=" sm:block pb-4">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              onClick={() => setTab(tab)}
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.name == currentTab.name
                  ? "bg-yellow-800 text-white"
                  : "text-gray-500 hover:text-yellow-800",
                "px-3 py-2 font-medium text-sm rounded-md"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </>
  );
};

export default LocationsTabs;
