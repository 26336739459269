import { EmployeeContextProvider } from "../../context/employeeContext";
import LeaveManagementLayout from "./leaveManagementLayout";

const EmployeeLeavePage = () => {
  return (
    <EmployeeContextProvider>
      <LeaveManagementLayout />
   </EmployeeContextProvider>
  );
};

export default EmployeeLeavePage;
