import { OrdersContextProvider } from "../context/ordersContext";
import OrdersLayout from "./ordersLayout";
const OrdersPage = () => {
  return (
    <OrdersContextProvider>
      <OrdersLayout />
    </OrdersContextProvider>
  );
};
export default OrdersPage;
