import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import commonConfigs from "../../../../config/config";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { InfoCircle } from "iconsax-react";
// import { AZURE_BLOB_CONTAINER_PRIZES } from "../../../../constants";
// import { useUploadFile } from "../../../../commons/components/uploadFileCard";
import { getRandomUUID } from "../../../../utils/custom/helper";
import { org_id } from "../../../../config/config";

const createPrizeUrl = commonConfigs.apiUrls.CreatePrize();
const getVoucherUrl = commonConfigs.apiUrls.GetVouchers(0);
const user = JSON.parse(localStorage.getItem("user"));

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function CreateVoucherPrizeModal({ setOpenModal, load }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(true);
  const [loadData, setLoadData] = useState(true);
  const [prizeName, setPrizeName] = useState();
  const [prizeInstruction, setPrizeInstruction] = useState();
  const [vouchers, setVoucher] = useState([])

  // const [prizeFile, prizeFileCard, uploadPrizeFile] = useUploadFile({
  //   container: AZURE_BLOB_CONTAINER_PRIZES,
  // });

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({});

  function formatData(data) {
    var newData = data;
    var voucher_id = data.voucher_id.value;
    newData.prize_name = prizeName;
    newData.prize_instruction = prizeInstruction;
    newData.voucher_id = voucher_id
    newData.updated_by = user.account_id;
    // newData.prize_img_url = prizeImg;

    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button

    const newData = formatData(data);
    console.log(newData);
    // if (prizeFile) {
    //   const url = await uploadPrizeFile(`${getRandomUUID()}`);

    //   if (url) {
    //     data.prize_img_url = url;
    //   }
    // }

    data.is_voucher = 1;
    data.org_id = org_id;

    axios
      .post(createPrizeUrl, newData, { headers: commonConfigs.apiHeader })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setOpenModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  useEffect(() => {
    async function getVouchers() {
      await axios
        .get(getVoucherUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          setVoucher(res.data.result.data.vouchers);
          console.log(res.data.result.data.vouchers)
          setLoadData(false)
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (loadData) {
      getVouchers().catch((err) => {
        console.log("err for vouchers: " + err);
      });
    }
  }, []);

  const voucherOption = vouchers.map((v) => ({
    value: v.voucher_id,
    label: v.voucher_code,
  }));


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setOpenModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg leading-6 font-medium text-gray-900"
                    >
                      Create Voucher Prize
                    </Dialog.Title>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="prize_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Voucher Prize Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="prize_name"
                          id="prize_name"
                          className={
                            !errors.prize_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Item Prize Name"}
                          {...register("prize_name", {
                            required: true,
                          })}
                          value={prizeName}
                          onChange={(e) => setPrizeName(e.target.value)}
                        />
                        {errors.prize_name?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="location_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Voucher Prize Instruction
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="prize_instruction"
                          id="prize_instruction"
                          className={
                            !errors.prize_instruction
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Prize Instruction"}
                          {...register("prize_instruction", { required: true })}
                          value={prizeInstruction}
                          onChange={(e) => setPrizeInstruction(e.target.value)}
                        />
                        {errors.prize_instruction?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>
                    <div className="sm:col-span-6 mt-2">
                      <label
                        htmlFor="voucher_id"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        eVoucher Code
                      </label>
                      <Controller
                        name="voucher_id"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={voucherOption}
                            isSearchable={true}
                            placeholder="Select an eVoucher code"
                            className="text-sm"
                            maxMenuHeight={60}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
