// import { useContext } from "react";
// import { UserContext } from "../../contexts/UserContext";
// import { useLocation } from "react-router-dom";
import { ScheduleContextProvider } from "../context/scheduleContext";
import ScheduleLayout from "./scheduleLayout";



const Schedule = () => {
  //   const location = useLocation();
  //   const [numPages] = useState(null);
  //   const [pageNumber] = useState(1);
  //   const [user] = useContext(UserContext);
  //   const payslip = location.state?.payslip;

  return (
    <div className="w-full bg-white">
      <ScheduleContextProvider>
        <ScheduleLayout />
      </ScheduleContextProvider>
    </div>
  );
};

export default Schedule;
