import { useState } from "react";
import { format } from "date-fns";
import { Edit, Trash } from "iconsax-react";
import Table from "../../../../../commons/components/table";
import UpdateAddOnMenuModal from "../../components/updateAddOnMenuModal";
import Spinner from "../../../../../commons/components/spinner";
import DeleteModal from "../../../../../commons/components/deleteModal";
import MultipleString from "../../../../../commons/components/multipleString";
import commonConfigs from "../../../../../config/config";

export default function AddonLayout({ data, load, setLoad }) {
  console.log(data);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const columns = [
    {
      Header: "Item Name",
      accessor: "product_name",
      Cell: ({ row, value }) => {
        return (
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img src={row.original.product_img_url} />
            </div>
            <div className="ml-4">
              <p className="text-sm text-black">{value}</p>
            </div>
          </div>
        );
      },
    },
    {
      Header: 'Item SKU',
      accessor: 'product_sku',
      Cell: ({ value }) => (value ? value : '-'), // If value is empty or null, return '-'
    },
    {
      Header: "Item UPC",
      accessor: "product_upc",
      Cell: ({ value }) => (value ? value : '-'), // If value is empty or null, return '-'
    },
    {
      Header: "Product Code (H365)",
      accessor: "product_code",
      Cell: ({ value }) => (value ? value : '-'), // If value is empty or null, return '-'
    },
    {
      Header: "Item Description",
      accessor: "product_desc",
      Cell: ({ value }) => {
        return (
          <div className="flex text-sm text-gray-900">
            <p>{value}</p>
          </div>
        );
      },
    },
    {
      Header: "Item Price",
      accessor: "product_price",
      Cell: ({ value }) => {
        return <div className="text-sm text-gray-900">${value}</div>;
      },
    },
    {
      Header: "Category",
      accessor: "product_cat",
      Cell: ({ row }) => {
        let item_description = [];
        if (row.original.product_cat.length > 0) {
          item_description = row.original.product_cat.map(
            (obj) => obj.product_cat_name
          );
        }
        return (
          <div className="text-sm text-gray-900">
            <MultipleString data={item_description} />
          </div>
        );
      },
    },
    {
      Header: "Calorie Count",
      accessor: "calorie",
      Cell: ({ row }) => {
        let calorieCount = row.original.calorie.calorie_count;
        let calorieIndicator = row.original.calorie.calorie_color_sys_code;

        return (
          <div className="text-sm text-gray-900">
            <div
              className={`w-20 h-8 rounded-md text-center text-white p-2 `}
              style={{ backgroundColor: `${calorieIndicator}` }}
            >
              {calorieCount} kcals
            </div>
          </div>
        );
      },
    },
    {
      Header: "HPB Healthier Choice",
      accessor: "hpb_health_choice",
      Cell: ({ row }) => {
        let hpb = row.original.hpb_health_choice.hpb_cat_name;
        return <div className="text-sm text-gray-900">{hpb}</div>;
      },
    },
    {
      Header: "Stock Status",
      accessor: "product_stock_status",
    },
    {
      Header: "Stock Quantity",
      accessor: "product_stock_qty",
    },
    {
      Header: "Visibility",
      accessor: "is_visible",
      Cell: ({ row }) => {
        let visibility = row.original.is_visible;
        if (visibility === 0) {
          return <div className="text-sm text-gray-900">False</div>;
        } else {
          return <div className="text-sm text-gray-900">True</div>;
        }
      },
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      Header: "Items",
      id: "expander",
      Cell: ({ row }) => {
        return (
          <div>
            {/* <button
                className="underline"
                onClick={() => {
                  row.toggleAllRowsExpanded(false);
                  row.row.toggleRowExpanded();
                }}
              >
                <Cards aria-hidden="true" />
              </button> */}
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModalOpen(true);
                setSelectedRow(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            {localStorage.getItem("roleid") == 2 || localStorage.getItem("roleid") == 5 ?
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModalOpen(true);
                setSelectedRow(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
            : <></> }
          </div>
        );
      },
    },
  ];

  if (data) {
    return (
      <>
        <Table
          columns={columns}
          data={data.filter((row) => row.is_add_on == 1)}
          filters={columns.map((column) => column.accessor)}
        />
        {isUpdateModalOpen && (
          <UpdateAddOnMenuModal
            setUpdateModal={setUpdateModalOpen}
            load={setLoad}
            row={selectedRow}
            isAddonModal={true}
          />
        )}

        {isDeleteModalOpen && (
          <DeleteModal
            setDeleteModal={setDeleteModalOpen}
            load={setLoad}
            url={commonConfigs.apiUrls.UpdateMenu(
              selectedRow.merchant_id,
              selectedRow.product_id
            )}
            // row={selectedRow}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
        <div className="flex place-content-center w-full">
          <Spinner className="w-20 h-20" />
        </div>
      </div>
    );
  }
}
