import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import commonConfigs from "../../../../config/config";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { InfoCircle } from "iconsax-react";
import {
  AZURE_BLOB_CONTAINER_LOCATIONS,
  AZURE_BLOB_CONTAINER_SITEMAPS,
} from "../../../../constants";
import { useUploadFile } from "../../../../commons/components/uploadFileCard";
import { getRandomUUID } from "../../../../utils/custom/helper";

const createLocationUrl = commonConfigs.apiUrls.CreateLocation();
const getOutletTypesUrl = commonConfigs.apiUrls.GetOutletTypes();
const user = JSON.parse(localStorage.getItem("user"));

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function CreateLocationsModal({ setOpenModal, load }) {
  const [open, setOpen] = useState(true);
  const [divisions, setDivision] = useState([]);
  const [outletTypes, setTypes] = useState([]);
  const [loadData, setLoadData] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const [locationFile, locationFileCard, uploadLocationFile] = useUploadFile({
    container: AZURE_BLOB_CONTAINER_LOCATIONS,
  });

  const [sitemapImageFile, sitemapFileCard, uploadSitemapFile] = useUploadFile({
    container: AZURE_BLOB_CONTAINER_SITEMAPS,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({});

  function formatData(data) {
    var division_id = data.division_id.value;
    var outlet_types_id = data.outlet_type.value;

    var newData = data;
    newData.division_id = division_id;
    newData.outlet_type = outlet_types_id;
    newData.updated_by = user.account_id;
    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button
    
    var FormattedData = formatData(data);
    console.log('formatted data',FormattedData);

    console.log('location image name',`${FormattedData.location_code}/${locationFile.name}`);
    if (locationFile) {
      const url = await uploadLocationFile(`${FormattedData.location_code}/${locationFile.name}`);

      if (url) {
        FormattedData.location_img_url = url;
      }
    }

    console.log('sitemap image name',`${FormattedData.location_code}/${sitemapImageFile.name}`);
    if (sitemapImageFile) {
      const url = await uploadSitemapFile(`${FormattedData.location_code}/${sitemapImageFile.name}`);

      if (url) {
        FormattedData.location_sitemap_url = url;
      }
    }

    axios
      .post(createLocationUrl, FormattedData, { headers: commonConfigs.apiHeader })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setOpenModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  useEffect(() => {
    async function getDivisions() {
      console.log(user.company_id)
      await axios
        .get(commonConfigs.apiUrls.GetAllDivisions(user.company_id), { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res);
          setDivision(res.data.result.data);
          setLoadData(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getOutletTypes() {
      await axios
        .get(getOutletTypesUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res);
          setTypes(res.data.result.data);
          setLoadData(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (loadData) {
      getDivisions().catch((err) => {
        console.log("err for divisions: " + err);
      });
      getOutletTypes().catch((err) => {
        console.log("err for outlet types: " + err);
      });
    }
  }, []);

  const divisionOption = divisions.map((d) => ({
    value: d.division_id,
    label: d.division_name,
  }));

  const outletTypesOption = outletTypes.map((d) => ({
    value: d.outlet_type_id,
    label: d.outlet_type_name,
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setOpenModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg leading-6 font-medium text-gray-900"
                    >
                      Create Location Outlet
                    </Dialog.Title>
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="division_id"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Division
                      </label>
                      <Controller
                        name="division_id"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={divisionOption}
                            isSearchable={true}
                            placeholder="Select a Division Type"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="outlet_type"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Outlet Type
                      </label>
                      <Controller
                        name="outlet_type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={outletTypesOption}
                            isSearchable={true}
                            placeholder="Select a Outlet Type"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="outlet_slot"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Outlet Slot
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="0"
                          name="location_outlet_total_stalls"
                          id="location_outlet_total_stalls"
                          className={
                            !errors.location_outlet_total_stalls
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Outlet Slot"}
                          {...register("location_outlet_total_stalls", {
                            required: true,
                            valueAsNumber: true,
                          })}
                        />
                        {errors.location_outlet_total_stalls?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="location_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Location Outlet
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="location_name"
                          id="location_name"
                          className={
                            !errors.location_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Location Name"}
                          {...register("location_name", { required: true })}
                        />
                        {errors.location_name?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="location_code"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Location Code
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="location_code"
                          id="location_code"
                          className={
                            !errors.location_code
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Request Location Code From Beveat"}
                          {...register("location_code", {
                            required: true,
                            pattern: {
                              value: /@/, // Regular expression to require the '@' special character
                              message: "The Location Code must contain the '@' special character",
                            },
                          })}
                        />
                        {errors.location_code?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="location_address"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Address
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="location_address"
                          id="location_address"
                          className={
                            !errors.location_address
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Location Address"}
                          {...register("location_address", { required: true })}
                        />
                        {errors.location_address?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="location_zip_code"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Postal Code
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          name="location_zip_code"
                          id="location_zip_code"
                          className={
                            !errors.location_zip_code
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Location Zip Code"}
                          {...register("location_zip_code", { required: true })}
                        />
                        {errors.location_zip_code?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="location_lat"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Latitude
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="location_lat"
                          id="location_lat"
                          className={
                            !errors.location_lat
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Location Latitude"}
                          {...register("location_lat", { required: true })}
                        />
                        {errors.location_lat?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="location_lng"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Longtitude
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="location_lng"
                          id="location_lng"
                          className={
                            !errors.location_lng
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Location Longitude"}
                          {...register("location_lng", { required: true })}
                        />
                        {errors.location_lng?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="sm:col-span-6 mt-2">
                      <label
                        htmlFor="upload_file"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Upload Location Image
                      </label>
                      {locationFileCard}
                    </div>

                    <div className="sm:col-span-6 mt-2">
                      <label
                        htmlFor="upload_file"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Upload Sitemap Image
                      </label>
                      {sitemapFileCard}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
