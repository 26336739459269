import ScheduleCalendar from "../components/scheduleCalendar";
import ScheduleTodayOverview from "../components/scheduleSideCard";
import { SearchNormal } from "iconsax-react";
import Breadcrumbs from "../../../commons/components/breadcrumbs";
import axios from "axios";
import commonConfigs from "../../../config/config";
import { useState, useContext, useEffect } from "react";

const pages = [{ name: "Calendar", href: "/schedule", current: true }];

const ScheduleLayout = () => {

  const [load, setLoad] = useState(true);

  const [currentAttendance, setCurrentAttendance] = useState([]);
  const [totalAttendance, setTotalAttendance] = useState([]);

  const [currentMerchantAttendance, setCurrentMerchantAttendance] = useState([]);
  const [totalMerchantAttendance, setTotalMerchantAttendance] = useState([]);

  const [leaveReuestAmt, setLeaveReuestAmt] = useState(0);

  const overview = [
    {
      name: `There is ${leaveReuestAmt} pending leave request`,
      color: "bg-red-400 text-white rounded-md md:h-3/6 p-6",
    },
    // {
    //   name: "10 out of 44 stalls are not in operations today for Bukit Canberra",
    //   color: "bg-white rounded-md md:h-3/6 p-6",
    // },
    {
      name: `${currentAttendance === '-' ? 0 : currentAttendance} out of ${totalAttendance} employees time in today`,
      color: "bg-orange-400 text-white rounded-md md:h-3/6 p-6",
    },
    {
      name: `${currentMerchantAttendance === '-' ? 0 : currentMerchantAttendance} out of ${totalMerchantAttendance} merchants time in today`,
      color: "bg-orange-600 text-white rounded-md md:h-3/6 p-6",
    },
    // {
    //   name: "Inspection Due Today for Bukit Canberra",
    //   color: "bg-blue-800 text-white rounded-md md:h-3/6 p-6",
    // },
  ];

  async function fetchCalendar() {

    const currentDate = new Date();
    const month = currentDate.getMonth() + 1; 
    const formattedDate = new Date().toLocaleDateString('en-CA');
    const GetCalendar = commonConfigs.apiUrls.GetCalendar(month, 0, '');

    await axios
      .get(GetCalendar, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        console.log("today's date", formattedDate);
        res.data.result.data.forEach((calendar) => {
          if (calendar.date == formattedDate) {
            setCurrentAttendance(calendar.attendance_current);
            setTotalAttendance(calendar.attendance_total);
          }
        });
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function fetchMerchantCalendar() {

    const currentDate = new Date();
    const month = currentDate.getMonth() + 1; 
    const formattedDate = new Date().toLocaleDateString('en-CA');
    const GetCalendar = commonConfigs.apiUrls.GetCalendar(month, 1, '');

    await axios
      .get(GetCalendar, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        console.log("today's date", formattedDate);
        res.data.result.data.forEach((calendar) => {
          if (calendar.date == formattedDate) {
            setCurrentMerchantAttendance(calendar.attendance_current);
            setTotalMerchantAttendance(calendar.attendance_total);
          }
        });
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function FetchLeaveRequest() {

    const GetAllLeaveTimecards = commonConfigs.apiUrls.GetAllLeaveTimecards(); 

    await axios
      .get(GetAllLeaveTimecards, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        console.log("Leave request amt", res.data.result.data.length);
        setLeaveReuestAmt(res.data.result.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (load) {
      fetchCalendar().catch((err) => {
        console.log("err: " + err);
      });
      FetchLeaveRequest().catch((err) => {
        console.log("err: " + err);
      });
      fetchMerchantCalendar().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="">
          <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
            <Breadcrumbs pages={pages} />
          </div>
        </div>
        <div className=" md:mt-0 md:ml-4 ">
          <div className="flex-1 flex ">
            <form className="w-full flex md:ml-0 " action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600 ">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                  <SearchNormal className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className="block w-full h-full pl-8 pr-3 py-2  border-gray-300 rounded-mdtext-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                  placeholder="Search"
                  type="search"
                  name="search"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="flex justify-around  ">
        <div className=" md:w-full">
          <ScheduleCalendar />
        </div>
        <div className="ml-8 bg-gray-100">
          <h3 className="font-bold p-8">{"Today's Overview"}</h3>
          <ScheduleTodayOverview>
            {overview.map((overview) => (
              <div key={overview.name} className=" mt-2 p-2">
                <div className={overview.color}>
                  <p>{overview.name}</p>
                </div>
              </div>
            ))}
          </ScheduleTodayOverview>
        </div>
      </div>
    </div>
  );
};

export default ScheduleLayout;
