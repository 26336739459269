import PaymentOptionLayout from "./paymentOptionLayout";
import { PaymentOptionContextProvider } from "../context/paymentOptionContext";

const PaymentOptionPage = () => {
  return (
    <PaymentOptionContextProvider>
        <PaymentOptionLayout />
    </PaymentOptionContextProvider>
  );
};

export default PaymentOptionPage;
