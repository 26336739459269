import { MerchantMenuContextProvider } from "../../context/merchantMenuContext";
import MerchantsDetailsLayout from "./merchantsDetailsLayout";

const MerchantsMenuPage = () => {
  return (
    <MerchantMenuContextProvider>
      <MerchantsDetailsLayout />
   </MerchantMenuContextProvider>
  );
};

export default MerchantsMenuPage;
