import axios from "axios";
import { useParams } from "react-router-dom";
import commonConfigs from "../../../../config/config";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import React, { useState, useEffect } from "react";
import Table from "../../../../commons/components/empDetailsTable";
import Table1 from "./employeeTable";
import Spinner from "../../../../commons/components/spinner";
import { Edit, Trash } from "iconsax-react";
import UpdateEmpDetails from "./updateEmployeeDetailsLayout";
import UpdateEmpDetailsAM from "./updateEmployeeDetailsLayoutAM";
import UpdateEmpDetailsOM from "./updateEmployeeDetailsLayoutOM";
import DeleteModal from "../../../../commons/components/deleteModal";
// import { format } from "date-fns";
const user = JSON.parse(localStorage.getItem("user"));

const EmployeeDetailsLayout = () => {
  const [employeeName, setEmployeeName] = useState("");
  const [rowToEdit, setRowToEdit] = useState();
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isUpdateModalAM, setUpdateModalAM] = useState(false);
  const [isUpdateModalOM, setUpdateModalOM] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 100000); // 10 seconds in milliseconds

    return () => clearTimeout(timer);
  }, []);

  let { date } = useParams();
  let { accountId } = useParams();
  let { location } = useParams();
  
  const pages = [
    { name: "Calendar", href: "/schedule", current: false },
    { name: date, href: `/schedule/date/${date}/${location}`, current: false },
    {
      name: employeeName,
      href: `/schedule/date/${date}/${location}/employeedetails/${accountId}`,
      current: true,
    },
  ];

  // Extract year and month from the date
  const [year, month] = date.split("-");

  // Calculate the start and end dates of the month
  const start = `${year}-${month}-01`;
  const end = `${year}-${month}-30`;

  const GetIndividualTimecardDetails = commonConfigs.apiUrls.GetIndividualTimecardDetails(accountId, start, end );

  // Data.
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);

  async function getEmployeeData() {
    await axios
      .get(GetIndividualTimecardDetails, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setData(res.data.result.data.timecards);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getEmployeeName() {

    const GetAllTimecards = commonConfigs.apiUrls.GetAllTimecards('');

    await axios
      .get(GetAllTimecards, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);

        const matchingData = res.data.result.data.find(
          (data) => data.account_id === accountId
        );
  
        if (matchingData) {
          setEmployeeName(matchingData.account_username);
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  useEffect(() => {
    if (load) {
      getEmployeeData().catch((err) => {
        console.log("emenu err: " + err);
      });
      getEmployeeName().catch((err) => {
        console.log("username err: " + err);
      });
    }
  }, [load]);

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Timecard Id",
      //   accessor: "timecard_id",
      //   Cell: ({ value }) => value ? value : '-'
      // },
      {
        Header: "Date",
        accessor: "timecard_date",
        Cell: ({ value }) => value ? value : '-'
      },
      {
        Header: "Time In",
        accessor: ({ timestamp_in }) => {
          if (timestamp_in) {
            const date = new Date(timestamp_in);
            const isValidDate = !isNaN(date.getTime());
            return isValidDate && timestamp_in !== "-" ? timestamp_in.substring(11, 19) : "-";
          } else {
            return "-";
          }
        },
      },
      {
        Header: "Time Out",
        accessor: ({ timestamp_out }) => {
          if (timestamp_out) {
            const date = new Date(timestamp_out);
            const isValidDate = !isNaN(date.getTime());
            return isValidDate && timestamp_out !== "-" ? timestamp_out.substring(11, 19) : "-";
          } else {
            return "-";
          }
        },
      },
      {
        Header: "Duration",
        accessor: "attendance_duration",
      },
      {
        Header: "Attendance Status",
        Cell: ({ row }) => {
          const timeInDatetime = row.original.timestamp_in;
          const timeOutDatetime = row.original.timestamp_out;
          const attendanceDuration = row.original.attendance_duration;
          const leaveRequest = row.original.leave_request; // Assuming 'leave_request' is a property in the row data
          
          if (leaveRequest === '-') {
            if (timeInDatetime === '-') {
              return (
                <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                  Absent
                </div>
              );
            } else if (timeInDatetime !== '-' && timeOutDatetime === '-') {
              return (
                <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                  Pending
                </div>
              );
            } else if (attendanceDuration < 8) {
              return (
                <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-amber-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                  {"<8Hrs"}
                </div>
              );
            } else if (attendanceDuration >= 8) {
              return (
                <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                  {">8Hrs"}
                </div>
              );
            }
          }
          else{
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
                Leave
              </div>
            );
          }
        },
      },
      {
        Header: "Leave Requested",
        accessor: "leave_request",
      },
      {
        Header: "status",
        accessor: "attendance_status",
        Cell: ({ row }) => {
          let status = row.original.attendance_status;
          if (status == 0 || status == '-') {
            return <div className="text-sm text-orange-700">Pending</div>;
          } 
          else if (status == 1){
            return <div className="text-sm text-green-700">Confirmed</div>;
          }
          else {
            return <div className="text-sm text-red-700">Declined</div>;
          }
        },
      },
      {
        Header: "Remarks",
        accessor: "supervisor_remarks",
        Cell: ({ value }) => value ? value : '-'
      },
      {
        Header: "Reason",
        accessor: "attendance_reason",
        Cell: ({ value }) => value ? value : '-'
      },      
      {
        Header: "Addition",
        accessor: "minutes_addition",
        Cell: ({ value }) => value ? value : '0'
      },
      {
        Header: "Deduction",
        accessor: "minutes_deduction",
        Cell: ({ value }) => value ? value : '0'
      },
      {
        Header: "Basic Salary",
        accessor: "basic_salary",
        Cell: ({ value, row }) => {      
          if (row.original.activity === "Leave") {
            return "-";
          } else {
            return value ? value : '0';
          }
        }
      },
      {
        Header: "Gross Salary",
        accessor: "gross_salary",
        Cell: ({ value, row }) => {      
          if (row.original.activity === "Leave") {
            return "-";
          } else {
            return value ? value : '0';
          }
        }
      },
      {
        Header: "Last Updated By",
      accessor: "updated_by",
        Cell: ({ value }) => value ? value : '-'
      },
      {
        Header: "Last Updated On",
        accessor: ({ updated_on }) => (updated_on) != "-" ? (updated_on.substring(0, 10) + " " + updated_on.substring(11, 16)) : "-",
      },
      {
        id: "edit",
        Cell: ({ row }) => {
          return (
            <div className="text-sm text-gray-900 flex justify-center">
              {row.original.activity == "Work" && (
                <button
                  type="button"
                  className="m-1"
                  onClick={() => {
                    setUpdateModal(true);
                    setRowToEdit(row.original);
                  }}
                >
                  <Edit aria-hidden="true" />
                </button>
              )}

              {row.original.activity == "Leave" && (
                <button
                  type="button"
                  className="m-1"
                  onClick={() => {
                    setDeleteModalOpen(true);
                    setRowToEdit(row.original);
                  }}
                >
                  <Trash aria-hidden="true" />
                </button>
              )}

            </div>
          );
        },
      },  
    ],
    []
  );

  const columnsManager = React.useMemo(
    () => [
      // {
      //   Header: "Timecard Id",
      //   accessor: "timecard_id",
      //   Cell: ({ value }) => value ? value : '-'
      // },
      {
        Header: "Date",
        accessor: "timecard_date",
        Cell: ({ value }) => value ? value : '-'
      },
      {
        Header: "Time In",
        accessor: ({ timestamp_in }) => {
          if (timestamp_in) {
            const date = new Date(timestamp_in);
            const isValidDate = !isNaN(date.getTime());
            return isValidDate && timestamp_in !== "-" ? timestamp_in.substring(11, 19) : "-";
          } else {
            return "-";
          }
        },
      },
      {
        Header: "Time Out",
        accessor: ({ timestamp_out }) => {
          if (timestamp_out) {
            const date = new Date(timestamp_out);
            const isValidDate = !isNaN(date.getTime());
            return isValidDate && timestamp_out !== "-" ? timestamp_out.substring(11, 19) : "-";
          } else {
            return "-";
          }
        },
      },
      // {
      //   Header: "Attendance Status",
      //   Cell: ({ row }) => {
      //     const timeInDatetime = row.original.timestamp_in;
      //     const timeOutDatetime = row.original.timestamp_out;
      //     const attendanceDuration = row.original.attendance_duration;
      //     const leaveRequest = row.original.leave_request; // Assuming 'leave_request' is a property in the row data
          
      //     if (leaveRequest === '-') {
      //       if (timeInDatetime === '-') {
      //         return (
      //           <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
      //             Absent
      //           </div>
      //         );
      //       } else if (timeInDatetime !== '-' && timeOutDatetime === '-') {
      //         return (
      //           <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
      //             Pending
      //           </div>
      //         );
      //       } else if (attendanceDuration < 8) {
      //         return (
      //           <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-amber-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
      //             {"<8Hrs"}
      //           </div>
      //         );
      //       } else if (attendanceDuration >= 8) {
      //         return (
      //           <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
      //             {">8Hrs"}
      //           </div>
      //         );
      //       }
      //     }
      //     else{
      //       return (
      //         <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" style={{ borderRadius: '50px', width: '100px' }}>
      //           Leave
      //         </div>
      //       );
      //     }
      //   },
      // },
      {
        Header: "Leave Requested",
        accessor: "leave_request",
      },
      {
        Header: "status",
        accessor: "attendance_status",
        Cell: ({ row }) => {
          let status = row.original.attendance_status;
          if (status == 0 || status == '-') {
            return <div className="text-sm text-orange-700">Pending</div>;
          } 
          else if (status == 1){
            return <div className="text-sm text-green-700">Confirmed</div>;
          }
          else {
            return <div className="text-sm text-red-700">Declined</div>;
          }
        },
      },
      {
        Header: "Remarks",
        accessor: "supervisor_remarks",
        Cell: ({ value }) => value ? value : '-'
      },
      {
        Header: "Reason",
        accessor: "attendance_reason",
        Cell: ({ value }) => value ? value : '-'
      },      
      // {
      //   Header: "Addition",
      //   accessor: "minutes_addition",
      //   Cell: ({ value }) => value ? value : '0'
      // },
      // {
      //   Header: "Deduction",
      //   accessor: "minutes_deduction",
      //   Cell: ({ value }) => value ? value : '0'
      // },
      // {
      //   Header: "Basic Salary",
      //   accessor: "basic_salary",
      //   Cell: ({ value, row }) => {      
      //     if (row.original.activity === "Leave") {
      //       return "-";
      //     } else {
      //       return value ? value : '0';
      //     }
      //   }
      // },
      // {
      //   Header: "Gross Salary",
      //   accessor: "gross_salary",
      //   Cell: ({ value, row }) => {      
      //     if (row.original.activity === "Leave") {
      //       return "-";
      //     } else {
      //       return value ? value : '0';
      //     }
      //   }
      // },
      {
        Header: "Last Updated By",
      accessor: "updated_by",
        Cell: ({ value }) => value ? value : '-'
      },
      {
        Header: "Last Updated On",
        accessor: ({ updated_on }) => (updated_on) != "-" ? (updated_on.substring(0, 10) + " " + updated_on.substring(11, 16)) : "-",
      },
      {
        id: "edit",
        Cell: ({ row }) => {
          if (user.role_id == 47) {
            return (
              <div className="text-sm text-gray-900 flex justify-center">
                {row.original.activity == "Work" && (
                  <button
                    type="button"
                    className="m-1"
                    onClick={() => {
                      setUpdateModalAM(true);
                      setRowToEdit(row.original);
                    }}
                  >
                    <Edit aria-hidden="true" />
                  </button>
                )}
  
                {row.original.activity == "Leave" && (
                  <button
                    type="button"
                    className="m-1"
                    onClick={() => {
                      setDeleteModalOpen(true);
                      setRowToEdit(row.original);
                    }}
                  >
                    <Trash aria-hidden="true" />
                  </button>
                )}
  
              </div>
            );
          } else if (user.role_id == 48 || user.role_id == 44) {
            return (
              <div className="text-sm text-gray-900 flex justify-center">
                {row.original.activity == "Work" && (
                  <button
                    type="button"
                    className="m-1"
                    onClick={() => {
                      setUpdateModalOM(true);
                      setRowToEdit(row.original);
                    }}
                  >
                    <Edit aria-hidden="true" />
                  </button>
                )}
  
                {row.original.activity == "Leave" && (
                  <button
                    type="button"
                    className="m-1"
                    onClick={() => {
                      setDeleteModalOpen(true);
                      setRowToEdit(row.original);
                    }}
                  >
                    <Trash aria-hidden="true" />
                  </button>
                )}
  
              </div>
            );
          }
        },
      },  
    ],
    []
  );

  
  if (user.role_id == 47) {
    return (   
      <div className="w-full desktop:p-6 mobile:p-2 ">
          <div className="md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
                  <Breadcrumbs pages={pages} />
              </div>
          </div>
          {(data != null) ? 
              (
                  <Table1
                  columns={columnsManager}
                  data={data}
                  filters={columnsManager.map((column) => column.accessor)}
                  />
              ):(
                <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
                  <div className="flex place-content-center w-full">
                    {showSpinner ? (
                      <Spinner className="w-20 h-20" />
                    ) : (
                      'There are no Timecards for this employee.'
                    )}
                  </div>
                </div>
              )
          }
          {isUpdateModalAM && (
            <UpdateEmpDetailsAM
              setUpdateModalAM={setUpdateModalAM}
              load={setLoad}
              row={rowToEdit}
            />
          )}
           {isDeleteModalOpen && (
          <DeleteModal
            setDeleteModal={setDeleteModalOpen}
            load={setLoad}
            url={commonConfigs.apiUrls.DeleteLeaveTimecard(rowToEdit.timecard_id)}
          />
        )}
      </div>
      );
  } else if (user.role_id == 48 || user.role_id == 44) {
    return (   
      <div className="w-full desktop:p-6 mobile:p-2 ">
          <div className="md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
                  <Breadcrumbs pages={pages} />
              </div>
          </div>
          {(data != null) ? 
              (
                <Table1
                  columns={columnsManager}
                  data={data}
                  filters={columnsManager.map((column) => column.accessor)}
                />
              ):(
                <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
                <div className="flex place-content-center w-full">
                  {showSpinner ? (
                    <Spinner className="w-20 h-20" />
                  ) : (
                    'There are no Timecards for this employee.'
                  )}
                </div>
              </div>
              )
          }
          {isUpdateModalOM && (
            <UpdateEmpDetailsOM
              setUpdateModalOM={setUpdateModalOM}
              load={setLoad}
              row={rowToEdit}
            />
          )}
           {isDeleteModalOpen && (
          <DeleteModal
            setDeleteModal={setDeleteModalOpen}
            load={setLoad}
            url={commonConfigs.apiUrls.DeleteLeaveTimecard(rowToEdit.timecard_id)}
          />
        )}
      </div>
      );
  } else {
    return (   
      <div className="w-full desktop:p-6 mobile:p-2 ">
          <div className="md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
                  <Breadcrumbs pages={pages} />
              </div>
          </div>
          {(data != null) ? 
              (
                  <Table
                  columns={columns}
                  data={data}
                  filters={columns.map((column) => column.accessor)}
                  />
              ):(
                <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
                  <div className="flex place-content-center w-full">
                    {showSpinner ? (
                      <Spinner className="w-20 h-20" />
                    ) : (
                      'There are no Timecards for this employee.'
                    )}
                  </div>
                </div>
              )
          }
          {isUpdateModal && (
            <UpdateEmpDetails
              setUpdateModal={setUpdateModal}
              load={setLoad}
              row={rowToEdit}
            />
          )}
           {isDeleteModalOpen && (
          <DeleteModal
            setDeleteModal={setDeleteModalOpen}
            load={setLoad}
            url={commonConfigs.apiUrls.DeleteLeaveTimecard(rowToEdit.timecard_id)}
          />
        )}
      </div>
      );
  }
     
};

export default EmployeeDetailsLayout;
