import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { InfoCircle } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import axios from "axios";
import DatePicker from "react-datepicker";

// const getMerchantType = commonConfigs.apiUrls.MerchantType();
const user = JSON.parse(localStorage.getItem("user"));
const getTypeUrl = commonConfigs.apiUrls.MerchantType();

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function UpdateSupplierModal({ setOpenModal, row, load }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(true);
  const [merchantTypes, setMerchantTypes] = useState([]);
  const [lazyData, setLazyData] = useState({});

  const id = row.merchant_id;


  const cutOffTime = new Date(`${row.merchant_gpo_cut_off_time}`);

  cutOffTime.setHours(cutOffTime.getHours() - 7);
  cutOffTime.setMinutes(cutOffTime.getMinutes() - 30);

  const cut_off_time = cutOffTime.toTimeString().substring(0, 8);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      merchant_type_sys_code: 
      {
        value: row.merchant_type.sys_code_id,
        label: row.merchant_type.sys_code_desc,
      },
      merchant_name: row.merchant_name,
      merchant_address: row.merchant_address,
      merchant_zip_code: row.merchant_zip_code,
      merchant_poc: row.merchant_poc,
      merchant_mobile: row.merchant_mobile,
      merchant_gpo_cut_off_time: cut_off_time,
      merchant_gpo_lead_time: row.merchant_gpo_lead_time,
    },
  });

  function formatData(data) {
    var sys_code = data.merchant_type_sys_code.value;
    var merchant_gpo_cut_off_time = new Date(`1970-01-01T${data.merchant_gpo_cut_off_time}`);
    var cut_off_time = merchant_gpo_cut_off_time.toTimeString().substring(0, 8);

    var newData = data;

    newData.merchant_type_sys_code = sys_code;
    newData.merchant_gpo_cut_off_time = cut_off_time;
    newData.updated_by = user.account_id;

    return newData;
  }

  const onSubmit = (data) => {
    setIsDisabled(true); // Disable the submit button
    const newData = formatData(data);
    console.log(newData);
    axios
      .put(commonConfigs.apiUrls.UpdateSupplier(id), newData, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setOpenModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  async function getTypeOptions() {
    await axios
      .get(getTypeUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        setMerchantTypes(res.data.result.data);

        res.data.result.data.forEach((type) => {
          if (type.merchant_type == row.menu_type) {
            setLazyData(
              (lazyData["merchant_type_sys_code"] = {
                value: type.sys_code_id,
                label: type.merchant_type,
              })
            );
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const getSelectData = async () => {
      await getTypeOptions().catch((err) => {
        console.log("err for menu_type: " + err);
      });
    };

    getSelectData()
      .then(() => {
        reset(lazyData);
      })
      .catch((err) => {
        console.log("err for menu_type: " + err);
      });
  }, []);

  const merchantTypeOptions = merchantTypes.map((d) => ({
    value: d.sys_code_id,
    label: d.merchant_type,
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setOpenModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg leading-6 font-medium text-gray-900"
                    >
                      Create Supplier
                    </Dialog.Title>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_type_sys_code"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Supplier Type
                      </label>
                      <Controller
                        name="merchant_type_sys_code"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={merchantTypeOptions}
                            isSearchable={true}
                            placeholder="Select a Type"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Supplier Company Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_name"
                          id="merchant_name"
                          className={
                            !errors.merchant_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Supplier Company Name"}
                          {...register("merchant_name", { required: true })}
                        />
                        {errors.merchant_name?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_address"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Supplier Address
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_address"
                          id="merchant_address"
                          className={
                            !errors.merchant_address
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Supplier Address"}
                          {...register("merchant_address", { required: true })}
                        />
                        {errors.merchant_address?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_zip_code"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Supplier Postal Code
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_zip_code"
                          id="merchant_zip_code"
                          className={
                            !errors.merchant_zip_code
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Supplier Zip Code"}
                          {...register("merchant_zip_code", { required: true })}
                        />
                        {errors.merchant_zip_code?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_poc"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        POC Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="merchant_poc"
                          id="merchant_poc"
                          className={
                            !errors.merchant_poc
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter POC Name"}
                          {...register("merchant_poc", { required: true })}
                        />
                        {errors.merchant_poc?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="merchant_mobile"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        POC Contact
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="tel"
                          name="merchant_mobile"
                          id="merchant_mobile"
                          className={
                            !errors.merchant_mobile
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter POC Contact"}
                          {...register("merchant_mobile", { required: true })}
                        />
                        {errors.merchant_mobile?.type === "required" &&
                          errorIcon}
                      </div>

                      <div className="mt-2 sm:col-span-6">
                        <label htmlFor="merchant_gpo_cut_off_time" className="block text-sm font-medium text-yellow-800">
                        Cut Off Time
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="time"
                            name="merchant_gpo_cut_off_time"
                            id="merchant_gpo_cut_off_time"
                            className={
                              !errors.merchant_gpo_cut_off_time
                                ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                                : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            }
                            placeholder={"Enter Start Time (HH:MM:SS:am/pm)"}
                            {...register("merchant_gpo_cut_off_time", {
                              required: true,
                              pattern: {
                                value: /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
                                message: "Please enter a valid time in the format 'HH:MM:SS'",
                              },
                            })}
                            step="1"
                          />
                          {errors.merchant_gpo_cut_off_time?.type === "required" && errorIcon}
                          {errors.merchant_gpo_cut_off_time?.type === "pattern" && (
                            <span className="text-red-600 text-sm">
                              {errors.merchant_gpo_cut_off_time?.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="mt-2 sm:col-span-6">
                        <label
                          htmlFor="merchant_gpo_lead_time"
                          className="block text-sm font-medium text-yellow-800"
                        >
                          GPO Delivery Lead
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="text"
                            name="merchant_gpo_lead_time"
                            id="merchant_gpo_lead_time"
                            className={
                              !errors.merchant_gpo_lead_time
                                ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                                : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            }
                            placeholder={"Enter Delivery Lead"}
                            {...register("merchant_gpo_lead_time", {
                            required : true,
                            })}
                          />
                          {errors.merchant_gpo_lead_time?.type ===
                            "required" && errorIcon}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
