import { createContext, useState } from "react";

const tabs = [
  { name: "Revenue", href: "/dashboard/hierarchy" },
  { name: "Billing", href: "/dashboard/hierarchy" },
];

export const DashboardTabsContext = createContext();
export const DashboardCurrentTabContext = createContext();

export const DashboardContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <DashboardTabsContext.Provider value={{ tabs }}>
      <DashboardCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </DashboardCurrentTabContext.Provider>
    </DashboardTabsContext.Provider>
  );
};
