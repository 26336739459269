import MerchantsLayout from "./merchantsLayout";
import {MerchantMenuContextProvider} from "../context/merchantMenuContext";

const MerchantsPage = () => {
  return (
    <MerchantMenuContextProvider>
      <MerchantsLayout />
    </MerchantMenuContextProvider>  
  );
};

export default MerchantsPage;
