import { LocationsContextProvider } from "../context/locationsContext";
import BillingLocationsLayout from "./billingLocationsLayout";

const LocationsPage = () => {
  return (
    <LocationsContextProvider>
      <BillingLocationsLayout />
    </LocationsContextProvider>
  );
};

export default LocationsPage;
