import Background from "../../../assets/bitmap.png";
import { HashLink } from "react-router-hash-link";

import { ArrowRight } from "iconsax-react";
const Survey = () => {
  return (
    <>
      <div
        className=" w-full bg-hero bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className=" bg-black bg-opacity-40">
          <div className="flex min-h-screen desktop:pl-8 mobile:pl-4">
            <div className="flex-col flex mt-40 py-2 px-4  desktop:w-2/4 mobile:w-full">
              <h1 className="text-5xl font-bold text-white ">
                Let us know how we can do better.
              </h1>

              {/* <div className="mt-24 ">
                <HashLink to="#feedback" smooth>
                  <button
                    type="submit"
                    className="space-x-2 items-center w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lime-500 hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span>Complete Survey</span> <ArrowRight />
                  </button>
                </HashLink>
              </div> */}

              <div className="mt-24">
                <HashLink to="#feedback" smooth>
                  <button
                    type="submit"
                    className="space-x-2 items-center w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lime-500 hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span>Complete Survey</span> <ArrowRight />
                  </button>
                </HashLink>
              </div>
            </div>
            <div className=" lg:block relative desktop:w-2/4 mobile:w-1/5"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Survey;
