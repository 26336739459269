import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import BillingDonut from "../../hierarchy_modules/components/billingDonut";

export default function TMACard() {

  const [todayMerchantAttendance, setTodayMerchantAttendance] = useState([]);
  const [selectedMerchantLocation, setSelectedMerchantLocation] = useState('');
  const [locationMerchantList, setLocationMerchantList] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))


  async function fetchTodayMerchantAttendance() {
    let arr = []
    const GetMerchantsAttendance = commonConfigs.apiUrls.GetMerchantsAttendance();

    await axios
    .get(GetMerchantsAttendance, { headers: commonConfigs.apiHeader })
    .then((res) => {
      // console.log(res.data.result.data);
      let response = res.data.result.data;
      if(user.location_id && (user.access_multiple_location === 0 || !user.access_multiple_location)) {
        arr.push(response.find(({location_id}) => user.location_id === location_id))
        setTodayMerchantAttendance(arr.filter(item => item.is_deleted === 0)) 
      } else {
        setTodayMerchantAttendance(res.data.result.data.filter(item => item.is_deleted === 0));
      }
    })
    .catch((err) => {
      console.log(err);
    });

  }

  async function fetchLocations() {

    const GetLocations = commonConfigs.apiUrls.GetLocations();

    await axios
    .get(GetLocations, { headers: commonConfigs.apiHeader })
    .then((res) => {
      console.log(res.data.result.data);
      setLocationMerchantList(res.data.result.data.filter(item => item.is_deleted === 0));
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const todayMerchantAttendanceData = todayMerchantAttendance.map((item) => ({
    location_name: item.location_name,
    info: [
      {
        status: 'Current',
        status_value: item.attendance_current,
      },
      {
        status: 'Total',
        status_value: item.attendance_total,
      }
    ]
  }));

  const filteredMerchantAttendanceData = todayMerchantAttendanceData.filter(
    (item) => item.location_name === selectedMerchantLocation
  );

  const handleLocationChangeMerchant = (event) => {
    setSelectedMerchantLocation(event.target.value);
  };
  
  useEffect(() => {
    if (locationMerchantList.length > 0) {
      setSelectedMerchantLocation("All");
    }
  }, [locationMerchantList]);

  useEffect(() => {
    fetchTodayMerchantAttendance().catch((err) => {
      console.log("err: " + err);
    });
    fetchLocations().catch((err) => {
      console.log("err: " + err);
    });
  }, []);


  return (
    <div style={{height:'inherit', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
      {todayMerchantAttendanceData && (
        <div>
          {/* <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <h6 style={{ marginBottom: '0px' }}>{"Today's Merchant Attendance"}</h6>
          </div> */}

          <h3 className="text-xl">
            Today's Merchant Attendance
          </h3>

          <div style={{ backgroundColor: '#FFF', padding: '20px', borderRadius: '10px' }}>
            <h6 style={{ display: 'inline-block' }}>Location:</h6>
            {
              user.location_id && user.access_multiple_location != 0 ?
              <select
              style={{ width: '100%', padding: '10px', borderRadius: '8px' }}
              value={selectedMerchantLocation}
              onChange={handleLocationChangeMerchant}
            >
              <option value="All">All</option>
              {locationMerchantList.map((location) => (
                <option key={location.location_id} value={location.location_name}>
                  {location.location_name}
                </option>
              ))}
            </select>
            :
          //   <select
          //   style={{ width: '100%', padding: '10px', borderRadius: '8px' }}
          //   value={selectedMerchantLocation}
          //   onChange={handleLocationChangeMerchant}
          // >
          <>
            {locationMerchantList.flatMap((location) => ( location.location_id == user.location_id ?
              <h6 style={{ display: 'inline-block', padding: '5px', marginBottom: '45px' }}>{location.location_name}</h6>
              :
              <></>
            ))}
            </>
          // </select>
            }
          </div>
          
          <div style={{ backgroundColor: '#FFF', padding: '25px', borderRadius: '10px' }}>
          
          </div>

          {selectedMerchantLocation === 'All' && (
            <div className="mt-6 mb-2">
              <BillingDonut
                data={todayMerchantAttendanceData.reduce((result, item) => {
                  item.info.forEach((status) => {
                    const existingMerchantStatus = result.find((data) => data.name === status.status);
                    if (existingMerchantStatus) {
                      existingMerchantStatus.value += status.status_value;
                    } else {
                      result.push({ name: status.status, value: status.status_value });
                    }
                  });
                  return result;
                }, [])}
                subtitle={`${todayMerchantAttendanceData.reduce(
                  (total, item) =>
                    total + item.info.reduce((sum, status) => sum + status.status_value, 0),
                  0
                )}`}
              />
            </div>
          )}

          {selectedMerchantLocation !== 'All' && (
            <div className="mt-6 mb-2">
              <BillingDonut
                data={filteredMerchantAttendanceData.flatMap((item) =>
                  item.info.map((status) => ({
                    name: status.status,
                    value: status.status_value,
                  }))
                )}
                subtitle={`${filteredMerchantAttendanceData.reduce(
                  (total, item) =>
                    total + item.info.reduce((sum, status) => sum + status.status_value, 0),
                  0
                )}`}
              />
            </div>
          )}
        </div>
      )}
    </div>   
  );
}
