import axios from "axios";
import { format } from "date-fns";
import { Link, useParams, useLocation } from "react-router-dom";
import { Edit, Trash, Cards, DocumentDownload } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import { Additem, DocumentUpload } from "iconsax-react";
import Table from "../../../../commons/components/table";
import React, { useState, useMemo, useEffect } from "react";
import Spinner from "../../../../commons/components/spinner";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";

// const getMerchantsUrl = commonConfigs.apiUrls.Merchants();

const MerchantsLayout = () => {
  const [isCreateModalOpen, setCreateModal] = useState(false);
  const [isUploadModalOpen, setUploadModal] = useState(false);
  // const [isDownloadModalOpen, setDownloadModal] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState(null);
  const [exportData, setExportData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  const locationName = useParams().locationName;
  var locationIdApi = "";
  var locationCodeApi = "";

  const formatDateAndTime = (datetimeString) => {
    if (!datetimeString) {
      return '-';
    }

    const date = new Date(datetimeString);
    const formattedDate = date.toISOString().split('T')[0]; // Extract date portion
    const formattedTime = date.toISOString().split('T')[1].split('.')[0]; // Extract time portion
    return `${formattedDate} ${formattedTime}`;
  };

  if (localStorage.getItem('locationid') == null) {
    const { locationId } = useLocation().state;

    localStorage.setItem('locationid', locationId);
    locationIdApi = locationId;
    console.log(locationIdApi);
  } else {
    locationIdApi = localStorage.getItem('locationid');
    console.log(locationIdApi);

  }

  if (localStorage.getItem('locationcode') == null) {
    const { locationCode } = useLocation().state;
    localStorage.setItem('locationcode', locationCode);
    locationCodeApi = locationCode;
    console.log(locationCodeApi);
  } else {
    locationCodeApi = localStorage.getItem('locationcode');
    console.log(locationCodeApi);

  }
  const getMerchantsUrl = commonConfigs.apiUrls.GetLocationMerchants(locationIdApi);
  // const getMerchantsUrl = commonConfigs.apiUrls.GetLocationMerchants(locationCodeApi);
  const getExportMerchantsUrl = commonConfigs.apiUrls.GetAllLocationProducts(locationIdApi);

  const pages = [
    { name: "Configurations", href: "#", current: false },
    { name: "Locations", href: "/configurations/paymentoptionlocations", current: false },
    { name: locationName, href: `/configurations/paymentoption/${locationName}`, current: true },
  ];


  // }
  useEffect(() => {
    async function getData() {
      await axios
        .get(getMerchantsUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          setData(res.data.result.data.filter(item => item.is_deleted === 0));
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Merchant Name",
      accessor: "merchant_name",
      width: 10,
      Cell: ({ row, value }) => {
        return (
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img src={row.original.merchant_img_url} />
            </div>
            <div className="ml-4">
              <p className="text-sm text-black">{value}</p>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Takeaway Charge",
      accessor: "takeaway_charge",
    },
    {
      Header: "Dishwashing Txn Fee",
      accessor: "dishwashing_txn_fee",
    },
    {
      Header: "GPO Commission Fee",
      accessor: "merchant_gpo_commission_fee",
    },
    {
      Header: "GPO Commission Fee Rate",
      accessor: "merchant_gpo_commission_fee_rate",
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;

          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        let merchantObj = {
          merchantName: row.original.merchant_name,
          id: row.original.merchant_id,
        };
        return (
          <div className=" text-gray-900  flex space-x-1 ">
            <button type="button">
              <Link
                to={{
                  pathname: `/configurations/paymentoption/${locationName}/${merchantObj.merchantName}/${merchantObj.id}`,
                }}
              >
                <Cards aria-hidden="true" className="w-6 h-6" />
              </Link>
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full desktop:p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 pb-5 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      {data != null ? (
        <Table
          columns={columns}
          data={data}
          filters={columns.map((column) => column.accessor)}
        />
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MerchantsLayout;
