import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Table from "../../../../commons/components/table";
import Spinner from "../../../../commons/components/spinner";
import { format } from "date-fns";
import commonConfigs from "../../../../config/config";
import axios from "axios";
import RefundModal from "../components/refundModal";
import CreateRefundModal from "../components/createRefundModal";
import { Additem, DocumentDownload } from "iconsax-react";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Excel from "exceljs";
// import fs from "fs";


import MerchantsMenuTabs from "../components/merchantMenuTab";
import { MerchantMenuCurrentTabContext } from "../context/merchantMenuContext";

export default function MainMenuLayout() {

  var StartDateData = sessionStorage.getItem('startdate');
  var EndDateData = sessionStorage.getItem('enddate');
  var PlatformData = sessionStorage.getItem('platform');
  var PaymentData = sessionStorage.getItem('payment');
  var LoyaltyData = sessionStorage.getItem('loyalty');

  const { currentTab } = useContext(MerchantMenuCurrentTabContext);

  let { merchantId } = useParams();
  // let { merchantName } = useParams();
  let { locationcode } = useParams();
  let { locationId } = useParams();

  const [data, setData] = useState(null);
  const [errormsgTxn, setErrorMsgTxn] = useState(false);
  const [errormsgRefundTxn, setErrorMsgrefundTxn] = useState(false);
  const [errormsg, setErrorMsg] = useState(false);
  const [generalData, setGeneralData] = useState(null);
  const [exportData, setExportData] = useState(null);
  const [txndata, setTxnData] = useState(null);
  const [refundtxndata, setRefundTxnData] = useState(null);
  const [exportRefundTxnData, setExportRefundTxnData] = useState(null);
  const [exportTxnData, setExportTxnData] = useState(null);
  const [selectedRowProd, setSelectedRowProd] = useState(null);
  const [selectedRowProdArr, setSelectedRowProdArr] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowArr, setSelectedRowArr] = useState(null)
  const [selectedRowAddOnArr, setSelectedRowAddOnArr] = useState(null)
  const [load, setLoad] = useState(true);

  // const platform = PLATFORM_TYPES;
  // const payment = PAYMENT_TYPES;

  const [startdate, setStartDate] = useState('');
  const [enddate, setEndDate] = useState('');

  const [startdateFilter, setStartDateFilter] = useState(StartDateData);
  const [enddateFilter, setEndDateFilter] = useState(EndDateData);

  const [platforms, setPlatforms] = useState('all');
  const [payments, setPayment] = useState('all');
  const [loyaltyId, setLoyaltyId] = useState('all');

  const [platformsName, setPlatformsName] = useState('all');
  const [paymentsName, setPaymentName] = useState('all');
  const [loyaltyIdName, setLoyaltyIdName] = useState('all');

  const [platformList, setPlatformsList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [loyaltyList, setLoyaltyList] = useState([]);

  const [isRefundModalOpen, setRefundModalOpen] = useState(false);
  const [isCreateRefundModalOpen, setCreateRefundModalOpen] = useState(false);

  const [isApplying, setIsApplying] = useState(false);
  const [isReseting, setIsReseting] = useState(false);

  const [isApplying2, setIsApplying2] = useState(false);
  const [isReseting2, setIsReseting2] = useState(false);

  const [isApplying3, setIsApplying3] = useState(false);
  const [isReseting3, setIsReseting3] = useState(false);

  const applyButtonEnabledClass = "bg-red-600 hover:bg-red-700";
  const applyButtonDisabledClass = "bg-red-400 cursor-not-allowed";

  const getMerchantsSalesFilterUrl = commonConfigs.apiUrls.GetFilterSalesParams(locationId);


  async function fetchMerchantSales() {
    var platformFilter = '';
    var paymentFilter = '';
    var loyaltyFilter = '';

    if (platforms !== "all") {
      platformFilter = platforms;
    }
    if (payments !== "all") {
      paymentFilter = payments;
    }
    if (loyaltyId !== "all") {
      loyaltyFilter = loyaltyId;
    }

    const getMerchantsSalesUrl = commonConfigs.apiUrls.GetMerchantFilterSales(merchantId, platformFilter, paymentFilter, loyaltyFilter, startdateFilter, enddateFilter);
    console.log(getMerchantsSalesUrl);
    await axios
      .get(getMerchantsSalesUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        var arr = [];
        if (res.data.result.data.message !== undefined) {
          // arr.push(res.data.result.data);
          // setGeneralData(arr);
          // setErrorMsg(res.data.result.data.message);
          setErrorMsg(true);
          // setExportData(arr);
          setLoad(false);  
          setIsApplying(false);
          setIsReseting(false);
        } else {
          arr.push(res.data.result.data);
          setGeneralData(arr);
          setData(res.data.result.data.categories);
          setExportData(res.data.result.data);
          setErrorMsg(false);
          setLoad(false);  
          setIsApplying(false);
          setIsReseting(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function fetchMerchantSalesTxn() {
    var platformFilter = '';
    var paymentFilter = '';
    var loyaltyFilter = '';

    if (platforms !== "all") {
      platformFilter = platforms;
    }
    if (payments !== "all") {
      paymentFilter = payments;
    }
    if (loyaltyId !== "all") {
      loyaltyFilter = loyaltyId;
    }

    const getMerchantsSalesTransactionUrl = commonConfigs.apiUrls.GetMerchantsSales(merchantId ,platformFilter, paymentFilter, loyaltyFilter, startdateFilter, enddateFilter);

    await axios
      .get(getMerchantsSalesTransactionUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        // var arr = [];
        if (res.data.result.data.message == "No Transactions found for this merchant") {
          // setErrorMsgTxn(res.data.result.data.message);
          setErrorMsgTxn(true);
          // setExportTxnData(arr);
          setLoad(false); 
          setIsApplying2(false);
          setIsReseting2(false);
        } else {
          setTxnData(res.data.result.data);
          setExportTxnData(res.data.result.data);
          setErrorMsgTxn(false);
          setLoad(false);  
          setIsApplying2(false);
          setIsReseting2(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function fetchRefundSalesTxn() {
    var platformFilter = '';
    var paymentFilter = '';
    // var loyaltyFilter = '';

    if (platforms !== "all") {
      platformFilter = platforms;
    }
    if (payments !== "all") {
      paymentFilter = payments;
    }
    // if (loyaltyId !== "all") {
    //   loyaltyFilter = loyaltyId;
    // }

    const getRefundsSalesTransactionUrl = commonConfigs.apiUrls.GetRefundsSales(merchantId ,platformFilter, paymentFilter, startdateFilter, enddateFilter);
    console.log(getRefundsSalesTransactionUrl);
    await axios
      .get(getRefundsSalesTransactionUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        // var arr = [];
        if (res.data.result.data.length == 0) {
          // setErrorMsgTxn(res.data.result.data.message);
          setErrorMsgrefundTxn(true);
          // setExportTxnData(arr);
          setLoad(false); 
          setIsApplying3(false);
          setIsReseting3(false);
        } else {
          setRefundTxnData(res.data.result.data);
          setExportRefundTxnData(res.data.result.data);
          setErrorMsgrefundTxn(false);
          setLoad(false);  
          setIsApplying3(false);
          setIsReseting3(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function fetchSalesFilters() {
    await axios
      .get(getMerchantsSalesFilterUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        var response = res.data.result.data;
        var loyaltyProgrammes = response.loyalty_programmes;
        var paymentTypes = response.sys_code_labels.ORDER_PAYMENT_TYPE;
        var platformTypes = response.sys_code_labels.PLATFORM_TYPE;

        setLoyaltyList(loyaltyProgrammes);
        setPlatformsList(platformTypes);
        setPaymentList(paymentTypes);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const downloadFilesTxn = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(exportData.merchant_name, {
      properties: { defaultColWidth: 30 },
    });
    console.log(exportTxnData);
    console.log(exportData);
    
    for (const item of exportTxnData) {
      
      const highlightColor = "F892FFFF";
      const fillStyle = Excel.Fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: highlightColor },
      };
  
      // Title
      worksheet.unMergeCells("A1:G1");

      worksheet.mergeCells("A1:G1");
      worksheet.getCell("A1").font = { size: 18, bold: true, underline: true };
      worksheet.getCell("A1").alignment = { vertical: "middle", horizontal: "center" };
      worksheet.getCell("A1").value = locationcode;
  
      // Address
      worksheet.getCell("A3").font = { size: 16, bold: true };
      worksheet.getCell("A3").value = exportData.merchant_name;
      worksheet.getCell("A4").value = "NETS CLICK MID: " + exportData.nets_click_mid;
      worksheet.getCell("A5").value = "NETS CLICK TID: " + exportData.nets_click_tid;
      worksheet.getCell("A6").value = "NETS QR MID: " + exportData.nets_qr_mid;
      worksheet.getCell("A7").value = "NETS QR TID: " + exportData.nets_qr_tid;
  
      // PO Info
      worksheet.getCell("G3").font = { size: 14, bold: true };
      worksheet.getCell("G3").value = `Start Date: ${startdate}`;
      worksheet.getCell("G4").font = { size: 14, bold: true };
      worksheet.getCell("G4").value = `End Date: ${enddate}`;
      worksheet.getCell("G5").font = { size: 14, bold: true };
      worksheet.getCell("G5").value = `Platform Type: ${platformsName}`;
      worksheet.getCell("G6").font = { size: 14, bold: true };
      worksheet.getCell("G6").value = `Payment Type: ${paymentsName}`;
      worksheet.getCell("G7").font = { size: 14, bold: true };
      worksheet.getCell("G7").alignment = { wrapText: true };
      worksheet.getCell("G7").value = `Loyalty Programme: ${loyaltyIdName}`;
  
      // Items
      worksheet.getRow(9).font = { size: 14, bold: true };
      worksheet.getRow(9).alignment = { vertical: "middle", horizontal: "center", wrapText:'trues' };
      worksheet.getRow(9).values = [
        "ORDER NO",
        "ORDER DATETIME",
        "DISCOUNT AMT (SGD)",
        "VOUCHER TYPE",
        "VOUCHER AMT (SGD)",
        "GOVERNMENT VOUCHER AMT (SGD)",
        "LOYALTY REDEMPTION AMT (SGD)",
        "TOTAL ORDER AMT (SGD)",
        "ORDER STATUS",
        // "REFUNDED ON",
        // "REFUNDED BY",
      ];
      for (let i = 1; i <= 9; i++) {
        worksheet.getRow(9).getCell(i).fill = fillStyle;
      }
  
      let row = 10;
      for (const orderItem of exportTxnData) {
        console.log(orderItem);
        worksheet.getRow(row).alignment = { vertical: "middle", horizontal: "center", wrapText: 'true' };
        worksheet.getRow(row).font = { size: 14 };
        if(orderItem.voucher_type == "3") {
          Object.assign (orderItem, orderItem.govt_voucher_amt = orderItem.voucher_amt)
          worksheet.getRow(row).values = [
            orderItem.order_no,
            orderItem.order_datetime,
            orderItem.discount_amt,
            orderItem.voucher_type_desc,
            "-",
            orderItem.govt_voucher_amt,
            orderItem.loyalty_redemption_amt,
            orderItem.order_total_amt,
            orderItem.order_status,
            // orderItem.refunded_by,
            // orderItem.refunded_on,
          ];
        } else {
          worksheet.getRow(row).values = [
            orderItem.order_no,
            orderItem.order_datetime,
            orderItem.discount_amt,
            orderItem.voucher_type_desc,
            orderItem.voucher_amt,
            "-",
            orderItem.loyalty_redemption_amt,
            orderItem.order_total_amt,
            orderItem.order_status,
            // orderItem.refunded_by,
            // orderItem.refunded_on,
          ];
        }

        // worksheet.unMergeCells("A"+row+":C"+row);
        // worksheet.mergeCells("A"+row+":C"+row);
        row++;

        // if (orderItem.txn_order_consumer_add_ons) {
        //   for (const item of orderItem.txn_order_consumer_add_ons){
        //     worksheet.getRow(row).alignment = { vertical: "middle", horizontal: "left", wrapText: 'true' };
        //     worksheet.getRow(row).values = [
        //       item.product_name,
        //       item.order_qty,
        //       item.order_total_amt,
        //       item.order_nett_amt
  
        //     ];
        //     row++;
        //   }  
        // }
        
      }

      var totalTableRows = 0;
      var totalProductsCategories = exportTxnData.length;

      for (let i = 0; i < totalProductsCategories; i++){
        console.log(exportTxnData[i].txn_order_consumer_details);
        // totalTableRows = totalTableRows + exportTxnData[i].txn_order_consumer_details.length;  
        
      }

      totalTableRows = totalProductsCategories + totalTableRows;
      console.log(totalTableRows);

      var productStartRow = totalTableRows + 10;
      
      // Add borders
      for (let i = 10; i < productStartRow; i++) {
        for (let j = 1; j <= 9; j++) {
          worksheet.getRow(i).getCell(j).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
      var totalStartRow = totalTableRows + 11;

      // Total
      // worksheet.getCell("A"+totalStartRow).value = "SUBTOTAL";
      // worksheet.getCell("F57").value = item.order_details.subtotal;
  
      // worksheet.getCell("A"+(totalStartRow+1)).value = "GST";
      // worksheet.getCell("F58").value = item.order_details.gst;
  
      // worksheet.getCell("A"+(totalStartRow+2)).value = "Total Qty";
      // worksheet.getCell("B"+(totalStartRow+2)).value = exportData.grand_total_qty;
      // worksheet.getCell("A"+(totalStartRow+2)).font = { size: 16, bold: true };
      // worksheet.getCell("A"+(totalStartRow+2)).border = { top: { style: "dotted" }, bottom: { style: "double" } };
      // worksheet.getCell("A"+(totalStartRow+2)).value = "Total";
      // worksheet.getCell("B"+(totalStartRow+2)).font = { size: 16, bold: true };
      // worksheet.getCell("B" + (totalStartRow + 2)).border = { top: { style: "dotted" }, bottom: { style: "double" } };
      // worksheet.getCell("C"+(totalStartRow+2)).font = { size: 16, bold: true };
      // worksheet.getCell("C" + (totalStartRow + 2)).border = { top: { style: "dotted" }, bottom: { style: "double" } };
      // worksheet.unMergeCells("B"+(totalStartRow+2)+":C"+(totalStartRow+2));
      // worksheet.mergeCells("B" + (totalStartRow + 2) + ":C" + (totalStartRow + 2));
      
      // worksheet.getCell("B" + (totalStartRow + 2)).fill = fillStyle;
      // worksheet.getCell("C"+(totalStartRow+2)).fill = fillStyle;
    
      // worksheet.getCell("B" + (totalStartRow + 2)).value = exportData.grand_total_qty;
      // worksheet.getCell("C"+(totalStartRow+2)).value = exportData.grand_total_amt;
  
      // worksheet.getCell("A"+(totalStartRow+3)).font = { size: 16, bold: true };
      // worksheet.getCell("A"+(totalStartRow+3)).border = { top: { style: "dotted" }, bottom: { style: "double" } };
      // worksheet.getCell("A"+(totalStartRow+3)).value = "Total Amount ($)";
      // worksheet.getCell("B"+(totalStartRow+3)).font = { size: 16, bold: true };
      // worksheet.getCell("B"+(totalStartRow+3)).border = { top: { style: "dotted" }, bottom: { style: "double" } };
      // worksheet.getCell("B"+(totalStartRow+3)).fill = fillStyle;
      // worksheet.getCell("B"+(totalStartRow+3)).value = exportData.grand_total_amt;
  
      // Comments/Instructions
      worksheet.unMergeCells("A"+(totalStartRow+5)+":C"+(totalStartRow+5));
      worksheet.mergeCells("A"+(totalStartRow+5)+":C"+(totalStartRow+5));
      worksheet.getCell("A"+(totalStartRow+5)).font = { size: 14, bold: true };
      worksheet.getCell("A"+(totalStartRow+5)).fill = { ...fillStyle, fgColor: { argb: "FFBAB5BF" } };
      worksheet.getCell("A"+(totalStartRow+5)).value = "Comments or Special Instructions";
      worksheet.unMergeCells("A"+(totalStartRow+6)+":C"+(totalStartRow+9));
      worksheet.mergeCells("A"+(totalStartRow+6)+":C"+(totalStartRow+9));
  
      // Footer
      worksheet.unMergeCells("A"+(totalStartRow+11)+":C"+(totalStartRow+11));
      worksheet.mergeCells("A"+(totalStartRow+11)+":C"+(totalStartRow+11));
      worksheet.getCell("A"+(totalStartRow+11)).alignment = { vertical: "middle", horizontal: "center" };
      worksheet.getCell("A"+(totalStartRow+11)).value = "If you have any questions , please contact";
      worksheet.unMergeCells("A"+(totalStartRow+12)+":C"+(totalStartRow+12));
      worksheet.mergeCells("A"+(totalStartRow+12)+":C"+(totalStartRow+12));
      worksheet.getCell("A"+(totalStartRow+12)).alignment = { vertical: "middle", horizontal: "center" };
      worksheet.getCell("A"+(totalStartRow+12)).value = "management office";
    }
  
      const fileName = `${exportData.merchant_name}_TransactionSales.xlsx`;
      const filePath = `${fileName}`;
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], { type: fileType }); 
        FileSaver.saveAs(blob, filePath);
      });
      return { filePath, fileName };

  }

  const downloadFilesProducts = async () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(exportData.merchant_name, {
      properties: { defaultColWidth: 30 },
    });
    
    for (const item of exportData.categories) {
      
      const highlightColor = "F892FFFF";
      const fillStyle = Excel.Fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: highlightColor },
      };
  
      // Title
      worksheet.unMergeCells("A1:P1");

      worksheet.mergeCells("A1:P1");
      worksheet.getCell("A1").font = { size: 18, bold: true, underline: true };
      worksheet.getCell("A1").alignment = { vertical: "middle", horizontal: "center" };
      worksheet.getCell("A1").value = locationcode;
  
      // Address
      worksheet.getCell("A3").font = { size: 16, bold: true };
      worksheet.getCell("A3").value = exportData.merchant_name;
      worksheet.getCell("A4").value = "NETS CLICK MID: " + exportData.nets_click_mid;
      worksheet.getCell("A5").value = "NETS CLICK TID: " + exportData.nets_click_tid;
      worksheet.getCell("A6").value = "NETS QR MID: " + exportData.nets_qr_mid;
      worksheet.getCell("A7").value = "NETS QR TID: " + exportData.nets_qr_tid;
  
      // PO Info
      worksheet.getCell("P3").font = { size: 14, bold: true };
      worksheet.getCell("P3").value = `Start Date: ${startdate}`;
      worksheet.getCell("P4").font = { size: 14, bold: true };
      worksheet.getCell("P4").value = `End Date: ${enddate}`;
      worksheet.getCell("P5").font = { size: 14, bold: true };
      worksheet.getCell("P5").value = `Platform Type: ${platformsName}`;
      worksheet.getCell("P6").font = { size: 14, bold: true };
      worksheet.getCell("P6").value = `Payment Type: ${paymentsName}`;
      worksheet.getCell("P7").font = { size: 14, bold: true };
      worksheet.getCell("P7").alignment = { wrapText: true };
      worksheet.getCell("P7").value = `Loyalty Programme: ${loyaltyIdName}`;

      // Items
      worksheet.getRow(9).font = { size: 14, bold: true };
      worksheet.getRow(9).alignment = { vertical: "middle", horizontal: "center" , wrapText: 'true'};
      worksheet.getRow(9).values = [
        "PAYEATFWD TOTAL DONATED AMT (SGD)",
        "PAYEATFWD TOTAL CLAIMED AMT (SGD)",
        "PAYEATFWD BALANCE AMT (SGD)",
        "LOYALTY PRG RETENTION AMT (SGD)",
        "GRAND TOTAL REDEMPTION AMT (SGD)",
        "TOTAL VOUCHER AMT (SGD)",
        "TOTAL GOVERNMENT VOUCHER AMT (SGD)",
        "GRAND TOTAL VOUCHER AMT (SGD)",
        "GRAND TOTAL DISCOUNT AMT (SGD)",
        "GRAND TOTAL SERVICE CHARGE FEE (SGD)",
        "GRAND TOTAL GST TXN FEE (SGD)",
        "GRAND TOTAL NETS TXN FEE (SGD)",
        "GRAND TOTAL TAKEAWAY FEE (SGD)",
        "GRAND TOTAL ROUNDING DIFFERENCE (SGD)",
        "GRAND TOTAL QTY (SGD)",
        "GRAND TOTAL AMT (SGD)",
        "GRAND TOTAL NETT AMT (SGD)",
        "MERCHANT RECEIVABLE AMT (SGD)",
      ];
      for (let i = 1; i <= 16; i++) {
        worksheet.getRow(9).getCell(i).fill = fillStyle;
      }
      worksheet.getRow(10).alignment = { vertical: "middle", horizontal: "center", wrapText: 'true' };
      // for (const item of exportData) {
        worksheet.getRow(10).values = [
          exportData.payeatfwd_total_donated_amt,
          exportData.payeatfwd_total_claimed_amt,
          exportData.payeatfwd_balance,
          exportData.loyalty_programme_retention_amt,
          exportData.grand_total_redemption_amt,
          exportData.total_voucher_amt,
          exportData.total_govt_voucher_amt,
          exportData.grand_total_voucher_amt,
          exportData.grand_total_discount_amt,
          exportData.grand_total_service_charge_fee,
          exportData.grand_total_gst_txn_fee,
          exportData.grand_total_nets_txn_fee,
          exportData.grand_total_takeaway_amt,
          exportData.grand_total_rounding_difference,
          exportData.grand_total_qty,
          exportData.grand_total_amt,
          exportData.grand_total_nett_amt,
          exportData.merchant_receivable_amt,

        ];
      // }
      for (let j = 1; j <= 17; j++) {
        worksheet.getRow(10).getCell(j).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
      
      // Items
      worksheet.getRow(12).font = { size: 14, bold: true };
      worksheet.getRow(12).alignment = { vertical: "middle", horizontal: "center" };
      worksheet.getRow(12).values = [
        "PRODUCT",
        "QTY",
        "TOTAL (SGD)",
      ];
      for (let i = 1; i <= 3; i++) {
        worksheet.getRow(12).getCell(i).fill = fillStyle;
      }
  
      let row = 13;
      for (const orderItem of exportData.categories) {
        worksheet.getRow(row).alignment = { vertical: "middle", horizontal: "center", wrapText: 'true' };
        worksheet.getRow(row).font = { size: 14, bold: true, underline: true };
        worksheet.getRow(row).values = [
          orderItem.product_cat_name
        ];
        worksheet.unMergeCells("A"+row+":C"+row);
        worksheet.mergeCells("A"+row+":C"+row);
        row++;
        
        for (const item of orderItem.items){
          worksheet.getRow(row).alignment = { vertical: "middle", horizontal: "left", wrapText: 'true' };
          worksheet.getRow(row).values = [
            item.product_name,
            item.qty,
            item.amt
          ];
          row++;
        }
      }

      var totalTableRows = 0;
      var totalProductsCategories = exportData.categories.length;

      for (let i = 0; i < totalProductsCategories; i++){
        totalTableRows = totalTableRows + exportData.categories[i].items.length;  
        
      }

      totalTableRows = totalProductsCategories + totalTableRows;
      console.log(totalTableRows);

      var productStartRow = totalTableRows + 13;
      
      // Add borders
      for (let i = 13; i < productStartRow; i++) {
        for (let j = 1; j <= 3; j++) {
          worksheet.getRow(i).getCell(j).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
      var totalStartRow = totalTableRows + 11;

      // Total
      // worksheet.getCell("A"+totalStartRow).value = "SUBTOTAL";
      // worksheet.getCell("F57").value = item.order_details.subtotal;
  
      // worksheet.getCell("A"+(totalStartRow+1)).value = "GST";
      // worksheet.getCell("F58").value = item.order_details.gst;
  
      // worksheet.getCell("A"+(totalStartRow+2)).value = "Total Qty";
      // worksheet.getCell("B"+(totalStartRow+2)).value = exportData.grand_total_qty;
      worksheet.getCell("A"+(totalStartRow+3)).font = { size: 16, bold: true };
      worksheet.getCell("A"+(totalStartRow+3)).border = { top: { style: "dotted" }, bottom: { style: "double" } };
      worksheet.getCell("A"+(totalStartRow+3)).value = "Total";
      worksheet.getCell("B"+(totalStartRow+3)).font = { size: 16, bold: true };
      worksheet.getCell("B" + (totalStartRow + 3)).border = { top: { style: "dotted" }, bottom: { style: "double" } };
      worksheet.getCell("C"+(totalStartRow+3)).font = { size: 16, bold: true };
      worksheet.getCell("C" + (totalStartRow + 3)).border = { top: { style: "dotted" }, bottom: { style: "double" } };
      // worksheet.unMergeCells("B"+(totalStartRow+2)+":C"+(totalStartRow+2));
      // worksheet.mergeCells("B" + (totalStartRow + 2) + ":C" + (totalStartRow + 2));
      
      worksheet.getCell("B" + (totalStartRow + 3)).fill = fillStyle;
      worksheet.getCell("C"+(totalStartRow+3)).fill = fillStyle;
    
      worksheet.getCell("B" + (totalStartRow +3)).value = exportData.grand_total_qty;
      worksheet.getCell("C"+(totalStartRow+3)).value = exportData.grand_total_amt;
  
      // worksheet.getCell("A"+(totalStartRow+3)).font = { size: 16, bold: true };
      // worksheet.getCell("A"+(totalStartRow+3)).border = { top: { style: "dotted" }, bottom: { style: "double" } };
      // worksheet.getCell("A"+(totalStartRow+3)).value = "Total Amount ($)";
      // worksheet.getCell("B"+(totalStartRow+3)).font = { size: 16, bold: true };
      // worksheet.getCell("B"+(totalStartRow+3)).border = { top: { style: "dotted" }, bottom: { style: "double" } };
      // worksheet.getCell("B"+(totalStartRow+3)).fill = fillStyle;
      // worksheet.getCell("B"+(totalStartRow+3)).value = exportData.grand_total_amt;
  
      // Comments/Instructions
      worksheet.unMergeCells("A"+(totalStartRow+5)+":P"+(totalStartRow+5));
      worksheet.mergeCells("A"+(totalStartRow+5)+":P"+(totalStartRow+5));
      worksheet.getCell("A"+(totalStartRow+5)).font = { size: 14, bold: true };
      worksheet.getCell("A"+(totalStartRow+5)).fill = { ...fillStyle, fgColor: { argb: "FFBAB5BF" } };
      worksheet.getCell("A"+(totalStartRow+5)).value = "Comments or Special Instructions";
      worksheet.unMergeCells("A"+(totalStartRow+6)+":P"+(totalStartRow+9));
      worksheet.mergeCells("A"+(totalStartRow+6)+":P"+(totalStartRow+9));
  
      // Footer
      worksheet.unMergeCells("A"+(totalStartRow+11)+":P"+(totalStartRow+11));
      worksheet.mergeCells("A"+(totalStartRow+11)+":P"+(totalStartRow+11));
      worksheet.getCell("A"+(totalStartRow+11)).alignment = { vertical: "middle", horizontal: "center" };
      worksheet.getCell("A"+(totalStartRow+11)).value = "If you have any questions , please contact";
      worksheet.unMergeCells("A"+(totalStartRow+12)+":P"+(totalStartRow+12));
      worksheet.mergeCells("A"+(totalStartRow+12)+":P"+(totalStartRow+12));
      worksheet.getCell("A"+(totalStartRow+12)).alignment = { vertical: "middle", horizontal: "center" };
      worksheet.getCell("A"+(totalStartRow+12)).value = "management office";
    }
  
      const fileName = `${exportData.merchant_name}_ProductSales.xlsx`;
      const filePath = `${fileName}`;
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], { type: fileType }); 
        FileSaver.saveAs(blob, filePath);
      });
      return { filePath, fileName };

  }

  const applyFilter = () => {
    setIsApplying(true); // Disable the "APPLY" button
    setIsApplying2(true);
    setIsApplying3(true);
    // console.log(startdate, enddate);
    setLoad(true);
    ((StartDateData.length > 0)? setStartDateFilter(StartDateData): setStartDateFilter(startdate));
    ((EndDateData.length > 0)? setEndDateFilter(EndDateData): setEndDateFilter(startdate));
    // setStartDateFilter(startdate);
    // setEndDateFilter(enddate);
    // setPlatforms(platforms);
    // setPayment(payments);
    // setLoyaltyId(loyaltyId);
    ((PlatformData.length > 0)? setPlatforms(PlatformData): setPlatforms(platforms));
    ((PaymentData.length > 0)? setPayment(PaymentData): setPayment(payments));
    ((LoyaltyData.length > 0)? setLoyaltyId(LoyaltyData): setLoyaltyId(loyaltyId));


    setPlatformsName(platformsName);
    setPaymentName(paymentsName);
    setLoyaltyIdName(loyaltyIdName);
  }

  const resetFilter = () => {
    setIsReseting(true);
    setIsReseting2(true);
    setIsReseting3(true);
    setLoad(true);
    setStartDate('')
    setEndDate('');
    sessionStorage.setItem('startdate', '');
    sessionStorage.setItem('enddate', '');
    sessionStorage.setItem('platform', '');
    sessionStorage.setItem('payment', '');
    sessionStorage.setItem('loyalty', '');
    setStartDateFilter('')
    setEndDateFilter('');
    setPlatforms('all');
    setPayment('all');
    setLoyaltyId('all');
    setPlatformsName('all');
    setPaymentName('all');
    setLoyaltyIdName('all');
  }


  useEffect(() => {
    if (load) {
      fetchMerchantSales().catch((err) => {
        console.log("err: " + err);
      });
      fetchMerchantSalesTxn().catch((err) => {
        console.log("err: " + err);
      });
      fetchRefundSalesTxn().catch((err) => {
        console.log("err: " + err);
      });
      fetchSalesFilters().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load,startdateFilter,enddateFilter,errormsg,errormsgTxn,errormsgRefundTxn,platforms,payments,loyaltyId]);

  ///////refunds/////////
  const columnsRefunds = React.useMemo(
    () => [
      {
        Header: "Retrieval Reference Number",
        accessor: "payment_id",
       
      },
      {
        Header: "Total Amount ($)",
        accessor: "order_total_amt",
      
      },
      {
        Header: "Total Nett Amount ($)",
        accessor: "order_total_nett_amt",
      
      },
      {
        Header: "Refund Type",
        accessor: "order_status_name",
       
      },
      {
        Header: "Refunded By",
        accessor: "refunded_by",
        Cell: ({ row }) => {
          if (row.values.refunded_by == null) {
            return <p className="text-sm text-gray-900">{"-"}</p>;
          } else {
            return <p className="text-sm text-gray-900">{row.values.refunded_by}</p>;
          }
        }
      },
      {
        Header: "Refunded On",
        accessor: "refunded_on",
        Cell: ({ row }) => {
          if (row.values.refunded_on == null) {
            return <p className="text-sm text-gray-900">{"-"}</p>;
          } else {
            return <p className="text-sm text-gray-900">{row.values.refunded_on}</p>;
          }
        }
      },
      {
        Header: "Remarks",
        accessor: "order_remarks",
        Cell: ({ row }) => {
          if (row.values.order_remarks == null) {
            return <p className="text-sm text-gray-900">{"-"}</p>;
          } else {
            return <p className="text-sm text-gray-900">{row.values.order_remarks}</p>;
          }
        }
      },
    ])
  ///////////////////////
  const expandedItemsCols = [
    {
      Header: "Product Name",
      accessor: "product_name",
      id: "addon_product_name",
    
    },
    {
      Header: "Qty",
      accessor: "qty",
    },
    {
      Header: "Voucher Amt",
      accessor: "voucher_amt",
    },
    {
      Header: "Government Voucher Amt",
      accessor: "govt_voucher_amt",
    },
    {
      Header: "Redemption Amt",
      accessor: "redemption_amt",
    },
    {
      Header: "Discount Amt",
      accessor: "discount_amt",
    },
    {
      Header: "Nett Amt",
      accessor: "nett_amt",
    },
    {
      Header: "Amt",
      accessor: "amt",
    },
  ];
  const columnsGrandTotal = React.useMemo(
    () => [
      {
        Header: "PAYEATFWD TOTAL DONATED AMT",
        accessor: "payeatfwd_total_donated_amt",
      },
      {
        Header: "PAYEATFWD TOTAL CLAIMED AMT",
        accessor: "payeatfwd_total_claimed_amt",
      },
      {
        Header: "PAYEATFWD BALANCE AMT",
        accessor: "payeatfwd_balance",
        Cell: ({ row }) => {
          var sales = row.values.payeatfwd_balance;
          if (sales == undefined || sales == null || sales == "-") {
            return <p className="text-sm text-gray-900">-</p>;
          } else {
            return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
          }
        }
      },
     
      {
        Header: "LOYALTY PRG RETENTION AMT",
        accessor: "loyalty_programme_retention_amt",
      },
      {
        Header: "GRAND TOTAL REDEMPTION AMT",
        accessor: "grand_total_redemption_amt",
      },
      {
        Header: "TOTAL VOUCHER AMT",
        accessor: "total_voucher_amt",
      },
      {
        Header: "TOTAL GOVERNMENT VOUCHER AMT",
        accessor: "total_govt_voucher_amt",
      },
      {
        Header: "GRAND TOTAL VOUCHER AMT",
        accessor: "grand_total_voucher_amt",
      },
      {
        Header: "GRAND TOTAL DISCOUNT AMT",
        accessor: "grand_total_discount_amt",
      },
      {
        Header: "GRAND TOTAL SERVICE CHARGE FEE",
        accessor: "grand_total_service_charge_fee",
      },
      {
        Header: "GRAND TOTAL GST TXN FEE",
        accessor: "grand_total_gst_txn_fee",
      },
      {
        Header: "GRAND TOTAL NETS TXN FEE",
        accessor: "grand_total_nets_txn_fee",
      },
      {
        Header: "GRAND TOTAL TAKEAWAY AMT",
        accessor: "grand_total_takeaway_amt",
      },
      {
        Header: "GRAND TOTAL ROUNDING DIFFERENCE",
        accessor: "grand_total_rounding_difference",
      },
      {
        Header: "GRAND TOTAL QTY",
        accessor: "grand_total_qty",
      },
      {
        Header: "GRAND TOTAL AMT",
        accessor: "grand_total_amt",
      },
      {
        Header: "GRAND TOTAL NETT AMT",
        accessor: "grand_total_nett_amt",
      },
      {
        Header: "MERCHANT RECEIVABLE AMT",
        accessor: "merchant_receivable_amt",
        Cell: ({ row }) => {
          var sales = row.values.merchant_receivable_amt;
          if (sales == undefined || sales == null || sales == "-") {
            return <p className="text-sm text-gray-900">-</p>;
          } else {
            return <p className="text-sm text-gray-900">{ sales.toFixed(2)}</p>;
          }
        }
      },
      {
        Header: "NETS CLICK MID",
        accessor: "nets_click_mid",
      },
      {
        Header: "NETS CLICK TID",
        accessor: "nets_click_tid",
      },
      {
        Header: "NETS QR MID",
        accessor: "nets_qr_mid",
      },
      {
        Header: "NETS QR TID",
        accessor: "nets_qr_tid",
      },
      
    ]
  )

  const columnsProducts = React.useMemo(
    () => [
      {
        Header: "Category Name",
        accessor: "product_cat_name",
      },
      {
        Header: "Total Qty",
        accessor: "total_qty",
      },
      // {
      //   Header: "Total Voucher Amt",
      //   accessor: "total_voucher_amt",
      // },
      // {
      //   Header: "Total Government Voucher Amt",
      //   accessor: "total_govt_voucher_amt",
      // },
      {
        Header: "Total Redemption Amt",
        accessor: "total_redemption_amt",
      },
      // {
      //   Header: "Total Retention Amt",
      //   accessor: "loyalty_programme_retention_amt",
      // },
      {
        Header: "Total Discount Amt",
        accessor: "total_discount_amt",
      },
      {
        Header: "Total Nett Amt",
        accessor: "total_nett_amt",
      },
      {
        Header: "Total Amt",
        accessor: "total_amt",
      },
      {
        Header: "Details",
        id: "expander_prod",
        Cell: ({ toggleAllRowsExpanded, row }) => {
          const isRowSelected = selectedRowProdArr === row.original.items;
          var arrDetails = [];
          arrDetails.push(row.original.items);
          // console.log(arrDetails);
          return (
            <button
              className="underline"
              onClick={() => {
                toggleAllRowsExpanded(false);
                // console.log(row);
                row.toggleRowExpanded();
                setSelectedRowProdArr(row.original.items);
                // setSelectedRowProd(row);
                toggleRowSelection(row.original.items);
                console.log(row.original.items);
              }}
            >
              {isRowSelected ? "Collapse" : "View More"}
            </button>
          );
        },
      },
    ]
  );

  const toggleRowSelection = (row) => {
    if (selectedRowProdArr === row) {
      setSelectedRowProdArr(null);
    } else {
      setSelectedRowProdArr(row);
    }
  };
  
    const expandedAddonCols = [
      {
        Header: "Add On Name",
        accessor: "product_name",
        id: "add_on_product_name"
      },
      {
        Header: "Add On Price",
        accessor: "add_on_amt",
      },
      {
        Header: "Add On Quantity",
        accessor: "add_on_qty",
      },
    ];
  const expandedCols = [
    {
      Header: "Product Name",
      accessor: "product_name",
    },
    {
      Header: "Total Price",
      accessor: "order_amt",
    },
    {
      Header: "Quantity",
      accessor: "order_qty",
    },
    {
      Header: "Details",
      id: "expander",
      Cell: ({ toggleAllRowsExpanded, row }) => {
        // var arrDetails = [];
        const isSubRowSelected = selectedRowAddOnArr === row.original.txn_order_consumer_add_ons;
        // arrDetails.push(row.original.txn_order_consumer_add_ons);
        // console.log(row);
        return (
          <button
            className="underline"
            onClick={() => {
              toggleAllRowsExpanded(false);
              row.toggleRowExpanded();
              setSelectedRowAddOnArr(row.original.txn_order_consumer_add_ons);
              toggleRowAddOnArr(row.original.txn_order_consumer_add_ons);
              console.log(row.original.txn_order_consumer_add_ons);
            }}
          >
            {isSubRowSelected ? "Collapse" : "View More"}
          </button>
        );
      },
    },
  ];

  const toggleRowAddOnArr = (row) => {
    if (selectedRowAddOnArr === row) {
      setSelectedRowAddOnArr(null);
    } else {
      setSelectedRowAddOnArr(row);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Order Number",
        accessor: "order_no",
      },
      {
        Header: "Retrieval Reference Number",
        accessor: "payment_id",
      },
      {
        Header: "Order Datetime",
        accessor: "order_datetime",
        Cell: ({ row }) => {
          if (row.values.order_datetime) {
            row.values.order_datetime = format(
              new Date(row.values.order_datetime),
              "dd MMM yyyy HH:mm"
            );
            var date = row.values.order_datetime;

            if (date) {
              return <p className="text-sm text-gray-900">{date}</p>;
            }
          }
          return <p className="text-sm text-gray-900">-</p>;
        },
      },
      {
        Header: "Discount Amount ($)",
        accessor: "discount_amt",
        id:"disc_amt"
      },
      {
        Header: "Voucher Amount ($)",
        accessor: "voucher_amt",
        id:"voucher_amt"
      },
      {
        Header: "Voucher Type",
        accessor: "voucher_type_desc",
        id:"voucher_type_desc"
      },
      {
        Header: "Loyal Redemption Amount ($)",
        accessor: "loyalty_redemption_amt",
        id:"loyal_redemption_amt"
      },
      {
        Header: "Total Order Amount ($)",
        accessor: "order_total_amt",
        id:"total_order_amt"
      },
      {
        Header: "Order Status",
        // accessor: "order_status",
        Cell: ({ row }) => {
          var orderstatus = row.original.order_status
          if (orderstatus == "Preparing") {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm" style={{borderRadius:'50px', width:'100px'}}>
                {orderstatus}
              </div>
            );
          } else if(orderstatus == "Completed") {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm" style={{borderRadius:'50px', width:'100px'}}>
                N.A.
              </div>
            );
          } else if(orderstatus == "Order Refund") {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" style={{borderRadius:'50px', width:'100px'}}>
                Refunded
              </div>
            );
          } else if(orderstatus == "Order Reversal") {
            return (
              <div className="w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" style={{borderRadius:'50px', width:'100px'}}>
                Reversed
              </div>
            );
          }
          
          
        },
      },
      {
        Header: "Refunded By",
        accessor: "refunded_by",
      },
      {
        Header: "Refunded On",
        accessor: "refunded_on",
      },
      {
        Header: "Refund",
        // id: "expander",
        Cell: ({ row }) => {
          var orderstatus = row.original.order_status;
          if (orderstatus !== "Order Refund" || orderstatus !== "Order Reversal") {
            return (
              <button
              type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                setRefundModalOpen(true);
                setSelectedRow(row.original);
              }}
            >
                Refund
            </button>
            );
          }
          
        },
      },
      {
        Header: "Details",
        id: "expander",
        Cell: ({ toggleAllRowsExpanded, row }) => {
          var arrDetails = [];
          arrDetails.push(row.original.txn_order_consumer_details);
          // console.log(arrDetails);
          return (
            <button
              className="underline"
              onClick={() => {
                toggleAllRowsExpanded(false);
                row.toggleRowExpanded();
                setSelectedRowArr(arrDetails);
                // console.log(selectedRowArr);
                setSelectedRow(row);
              }}
            >
               View More
            </button>
          );
        },
      },
    ],
    []
  );
  
  // if (txndata && data) {
// if (!load) {
  return (
      <>
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <MerchantsMenuTabs />
        </div>
        
        {currentTab.name == "Products" && (
          <>
           
            <div style={{backgroundColor:'#FFF', padding:'20px', borderRadius:'10px', margin:'20px 0 20px 0 '}}>
            <row style={{display:'flex'}}>
              <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> Start Date </h6>
                </div>
                <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={StartDateData} onChange={(e) => {
                  sessionStorage.setItem('startdate', e.target.value);
                  setStartDate(e.target.value);
                }}/>
              </div>
              <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> End Date </h6>
                </div>
                <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={EndDateData} onChange={(e) => {
                  sessionStorage.setItem('enddate', e.target.value);
                  setEndDate(e.target.value);
              }}/>
              </div>
              <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> Platform Type </h6>
                </div>
                <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={PlatformData} onChange={(e) => {
                  sessionStorage.setItem('platform', e.target.value);
                  setPlatforms(e.target.value), setPlatformsName(e.target.selectedOptions[0].text);
                }}>
                  <option value='all'>All</option>
                  {platformList && platformList.length > 0 && platformList.map(
                    (comm, index) => {
                      return <option key={index} value={comm.sys_code_id}>{comm.sys_code_desc}</option>
                    })}
                </select>
              </div>
              <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> Payment Type </h6>
                </div>
                <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={PaymentData} onChange={(e) => {
                  sessionStorage.setItem('payment', e.target.value);
                  setPayment(e.target.value), setPaymentName(e.target.selectedOptions[0].text);
                }}>
                  <option value='all'>All</option>
                  {paymentList && paymentList.length > 0 && paymentList.map(
                    (comm, index) => {
                      return <option key={index} value={comm.sys_code_id}>{comm.sys_code_desc}</option>
                    })}
                </select>
                </div>
                <div style={{width:'100%',padding:'10px'}}>
                  <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <h6 style={{ marginBottom: '0px' }}> Loyalty Programme </h6>
                  </div>
                  <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={LoyaltyData} onChange={(e) => {
                    sessionStorage.setItem('loyalty', e.target.value);
                    setLoyaltyId(e.target.value), setLoyaltyIdName(e.target.selectedOptions[0].text);
                  }}>
                    <option value='all'>All</option>
                    {loyaltyList && loyaltyList.length > 0 && loyaltyList.map(
                      (comm, index) => {
                        return <option key={index} value={comm.loyalty_id}>{comm.loyalty_name}</option>
                      })}
                  </select>
                </div>
            </row>
            <div style={{ textAlign: 'end', paddingTop: '34px' }}>
              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  (isApplying && isApplying2 && isApplying3) ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => applyFilter()}
                disabled={(isApplying && isApplying2 && isApplying3)} // Disable the button when any of the conditions is not met
              >
                {(isApplying || isApplying2 || isApplying3) ? "Applying..." : "APPLY"}
              </button>

              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  (isReseting && isReseting2 && isReseting3) ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => resetFilter()}
                disabled={(isReseting && isReseting2 && isReseting3)} // Disable the button when any of the conditions is not met
              >
                {(isReseting || isReseting2 || isReseting3) ? "Reseting..." : "Reset"}
              </button>

            </div>
            
            </div>
          <div className="md:flex md:items-center md:justify-between" style={{ position:'relative', width:'100%'}}>
              <button
                type="button"
                className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
              onClick={() => downloadFilesProducts()}
                style={{ position:'absolute', right:0, margin:'20px 0'}}
              >
                Export Data <DocumentDownload className="ml-3" />
              </button>
          </div>
            {errormsg == false && data ? <>
                <div style={{margin:'20px 0 20px 0'}}>
                  <Table
                    columns={columnsGrandTotal}
                    data={generalData}
                    filters={columnsGrandTotal.map((column) => column.accessor)}
                    hasSearch={false}
                    hasPagination={false}
                  />
                </div>
                
                <Table
                  columns={columnsProducts}
                  data={data}
                  filters={columnsProducts.map((column) => column.accessor)}
                  expandedTable={
                    selectedRowProdArr ? (
                      <Table
                        columns={expandedItemsCols}
                        data={selectedRowProdArr}
                        filters={expandedItemsCols.map((column) => column.accessor)}
                        hasSearch={false}
                        hasPagination={true}
                      />
                    ) : (
                      null
                    )
                  }
                />
              </> : <div className="w-screen h-screen flex flex-wrap flex-row place-items-baseline">
              <div className="flex place-content-center w-full">
                <Spinner className="w-20 h-20" />
              </div>
            </div>
              }
              {/* {isRefundModalOpen && (
              <RefundModal
                setRefundModal={setRefundModalOpen}
                load={setLoad}
                row={selectedRow}
                isRefundModal={false}
              />
            )}   */}
          </>
        )}
        {currentTab.name == "Transactions" && (
          <>
            <div style={{backgroundColor:'#FFF', padding:'20px', borderRadius:'10px', margin:'20px 0 20px 0 '}}>
            <row style={{display:'flex'}}>
              <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> Start Date </h6>
                </div>
                <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={StartDateData} onChange={(e) => {
                  sessionStorage.setItem('startdate', e.target.value);
                  setStartDate(e.target.value);
                }}/>
              </div>
              <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> End Date </h6>
                </div>
                <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={EndDateData} onChange={(e) => {
                  sessionStorage.setItem('enddate', e.target.value);
                  setEndDate(e.target.value);
                }}/>
              </div>
              <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> Platform Type </h6>
                </div>
                <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={PlatformData} onChange={(e) => {
                  sessionStorage.setItem('platform', e.target.value);
                  setPlatforms(e.target.value);
                }}>
                  <option value='all'>All</option>
                  {platformList && platformList.length > 0 && platformList.map(
                    (comm, index) => {
                      return <option key={index} value={comm.sys_code_id}>{comm.sys_code_desc}</option>
                    })}
                </select>
              </div>
              <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> Payment Type </h6>
                </div>
                <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={PaymentData} onChange={(e) => {
                  sessionStorage.setItem('payment', e.target.value);
                  setPayment(e.target.value);
                }}>
                  <option value='all'>All</option>
                  {paymentList && paymentList.length > 0 && paymentList.map(
                    (comm, index) => {
                      return <option key={index} value={comm.sys_code_id}>{comm.sys_code_desc}</option>
                    })}
                </select>
                </div>
                <div style={{width:'100%',padding:'10px'}}>
                  <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <h6 style={{ marginBottom: '0px' }}> Loyalty Programme </h6>
                  </div>
                  <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={LoyaltyData} onChange={(e) => {
                    sessionStorage.setItem('loyalty', e.target.value);
                    setLoyaltyId(e.target.value);
                  }}>
                    <option value='all'>All</option>
                    {loyaltyList && loyaltyList.length > 0 && loyaltyList.map(
                      (comm, index) => {
                        return <option key={index} value={comm.loyalty_id}>{comm.loyalty_name}</option>
                      })}
                  </select>
                </div>
            </row>
            <div style={{ textAlign: 'end', paddingTop: '34px' }}>
            <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  (isApplying && isApplying2 && isApplying3) ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => applyFilter()}
                disabled={(isApplying && isApplying2 && isApplying3)} // Disable the button when any of the conditions is not met
              >
                {(isApplying || isApplying2 || isApplying3) ? "Applying..." : "APPLY"}
              </button>

              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  (isReseting && isReseting2 && isReseting3) ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => resetFilter()}
                disabled={(isReseting && isReseting2 && isReseting3)} // Disable the button when any of the conditions is not met
              >
                {(isReseting || isReseting2 || isReseting3) ? "Reseting..." : "Reset"}
              </button>
            </div>
            
            </div>
            <div className="md:flex md:items-center md:justify-between" style={{float:'right'}}>
              <button
                type="button"
                className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
                onClick={() => downloadFilesTxn()}
              >
                Export Data <DocumentDownload className="ml-3" />
              </button>
          </div>
           {errormsgTxn == false && txndata ? 
            <Table
              columns={columns}
              // data={data.filter((row) => row.is_add_on == 0)}
              data={txndata}
              filters={columns.map((column) => column.accessor)}
              expandedTable={
                selectedRow ? (
                  <Table
                    columns={expandedCols}
                    data={selectedRowArr}
                    filters={expandedCols.map((column) => column.accessor)}
                    expandedTable={
                      selectedRowAddOnArr ? (
                        <Table
                          columns={expandedAddonCols}
                          data={selectedRowAddOnArr}
                          filters={expandedAddonCols.map((column) => column.accessor)}
                          hasSearch={false}
                          hasPagination={false}
                        />
                      ) : (
                        null
                      )
                    }
                    hasSearch={false}
                    hasPagination={false}
                  />
                ) : (
                  null
                )
              }
            /> : null
            }

              {isRefundModalOpen && (
              <RefundModal
                setRefundModal={setRefundModalOpen}
                load={setLoad}
                row={selectedRow}
                isRefundModal={false}
              />
            )}  
          </>
      )}
      {currentTab.name == "Refunds" && (
          <>
            <div style={{backgroundColor:'#FFF', padding:'20px', borderRadius:'10px', margin:'20px 0 20px 0 '}}>
            <row style={{display:'flex'}}>
              <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> Start Date </h6>
                </div>
                <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={StartDateData} onChange={(e) => {
                  sessionStorage.setItem('startdate', e.target.value);
                  setStartDate(e.target.value);
                }}/>
              </div>
              <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> End Date </h6>
                </div>
                <input type="date" style={{width:'100%',padding:'10px',borderRadius:'8px'}} value={EndDateData} onChange={(e) => {
                  sessionStorage.setItem('enddate', e.target.value);
                  setEndDate(e.target.value);
                }}/>
              </div>
              <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> Platform Type </h6>
                </div>
                <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={PlatformData} onChange={(e) => {
                  sessionStorage.setItem('platform', e.target.value);
                  setPlatforms(e.target.value);
                }}>
                  <option value='all'>All</option>
                  {platformList && platformList.length > 0 && platformList.map(
                    (comm, index) => {
                      return <option key={index} value={comm.sys_code_id}>{comm.sys_code_desc}</option>
                    })}
                </select>
              </div>
              <div style={{width:'100%',padding:'10px'}}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <h6 style={{ marginBottom: '0px' }}> Payment Type </h6>
                </div>
                <select style={{width:'100%',padding:'10px',borderRadius:'8px'}}name="selectedType" id="selectedComms" value={PaymentData} onChange={(e) => {
                  sessionStorage.setItem('payment', e.target.value);
                  setPayment(e.target.value);
                }}>
                  <option value='all'>All</option>
                  {paymentList && paymentList.length > 0 && paymentList.map(
                    (comm, index) => {
                      return <option key={index} value={comm.sys_code_id}>{comm.sys_code_desc}</option>
                    })}
                </select>
                </div>
            </row>
            <div style={{ textAlign: 'end', paddingTop: '34px' }}>
              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  (isApplying && isApplying2 && isApplying3) ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => applyFilter()}
                disabled={(isApplying && isApplying2 && isApplying3)} // Disable the button when any of the conditions is not met
              >
                {(isApplying || isApplying2 || isApplying3) ? "Applying..." : "APPLY"}
              </button>

              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  (isReseting && isReseting2 && isReseting3) ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => resetFilter()}
                disabled={(isReseting && isReseting2 && isReseting3)} // Disable the button when any of the conditions is not met
              >
                {(isReseting || isReseting2 || isReseting3) ? "Reseting..." : "Reset"}
              </button>
            </div>
            
            </div>
          <div className="md:flex md:items-center md:justify-between" style={{ float: 'right' }}>
          <button
                type="button"
                className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
                onClick={() => setCreateRefundModalOpen(true)}
              >
                Create Refund <Additem className="ml-3" />
              </button>
              <button
                type="button"
                className="ml-3 inline-flex items-center  text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
                onClick={() => downloadFilesTxn()}
              >
                Export Data <DocumentDownload className="ml-3" />
              </button>
          </div>
           {errormsgRefundTxn == false && refundtxndata ? <Table
              columns={columnsRefunds}
              // data={data.filter((row) => row.is_add_on == 0)}
              data={refundtxndata}
              filters={columnsRefunds.map((column) => column.accessor)}
            /> : <div className="w-screen h-screen flex flex-wrap flex-row place-items-baseline">
              <div className="flex place-content-center w-full">
                <>No Data</>
              </div>
            </div>
            }

              {isCreateRefundModalOpen && (
              <CreateRefundModal
              setCreateModal={setCreateRefundModalOpen}
              load={setLoad}
              merchantid={ merchantId}
              />
            )}  
          </>
        )}
      </>
  );
  
//   } else {
//     return (
//       <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
//         <div className="flex place-content-center w-full">
//           <Spinner className="w-20 h-20" /> 
//         </div>
//       </div>
//     );
//   }
}
