import { createContext, useState } from "react";

const tabs = [
  { name: "All" },
  { name: "Food Court" },
  { name: "Food Shop" },
  { name: "Staff Canteen" },
  { name: "Hawker" },
];

export const CompanyTabsContext = createContext();
export const CompanyCurrentTabContext = createContext();

export const CompanyContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <CompanyTabsContext.Provider value={{ tabs }}>
      <CompanyCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </CompanyCurrentTabContext.Provider>
    </CompanyTabsContext.Provider>
  );
};
