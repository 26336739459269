import React, { useMemo, useState} from "react";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import Table from "../../../../commons/components/table";
import { Add, InfoCircle, Trash, Edit } from "iconsax-react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm, Controller } from "react-hook-form";

const pages = [
  { name: "Marketing", href: "#", current: false },
  {
    name: "Survey Questions",
    href: "/marketing/surveys",
    current: true,
  },
];

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

const SurveyLayout = () => {
  const [inputs, setInputs] = useState([
    {
      survey_qns: "",
    },
  ]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});

  const addInput = () => {
    setInputs([
      ...inputs,
      {
        survey_qns: "",
      },
    ]);
  };

  const removeInput = (index) => {
    const rows = [...inputs];
    rows.splice(index, 1);
    setInputs(rows);
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className="w-full p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:p-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-3 sm:mt-5">
            <div className="row">
              <div className="col-sm-8">
                {inputs.map((data, index) => {
                  return (
                    <div className="row my-3">
                      <div className="mt-2 sm:col-span-6" key={index}>
                        <div className="flex justify-between">
                          <label
                            htmlFor="survey_qns"
                            className="block text-sm font-medium text-yellow-800"
                          >
                            Survey Question {index + 1}
                          </label>
                        </div>

                        <div className="mt-1 relative rounded-md flex">
                          <input
                            type="text"
                            name="survey_qns"
                            id="survey_qns"
                            className={
                              !errors.inputs?.[index]
                                ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                                : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            }
                            placeholder={"Enter Question"}
                            {...register(`inputs.${index}.survey_qns`, {
                              required: true,
                            })}
                          />
                          {/* {errors.survey_qns?.type === "required" && errorIcon} */}
                          {inputs.length !== 1 ? (
                            <button
                              className="flex justify-end items-center p-3"
                              onClick={removeInput}
                            >
                              <Trash />
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button
                type="submit"
                className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
              >
                Submit
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm items-center"
                onClick={addInput}
              >
                Add New Question <Add />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SurveyLayout;
