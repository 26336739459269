import axios from "axios";
import { format } from "date-fns";
import { Link, useParams, useLocation } from "react-router-dom";
import { Edit, Trash, Cards, DocumentDownload } from "iconsax-react";
import commonConfigs from "../../../../config/config";
import Table from "../../../../commons/components/table";
import React, { useState, useMemo, useEffect, useContext } from "react";
import Spinner from "../../../../commons/components/spinner";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import { current } from "@reduxjs/toolkit";
import { PaymentOptionCurrentTabContext } from "../context/paymentOptionContext";
import PaymentOptionTabs from "../components/paymentOptionTabs"
import NetsQRPaymentLayout from "./netsQRLayout"
import NetsClickPaymentLayout from "./netsClickLayout"
import ENETSPaymentLayout from "./eNetsLayout"


const PaymentOptionLayout = () => {
  const locationName = useParams().locationName;
  const merchantId = useParams().merchantId;
  const merchantName = useParams().merchantName;


//   const getMerchantPaymentOptionUrl = commonConfigs.apiUrls.GetPaymentOptions(merchantId);

  const pages = [
    { name: "Configurations", href: "#", current: false },
    { name: "Locations", href: "/configurations/paymentoptionlocations", current: false },
    { name: locationName, href: `/configurations/paymentoption/${locationName}`, current: false },
    { name: merchantName, href: `/configurations/paymentoption/${locationName}/${merchantName}/${merchantId}`, current: true}
  ];

  const { currentTab } = useContext(PaymentOptionCurrentTabContext);

  const renderTabContent = () => {
    switch (currentTab.name) {
      case "NETS QR":
        return <NetsQRPaymentLayout merchantId={merchantId} />;
      case "NETS CLICK":
        return <NetsClickPaymentLayout merchantId={merchantId} />;
      case "eNETS":
        return <ENETSPaymentLayout merchantId={merchantId} />;
      default:
        return <div>Select a tab to view content</div>;
    }
  };


  return (
    <div>
        <div className="w-full desktop:p-6">
        <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 pb-5 min-w-0 mobile:pt-4 mobile: ml-2">
            <Breadcrumbs pages={pages} />
            </div>
        </div>
        <div className="mt-8 ">
            <div>
                <PaymentOptionTabs/>
                {/* {currentTab.name === "NETS QR" ? (
                <div>
                <NetsQRPaymentLayout merchantId={merchantId}/>
                </div>
                ) : (
                <div>
                    <></>
                </div>
                )} */}
                {
                  renderTabContent()
                }
            </div> 
            </div>
        </div>
    </div>
  );
};

export default PaymentOptionLayout;