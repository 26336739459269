import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import OrderCard from "./orderCard";

export default function DSCard() {

  const [divisionSales, setDivisionSales] = useState(null);

  const [load, setLoad] = useState(true);

  async function fetchDivisionSales() {

    const GetDivisionSales = commonConfigs.apiUrls.GetDivisionSales();

    await axios
      .get(GetDivisionSales, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setDivisionSales(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
 }

  useEffect(() => {
    fetchDivisionSales().catch((err) => {
      console.log("err: " + err);
    });
  }, [load]);


  return (
    <div style={{height:'inherit', minHeight: 0, minWidth: 0, overflow: 'visible'}}>
      {divisionSales && (
        <OrderCard
          title="Division Sales"              
          subtitle={`$${parseFloat(divisionSales.reduce((acc, item) => acc + parseFloat(item.total_sales), 0)).toFixed(2)}`}
          data={divisionSales.map((item) => ({
            name: item.division_name,
            value: item.total_sales,
          }))}
        />
      )}
    </div>   
  );
}
