import { useState } from "react";
import { getBase64 } from "../../utils/custom/helper";
import { BlobServiceClient } from "@azure/storage-blob";
import { AZURE_BLOB_SAS_URL, AZURE_URL } from "../../constants";

function UploadFileCard({ file, setFile }) {
  return (
    <div>
      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className="space-y-1 text-center ">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label className="relative cursor-pointer bg-white rounded-md font-medium text-yellow-800 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
              <span>Upload a file</span>
              <input
                name="file-upload"
                type="file"
                className="sr-only"
                onChange={(event) => {
                  setFile(event.target.files[0]);
                }}
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">PNG, JPG, GIF</p>
        </div>
      </div>
      {file && (
        <div className="sm:col-span-6 mt-2">
          <label
            htmlFor="uploaded-files"
            className="block text-sm font-medium text-yellow-800"
          >
            Uploaded Files
          </label>
          <div className="flex justify-between ">
            <span className="text-yellow-800 font-medium">{file.name}</span>
            <span
              className="text-yellow-800 font-medium hover:text-red-700 flex-end"
              onClick={(e) => setFile((e.target.value = null))}
            >
              remove
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

function useUploadFile({ container }) {
  console.log(container);
  const [file, setFile] = useState("");

  function uploadFile(blobPath) {
    return new Promise((resolve, reject) => {
      console.log("uploadFile");
      if (!blobPath) {
        // blobPath is required
        reject("blobPath is required");
        // resolve(null);
      }

      // Base path.
      const blobServiceClient = new BlobServiceClient(AZURE_BLOB_SAS_URL);
      // Container.
      const containerClient = blobServiceClient.getContainerClient(container);

      getBase64(file, function (base64Data) {
        const blobOptions = {
          blobHTTPHeaders: { blobContentType: "image/jpg" },
        };
        const blockBlobClient = containerClient.getBlockBlobClient(blobPath);
        blockBlobClient
          .upload(file, base64Data.length, blobOptions)
          .then((res) => {
            console.log(`Upload block blob ${blobPath} successfully`);
            console.log(res);
            resolve(`${AZURE_URL}/${container}/${blobPath}`);
          })
          .catch((err) => {
            reject(err);
            // resolve(null);
            // console.log(err);
          });
      });
    });
  }

  const fileCard = <UploadFileCard file={file} setFile={setFile} />;

  return [file, fileCard, uploadFile];
}

export { useUploadFile };
