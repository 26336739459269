import { createContext, useState } from "react";

const tabs = [{ name: "Push Notification" }, { name: "Pull Notification" }];

export const NotificationTabsContext = createContext();
export const NotificationCurrentTabContext = createContext();

export const NotificationContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <NotificationTabsContext.Provider value={{ tabs }}>
      <NotificationCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </NotificationCurrentTabContext.Provider>
    </NotificationTabsContext.Provider>
  );
};
