import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import commonConfigs from "../../../../config/config";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { InfoCircle } from "iconsax-react";

const user = JSON.parse(localStorage.getItem("user"));

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function UpdateLeaveRequest({ 
  setUpdateModal, 
  row, 
  load 
}) {
  const timecard_id = row.timecard_id;
  const account_id = row.account_id;
  const start_time = new Date().toISOString().slice(0, 10);
  console.log("date for the timecard", start_time);
  console.log("Timecard Id", row.timecard_id);

  const UpdateEmployeeTimecards = commonConfigs.apiUrls.UpdateEmployeeTimecards(timecard_id);
  const GetUserLeaveBalance = commonConfigs.apiUrls.GetUserLeaveBalance(account_id);
  const [open, setOpen] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const [leaveBalance, setLeaveBalance] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      attendance_status: 
      {
        value: row.attendance_status,
        label:  row.attendance_status_name === '-' || row.attendance_status_name === null ? 'Pending' : row.attendance_status_name,
      },
      supervisor_remarks: row.supervisor_remarks,
    },
  });
  
  const AttendanceStatusOptions = [
    { value: "0", label: "Pending" },
    { value: "1", label: "Confirmed" },
    { value: "2", label: "Declined" },
  ];
    
  function formatData(data) {
    var attendance_status = data.attendance_status.value;
    var supervisor_remarks = data.supervisor_remarks;
    var attendance_reason = data.attendance_reason;

    var newData = data;

    newData.attendance_status = attendance_status;
    newData.supervisor_remarks = supervisor_remarks;
    newData.attendance_reason = attendance_reason;
    
    newData.updated_by = user.account_id;

    return newData;
  }

  const onSubmit = (data) => {
    setIsDisabled(true); // Disable the submit button

    formatData(data);
    console.log(data);

    axios
      .put(UpdateEmployeeTimecards, data, {
        headers: commonConfigs.apiHeader,
      })
    .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setUpdateModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  async function GetLeaveBalance() {
    await axios
      .get(GetUserLeaveBalance, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log("Leave Balance",res.data.result.data.leave_entitlement_balance);
        res.data.result.data.leave_entitlement_balance.forEach((Balance) => {
          if (Balance.leave_name == row.leave_request) {
            setLeaveBalance(Balance.leave_balance);
          }
        });

      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    GetLeaveBalance().catch((err) => {
      console.log("err for LeaveBalance:" + err);
    });

  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setUpdateModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                    >
                      Update Employee Details
                    </Dialog.Title>
                    
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="leave_request"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Leave Requested
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="leave_request"
                          id="leave_request"
                          style={{ backgroundColor: '#ececec' }}
                          className="block w-full pr-10 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                          readOnly
                          value={row.leave_request}
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="leave_request"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Leave Balance
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="leave_request"
                          id="leave_request"
                          style={{ backgroundColor: '#ececec' }}
                          className="block w-full pr-10 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                          readOnly
                          value={leaveBalance}
                        />
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="attendance_status"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Confirmation Status
                      </label>
                      <Controller
                        name="attendance_status"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={AttendanceStatusOptions}
                            isSearchable={true}
                            placeholder="Select the status"
                            className="text-sm"
                          />
                        )}
                      />
                    </div>
                            
                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="supervisor_remarks"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Remarks
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="supervisor_remarks"
                          id="supervisor_remarks"
                          className={
                            !errors.supervisor_remarks
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          // defaultValue={row.supervisor_remarks}
                          placeholder={"Enter Remarks"}
                          {...register("supervisor_remarks", { required: false })}
                        />
                        {errors.supervisor_remarks?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-8 sm:mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setUpdateModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
