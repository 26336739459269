import { createContext, useState } from "react";

const tabs = [{ name: "Loyalty Programme" }, { name: "eVouchers" }];

export const MarketingTabsContext = createContext();
export const MarketingCurrentTabContext = createContext();

export const MarketingContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <MarketingTabsContext.Provider value={{ tabs }}>
      <MarketingCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </MarketingCurrentTabContext.Provider>
    </MarketingTabsContext.Provider>
  );
};
