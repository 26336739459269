import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { InfoCircle } from "iconsax-react";
import commonConfigs from "../../../../../config/config";
import axios from "axios";

const CreateLoyaltyProgramme = commonConfigs.apiUrls.CreateLoyaltyProgramme();
const getLocationsUrl = commonConfigs.apiUrls.GetLocations()

const user = JSON.parse(localStorage.getItem("user"));

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function LoyaltyProgrammeModal({ setCreateModal, load }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(true);
  const [optionLoad, setOptionLoad] = useState(true);
  const [accumulateLocation, setAccumulateLocation] = useState([]);
  const [claimableLocation, setClaimableLocation] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({});

  useEffect(() => {
    async function getLocationsData() {
      await axios
        .get(getLocationsUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          setAccumulateLocation(res.data.result.data.filter(item => item.is_deleted === 0));
          setClaimableLocation(res.data.result.data.filter(item => item.is_deleted === 0));
          setOptionLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (optionLoad) {
      getLocationsData().catch((err) => {
        console.log("err for locations : " + err);
      });
    }
  }, []);

  function formatData(data) {

    var points_validity = data.points_validity;
    var points_per_dollar = data.points_per_dollar;
    var percent_of_min_spend = data.percent_of_min_spend;

    if (data.claimable_locations) {
      var claimable_locations = [];
      if (data.claimable_locations.length > 0) {
        data.claimable_locations.forEach((item) => {
          console.log(item);
          claimable_locations.push(item.value);
        });
      }
    } else {
      claimable_locations = null;
    }

    if (data.accumulate_locations) {
      var accumulate_locations = [];
      if (data.accumulate_locations.length > 0) {
        data.accumulate_locations.forEach((item) => {
          console.log(item);
          accumulate_locations.push(item.value);
        });
      }
    } else {
      accumulate_locations = null;
    }

    var newData = data;

    newData.points_validity = points_validity;
    newData.points_per_dollar = points_per_dollar;
    newData.percent_of_min_spend = percent_of_min_spend;
    newData.claimable_locations = claimable_locations;
    newData.accumulate_locations = accumulate_locations;

    newData.updated_by = user.account_id;

    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button

    const formatteddata = formatData(data);
    console.log("formatData(data)", formatteddata);

    axios
      .post(CreateLoyaltyProgramme, formatteddata, { headers: commonConfigs.apiHeader })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setCreateModal(false);
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  const accumulateLocationOptions = accumulateLocation.map((d) => ({
    value: d.location_id,
    label: d.location_name,
  }));

  const claimableLocationOptions = claimableLocation.map((d) => ({
    value: d.location_id,
    label: d.location_name,
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => setCreateModal(false)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg leading-6 font-medium text-gray-900"
                    >
                      Create Loyalty Programme
                    </Dialog.Title>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="loyalty_name"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Loyalty Programme Name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="loyalty_name"
                          id="loyalty_name"
                          className={
                            !errors.loyalty_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Loyalty Programme Name"}
                          {...register("loyalty_name", { required: true })}
                        />
                        {errors.loyalty_name?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="accumulate_locations"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Accumulate Location
                      </label>

                      <Controller
                        name="accumulate_locations"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={accumulateLocationOptions}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
                            isMulti={true}
                          />
                        )}
                      />
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="claimable_locations"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Claimable Location
                      </label>
                      <Controller
                        name="claimable_locations"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={claimableLocationOptions}
                            isSearchable={true}
                            placeholder="Select an option"
                            className="text-sm"
                            isMulti={true}
                          />
                        )}
                      />
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="points_validity"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Points Validity
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number" 
                          name="points_validity"
                          id="points_validity"
                          className={
                            !errors.points_validity
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Number of Points Validity"}
                          {...register("points_validity", {
                            required: true,
                            pattern: /^[0-9]*$/, 
                          })}
                          step="1" 
                        />
                        {errors.points_validity?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="points_per_dollar"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Points Per Dollar
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number" 
                          name="points_per_dollar"
                          id="points_per_dollar"
                          className={
                            !errors.points_per_dollar
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Number of Points Per Dollar"}
                          {...register("points_per_dollar", {
                            required: true,
                            pattern: /^[0-9]*$/, 
                          })}
                          step="1" 
                        />
                        {errors.points_per_dollar?.type === "required" && errorIcon}
                      </div>
                    </div>

                    <div className="mt-2 sm:col-span-6">
                      <label
                        htmlFor="percent_of_min_spend"
                        className="block text-sm font-medium text-yellow-800"
                      >
                        Percent Of Minimum Spend
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          min="0"
                          step="any"
                          name="percent_of_min_spend"
                          id="percent_of_min_spend"
                          className={
                            !errors.percent_of_min_spend
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Percent Of Minimum Spend"}
                          {...register("percent_of_min_spend", {
                            required: true,
                            valueAsNumber: true,
                            pattern: /^(?:\d*\.\d{1,2}|\d+)$/,
                          })}
                        />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setCreateModal(false) && load(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
