import { createContext, useState } from "react";

const tabs = [
  { name: "Divisions" },
  { name: "Departments" },
];

export const CompanyItemTabsContext = createContext();
export const CompanyItemCurrentTabContext = createContext();

export const CompanyItemContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <CompanyItemTabsContext.Provider value={{ tabs }}>
      <CompanyItemCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </CompanyItemCurrentTabContext.Provider>
    </CompanyItemTabsContext.Provider>
  );
};
