import { createContext, useState } from "react";

const tabs = [
  { name: "NETS QR" }, 
  { name: "NETS CLICK" },
  { name: "eNETS" },
];

export const PaymentOptionTabContext = createContext();
export const PaymentOptionCurrentTabContext = createContext();

export const PaymentOptionContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <PaymentOptionTabContext.Provider value={{ tabs }}>
      <PaymentOptionCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </PaymentOptionCurrentTabContext.Provider>
    </PaymentOptionTabContext.Provider>
  );
};
