import DashboardBarChart from "../components/dashboardStatusBarChart";
// import { FaRegCalendar } from "react-icons/fa";
// import { Link } from "react-router-dom";

export default function SalesCard({ title, data }) {
  return (
    // <DashboardLongCard>
    
    <>
      <div>
      <h3 className="text-xl" style={{display:'inline-block'}}>{title} </h3>

        {/* <button style={{backgroundColor:"#7B4620",padding:'4px', width:'35px',height:'32px',borderRadius:'5px', outline:'none', marginTop: '10px', textAlign:'-webkit-center'}}>
          <FaRegCalendar color="FFF" width={50} />
        </button> */}
        {/* <Link to={'merchantsales'} style={{marginRight: '10px', textDecoration:'underline', display:'inline-block', float:'right' }}>
          View Details
        </Link> */}
      </div>
      <div className="mt-12  ">
        <DashboardBarChart
          data={data.map((item) => ({
            name: item.location_name,
            values: item.revenue_stream.map((revenueStream) => ({
              name: revenueStream.order_type,
              value: revenueStream.revenue.total_sales,
            })),
          }))}
        />
      </div>
    </>
      
    // </DashboardLongCard>
  );
}
