import { Component, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import firebase from "../../../config/firebaseConfig";
import commonConfigs from "../../../config/config";
import axios from "axios";
import firebaseConfig from "../../../config/firebaseConfig";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
const headers = commonConfigs.apiHeader;
const apiKey = headers["api-key"];
const createUserFeedbackUrl = commonConfigs.apiUrls.CreateUserFeedback();

class OtpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      disabled: true,
      isloading: false,
      openModal: true,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.isOpen = this.isOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOTPChange = this.handleOTPChange.bind(this);
  }

  isOpen() {
    return this.state.openModal;
  }

  open() {
    console.log("Opening", this.isOpen());
    this.setState({ openModal: true });
    return this.isOpen();
  }

  close() {
    this.setState({ openModal: false });
    this.props.setOpenModal(false);
  }

  async handleSubmit(event) {
    this.setState({
      isloading: true,
    });
    event.preventDefault();
    if (this.state.otp !== "") {
      window.confirmationResult
        .confirm(this.state.otp)
        .then(function (result) {
          return result;
          // alert('Verification Success');
          //reset localstorage
          //localStorage.clear();
        })
        .then((result) => {
          if (result) {
            //this.submit();
            var body = {
              user_feedback_mobile: this.props.mobile,
              user_feedback_email: this.props.email,
              user_feedback_title: this.props.title,
              user_feedback_desc: this.props.desc,
            };
            console.log(this.props.user.access_token);
            console.log(
              "API HEADER:" + JSON.stringify(commonConfigs.apiHeader)
            );
            axios
              .post(createUserFeedbackUrl, body, {
                headers: {
                  "api-key": apiKey,
                  Authorization: "Bearer " + this.props.user.access_token,
                },
              })
              .then((res) => {
                if (res.data.status == "success") {
                  /* empty */
                  console.log(res);
                  window.location = `/`;
                } else {
                  alert("Account does not exist.");
                  this.close();
                }
              })
              .catch((err) => {
                const err_msg = JSON.stringify(
                  err.response.data.result.message
                );
                alert(`Error code : ${err.response.status} , ${err_msg}`);
                // alert('Account does not exist.');
              });
          } else {
            alert("Error");
          }
        })
        .catch((e) => {
          // this.setState({disabledProceed: true})
          // alert("Invalid OTP. Please try again. " + err)
          alert(e);
        });
    } else {
      alert("Please enter 6 digits OTP number.");
    }
  }
  sendCode() {
    window.appVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      getAuth()
    );
    const appVerifier = window.appVerifier;
    this.setState({
      //eventid: this.props.eventid,
      mobile: this.props.mobile,
      disabled: false,
    });
    var number = "+65 " + this.props.mobile;
    console.log(number);
    signInWithPhoneNumber(getAuth(), number, appVerifier)
      .then(function (confirmationResult) {
        console.log("Success");
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // this.setState({confirmResult: confirmationResult});
        console.log(confirmationResult);
        //console.log("OTP Sent");
        // this.setState({ disabled: false });
        // store.addNotification({
        //   title: "Success!",
        //   message: "Otp Sent",
        //   type: "default",
        //   insert: "top",
        //   container: "top-left",
        //   animationIn: ["animate__animated", "animate__fadeIn"],
        //   animationOut: ["animate__animated", "animate__fadeOut"],
        //   dismiss: {
        //     duration: 2000,
        //     onScreen: true,
        //   },
        // });
        alert("OTP sent");
      })
      .catch(function (error) {
        alert(error);
        console.log("Error:" + error);
      });
  }
  handleOTPChange(event) {
    this.setState({ otp: event.target.value, disabled: false });
    // this.setState({otpnum: event.target.value});
  }
  componentDidMount() {
    initializeApp(firebaseConfig);
  }
  render() {
    return (
      <>
        <Transition.Root show={this.isOpen()} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => this.close()}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay
                  className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                  onClick={() => this.close()}
                />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <form
                    className="bg-white rounded px-8 pt-6 pb-8 mb-4"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3 mt-5">
                        <label
                          htmlFor="mobile"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Mobile Number *
                        </label>
                        <input
                          type="text"
                          name="mobile"
                          id="mobile"
                          placeholder="Enter Mobile Number"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          value={this.props.mobile}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 self-end">
                        <label
                          htmlFor="button"
                          className="block text-sm font-medium text-gray-700"
                        ></label>
                        <button
                          type="button"
                          className="w-full justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => this.sendCode()}
                        >
                          Get SMS OTP
                        </button>
                      </div>
                    </div>

                    <div className="col-span-6 mt-5">
                      <label
                        htmlFor="otp"
                        className="block text-sm font-medium text-gray-700"
                      >
                        SMS OTP
                      </label>
                      <input
                        type="password"
                        name="otp"
                        id="otp"
                        placeholder="Enter SMS OTP"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={this.state.otp}
                        onChange={this.handleOTPChange}
                        disabled={this.state.disabled}
                      />
                    </div>

                    <div className="flex items-center justify-between mt-5">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={this.handleSubmit}
                      >
                        Proceed
                      </button>
                    </div>
                  </form>
                  <div id="recaptcha-container" />
                  {/* {this.state.isloading == true ? (
            <div
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader
                type="Circles"
                color="#567EC8"
                height={100}
                width={100}
                timeout={10000} //10 secs
              />
            </div>
          ) : (
            <div></div>
          )} */}
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default OtpModal;
