import { useState, useEffect } from "react";
import axios from "axios";
import commonConfigs from "../../../../config/config";

import DashboardStackedBarChart from "../../components/dashboardStackedBarChart";

export default function NSEVoucherCard() {
  const [isApplying, setIsApplying] = useState(false);
  const [isReseting, setIsReseting] = useState(false);

  const [NSeVouchersData, setNSEVoucherData] = useState(null);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1 to get the current month.

  // Calculate the first day of the current month.
  const firstDayOfMonth = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;

  // Calculate the last day of the current month.
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  const lastDayOfMonthFormatted = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${lastDayOfMonth.getDate()}`;

  const [NSeVouchersstartdate, setNSeVouchersStartDate] = useState(firstDayOfMonth);
  const [NSeVouchersenddate, setNSeVouchersEndDate] = useState(lastDayOfMonthFormatted);
  
  const [NSeVouchersstartdateFilter, setNSeVouchersStartDateFilter] = useState(NSeVouchersstartdate);
  const [NSeVouchersenddateFilter, setNSeVouchersEndDateFilter] = useState(NSeVouchersenddate);

  const [NSeLocationOutletList, setNSeLocationOutletList] = useState([]);

  const [load, setLoad] = useState(true);
  const [outlet, setOutlet] = useState(null);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))


  const applyButtonEnabledClass = "bg-red-600 hover:bg-red-700";
  const applyButtonDisabledClass = "bg-red-400 cursor-not-allowed";

  async function fetchNSEVoucherData() {
    var serialized = '0';
  
    // console.log(NSeVouchersstartdateFilter, NSeVouchersenddateFilter);
    let getEVoucherStatistics = commonConfigs.apiUrls.eVoucherStatistics(NSeVouchersstartdateFilter, NSeVouchersenddateFilter, serialized);
    if(user.access_multiple_location == 0 && user.location_id) {
      getEVoucherStatistics = getEVoucherStatistics + `&location_id=${user.location_id}`
    }

    await axios
      .get(getEVoucherStatistics, { headers: commonConfigs.apiHeader })
      .then((res) => {
        // console.log(res.data.result.data);
        setNSEVoucherData(res.data.result.data);
        setLoad(false);
        setIsApplying(false);
        setIsReseting(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function fetchLocations() {

    const GetLocations = commonConfigs.apiUrls.GetLocations();

    await axios
    .get(GetLocations, { headers: commonConfigs.apiHeader })
    .then((res) => {
      setNSeLocationOutletList(res.data.result.data.filter(item => item.is_deleted === 0));
      setLoad(false);
      setIsApplying(false);
      setIsReseting(false);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const applyNSeVouchersFilter = () => 
  {
    setIsApplying(true); // Disable the "APPLY" button
    setLoad(true);
    setNSeVouchersStartDateFilter(NSeVouchersstartdate);
    setNSeVouchersEndDateFilter(NSeVouchersenddate);
    setSelectedOutlet(outlet);

  }

  const resetNSeVouchersFilter = () => 
  {
    setIsReseting(true);
    setLoad(true);
    setNSeVouchersStartDate('')
    setNSeVouchersEndDate('');
    setNSeVouchersStartDateFilter('')
    setNSeVouchersEndDateFilter('');
    setSelectedOutlet(null);
  }

  useEffect(() => {
    fetchNSEVoucherData().catch((err) => {
      console.log("err: " + err);
    });
    fetchLocations().catch((err) => {
      console.log("err: " + err);
    })
  }, [load,NSeVouchersstartdateFilter,NSeVouchersenddateFilter]);

  useEffect(() => {
    if (user.access_multiple_location == 0 && user.location_id) {
      const singleLocation = NSeLocationOutletList.find(location => location.location_id == user.location_id);
      console.log(singleLocation)
      if (singleLocation) {
        setOutlet(singleLocation.location_name);
        setSelectedOutlet(outlet);
      }
    }
  }, [NSeLocationOutletList, user, setOutlet]);


  const EVoucherCardData = NSeVouchersData?.map((item) => ({
    voucher_code: item.voucher_code,
    locations: [
      item.voucher_issued_on !== null && item.voucher_issued_on >= NSeVouchersstartdateFilter && item.voucher_issued_on <= NSeVouchersenddateFilter ? 
      {
        label: 'Vouchers Issued',
        location_name: 'Vouchers Issued',
        voucher: item.voucher_qty_issued,
      } : [],
      ...(item.locations?.filter((location) => location.location_name === selectedOutlet).map((location) => ({
        label: 'Vouchers Redeemed',
        location_name: location.location_name,
        voucher: location.voucher_qty_redeemed,
      })) || []),
    ],
  })).filter((voucher) => voucher.locations.some((location) => location.label === "Vouchers Issued" || location.label === "Vouchers Redeemed")) || [];

  return (
    <div>
     
      <h3 className="text-xl">Non-Serialized eVouchers Issued | Redeemed</h3>

      <div style={{backgroundColor:'#FFF', padding:'20px 20px 0px 0px', borderRadius:'10px' }}>
        <div style={{'text-align': 'right', display:'inline-flex', paddingBottom: '20px'}}>
          <div style={{ paddingTop: '10px', paddingBottom: '10px', padding:'10px' }}>
            <h6> Start Date: </h6>
          </div>
          <input data-inline="true" type="date" style={{width:'30%',borderRadius:'8px'}} value={NSeVouchersstartdate} onChange={(e) => {
                  setNSeVouchersStartDate(e.target.value);}}
          />
          <div style={{ paddingTop: '10px', paddingBottom: '10px', padding:'10px'  }}>
            <h6> End Date: </h6>
          </div>
          <input data-inline="true" type="date" style={{width:'30%',borderRadius:'8px'}} value={NSeVouchersenddate} onChange={(e) => {
                  setNSeVouchersEndDate(e.target.value);}}
          />
           <div style={{ paddingTop: '10px', paddingBottom: '10px', padding:'10px'  }}>
            <h6> Redemption Outlet: </h6>
          </div>
          {
            user.location_id && user.access_multiple_location != 0 ? 
            <select
            style={{ paddingTop: '10px', paddingBottom: '10px', borderRadius: '10px', paddingLeft: "10px" }}
            name="selectedType"
            id="selectedOutlet"
            onChange={(e) => setOutlet(e.target.value)}
          >
            {NSeLocationOutletList.map((location) => (
                <option key={location.location_id} value={location.location_name}>
                  {location.location_name}
                </option>
              ))}
          </select> : 
          //   <select
          //   style={{ paddingTop: '10px', paddingBottom: '10px', borderRadius: '10px', paddingLeft: "10px"  }}
          //   name="selectedType"
          //   id="selectedOutlet"
          //   disabled
          // >
          <div style={{ paddingTop: '10px', paddingBottom: '10px', padding:'10px' }}>
          {NSeLocationOutletList.flatMap((location) => ( location.location_id == user.location_id ?
              <h6>{location.location_name}</h6> 
              : 
              <></>
            ))}
        </div>
          // </select>
          }
        </div>
            <div style={{'text-align': 'right', display:'block', padding:'10px' }}>
              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  isApplying ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => applyNSeVouchersFilter()}
                disabled={isApplying} // Disable the button when isApplying is true
              >
                {isApplying ? "Applying..." : "APPLY"}
              </button>
              <button
                data-inline="true"
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-2 sm:w-auto sm:text-sm ${
                  isReseting ? applyButtonDisabledClass : applyButtonEnabledClass
                }`}
                onClick={() => resetNSeVouchersFilter()}
                disabled={isReseting} // Disable the button when isReseting is true
              >
                {isReseting ? "Reseting..." : "Reset"}
              </button>
            </div>
        </div>

      <div className="mt-2">
        <DashboardStackedBarChart
          data={EVoucherCardData.map((item) => ({
            name: item.voucher_code,
            values: item.locations.map((location) => ({
              label: location.label,
              name: location.location_name,
              value: location.voucher,
            })),
          }))}
        />
      </div>

    </div>
  );
}

