import MarketingTabs from "../components/marketingTabs";
import { MarketingCurrentTabContext } from "../context/marketingContext";
import Breadcrumbs from "../../../../../commons/components/breadcrumbs";
import LoyaltyProgrammeLayout from "./loyaltyProgrammeLayout";
import EVoucherLayout from "./eVoucherLayout";
import { useContext } from "react";
import { EVoucherContextProvider } from "../context/eVoucherContext";

const pages = [
  { name: "Marketing", href: "/marketing/loyaltyprogramme", current: false },
  {
    name: "Loyalty Programme & eVouchers",
    href: "/marketing/loyaltyprogramme",
    current: true,
  },
];

const MarketingLayout = () => {
  const { currentTab } = useContext(MarketingCurrentTabContext);

  return (
    <div>
      <div className="w-full p-6">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <Breadcrumbs pages={pages} />
          </div>
        </div>
        <div className="mt-8 ">
          <MarketingTabs />
          {currentTab.name == "Loyalty Programme" ? (
            <LoyaltyProgrammeLayout />
          ) : (
            <EVoucherContextProvider>
              <EVoucherLayout />
            </EVoucherContextProvider>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketingLayout;
