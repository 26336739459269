import { GroupPurchaseContextProvider } from "../context/groupPurchaseContext";
import GroupPurchaseLayout from "./groupPurchaseLayout";

const GroupPurchasePage = () => {
  return (
    <GroupPurchaseContextProvider>
      <GroupPurchaseLayout />
    </GroupPurchaseContextProvider>
  );
};

export default GroupPurchasePage;
