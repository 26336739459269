import React, { useEffect, useState, useMemo } from "react";
import GroupPurchaseTabs from "../components/groupPurchaseTabs";
import { GroupPurchaseCurrentTabContext } from "../context/groupPurchaseContext";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import { Additem } from "iconsax-react";
import OrderingModal from "../components/createOrderingModal";
import UpdateOrderingModal from "../components/updateOrderingModal";
import DeleteModal from "../../../../commons/components/deleteModal";
import Table from "../../../../commons/components/table";
import { Edit, Trash } from "iconsax-react";
import axios from "axios";
import commonConfigs from "../../../../config/config";
import { format } from "date-fns";
import Spinner from "../../../../commons/components/spinner";

const getGPOUrl = commonConfigs.apiUrls.GroupPurchase();

const pages = [
  { name: "Configurations", href: "#", current: false },
  {
    name: "Group Purchase Ordering Menu",
    href: "/configurations/groupmenu",
    current: true,
  },
];

const GroupPurchaseLayout = () => {
  const [isOpen, setOpen] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();

  useEffect(() => {
    async function getData() {
      await axios
        .get(getGPOUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          setData(res.data.result.data.filter(item => item.is_deleted === 0));
          console.log(res.data.result.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Item Name",
      accessor: "product_name",
      Cell: ({ row, value }) => {
        return (
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img src={row.original.product_img_url} />
            </div>
            <div className="ml-4">
              <div className="text-sm text-black">{value}</div>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Item SKU",
      accessor: "product_sku",
    },
    {
      Header: "Item Description",
      accessor: "product_desc",
    },
    {
      Header: "Item Price",
      accessor: "product_price",
      Cell: ({ row }) => {
        let price = row.original.product_price;
        return <div className="text-sm text-gray-900">${price}</div>;
      },
    },
    {
      Header: "Supplier Type",
      accessor: "menu_type",
    },
    {
      Header: "Item Type",
      accessor: "product_type_sys_code",
      Cell: ({ row }) => {
        let productType = row.original.product_type_sys_code;

        if (productType === 0) {
          return <div className="text-sm text-gray-900">Generic</div>;
        } else if (productType === 1) {
          return <div className="text-sm text-gray-900">Goods</div>;
        } else if (productType === 2) {
          return <div className="text-sm text-gray-900">Services</div>;
        } else {
          return <div className="text-sm text-gray-900">-</div>;
        }
      }
    },
    {
      Header: "Supplier Company Name",
      accessor: "supplier_name",
    },
    {
      Header: "Visibility",
      accessor: "is_visible",
      Cell: ({ row }) => {
        let visibility = row.original.is_visible;

        row.values.is_visible = visibility == 1 ? "True" : "False";

        if (visibility === 0) {
          return <div className="text-sm text-gray-900">False</div>;
        } else {
          return <div className="text-sm text-gray-900">True</div>;
        }
      },
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            {localStorage.getItem("roleid") == 2 || localStorage.getItem("roleid") == 5 ?
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
            : <></> }
          </div>
        );
      },
    },
  ]);

  return (
    <div className="w-full desktop:p-6 mobile:p-2 ">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="mt-4 flex mobile:pt-4  mobile: pb-4 mobile:space-x-2">
          <button
            type="button"
            className="ml-3 inline-flex items-center text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
            onClick={() => setOpen(true)}
          >
            Create Ordering Menu <Additem className="ml-3" />
          </button>
        </div>
      </div>
      <GroupPurchaseTabs />
      {isOpen && <OrderingModal setOpenModal={setOpen} load={setLoad} />}
      {data != null ? (
        <GroupPurchaseCurrentTabContext.Consumer>
          {(value) => (
            <Table
              columns={columns}
              data={data}
              filters={columns.map((column) => column.accessor)}
              initialValue={value.currentTab.name}
              initialFilter="menu_type"
            />
          )}
        </GroupPurchaseCurrentTabContext.Consumer>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      {isUpdateModal && (
        <UpdateOrderingModal
          setOpenModal={setUpdateModal}
          load={setLoad}
          row={rowToEdit}
        />
      )}
      {isDeleteModal && (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          load={setLoad}
          url={commonConfigs.apiUrls.UpdateGroupPurchase(rowToEdit.product_id)}
        />
      )}
    </div>
  );
};

export default GroupPurchaseLayout;
