import { Doughnut } from "react-chartjs-2";
import React, { useState, useEffect } from "react";
import "chartjs-plugin-doughnut-innertext";



const BillingDonut = ({ title, data, subtitle }) => {
  console.log(data);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          boxWidth:8
          
        }
      },
     
    },
    centerText: {
      color: "#506883",
      value: subtitle,
      fontSizeAdjust: -0.6,

    },
  };
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  
  useEffect(() => {
    setChartData({
      label: title,
      labels: data.map((item) => item.name),
      datasets: [
        {
          data: data.map((item) => item.value),
          backgroundColor: [
            "rgba(212, 94, 117)",
            "rgba(94, 159, 117)",
            "rgba(134, 206, 190)",
            "rgba(95, 89, 134)",
            "rgba(235, 174, 71)",
            "rgba(77, 116, 168)",
            "rgba(94, 159, 117)",
          ],
          borderWidth: 1,
          cutout: '40%',

        },
      ],

    });
  }, [data]);

  return (
    <div>
      {/* <div className="text-center mb-2">
        <h3 className=" font-gray-500 text-xl font-bold text-center ">{100}</h3>
        <p className="text-sm font-medium">Orders</p> */}
      {/* </div> */}
        
      <Doughnut options={options} data={chartData} />
    </div>
  );
};

export default BillingDonut;
