import { createContext, useState } from "react";

const tabs = [
  { name: "eMenu Item" }, 
  { name: "Merchant" },
];

export const EMENUTabsContext = createContext();
export const EMENUCurrentTabContext = createContext();

export const EMENUContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <EMENUTabsContext.Provider value={{ tabs }}>
      <EMENUCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </EMENUCurrentTabContext.Provider>
    </EMENUTabsContext.Provider>
  );
};
