import axios from "axios";
import { format } from "date-fns";
import { Edit, Trash, Additem } from "iconsax-react";
import commonConfigs from "../../../../../config/config";
import Table from "../../../../../commons/components/table";
import React, { useState, useMemo, useEffect } from "react";
import Spinner from "../../../../../commons/components/spinner";
import UpdateMerchantCatModal from "../../components/updateMerchantCatModal";
import CreateMerchantCatModal from "../../components/createMerchantCatModal";
import DeleteModal from "../../../../../commons/components/deleteModal";

const MerchantCatLayout = () => {
  const [isOpen, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [rowToEdit, setRowToEdit] = useState();

  const getEMenuCatUrl = commonConfigs.apiUrls.CategoriesTags();

  useEffect(() => {
    async function getData() {
      console.log(getData);
      await axios
        .get(getEMenuCatUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data);
          setData(res.data.result.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (load) {
      getData().catch((err) => {
        console.log("err: " + err);
        console.log(load);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Category Name",
      accessor: "tag_cat_name",
      Cell: ({ row }) => {
        return (
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img src={row.original.tag_cat_img_url} />
            </div>
            <div className="ml-4">
              <div className="text-sm text-black">
                {row.original.tag_cat_name}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;
    
          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    }, 
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex justify-center">
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" />
            </button>
            {localStorage.getItem("roleid") == 2 || localStorage.getItem("roleid") == 5 ?
            <button
              type="button"
              className="m-1"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" />
            </button>
            : <></> }
          </div>
        );
      },
    },
  ]);

  return (
    <div >
      <div className=" flex flex-row-reverse">
        <button
          type="button"
          className="ml-3 inline-flex items-center px-4 py-2 text-md font-medium text-green-600 focus:outline-none hover:text-green-700"
          onClick={() => setOpen(true)}
        >
          Create Merchant Category <Additem className="ml-3" />
        </button>
        {isOpen && <CreateMerchantCatModal setOpenModal={setOpen} load={setLoad} />}
      </div>
      
      {data != null ? (
        <div className="mt-5">
          <Table
            columns={columns}
            data={data}
            filters={columns.map((column) => column.accessor)}
          />
        </div>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      {isUpdateModal && (
        <UpdateMerchantCatModal
          setOpenModal={setUpdateModal}
          load={setLoad}
          row={rowToEdit}
        />
      )}
      {isDeleteModal && (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          load={setLoad}
          url={commonConfigs.apiUrls.DeleteMerchantCategory(
            rowToEdit.tag_cat_id
          )}
        />
      )}
    </div>
  );
};

export default MerchantCatLayout;
