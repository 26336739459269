import { LocationsContextProvider } from "../context/locationsContext";
import LocationsLayout from "./layouts/locationsLayout";

const LocationsPage = () => {
  return (
    <LocationsContextProvider>
      <LocationsLayout />
    </LocationsContextProvider>
  );
};

export default LocationsPage;
