import {
    Box,
    Calendar,
    Calculator,
    Clipboard,
    Home,
    Setting2,
    Share,
    User,
} from "iconsax-react";

const navigation = [
    {
      title: "Dashboard",
      path: "/dashboard/revenue",
      icon: Home,
      current: true,
    },
    {
      title: "Accounts",
      path: "#0",
      icon: Calculator,
      current: true,
      subNav: [
        {
          title: "Transactions",
          path: "/dashboard/hierarchy/merchantsales",
        },
        {
          title: "Fees",
          path: "/dashboard/hierarchy/merchanttotalbilling",
        },
      ]
    },
    {
      title: "Calendar",
      path: "/schedule",
      icon: Calendar,
      current: true,
    },
    {
      title: "Manage Users",
      path: "#0",
      icon: User,
  
      subNav: [
        {
          title: "Members",
          path: "/users/members",
        },
        {
          title: "Employees",
          path: "/users/employees",
        },
      ],
    },
    {
      title: "Action Items",
      path: "#0",
      icon: Clipboard,
  
      subNav: [
        {
          title: "Incident Reports",
          path: "/action/incidentslocations",
        },
        {
          title: "Maintenances",
          path: "/action/maintenanceslocations",
        },
        {
          title: "User Feedback",
          path: "/action/feedback",
        },
      ],
    },
    {
      title: "Group Purchase Order",
      path: "#0",
      icon: Box,
  
      subNav: [
        {
          title: "Goods Requested",
          path: "/grouporder/goods",
        },
        {
          title: "Services Requested",
          path: "/grouporder/services",
        },
        // {
        //   title: "Food And Beverage Requested",
        //   path: "/grouporder/foodbeverage"
        // },
        // {
        //   title: "Retails Requested",
        //   path: "/grouporder/retails"
        // },
        {
          title: "Orders",
          path: "/grouporder/orders",
        },
      ],
    },
    {
      title: "Marketing",
      path: "#0",
      icon: Share,
  
      subNav: [
        {
          title: "Loyalty Programme & eVouchers",
          path: "/marketing/loyaltyprogramme",
        },
        {
          title: "Broadcasts",
          path: "/marketing/broadcasts",
        },
        {
          title: "Surveys",
          path: "/marketing/surveys",
        },
      ],
    },
    {
      title: "Operations",
      path: "#0",
      icon: Home,
      subNav: [
        {
          title: "Orders",
          path: "/operations/locations",
        },
      ],
    },
    {
      title: "Configurations",
      path: "#0",
      icon: Setting2,
      subNav: [
        {
          title: "Merchants and eMenu",
          path: "/configurations/merchantlocations",
        },
        {
          title: "Prize Wheel",
          path: "/configurations/prizewheelconfig",
        },
        {
          title: "Prizes",
          path: "/configurations/prizes",
        },
        {
          title: "Suppliers",
          path: "/configurations/suppliers",
        },
        {
          title: "Dishware",
          path: "/configurations/dishwarelocations",
        },
        {
          title: "Group Purchase Menu",
          path: "/configurations/groupmenu",
        },
        {
          title: "Locations",
          path: "/configurations/locations",
        },
        {
          title: "Companies",
          path: "/configurations/companies",
        },
        {
          title: "Navigation Page Access",
          path: "/configurations/access"
        },
        {
          title: "Access Roles",
          path: "/configurations/accessroles",
        },
        {
          title: "HPB H365",
          path: "/configurations/hpb",
        },
        {
          title: "eMenu Category",
          path: "/configurations/emenucat",
        },
        {
          title: "FAQ",
          path: "/configurations/faq",
        },
        {
          title: "Billing",
          path: "/configurations/billing",
        },
        {
          title: "Leave Entitlement",
          path: "/configurations/leave",
        },
        {
          title: "Payment Option",
          path: "/configurations/paymentoptionlocations"
        }
      ],
    },
  ];

  export default navigation;